import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import HolidayFormField from '@app/SettingsLists/Holidays/holidayFormField';

import { IHolidayFieldsProps, holidayInfo } from './holiday.interface';
import { holidayDefaultData, schema } from './holidays.const';
import { addHoliday } from './functions';

const HolidaysAddPopup = ({
    showPopup,
    handleClose,
    editData,
    getDataListCall,
    isDisabled,
    modelTitle,
    handleEditShow,
    showAlert,
}: any) => {
    const holidayId = editData?.holiday_nano_id;
    const dispatch = useDispatch<any>();

    const { holidaysData } = useSelector((state: any) => state);
    const { isAddHoliday, isUpdateHoliday, isGetHolidayInfo } = holidaysData;

    const [preview, setPreview] = useState<any[]>([]);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors, isDirty },
    } = useForm<IHolidayFieldsProps>({
        resolver: yupResolver(schema),
        defaultValues: holidayDefaultData,
    });

    useEffect(() => {
        reset(editData);
        const holidayDataInfo: holidayInfo = {
            holiday_nano_id: editData?.holiday_nano_id,
            type: editData?.category,
            date: editData?.date,
            name: editData?.name,
        };
        setValue(`holiday_info`, [holidayDataInfo]);
    }, [editData]);

    const HandleChange = async (data: any) => {
        const { payload } = await dispatch(addHoliday(data));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            handleClose();
            getDataListCall();
            reset(holidayDefaultData);
        } else {
            if (payload?.message !== '') {
                Swal.fire({
                    title: 'Error',
                    text: payload?.message || '',
                    icon: 'error',
                    cancelButtonColor: cancelButtonColor,
                });
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                centered
                className="mfnw_model_size z-999"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        {modelTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {isGetHolidayInfo ? (
                        <LoaderBtn />
                    ) : (
                        <>
                            <Form onSubmit={handleSubmit(HandleChange)}>
                                <HolidayFormField
                                    control={control}
                                    isDisabled={isDisabled}
                                    errors={errors}
                                    isAddHoliday={isAddHoliday}
                                    isUpdateHoliday={isUpdateHoliday}
                                    onClose={onClose}
                                    holidayId={holidayId}
                                    getValues={getValues}
                                    watch={watch}
                                    editData={editData}
                                    setValue={setValue}
                                    preview={preview}
                                    setPreview={setPreview}
                                    modelTitle={modelTitle}
                                />
                            </Form>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};
export default HolidaysAddPopup;
