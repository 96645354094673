import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form, Row, Col, Image, Button } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { faNetworkWired } from '@fortawesome/pro-light-svg-icons';
import Department from '@images/department.svg';

import { updateCurrentStep } from '@app/Auth/Login/loginSlice';

import { IStep3Props } from './step3.interface';
import { addDepartmentData } from './functions';

const DepartmentPopupStep3 = ({
    show,
    getData,
    handleClose,
    organizationId,
}: any) => {
    const dispatch = useDispatch<any>();

    const { departmentStep3Data } = useSelector((state: any) => state);
    const { departmentData, isAddDepartmentData } = departmentStep3Data;

    const schema = Yup.object({
        department_name: Yup.string().required(
            'Department Name is a required field',
        ),
    }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: departmentData,
        mode: 'onBlur',
    });

    const HandleChange = async (data: IStep3Props) => {
        const sendData = { ...data, nanoId: organizationId };
        const { payload } = await dispatch(addDepartmentData(sendData));
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            dispatch(updateCurrentStep(payload));
            getData();
            handleClose();
            setValue('department_name', '');
        } else {
            toast.error(payload?.message);
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    setValue('department_name', '');
                }
            });
        } else {
            handleClose();
            setValue('department_name', '');
        }
    };

    return (
        <Modal show={show} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Add New Department
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <Row className="justify-content-center">
                        <Col md={12} className="text-center">
                            <Image src={Department} alt="building icon" />
                        </Col>
                        <Col md={12} className="my-3">
                            <Controller
                                name={`department_name`}
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <>
                                        <InputGroupText
                                            onChangeFunc={onChange}
                                            value={value}
                                            id={`department_name`}
                                            inputName={`department_name`}
                                            inputType={'text'}
                                            placeholder={'Department'}
                                            label={null}
                                            tabIndex={1}
                                            inputGroupClassName="mfnw__input-group"
                                            icon={faNetworkWired}
                                            disabled={false}
                                            autoFocus={true}
                                            errorText={
                                                errors?.department_name?.message
                                            }
                                        />
                                    </>
                                )}
                            />
                        </Col>
                        <Col md={12}>
                            <Button
                                variant="primary w-100 mt-3 text-uppercase"
                                className="button__details"
                                type="submit"
                                tabIndex={2}
                                disabled={isAddDepartmentData}
                            >
                                {isAddDepartmentData ? <LoaderBtn /> : 'SAVE'}
                            </Button>
                            <Button
                                className="btn-cancel mt-4 w-100 text-uppercase fw-semibold"
                                onClick={() => onClose()}
                                tabIndex={3}
                            >
                                CANCEL
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default DepartmentPopupStep3;
