import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addRegionData,
    deleteRegion,
    getRegionDataList,
    getRegionInfo,
    addRegion,
    updateRegion,
    getCityStateInfo,
} from '@app/CreateOrganization/step6/functions';

const defaultState = {
    isGetRegionDataList: false,
    isGetCityStateInfo: false,
    isGetRegionInfo: false,
    isAddRegionData: false,
    isAddRegion: false,
    isUpdateRegion: false,
    isDeleteRegion: false,
    regionData: {
        region_name: '',
        region_type: 'State',
        region_state: [],
        region_city: '',
        is_active: 1,
    },
};

export const regionsStep6Slice: any = createSlice({
    name: 'regionsStep6Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addRegionData.pending.type]: (state: any) => {
            state.isAddRegionData = true;
        },
        [addRegionData.fulfilled.type]: (state: any) => {
            state.isAddRegionData = false;
        },
        [addRegionData.rejected.type]: (state: any) => {
            state.isAddRegionData = false;
        },
        [getCityStateInfo.pending.type]: (state: any) => {
            state.isGetCityStateInfo = true;
        },
        [getCityStateInfo.fulfilled.type]: (state: any) => {
            state.isGetCityStateInfo = false;
        },
        [getCityStateInfo.rejected.type]: (state: any) => {
            state.isGetCityStateInfo = false;
        },

        [getRegionDataList.pending.type]: (state: any) => {
            state.isGetRegionDataList = true;
        },
        [getRegionDataList.fulfilled.type]: (state: any) => {
            state.isGetRegionDataList = false;
            if (state?.isDeleteRegion) {
                state.isDeleteRegion = false;
            }
        },
        [getRegionDataList.rejected.type]: (state: any) => {
            state.isGetRegionDataList = false;
            if (state?.isDeleteRegion) {
                state.isDeleteRegion = false;
            }
        },

        [deleteRegion.pending.type]: (state: any) => {
            state.isDeleteRegion = true;
        },
        [deleteRegion.fulfilled.type]: (state: any) => {
            state.isDeleteRegion = true;
        },
        [deleteRegion.rejected.type]: (state: any) => {
            state.isDeleteRegion = false;
        },

        [getRegionInfo.pending.type]: (state: any) => {
            state.isGetRegionInfo = true;
        },
        [getRegionInfo.fulfilled.type]: (state: any) => {
            state.isGetRegionInfo = false;
        },
        [getRegionInfo.rejected.type]: (state: any) => {
            state.isGetRegionInfo = false;
        },

        [addRegion.pending.type]: (state: any) => {
            state.isAddRegion = true;
        },
        [addRegion.fulfilled.type]: (state: any) => {
            state.isAddRegion = false;
        },
        [addRegion.rejected.type]: (state: any) => {
            state.isAddRegion = false;
        },

        [updateRegion.pending.type]: (state: any, { payload }) => {
            state.isUpdateRegion = true;
            state.registerResponse = payload;
        },
        [updateRegion.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateRegion = false;
            state.registerResponse = payload;
        },
        [updateRegion.rejected.type]: (state: any, { payload }) => {
            state.isUpdateRegion = false;
            state.registerResponse = payload;
        },
    },
});

export const { saveLoggedInUser, loginChange } = regionsStep6Slice.actions;

export default regionsStep6Slice.reducer;
