import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Badge, Image, Dropdown } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactPagination from '@components/reactPagination';

import { routesPath } from '@constants/routesNavigate';

import {
    faCircleSmall,
    faEllipsisVertical,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopLogo from '@images/top-logo.png';

const OrganizationTable = ({
    currentPageData,
    userOrganizationArray,
    changeOrganization,
    isGetUserOrganizationList,
    resMessage,
    pageCount,
    handlePageClick,
    filters,
}: any) => (
    <>
        <>
            {currentPageData && currentPageData.length !== 0 ? (
                currentPageData.map((item: any, index: number) => (
                    <Row key={index}>
                        <Col md={12} className="mt-4">
                            <div className="bg-white rounded-4 p-4">
                                <Row>
                                    <Col md={6}>
                                        <div className="mfnw__left_content ms-4 ms-lg-0">
                                            <div className="mfnw__circle-logo">
                                                {item?.organization_logo ? (
                                                    <Image
                                                        className="rounded-circle"
                                                        height="100px"
                                                        width="100px"
                                                        src={
                                                            item?.organization_logo
                                                        }
                                                    />
                                                ) : (
                                                    <Image src={TopLogo} />
                                                )}
                                            </div>
                                            <div className="mfnw__right-content">
                                                <h4 className="cp_name fs-6 text-capitalize">
                                                    {item?.organization_name}
                                                </h4>
                                                <p className="address-text mt-1">
                                                    {`${item?.organization_address}, ${item?.organization_city_name}, ${item?.organization_state_name}, ${item?.organization_zip_code}`}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-flex align-items-center justify-content-between h-100">
                                            <span className="orgInfo">
                                                {item?.organization_email}
                                            </span>
                                            <span className="orgInfo">
                                                {item?.organization_phone}
                                            </span>
                                            {userOrganizationArray?.organization_nano_id ===
                                            item?.organization_nano_id ? (
                                                <div className="d-flex align-items-center">
                                                    <Badge className="badge bg-success align-text-bottom ms-2 px-3 py-2 rounded-pill">
                                                        <FontAwesomeIcon
                                                            icon={faCircleSmall}
                                                        />
                                                        <span className="ms-2">
                                                            Default
                                                        </span>
                                                    </Badge>
                                                </div>
                                            ) : (
                                                <div
                                                    className="d-flex align-items-center"
                                                    onClick={() =>
                                                        changeOrganization(
                                                            item?.organization_nano_id,
                                                        )
                                                    }
                                                >
                                                    <Badge className="badge align-text-bottom ms-2 px-3 py-2 rounded-pill">
                                                        <span className="me-2">
                                                            Activate
                                                        </span>
                                                        <FontAwesomeIcon
                                                            icon={faCircleSmall}
                                                        />
                                                    </Badge>
                                                </div>
                                            )}
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn-header">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faEllipsisVertical
                                                        }
                                                        color="#b7b7b7"
                                                        className="ms-2"
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="mt-2">
                                                    <Link
                                                        to={
                                                            routesPath?.updateOrganizations +
                                                            item?.organization_nano_id
                                                        }
                                                        className="text-dark"
                                                    >
                                                        <Dropdown.Item as="button">
                                                            Edit
                                                        </Dropdown.Item>
                                                    </Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                ))
            ) : (
                <tr>
                    {isGetUserOrganizationList ? (
                        <span className="ms-5">
                            <LoaderBtn size="sm" />
                        </span>
                    ) : (
                        <td>
                            <h3>{resMessage}</h3>
                        </td>
                    )}
                </tr>
            )}
        </>
        <div className="mt-4">
            <ReactPagination
                currentPageData={currentPageData}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                filters={filters}
            />
        </div>
    </>
);

export default OrganizationTable;
