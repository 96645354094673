import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addAgent,
    deleteAgent,
    agentList,
    getAgentInfo,
    updateAgent,
} from './agent.function';

const defaultState = {
    isAddAgent: false,
    isAgentList: false,
    isUpdateAgent: false,
    isGetAgentInfo: false,
    isDeleteAgent: false,
};

export const agentSlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addAgent.pending.type]: (state: any, { payload }) => {
            state.isAddAgent = true;
            state.registerResponse = payload;
        },
        [addAgent.fulfilled.type]: (state: any, { payload }) => {
            state.isAddAgent = false;
            state.registerResponse = payload;
        },
        [addAgent.rejected.type]: (state: any, { payload }) => {
            state.isAddAgent = false;
            state.registerResponse = payload;
        },

        [agentList.pending.type]: (state: any, { payload }) => {
            state.isAgentList = true;
            state.registerResponse = payload;
        },
        [agentList.fulfilled.type]: (state: any, { payload }) => {
            state.isAgentList = false;
            state.registerResponse = payload;
        },
        [agentList.rejected.type]: (state: any, { payload }) => {
            state.isAgentList = false;
            state.registerResponse = payload;
        },

        [updateAgent.pending.type]: (state: any, { payload }) => {
            state.isUpdateAgent = true;
            state.registerResponse = payload;
        },
        [updateAgent.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateAgent = false;
            state.registerResponse = payload;
        },
        [updateAgent.rejected.type]: (state: any, { payload }) => {
            state.isUpdateAgent = false;
            state.registerResponse = payload;
        },

        [getAgentInfo.pending.type]: (state: any, { payload }) => {
            state.isGetAgentInfo = true;
            state.registerResponse = payload;
        },
        [getAgentInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetAgentInfo = false;
            state.registerResponse = payload;
        },
        [getAgentInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetAgentInfo = false;
            state.registerResponse = payload;
        },

        [deleteAgent.pending.type]: (state: any, { payload }) => {
            state.isDeleteAgent = true;
            state.registerResponse = payload;
        },
        [deleteAgent.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteAgent = false;
            state.registerResponse = payload;
        },
        [deleteAgent.rejected.type]: (state: any, { payload }) => {
            state.isDeleteAgent = false;
            state.registerResponse = payload;
        },
    },
});

export { addAgent, agentList, updateAgent, getAgentInfo, deleteAgent };

export default agentSlice.reducer;
