/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { searchTimeOut, FiltersData } from '@constants/general.const';

import OfferNotFound from '@images/offer-not-found.svg';

import ListViewOffer from '@app/OffersList/ListViewOffer';
import GridViewOffer from '@app/OffersList/GridViewOffer';
import CompactViewOffer from '@app/OffersList/compactViewOffer';
import OfferRejectPopup from '@app/OffersList/offerRejectPopup';
import AddOfferPopup from '@app/OffersList/addOfferPopup';
import OfferHeaderFilters from '@app/OffersList/OfferHeaderFilters';
import { clearResponse } from '@redux/app/appSlice';
import { setSearchText } from '@redux/app/appSlice';
import { getElapsedTime } from '@utils/utilFunctions';

import { useOfferPopup } from '../../constants/offer.const';
import './offers.scss';

const OffersList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const [acceptViewPopup, setAcceptViewPopup] = useState<boolean>(false);
    const { offersData, common } = useSelector((state: any) => state);
    const { isOfferList, isRejectOffer } = offersData;
    const { searchText } = common;

    const {
        offerDataObj,
        offerId,
        showAddPopup,
        showEditPopup,
        resMessage,
        currentPageData,
        totalDataCount,
        pageCount,
        temp,
        setOfferId,
        popup,
        setPopup,
        getOfferData,
        setOfferDataObj,
        showViewPopup,
        filters,
        setFilters,
        setTemp,
        handleEditShow,
        handleEditClose,
        setShowViewPopup,
        handleViewShow,
        handleViewClose,
        handleAcceptActions,
        handleSortChange,
        getOfferList,
        handleLimitChange,
        handleOrderChange,
        handleGrid,
        handleCompactView,
        handlePageClick,
        handleAddShow,
        handleAddClose,
        renderAddOfferPopup,
    } = useOfferPopup();

    const handleAcceptViewClose = () => {
        setAcceptViewPopup(false);
        setOfferDataObj(null);
        setOfferId(null);
    };

    const handleAcceptViewShow = (offerIdForView: string) => {
        setAcceptViewPopup(true);
        getOfferData(offerIdForView);
        setOfferId(offerIdForView);
    };

    const handleClose = () => {
        setPopup(false);
        showViewPopup && setShowViewPopup(false);
    };

    useEffect(() => {
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        let getData: NodeJS.Timeout | undefined;

        if (!temp) {
            getData = setTimeout(() => {
                getOfferList(1, filters);
            }, searchTimeOut);
        } else {
            setTemp(false);
        }

        return () => {
            if (!temp && getData) {
                clearTimeout(getData);
            }
        };
    }, [searchText]);

    useEffect(
        () => () => {
            updateSearchText('');
        },
        [],
    );

    const updateSearchText = async (text: any) => {
        await dispatch(setSearchText(text));
    };

    const getFilters = async () => {
        FiltersData(
            searchText,
            getOfferList,
            setFilters,
            'offer_list',
            dispatch,
            navigate,
        );
    };

    return (
        <div className="mfpro-info mfnw__joblisting_content">
            {filters && (
                <OfferHeaderFilters
                    totalDataCount={totalDataCount}
                    filters={filters}
                    handleLimitChange={handleLimitChange}
                    handleSortChange={handleSortChange}
                    handleRecentActivityChange={null}
                    handleOrderChange={handleOrderChange}
                    handleGrid={handleGrid}
                    handleCompactView={handleCompactView}
                    handleAddShow={handleAddShow}
                    titleText={'Offer List'}
                    btnText={'Add New Offer'}
                />
            )}
            <Row className="mx-lg-2">
                <Col>
                    {isOfferList ? (
                        <div className="text-center mx-4">
                            <span className="ms-5 text-center">
                                <LoaderBtn size="sm" />
                            </span>
                        </div>
                    ) : (
                        <>
                            {filters?.compact ? (
                                <CompactViewOffer
                                    currentPageData={currentPageData}
                                    resMessage={resMessage}
                                    getElapsedTime={getElapsedTime}
                                    handleViewShow={handleViewShow}
                                    handleAcceptActions={handleAcceptActions}
                                    handleAcceptViewShow={handleAcceptViewShow}
                                    isRejectOffer={isRejectOffer}
                                />
                            ) : (
                                ''
                            )}
                            {filters?.grid ? (
                                <div className="d-flex flex-column">
                                    <Row>
                                        {currentPageData &&
                                        currentPageData.length > 0 ? (
                                            currentPageData.map(
                                                (offer: any, index: number) => (
                                                    <GridViewOffer
                                                        key={index}
                                                        offer={offer}
                                                        getElapsedTime={
                                                            getElapsedTime
                                                        }
                                                        handleAcceptActions={
                                                            handleAcceptActions
                                                        }
                                                        handleAcceptViewShow={
                                                            handleAcceptViewShow
                                                        }
                                                        handleViewShow={
                                                            handleViewShow
                                                        }
                                                        isRejectOffer={
                                                            isRejectOffer
                                                        }
                                                    />
                                                ),
                                            )
                                        ) : (
                                            <NotFoundPages
                                                currentPageData={
                                                    currentPageData
                                                }
                                                image={OfferNotFound}
                                                message={`You
                                                    haven&apos;
                                                    received any
                                                    offers yet.
                                                    <br />
                                                    Don&apos;t
                                                    give up,
                                                    keep
                                                    searching
                                                    and
                                                    you&apos;ll
                                                    eventually
                                                    find them.`}
                                            />
                                        )}
                                    </Row>
                                </div>
                            ) : (
                                <>
                                    {!filters?.compact && (
                                        <div className="mfpro-info my-2 my-lg-4">
                                            <>
                                                {currentPageData &&
                                                currentPageData.length > 0 ? (
                                                    currentPageData.map(
                                                        (
                                                            offer: any,
                                                            index: number,
                                                        ) => (
                                                            <ListViewOffer
                                                                handleViewShow={
                                                                    handleViewShow
                                                                }
                                                                key={index}
                                                                offer={offer}
                                                                getElapsedTime={
                                                                    getElapsedTime
                                                                }
                                                                handleAcceptActions={
                                                                    handleAcceptActions
                                                                }
                                                                handleAcceptViewShow={
                                                                    handleAcceptViewShow
                                                                }
                                                                isRejectOffer={
                                                                    isRejectOffer
                                                                }
                                                            />
                                                        ),
                                                    )
                                                ) : (
                                                    <NotFoundPages
                                                        currentPageData={
                                                            currentPageData
                                                        }
                                                        image={OfferNotFound}
                                                        message={`You
                                                                haven&apos;
                                                                received any
                                                                offers yet.
                                                                <br />
                                                                Don&apos;t
                                                                give up,
                                                                keep
                                                                searching
                                                                and
                                                                you&apos;ll
                                                                eventually
                                                                find them.`}
                                                    />
                                                )}
                                            </>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <Row>
                        <Col className="px-5 mt-3">
                            {currentPageData && currentPageData.length > 0 && (
                                <ReactPaginate
                                    previousLabel={'← Previous'}
                                    nextLabel={'Next →'}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    previousLinkClassName={'pagination__link'}
                                    nextLinkClassName={'pagination__link'}
                                    disabledClassName={
                                        'pagination__link--disabled'
                                    }
                                    activeClassName={
                                        'pagination__link--active active'
                                    }
                                    pageRangeDisplayed={2}
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-link rounded-start"
                                    nextClassName="page-link rounded-end"
                                    forcePage={filters?.currentPage - 1}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {showAddPopup &&
                renderAddOfferPopup(
                    showAddPopup,
                    handleAddClose,
                    'Add Offer',
                    false,
                    () => getOfferList(1, filters),
                )}

            {showViewPopup && (
                <AddOfferPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    handleEditShow={handleEditShow}
                    editData={offerDataObj}
                    isDisabled={true}
                    isJobForm={false}
                    isAcceptable={false}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    listCall={() => getOfferList(1, filters)}
                    handleAcceptActions={handleAcceptActions}
                    handleAcceptViewShow={handleAcceptViewShow}
                    isRejectOffer={isRejectOffer}
                    title={'View Offer'}
                />
            )}

            {acceptViewPopup && (
                <AddOfferPopup
                    showPopup={acceptViewPopup}
                    handleClose={handleAcceptViewClose}
                    handleEditShow={handleEditShow}
                    editData={offerDataObj}
                    isDisabled={true}
                    isAcceptable={true}
                    isJobForm={false}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    listCall={() => getOfferList(1, filters)}
                    title={'Accept Offer'}
                />
            )}
            {showEditPopup && (
                <AddOfferPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    editData={offerDataObj}
                    isDisabled={false}
                    isJobForm={false}
                    isAcceptable={false}
                    listCall={() => getOfferList(1, filters)}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    title={'Edit Offer'}
                />
            )}

            <OfferRejectPopup
                handleClose={handleClose}
                popup={popup}
                offerId={offerId}
            />
        </div>
    );
};

export default OffersList;
