import React from 'react';
import { Badge, Col, Dropdown, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
    orderItemArray,
    pageLimitList,
    recentActivity,
    filtetItemArray,
} from '@constants/general.const';

import { faListUl } from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToLine, faGrid2 } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OfferHeaderFilters = ({
    totalDataCount,
    filters,
    handleLimitChange,
    handleSortChange,
    handleRecentActivityChange,
    handleFilterChange,
    handleOrderChange,
    handleGrid,
    handleCompactView,
    handleAddShow,
    titleText,
    btnText,
}: any) => (
    <Row className="mb-3 mb-md-4 justify-content-center justify-content-lg-start">
        <Col
            xs={12}
            lg={2}
            className="d-flex justify-content-center justify-content-lg-start"
        >
            <div className="title_common_main d-flex">
                <h4 className="fw-semibold m-0">{titleText}</h4>
                <Badge bg="secondary" className="d-flex align-items-center">
                    {totalDataCount}
                </Badge>
            </div>
        </Col>
        <Col xs={12} sm={11} md={8} lg={10} className="mt-3 mt-lg-0">
            <div className="left-side-title d-flex flex-wrap gap-2 justify-content-center justify-content-xl-end gap-sm-3 gap-lg-2">
                <div className="dropdown_filter-top">
                    <label
                        className={`text-sm text-light ${
                            handleFilterChange ? 'lable_set_padding' : 'ps-3 '
                        }`}
                        htmlFor="dropdown-basic-limit"
                    >
                        Limit:
                    </label>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="limit"
                        >
                            {filters?.perPageLimit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {pageLimitList?.map((item: any, index: number) => (
                                <Dropdown.Item
                                    active={filters?.perPageLimit === item}
                                    onClick={() => {
                                        handleLimitChange(item);
                                    }}
                                    key={index}
                                    eventKey={`#/action-${index}`}
                                >
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {handleFilterChange && (
                    <div className="dropdown_filter-top">
                        <label
                            className={`text-sm text-light ${
                                handleFilterChange
                                    ? 'lable_set_padding'
                                    : 'ps-3 '
                            }`}
                            htmlFor="dropdown-basic-limit"
                        >
                            Filter by:
                        </label>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                className="limit"
                            >
                                {filters?.filterText}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {filtetItemArray?.map(
                                    (item: any, index: number) => {
                                        const { name, displayName } = item;
                                        return (
                                            <Dropdown.Item
                                                active={
                                                    filters?.filterValue ===
                                                    name
                                                }
                                                key={index}
                                                onClick={() => {
                                                    handleFilterChange(
                                                        name,
                                                        displayName,
                                                    );
                                                }}
                                                eventKey={`#/action-${index}`}
                                            >
                                                {displayName}
                                            </Dropdown.Item>
                                        );
                                    },
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                <div className="dropdown_filter-top">
                    <label
                        className={`text-sm text-light ${
                            handleFilterChange ? 'lable_set_padding' : 'ps-3 '
                        }`}
                        htmlFor="dropdown-basic-sort"
                    >
                        Sort by:
                    </label>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="sort-by text-capitalize"
                        >
                            {filters?.sortText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {orderItemArray?.map((item: any, index: number) => {
                                const { name, displayName } = item;
                                if (name === 'offerStatus') {
                                    return null;
                                }
                                return (
                                    <Dropdown.Item
                                        active={filters?.sortValue === name}
                                        key={index}
                                        onClick={() => {
                                            handleSortChange(name, displayName);
                                        }}
                                        eventKey={`#/action-${index}`}
                                    >
                                        {displayName}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {handleRecentActivityChange && (
                    <div className="dropdown_filter-top">
                        <label
                            className={`text-sm text-light ${
                                handleFilterChange
                                    ? 'lable_set_padding'
                                    : 'ps-3 '
                            }`}
                            htmlFor="dropdown-basic-sort"
                        >
                            Recent Activity:
                        </label>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                className="sort-by text-capitalize"
                            >
                                {filters?.recentActivityText}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {recentActivity?.map(
                                    (item: any, index: number) => {
                                        const { name, displayName } = item;
                                        if (name === 'offerStatus') {
                                            return null;
                                        }
                                        return (
                                            <Dropdown.Item
                                                active={
                                                    filters?.recentActivityValue ===
                                                    name
                                                }
                                                key={index}
                                                onClick={() => {
                                                    handleRecentActivityChange(
                                                        name,
                                                        displayName,
                                                    );
                                                }}
                                                eventKey={`#/action-${index}`}
                                            >
                                                {displayName}
                                            </Dropdown.Item>
                                        );
                                    },
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                <div className="dropdown_filter-top">
                    <label
                        className={`text-sm text-light ${
                            handleFilterChange ? 'lable_set_padding' : 'ps-3 '
                        }`}
                        htmlFor="dropdown-basic-order"
                    >
                        Order:
                    </label>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="order"
                        >
                            {filters?.orderValue}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                active={filters?.orderValue === 'ASC'}
                                onClick={() => {
                                    handleOrderChange('ASC');
                                }}
                                eventKey="#/action-1"
                            >
                                ASC
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={filters?.orderValue === 'DESC'}
                                onClick={() => {
                                    handleOrderChange('DESC');
                                }}
                                eventKey="#/action-2"
                            >
                                DESC
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="d-flex align-items-center">
                    <Link
                        onClick={() => {
                            handleGrid(false);
                        }}
                        to=""
                        className={`bg-white rounded-3 mx-1 p-2 px-3 ${
                            !filters?.compact && !filters?.grid
                                ? 'text-primary'
                                : 'text-light'
                        } fs-5`}
                    >
                        <FontAwesomeIcon icon={faListUl} />
                    </Link>
                    <Link
                        onClick={() => {
                            handleGrid(true);
                        }}
                        to=""
                        className={`bg-white rounded-3 mx-1 p-2 px-3 ${
                            filters?.grid ? 'text-primary' : 'text-light'
                        } fs-5`}
                    >
                        <FontAwesomeIcon icon={faGrid2} />
                    </Link>
                    <Link
                        onClick={() => handleCompactView(true)}
                        to=""
                        className={`bg-white rounded-3 mx-1 p-2 px-3 ${
                            filters?.compact ? 'text-primary' : 'text-light'
                        } fs-5`}
                    >
                        <FontAwesomeIcon icon={faArrowsToLine} />
                    </Link>
                </div>
                <div className="buttonm">
                    <Button
                        variant="primary"
                        className={`btn_type_large fw-semibold ${
                            handleFilterChange ? 'offer_btn_set_min_size' : ''
                        }`}
                        onClick={() => handleAddShow()}
                    >
                        {btnText}
                    </Button>
                </div>
            </div>
        </Col>
    </Row>
);

export default OfferHeaderFilters;
