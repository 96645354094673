import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { routesPath } from '@constants/routesNavigate';
import {
    pageLimitList,
    searchTimeOut,
    showDeleteConfirmationDialog,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import DriverPopup from '@app/SettingsLists/Drivers/driverPopup';
import DriverViewPopup from '@app/SettingsLists/Drivers/driverViewPopup';
import DriverTable from '@app/SettingsLists/Drivers/driverTable';
import {
    deleteDriver,
    driversList,
    getDriverInfo,
} from '@app/SettingsLists/Drivers/functions';
import { confimationDialogBoxText } from '@app/SettingsLists/lists.const';
import ListHeader from '@app/SettingsLists/listHeader';
import { clearResponse } from '@redux/app/appSlice';

const DriversList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common } = useSelector((state: any) => state);
    const { response } = common;

    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [pageCount, setPageCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [driversData, setDriversData] = useState<any>(null);
    const [filters, setFilters] = useState<any>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);
    const handleViewClose = () => setShowViewPopup(false);
    const handleEditClose = () => setShowEditPopup(false);

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getDriversList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getDriverData = async (driverId: string) => {
        const { payload } = await dispatch(getDriverInfo(driverId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setDriversData(payload?.data?.data);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getDriversList = async (currentPage: number = 1, res: any = {}) => {
        const { payload } = await dispatch(
            driversList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
            }),
        );

        if (payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getFilters = async () => {
        FiltersData(
            searchText,
            getDriversList,
            setFilters,
            'driver_list',
            dispatch,
            navigate,
        );
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        setFilters(updateFilter);
        getDriversList(updateFilter?.currentPage, updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        setFilters(updateFilter);
        getDriversList(updateFilter?.currentPage, updateFilter);
    };

    const handleViewShow = (nanoId: string) => {
        setShowViewPopup(true);
        getDriverData(nanoId);
    };

    const handleEditShow = (nanoId: string) => {
        nanoId && getDriverData(nanoId);
        setShowEditPopup(true);
        setShowViewPopup(false);
    };

    const showAlert = async (id: any, type: string) => {
        const confimaBoxText = confimationDialogBoxText(type);

        const confirmationOptions = {
            title: confimaBoxText?.swalTitle,
            text: confimaBoxText?.swalText,
            icon: 'warning',
            confirmButtonText: confimaBoxText?.confirmButtonText,
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(
                deleteDriver({ id, type: type }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                getDriversList(1, filters);
                handleViewClose();
            } else {
                toast.error(payload?.message);
            }
        }
    };

    return (
        <div>
            <ListHeader
                title={'Driver List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShow}
                handleLimitChange={handleLimitChange}
                buttonText={'Add New Driver'}
            />
            <DriverTable
                currentPageData={currentPageData}
                handleViewShow={handleViewShow}
                handleEditShow={handleEditShow}
                showAlert={showAlert}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                offerFilters={filters}
            />
            {showPopup && (
                <DriverPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getDriversList(1, getFilters)}
                    editData={null}
                />
            )}

            {showEditPopup && (
                <DriverPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getDriversList(1, getFilters)}
                    editData={driversData}
                />
            )}
            {showViewPopup && (
                <DriverViewPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    data={driversData}
                    actionUserById={showAlert}
                    handleEditShow={handleEditShow}
                />
            )}
        </div>
    );
};

export default DriversList;
