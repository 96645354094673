import { routesPath } from '@constants/routesNavigate';

import { IStepBarProps } from './stepBar.interface';

export const stepBarList: IStepBarProps[] = [
    {
        isCompleted: false,
        step: 1,
        label: 'Step 1',
        route: routesPath?.step1,
    },
    {
        isCompleted: false,
        step: 2,
        label: 'Step 2',
        route: routesPath?.step2,
    },
    {
        isCompleted: false,
        step: 3,
        label: 'Step 3',
        route: routesPath?.step3,
    },
    {
        isCompleted: false,
        step: 4,
        label: 'Step 4',
        route: routesPath?.step4,
    },
    {
        isCompleted: false,
        step: 5,
        label: 'Step 5',
        route: routesPath?.step5,
    },
    {
        isCompleted: false,
        step: 6,
        label: 'Step 6',
        route: routesPath?.step6,
    },
];
