import { cloneDeep } from 'lodash';

import { usersList } from '@app/UserListing/Users/functions';

import { createSlice } from '@reduxjs/toolkit';

import { registerUser } from './functions';

const defaultState = {
    isUsersList: false,
    isRegisteringUser: false,
    registerResponse: '',
    email: '',
};

export const registerSlice: any = createSlice({
    name: 'register',
    initialState: cloneDeep(defaultState),
    reducers: {
        registerChange: (state, { payload }) => {
            state.email = payload?.email;
            return state;
        },
    },
    extraReducers: {
        [registerUser.pending.type]: (state: any, { payload }) => {
            state.isRegisteringUser = true;
            state.registerResponse = payload;
        },
        [registerUser.fulfilled.type]: (state: any, { payload }) => {
            state.isRegisteringUser = false;
            state.registerResponse = payload;
        },
        [registerUser.rejected.type]: (state: any, { payload }) => {
            state.isRegisteringUser = false;
            state.registerResponse = payload;
        },

        [usersList.pending.type]: (state: any, { payload }) => {
            state.isUsersList = true;
            state.registerResponse = payload;
        },
        [usersList.fulfilled.type]: (state: any, { payload }) => {
            state.isUsersList = false;
            state.registerResponse = payload;
        },
        [usersList.rejected.type]: (state: any, { payload }) => {
            state.isUsersList = false;
            state.registerResponse = payload;
        },
    },
});

export const { registerChange, extraReducers } = registerSlice.actions;
export { registerUser, usersList };
export default registerSlice.reducer;
