import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import TspListPage from '@app/SettingsLists/TSP/tspTabList';
import AgentList from '@app/SettingsLists/Agents';
import DriversList from '@app/SettingsLists/Drivers';
import PackersList from '@app/SettingsLists/Packers';
import ImportExceptions from '@app/SettingsLists/ImportExceptions';

import '@app/SettingsLists/listing.scss';
import HolidayList from './Holidays';

const UserTypeListing = () => {
    const [selectedTab, setSelectedTab] = useState<string>('tsp01');

    return (
        <div className="mfnw__dashboard-content setting-list">
            <Tabs
                defaultActiveKey={selectedTab}
                onSelect={(e: any) => setSelectedTab(e)}
                className="mfnw__dashboard_linetab mb-3 flex-wrap"
            >
                <Tab eventKey="tsp01" title="TSP" className="mt-2">
                    {selectedTab === 'tsp01' && <TspListPage />}
                </Tab>
                <Tab eventKey="agent02" title="AGENTS">
                    {selectedTab === 'agent02' && <AgentList />}
                </Tab>
                <Tab eventKey="driver03" title="DRIVERS">
                    {selectedTab === 'driver03' && <DriversList />}
                </Tab>
                <Tab eventKey="packers04" title="PACKERS">
                    {selectedTab === 'packers04' && <PackersList />}
                </Tab>
                <Tab eventKey="pay056" title="PAY PERIOD" disabled></Tab>
                <Tab eventKey="exceptions05" title="EMAIL EXCEPTIONS">
                    {selectedTab === 'exceptions05' && <ImportExceptions />}
                </Tab>
                <Tab eventKey="holidays06" title="HOLIDAYS">
                    {selectedTab === 'holidays06' && <HolidayList />}
                </Tab>
            </Tabs>
        </div>
    );
};
export default UserTypeListing;
