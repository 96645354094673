import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddJobTitleData,
    apiDeleteJobTitle,
    apiGetJobTitleDataList,
} from '@services/API/apiFunctions';

const getJobTitleDataList = createAsyncThunk(
    'getJobTitleDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetJobTitleDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addJobTitleData = createAsyncThunk(
    'addJobTitleData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddJobTitleData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteJobTitle = createAsyncThunk(
    'deleteJobTitle',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteJobTitle(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { getJobTitleDataList, addJobTitleData, deleteJobTitle };
