import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';

import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { dateEndTimeFormat } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faNote } from '@fortawesome/pro-light-svg-icons';
import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';
import JobNotFound from '@images/job-not-found.svg';

import AddressDiv from '@app/OffersList/offerContentComponent/addressDiv';
import InfoDiv from '@app/OffersList/offerContentComponent/infoDiv';
import StatusButtons from '@app/OffersList/offerContentComponent/StatusButtons';
import RegionsDiv from '@app/OffersList/offerContentComponent/regionsDiv';
import MainInfoDiv from '@app/OffersList/offerContentComponent/MainInfoDiv';
import { setTimeZone, dateTimeZone } from '@utils/utilFunctions';

import '@app/JobList/jobList.scss';

const JobListing = ({ currentPageData, handleViewShow }: any) => (
    <div className="d-flex flex-column mfpro-info">
        <Row>
            {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((job: any, index: number) => (
                    <Col xl={6} lg={6} key={index}>
                        <Card
                            className="position-relative border-0 p-4 rounded-4 mt-4 max-card-height"
                            onClick={() => handleViewShow(job?.offers_nano_id)}
                        >
                            <Row>
                                <Col xs={12} sm={6}>
                                    <MainInfoDiv
                                        name={'SHIPPER'}
                                        value={
                                            job?.shipperdata?.shipper_name ??
                                            job?.shipper
                                        }
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <MainInfoDiv
                                        name={'OFFERER'}
                                        value={job?.forwarder_data?.name}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} sm={6}>
                                    <InfoDiv
                                        name="RECEIVED"
                                        value={` Received on${' '}
                                            ${setTimeZone(
                                                job?.offeredOn,
                                                dateEndTimeFormat,
                                            )}`}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <InfoDiv
                                        name="ACCEPTED"
                                        value={dateTimeZone(job?.statusDate)}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-start">
                                <Col xs={12} sm={6}>
                                    <AddressDiv
                                        icon={OriginIcon}
                                        offerAddress={job?.address_origin}
                                        addressName="ORIGIN"
                                        color="text-primary"
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <AddressDiv
                                        icon={Destination}
                                        offerAddress={job?.address_destination}
                                        addressName="DESTINATION"
                                        color="text-lightpink"
                                    />
                                </Col>
                            </Row>
                            <div className="mb-1 mb-sm-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="1"
                                    viewBox="0 0 1620 1"
                                    fill="none"
                                >
                                    <path
                                        d="M0 1H1620"
                                        stroke="#898989"
                                        strokeWidth="0.8"
                                        strokeDasharray="8 8"
                                    />
                                </svg>
                            </div>
                            <Row>
                                <Col xs={6} sm={4} md={3}>
                                    <InfoDiv
                                        name="WEIGHT"
                                        value={job?.estimatedWeight}
                                    />
                                </Col>
                                <Col xs={6} sm={4} md={3}>
                                    <>
                                        {job?.type === 'COD' ? (
                                            <InfoDiv
                                                name="B/L"
                                                value={
                                                    job?.forwarderBillOfLaden !==
                                                    ''
                                                        ? job?.forwarderBillOfLaden
                                                        : '-'
                                                }
                                            />
                                        ) : (
                                            <InfoDiv
                                                name="GBL #"
                                                value={
                                                    job?.govBillOfLaden !== ''
                                                        ? job?.govBillOfLaden
                                                        : '-'
                                                }
                                            />
                                        )}
                                    </>
                                </Col>
                                <Col xs={6} sm={4} md={2}>
                                    <InfoDiv
                                        name="LINE HAUL"
                                        value={`${job?.line_haule_data?.miles} miles`}
                                    />
                                </Col>
                                <Col xs={6} sm={4} md={2}>
                                    <RegionsDiv regions={job?.origin_regions} />
                                </Col>
                                <Col xs={6} sm={4} md={2}>
                                    <RegionsDiv
                                        regions={job?.destination_regions}
                                        type="dest."
                                    />
                                </Col>
                                <Col xs={6} sm={4} md={3}>
                                    <InfoDiv
                                        name="PACK"
                                        value={dateTimeZone(job?.packDate)}
                                    />
                                </Col>

                                <Col xs={6} sm={4} md={3}>
                                    <InfoDiv
                                        name="PICKUP"
                                        value={dateTimeZone(job?.pickupDate)}
                                    />
                                </Col>
                                <Col xs={6} sm={4} md={3}>
                                    <InfoDiv
                                        name="RDD"
                                        value={dateTimeZone(job?.deliveryDate)}
                                    />
                                </Col>
                                <Col
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    className="d-flex align-items-center"
                                >
                                    <StatusButtons status={job?.offerStatus} />
                                </Col>
                            </Row>
                            <div className="position-absolute top-0 end-0 d-flex">
                                {job?.offer_notes.length > 0 && (
                                    <div
                                        className="position-relative cursor-pointer mx-3 mt-1 ps-2 pt-2"
                                        title="Notes"
                                    >
                                        <FontAwesomeIcon
                                            icon={faNote}
                                            size="2xl"
                                            className="form-control-serch text-primary m-auto text-light  align-self-center"
                                        />
                                        <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                            {(job?.offer_notes).length}
                                        </Badge>
                                    </div>
                                )}
                                {job?.offer_documents.length > 0 && (
                                    <div
                                        className="position-relative cursor-pointer mx-3 mt-1 ps-2 pt-2"
                                        title="Documents"
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileLines}
                                            size="2xl"
                                            className="form-control-serch text-primary m-auto text-light  align-self-center"
                                        />

                                        <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                            {(job?.offer_documents).length}
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col>
                ))
            ) : (
                <>
                    <NotFoundPages
                        currentPageData={currentPageData}
                        image={JobNotFound}
                        message={`Exciting opportunities are on the horizon and will be available soon.`}
                    />
                </>
            )}
        </Row>
    </div>
);
export default JobListing;
