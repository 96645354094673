/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Modal, Badge, Button, Form } from 'react-bootstrap';

import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';

import {
    cancelButtonColor,
    confirmButtonColor,
    contactPhoneNoTypes2,
    phoneFormat,
} from '@constants/general.const';
import {
    IContactInfoProps,
    IContactPhoneObj,
    IShipperField,
    shipperSchema,
} from './offers.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import ContactInfo from '../SettingsLists/contactInfo';

const AddShipperInformation = ({
    showPopup,
    handleClose,
    shipperName,
    shippersData,
    shipperNanoId,
    isDisabled,
}: any) => {
    const PhoneObj: IContactPhoneObj = {
        phoneNumberType: 'work',
        phone_number: '',
        is_default: 1,
    };

    const contactPhoneObj: IContactPhoneObj = {
        phoneNumberType: 'work',
        phone_number: '',
        is_default: 1,
    };

    const contactObj: IContactInfoProps = {
        name: '',
        email: '',
        phone_number: [contactPhoneObj],
    };

    const contactPhone: IContactInfoProps = {
        name: '',
        email: '',
        phone_number: [PhoneObj],
    };

    const shipperDefaultData = {
        shipper_name: shipperName && shipperName,
        shipper_phone: [contactPhone],
        shipper_contact_info: [contactObj],
        is_active: 1,
    };

    const [phoneInfo, setPhoneInfo] = useState<IContactInfoProps[]>([
        contactPhone,
    ]);

    const {
        control,
        getValues,
        setValue,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isDirty },
    } = useForm<IShipperField>({
        defaultValues: shipperDefaultData,
        resolver: yupResolver(shipperSchema),
    });

    useEffect(() => {
        if (shippersData) {
            reset(shippersData);
            if (shippersData?.shipper_phone?.length === 0) {
                setValue('shipper_phone', phoneInfo);
            }
        }
    }, [shippersData]);

    const watchContactInfo = watch('shipper_contact_info');

    const addContactPhoneNo = async (
        phoneNumberType: string,
        index: number,
    ) => {
        if (phoneNumberType === 'phone') {
            const updatedContactInfo = [...getValues('shipper_phone')];
            const updatedPhoneArray = [
                ...updatedContactInfo[index]?.phone_number,
                { ...contactPhoneObj, is_default: 0 },
            ];

            updatedContactInfo[index] = {
                ...updatedContactInfo[index],
                phone_number: updatedPhoneArray,
            };
            setPhoneInfo(updatedContactInfo);
            setValue('shipper_phone', updatedContactInfo);
        }
    };

    const deletePhone = (
        phoneIndex: number,
        phoneNumberType: string,
        contactIndex: number = 0,
    ) => {
        if (phoneNumberType === 'Phone') {
            if (phoneIndex !== -1) {
                const updatedContactInfo = [...getValues('shipper_phone')];

                const isDefaultPhone =
                    updatedContactInfo[contactIndex].phone_number[phoneIndex]
                        .is_default === 1;
                if (
                    isDefaultPhone &&
                    updatedContactInfo[contactIndex].phone_number.length > 0
                ) {
                    updatedContactInfo[
                        contactIndex
                    ].phone_number[0].is_default = 1;
                }

                const updatedPhoneArray = [
                    ...updatedContactInfo[contactIndex]?.phone_number,
                ];
                updatedPhoneArray.splice(phoneIndex, 1);
                updatedContactInfo[contactIndex] = {
                    ...updatedContactInfo[contactIndex],
                    phone_number: updatedPhoneArray,
                };

                setValue('shipper_phone', updatedContactInfo);
                setPhoneInfo(updatedContactInfo);
            }
        }
    };

    const setPrimary = (
        phoneIndex: number,
        phoneNumberType: string,
        contactIndex: number = 0,
    ) => {
        if (phoneNumberType === 'Phone') {
            if (contactIndex !== undefined && phoneIndex !== undefined) {
                const updatedContactInfo = [...getValues('shipper_phone')];

                updatedContactInfo[contactIndex].phone_number.forEach(
                    (contactPhoneObjs: any, index: number) => {
                        contactPhoneObjs.is_default =
                            index === phoneIndex ? 1 : 0;
                    },
                );
                setPhoneInfo(updatedContactInfo);
                setValue('shipper_phone', updatedContactInfo);
            }
        }
    };

    const HandleChange = async (data: IShipperField) => {
        if (data) {
            const { ...restData } = data;

            handleClose(restData);
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose(shippersData);
                }
            });
        } else {
            handleClose(shippersData);
        }
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                backdropClassName="modal-backdrop-upper"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Shipper Info
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-4">
                    <h6 className="text-sm text-uppercase text-dark text-bold">
                        PHONE
                    </h6>
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <Row>
                            <>
                                {getValues('shipper_phone') &&
                                    getValues('shipper_phone').length > 0 &&
                                    getValues('shipper_phone')?.map(
                                        (item: any, index: number) => (
                                            <Fragment key={index}>
                                                {(shipperNanoId &&
                                                shipperNanoId !== '' &&
                                                getValues(
                                                    `shipper_phone.${index}.phone_number`,
                                                ).length > 0
                                                    ? getValues(
                                                          `shipper_phone.${index}.phone_number.${0}.phoneNumberType`,
                                                      ) !== ''
                                                    : getValues(
                                                          `shipper_phone.${index}.phone_number`,
                                                      ) &&
                                                      getValues(
                                                          `shipper_phone.${index}.phone_number.${0}.phoneNumberType`,
                                                      ) !== '') &&
                                                    getValues(
                                                        `shipper_phone.${index}.phone_number`,
                                                    ).map(
                                                        (
                                                            data: any,
                                                            dataIndex: number,
                                                        ) => (
                                                            <Row
                                                                key={dataIndex}
                                                                className="align-items-center gx-3 px-2"
                                                            >
                                                                <Col
                                                                    md={8}
                                                                    className="d-flex"
                                                                >
                                                                    <div className="mt-3 min-w-120">
                                                                        <Controller
                                                                            name={`shipper_phone.${index}.phone_number.${dataIndex}.phoneNumberType`}
                                                                            control={
                                                                                control
                                                                            }
                                                                            render={({
                                                                                field: {
                                                                                    onChange,
                                                                                    value,
                                                                                },
                                                                            }: any) => (
                                                                                <ReactSelectGroup
                                                                                    value={contactPhoneNoTypes2.find(
                                                                                        (
                                                                                            c: any,
                                                                                        ) =>
                                                                                            c?.value ===
                                                                                            value,
                                                                                    )}
                                                                                    labelShow={
                                                                                        false
                                                                                    }
                                                                                    setBorderRadiusClass="mfnw__borderless_select"
                                                                                    inputClassName="mfnw__input-group"
                                                                                    options={
                                                                                        contactPhoneNoTypes2
                                                                                    }
                                                                                    onChangeFunc={(
                                                                                        valueStateTownChange: any,
                                                                                    ) => {
                                                                                        setValue(
                                                                                            `shipper_phone.${index}.phone_number.${dataIndex}.phoneNumberType`,
                                                                                            valueStateTownChange?.value,
                                                                                        );
                                                                                    }}
                                                                                    tabIndex={
                                                                                        9
                                                                                    }
                                                                                    className="w-100"
                                                                                    isSearchable={
                                                                                        false
                                                                                    }
                                                                                    isDisabled={
                                                                                        isDisabled
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <Controller
                                                                            name={`shipper_phone.${index}.phone_number.${dataIndex}.phone_number`}
                                                                            control={
                                                                                control
                                                                            }
                                                                            render={({
                                                                                field: {
                                                                                    onChange,
                                                                                    value,
                                                                                },
                                                                            }: any) => (
                                                                                <PhoneInputMaskGroup
                                                                                    maskFormat={
                                                                                        phoneFormat
                                                                                    }
                                                                                    inputName="organization_phone"
                                                                                    inputType="text"
                                                                                    label="Phone No."
                                                                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                                    onChangeFunc={
                                                                                        onChange
                                                                                    }
                                                                                    id="organization_phone"
                                                                                    aria-describedby="phone"
                                                                                    tabIndex={
                                                                                        9
                                                                                    }
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                    labelShow={
                                                                                        false
                                                                                    }
                                                                                    setPhoneRadiusClass="mnfw_border-left"
                                                                                    placeholder="(xxx) xxx-xxxx"
                                                                                    disabled={
                                                                                        isDisabled
                                                                                    }
                                                                                    errorText={
                                                                                        errors
                                                                                            ?.shipper_phone?.[
                                                                                            index
                                                                                        ]
                                                                                            ?.phone_number?.[
                                                                                            dataIndex
                                                                                        ]
                                                                                            ?.phone_number
                                                                                            ?.message
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={3}>
                                                                    {getValues(
                                                                        'shipper_phone',
                                                                    )?.[index]
                                                                        ?.phone_number?.[
                                                                        dataIndex
                                                                    ]
                                                                        ?.is_default ===
                                                                    1 ? (
                                                                        <div className="mt-3">
                                                                            <Badge
                                                                                className="badge bg-success text-base align-text-bottom ms-2 px-3 py-2 cursor-pointer"
                                                                                onClick={() =>
                                                                                    !isDisabled &&
                                                                                    setPrimary(
                                                                                        dataIndex,
                                                                                        'Phone',
                                                                                        index,
                                                                                    )
                                                                                }
                                                                            >
                                                                                PRIMARY
                                                                            </Badge>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="mt-3">
                                                                            <Badge
                                                                                className="badge bg-disable text-base align-text-bottom ms-2 px-3 py-2 cursor-pointer"
                                                                                onClick={() =>
                                                                                    !isDisabled &&
                                                                                    setPrimary(
                                                                                        dataIndex,
                                                                                        'Phone',
                                                                                        index,
                                                                                    )
                                                                                }
                                                                            >
                                                                                PRIMARY
                                                                            </Badge>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                                {!isDisabled && (
                                                                    <Col md={1}>
                                                                        <div className="mt-3">
                                                                            {dataIndex !==
                                                                                0 && (
                                                                                <Button
                                                                                    variant="default"
                                                                                    className="p-2 rounded-3 py-1"
                                                                                    onClick={() =>
                                                                                        deletePhone(
                                                                                            dataIndex,
                                                                                            'Phone',
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faXmark
                                                                                        }
                                                                                    />
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        ),
                                                    )}
                                                {!isDisabled && (
                                                    <Col md={12}>
                                                        <div
                                                            className="text-primary my-3 cursor-pointer"
                                                            onClick={() =>
                                                                addContactPhoneNo(
                                                                    'phone',
                                                                    0,
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPlus}
                                                            />
                                                            <span className="fw-semibold ms-3">
                                                                Add more phone
                                                                numbers
                                                            </span>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Fragment>
                                        ),
                                    )}
                            </>
                        </Row>
                        <h6 className="text-sm text-uppercase text-dark text-bold">
                            ADDITIONAL CONTACT (Optional)
                        </h6>
                        <ContactInfo
                            ContactInfo={ContactInfo}
                            watchContactInfo={watchContactInfo}
                            getValues={getValues}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            TspId={shipperNanoId}
                            fieldName={'shipper_contact_info'}
                            contactObj={contactObj}
                            contactPhoneObj={contactPhoneObj}
                            isDisabled={isDisabled}
                        />
                        {!isDisabled && (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Button
                                            variant="primary w-100 mt-3 text-uppercase fw-semibold rounded-2"
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            className="btn-cancel mt-3 w-100 text-uppercase fw-semibold rounded-2"
                                            onClick={() => onClose()}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddShipperInformation;
