import React from 'react';
import { Controller } from 'react-hook-form';
import { Badge, Button, Col, Row, Image, FormLabel } from 'react-bootstrap';

import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import Region from '@images/region.svg';

const SelectRegionFormField = ({
    control,
    optionArr,
    setSearchStateText,
    setValue,
    selectList,
    setSelectList,
    errors,
    handleAddRegion,
    updateSelectedList,
    updateSelectedList2,
    isAddRegionData,
    onClose,
}: any) => (
    <Row className="justify-content-center">
        <Col md={12} className="text-center">
            <Image src={Region} alt="building icon" />
        </Col>
        <Col md={12} className="my-3">
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={9}>
                            <div className="mt-4">
                                <Controller
                                    name="region_state"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <ReactSelectGroup
                                            value={
                                                value === selectList
                                                    ? value
                                                    : selectList
                                            }
                                            name={'region_state'}
                                            inputClassName="mfnw__input-group"
                                            options={optionArr}
                                            onInputChange={(
                                                searchStateValue: any,
                                            ) => {
                                                setSearchStateText(
                                                    searchStateValue,
                                                );
                                            }}
                                            isMulti={true}
                                            labelShow={false}
                                            tabIndex={3}
                                            onChangeFunc={(stateValue: any) => {
                                                setValue(
                                                    'region_state',
                                                    stateValue,
                                                );
                                                setSelectList(stateValue);
                                            }}
                                            label={''}
                                            isSearchable={true}
                                            errorText={
                                                errors?.region_state?.message
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={3} className="d-flex pt-4 mfpro-regions">
                            {
                                <div className="d-flex mt-3 mf-addRegion">
                                    <div>
                                        <FontAwesomeIcon
                                            role="button"
                                            onClick={handleAddRegion}
                                            icon={faCirclePlus}
                                            className="icon text-center"
                                        />
                                    </div>
                                    <div>
                                        <span
                                            role="button"
                                            className="text-primary text-base fw-bold ps-1"
                                            onClick={() => {
                                                handleAddRegion();
                                            }}
                                        >
                                            Add Region
                                        </span>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <FormLabel className="mt-3">LIST OF REGIONS</FormLabel>
                    <Row className="mt-3 g-0">
                        {selectList?.map((item: any, index: number) => (
                            <Badge
                                key={index}
                                bg="light"
                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                            >
                                <span
                                    className="me-3"
                                    onClick={() =>
                                        updateSelectedList(
                                            item?.cityState,
                                            item?.label,
                                        )
                                    }
                                >
                                    {item?.label}
                                </span>
                                <div
                                    onClick={() =>
                                        updateSelectedList2(item?.label)
                                    }
                                    className="cursor-pointer"
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleXmark}
                                        color="#b7b7b7"
                                        size="lg"
                                    />
                                </div>
                            </Badge>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Col>
        <Col md={12}>
            <Button
                variant="primary w-100 mt-3 text-uppercase"
                className="button__details"
                type="submit"
                tabIndex={4}
                disabled={isAddRegionData}
            >
                {isAddRegionData ? <LoaderBtn /> : 'SAVE'}
            </Button>
            <Button
                variant="default"
                className="btn-cancel mt-3 w-100 button__details"
                tabIndex={5}
                onClick={() => onClose()}
            >
                CANCEL
            </Button>
        </Col>
    </Row>
);

export default SelectRegionFormField;
