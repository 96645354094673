import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiJobHistoryList } from '@services/API/apiFunctions';

const jobHistoryList = createAsyncThunk(
    'jobHistoryList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiJobHistoryList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { jobHistoryList };
