import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneLengthCheck,
    phoneRegex,
} from '@constants/general.const';

import { IDriverAllFieldsProps } from './driver.interface';

export const driverDefaultData: IDriverAllFieldsProps = {
    first_name: '',
    middle_name: '',
    last_name: '',
    driver_type: 'Company',
    driver_email: '',
    driver_phone: '',
    driver_license: '',
    driver_license_expiration_date: null,
    is_active: 1,
    haul: '',
    fsc: '',
    atc_or: '',
    atc_dst: '',
    pack: '',
    unpack: '',
    shuttle: '',
    fuel: '',
    wrk_cmp: '',
    new_mex: '',
    truck: '',
    ins: '',
    tax2290: '',
    eld: '',
    driver_license_pdf: [],
    driver_certificate: [],
};

export const addSchema = Yup.object({
    first_name: Yup.string().required('First Name is a required field'),

    last_name: Yup.string()
        .required('Last Name is a required field')
        .nullable(true),

    driver_email: Yup.string()
        .required('Email is a required field')
        .email('Please enter a valid email')
        .nullable(true),

    driver_phone: Yup.string()
        .test(
            'len',
            `Phone number must be exactly ${minPhoneLength} characters`,
            (val) => {
                if (val) {
                    const cleanedPhone = val.replace(phoneRegex, '');
                    return cleanedPhone.length === phoneLengthCheck;
                }
                return false;
            },
        )
        .nullable(true),
    driver_license: Yup.string()
        .required('Driver license is a required field')
        .nullable(true),

    driver_license_expiration_date: Yup.string()
        .required('Driver license expiration date is a required field')
        .nullable(true),
}).required();
