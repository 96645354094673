import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Image, Modal, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPen,
    faTrashCan,
    faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';

const ShowUserPopup = ({
    showPopup,
    handleCloseEvent,
    data,
    handleViewEditUser,
    actionUserById,
}: any) => {
    const { login, inviteUsers } = useSelector((state: any) => state);
    const { authUser } = login;
    const { isUserDataGet } = inviteUsers;

    return (
        <Modal
            show={showPopup}
            onHide={handleCloseEvent}
            backdrop="static"
            centered
            className="mfnw__userlisting_viewmodal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    View User
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="p-4">
                {isUserDataGet ? (
                    <LoaderBtn />
                ) : (
                    <>
                        <div className="d-flex justify-content-center mb-4 flex-column align-items-center position-relative">
                            {data?.user_profile ? (
                                <Image
                                    className="rounded-circle"
                                    height="100px"
                                    width="100px"
                                    src={data?.user_profile}
                                />
                            ) : (
                                <div className="profile_bg_initial">
                                    {data?.firstName
                                        .toUpperCase()
                                        .substring(0, 1) +
                                        data?.lastName
                                            .toUpperCase()
                                            .substring(0, 1)}
                                </div>
                            )}
                            <h4 className="fw-bold mt-2">{`${data?.firstName}, ${data?.lastName}`}</h4>
                            <p className="text-center">{data?.email}</p>

                            <div className="d-flex text-light align-items-center  ms-2 px-2 py-1 position-absolute top-0 end-0">
                                <FontAwesomeIcon
                                    className="mx-1 cursor-pointer align-baseline"
                                    icon={faPen}
                                    size="sm"
                                    onClick={() =>
                                        handleViewEditUser(data?.users_nano_id)
                                    }
                                />
                                {authUser?.email !== data?.email && (
                                    <>
                                        <FontAwesomeIcon
                                            className="mx-1 cursor-pointer align-baseline"
                                            icon={faTrashCan}
                                            size="sm"
                                            onClick={() =>
                                                actionUserById(
                                                    data?.users_nano_id,
                                                    'delete',
                                                )
                                            }
                                        />
                                        {data?.is_active === 1 ? (
                                            <Badge
                                                className="badge bg-success cursor-pointer align-text-bottom ms-2 rounded-1 py-1 px-2"
                                                onClick={() =>
                                                    actionUserById(
                                                        data?.users_nano_id,
                                                        'Disable',
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                    className="me-2"
                                                    size="sm"
                                                />
                                                Enabled
                                            </Badge>
                                        ) : (
                                            <Badge
                                                className="badge bg-disable cursor-pointer align-text-bottom ms-2 rounded-1 py-1 px-2"
                                                onClick={() =>
                                                    actionUserById(
                                                        data?.users_nano_id,
                                                        'Enable',
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                    className="me-2"
                                                    size="sm"
                                                />
                                                Disabled
                                            </Badge>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column list_user">
                            <output className="d-flex output_view_user mb-3">
                                <div className="left_title text-uppercase">
                                    DEPARTMENT
                                </div>
                                <div className="right-content">
                                    {data?.departments?.department_name}
                                </div>
                            </output>
                            <output className="d-flex output_view_user mb-3">
                                <div className="left_title text-uppercase">
                                    OFFICE
                                </div>
                                <div className="right-content">
                                    {data?.offices?.office_name}
                                </div>
                            </output>
                            <output className="d-flex output_view_user mb-3">
                                <div className="left_title text-uppercase">
                                    ROLE
                                </div>
                                <div className="right-content">
                                    {data?.roles?.role_name}
                                </div>
                            </output>
                            <output className="d-flex output_view_user mb-3">
                                <div className="left_title text-uppercase">
                                    GROUP
                                </div>
                                <div className="right-content">
                                    {data?.groups?.group_name}
                                </div>
                            </output>
                            <output className="d-flex output_view_user mb-3">
                                <div className="left_title text-uppercase">
                                    JOB TITLE
                                </div>
                                <div className="right-content">
                                    {data?.job_titles?.job_title}
                                </div>
                            </output>
                        </div>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ShowUserPopup;
