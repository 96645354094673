import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addDepartmentData,
    deleteDepartment,
    getDepartmentDataList,
} from '@app/CreateOrganization/step3/functions';

const defaultState = {
    isGetDepartmentDataList: false,
    isAddDepartmentData: false,
    isDeleteDepartment: false,
    departmentData: {
        department_name: '',
    },
};

export const departmentStep3Slice: any = createSlice({
    name: 'departmentStep3Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addDepartmentData.pending.type]: (state: any, { payload }) => {
            state.isAddDepartmentData = true;
        },
        [addDepartmentData.fulfilled.type]: (state: any, { payload }) => {
            state.isAddDepartmentData = false;
        },
        [addDepartmentData.rejected.type]: (state: any, { payload }) => {
            state.isAddDepartmentData = false;
        },

        [getDepartmentDataList.pending.type]: (state: any, { payload }) => {
            state.isGetDepartmentDataList = true;
        },
        [getDepartmentDataList.fulfilled.type]: (state: any, { payload }) => {
            state.isGetDepartmentDataList = false;
            if (state?.isDeleteDepartment) {
                state.isDeleteDepartment = false;
            }
        },
        [getDepartmentDataList.rejected.type]: (state: any, { payload }) => {
            state.isGetDepartmentDataList = false;
            if (state?.isDeleteDepartment) {
                state.isDeleteDepartment = false;
            }
        },

        [deleteDepartment.pending.type]: (state: any, { payload }) => {
            state.isDeleteDepartment = true;
        },
        [deleteDepartment.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteDepartment = true;
        },
        [deleteDepartment.rejected.type]: (state: any, { payload }) => {
            state.isDeleteDepartment = false;
        },
    },
});

export const { saveLoggedInUser, loginChange, updateIsLogOutUser } =
    departmentStep3Slice.actions;

export default departmentStep3Slice.reducer;
