import * as Yup from 'yup';

import { passwordLength } from '@constants/general.const';

export const schema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is a required field'),

    firstName: Yup.string().required('Please provide your first name'),

    lastName: Yup.string().required('Please provide your last name'),
    current_pass: Yup.string()
        .min(
            passwordLength,
            'Current password is too short - should be at least 8 characters.',
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),

    password: Yup.string().when(
        'current_pass',
        (current_pass: string | undefined, schemaPackDate: any) =>
            current_pass
                ? schemaPackDate
                      .required('Please provide a new password.')
                      .min(
                          passwordLength,
                          'Password is too short - should be at least 8 characters.',
                      )
                : schemaPackDate.default(''),
    ),
    password_confirmation: Yup.string().when(
        'password',
        (password: string | undefined, schemaPackDate: any) =>
            password
                ? schemaPackDate
                      .required('Please provide a password confirmation.')
                      .min(
                          passwordLength,
                          'Password confirmation is too short - should be at least 8 characters.',
                      )
                      .oneOf(
                          [Yup.ref('password'), null],
                          'Passwords must match.',
                      )
                : schemaPackDate.default(''),
    ),
}).required();
