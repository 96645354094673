import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import OrganizationListTab from '@app/CreateOrganization/Organizations/OrganizationList/organizationListTab';

import '../organization.scss';

const OrganizationsList = () => (
    <div className="mfnw__dashboard-content">
        <Tabs
            defaultActiveKey="organization"
            className="mfnw__dashboard_linetab mb-3"
        >
            <Tab
                eventKey="organization"
                title="ORGANIZATION"
                className="cursor-pointer"
            >
                <OrganizationListTab />
            </Tab>
            <Tab
                eventKey="notification"
                title="NOTIFICATION"
                disabled
                className="cursor-pointer"
            ></Tab>
            <Tab
                eventKey="integration"
                title="INTEGRATION"
                disabled
                className="cursor-pointer"
            ></Tab>
        </Tabs>
    </div>
);

export default OrganizationsList;
