import { routesPath } from '@constants/routesNavigate';

import OfferIcon from '@images/offered-icon.svg';
import AcceptIcon from '@images/accept.svg';
import RejectIcon from '@images/reject.svg';
import CanceledIcon from '@images/canceled.svg';
import offerColor from '@images/offered-color.svg';
import acceptColor from '@images/accept-color.svg';
import rejectColor from '@images/reject-color.svg';
import cancelColor from '@images/cancel-color.svg';
import projectColor from '@images/project-color.svg';
import JobCompleted from '@images/job_complete icon3.svg';
import Unschedule from '@images/unscheduled icon1.svg';
import Scheduled from '@images/scheduled icon2.svg';
import JobCancel from '@images/job_cancel icon4.svg';
import BottomUnschedule from '@images/bottomImg1.svg';
import BottomSchedule from '@images/bottomImg2.svg';
import BottomJob from '@images/bottomImg3.svg';
import BottomJobSchedule from '@images/bottomImg4.svg';
import BottomJobSalary from '@images/imageBottom5.svg';

import { IDashboardTopItem } from './dashboard.interface';

export const dashboardTopItemArray: IDashboardTopItem[] = [
    {
        path: routesPath.offers,
        displayName: 'OFFERED',
        count: 'pending',
        icon: OfferIcon,
        backGround: 'warning',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.jobs,
        displayName: 'ACCEPTED',
        count: 'accepted',
        icon: AcceptIcon,
        backGround: 'success',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'REJECTED',
        count: 'rejected',
        icon: RejectIcon,
        backGround: 'danger',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'CANCELED',
        count: 'canceled',
        icon: CanceledIcon,
        backGround: 'pink',
        accessible: ['user', 'admin'],
    },
];

export const dashboardBottomItemArray: IDashboardTopItem[] = [
    {
        path: routesPath.offers,
        displayName: 'OFFERED (YTD)',
        count: 'offered',
        icon: offerColor,
        backGround: 'warning',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.jobs,
        displayName: 'ACCEPTED (YTD)',
        count: 'accepted',
        icon: acceptColor,
        backGround: 'success',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'REJECTED (YTD)',
        count: 'rejected',
        icon: rejectColor,
        backGround: 'danger',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'CANCELED (YTD)',
        count: 'canceled',
        icon: cancelColor,
        backGround: 'pink',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'PROJECTED (Active)',
        count: 'projected',
        icon: projectColor,
        backGround: 'primary',
        accessible: ['user', 'admin'],
    },
];

export const dashboardJobBottomItemArray: IDashboardTopItem[] = [
    {
        path: routesPath.offers,
        displayName: 'UNSCHEDULED (YTD)',
        count: 'unscheduled',
        icon: BottomUnschedule,
        backGround: 'warning',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.jobs,
        displayName: 'SCHEDULED (YTD)',
        count: 'scheduled',
        icon: BottomSchedule,
        backGround: 'success',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'COMPLETED (YTD)',
        count: 'completed',
        icon: BottomJob,
        backGround: 'danger',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'CANCELED (YTD)',
        count: 'canceled',
        icon: BottomJobSchedule,
        backGround: 'pink',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'PROJECTED (Active)',
        count: 'projected',
        icon: BottomJobSalary,
        backGround: 'primary',
        accessible: ['user', 'admin'],
    },
];

export const dashboardJobTopItemArray: IDashboardTopItem[] = [
    {
        path: routesPath.jobs,
        displayName: 'UNSCHEDULED',
        count: 'unscheduled',
        icon: Unschedule,
        backGround: 'warning',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.jobs,
        displayName: 'SCHEDULED',
        count: 'scheduled',
        icon: Scheduled,
        backGround: 'success',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'COMPLETED',
        count: 'completed',
        icon: JobCompleted,
        backGround: 'danger',
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.dashboard,
        displayName: 'CANCELED',
        count: 'canceled',
        icon: JobCancel,
        backGround: 'pink',
        accessible: ['user', 'admin'],
    },
];

export const offerListStatusArray: string[] = [
    'pending',
    'accepted',
    'rejected',
    'canceled',
];

export const jobListStatusArray: string[] = [
    'unscheduled',
    'scheduled',
    'completed',
    'canceled',
];
