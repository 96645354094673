/* eslint-disable no-unused-vars */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import { APP_ROOT, AUTH_ROUTES, ROUTES } from '@constants/routes';
import { routesPath } from '@constants/routesNavigate';

import AppLayout from '@app/AppLayout';
import Dashboard from '@app/Dashboard';
import EmailVerify from '@app/Auth/EmailVerify';
import ForgotPassword from '@app/Auth/ForgotPassword';
import JobList from '@app/JobList';
import Login from '@app/Auth/Login';
import Register from '@app/Auth/Register';
import UserProfile from '@app/Auth/UserProfile';

import PageNotFound from '@app/PageNotFound';
import Step1 from '@app/CreateOrganization/step1/step1';
import Step2 from '@app/CreateOrganization/step2/step2';
import Step3 from '@app/CreateOrganization/step3/step3';
import Step4 from '@app/CreateOrganization/step4/step4';
import Step5 from '@app/CreateOrganization/step5/step5';
import Step6 from '@app/CreateOrganization/step6/step6';
import Step7 from '@app/CreateOrganization/step7/step7';
import FinalStep from '@app/CreateOrganization/step7/final-step';
// import Organizations from '@app/CreateOrganization/Organizations';
import OrganizationsList from '@app/CreateOrganization/Organizations/OrganizationList';
import Organizations from '@app/CreateOrganization/Organizations';
import UserListing from '@app/UserListing';
import WorkFlow from '@app/WorkFlow';
import SeetingsLists from '@app/SettingsLists';
import UserOrganization from '@app/CreateOrganization/Organizations/OrganizationList/UserOrganization';
import OffersList from '@app/OffersList';
import Schedule from '@app/Schedule';

const Routes: React.FC = () => {
    const { JWTToken } = useSelector((state: any) => state.login);

    useEffect(() => {
        const title =
            process.env.REACT_APP_TEST_SERVER === 'DEV'
                ? 'Moveflows - Staging'
                : 'Moveflows';
        document.title = title || 'test';
    }, []);
    // const searchTextClear = () => {};

    return (
        <BrowserRouter>
            <ReactRoutes>
                <Route
                    path={APP_ROOT}
                    element={
                        !JWTToken ? (
                            <Login />
                        ) : (
                            <Navigate to={routesPath?.dashboard} />
                        )
                    }
                />
                <Route
                    path={AUTH_ROUTES.LOGIN}
                    element={
                        !JWTToken ? (
                            <Login />
                        ) : (
                            <Navigate to={routesPath?.dashboard} />
                        )
                    }
                />
                <Route
                    path={AUTH_ROUTES.REGISTER}
                    element={
                        !JWTToken ? (
                            <Register />
                        ) : (
                            <Navigate to={routesPath?.dashboard} />
                        )
                    }
                />
                <Route
                    path={AUTH_ROUTES.EMAIL_VERIFY}
                    element={
                        !JWTToken ? (
                            <EmailVerify />
                        ) : (
                            <Navigate to={routesPath?.dashboard} />
                        )
                    }
                />
                <Route
                    path={AUTH_ROUTES.FORGOT_PASSWORD}
                    element={
                        !JWTToken ? (
                            <ForgotPassword />
                        ) : (
                            <Navigate to={routesPath?.dashboard} />
                        )
                    }
                />

                <Route
                    path={ROUTES.DASHBOARD}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isSearch={true}>
                                <Dashboard />
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.USERPROFILE}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout>
                                <UserProfile />
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.OFFERS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isSearch={true} isListView={true}>
                                <OffersList />
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.JOBS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isSearch={true} isListView={true}>
                                <JobList />
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_1}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step1 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_2}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step2 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_3}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step3 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_4}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step4 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_5}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step5 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_6}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step6 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.STEP_7}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <Step7 />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.FINAL_STEP}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <FinalStep />
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.ORGANIZATIONS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isSearch={true} isListView={true}>
                                {<OrganizationsList />}
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.UPDATE_ORGANIZATIONS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout>{<Organizations />}</AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.UPDATE_USER_ORGANIZATIONS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout>{<UserOrganization />}</AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.USERS}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isListView={true}>
                                {<UserListing />}
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.LISTING}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isListView={true}>
                                {<SeetingsLists />}
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.WORKFLOW}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout>{<WorkFlow />}</AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route
                    path={ROUTES.SCHEDULE}
                    element={
                        JWTToken && JWTToken !== '' ? (
                            <AppLayout isListView={true}>
                                {<Schedule />}
                            </AppLayout>
                        ) : (
                            <Navigate to={routesPath?.login} />
                        )
                    }
                />
                <Route path="*" element={<PageNotFound />} />
            </ReactRoutes>
        </BrowserRouter>
    );
};

export default Routes;
