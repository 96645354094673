/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { Form } from '@components-default';

const ChangeImage = ({
    id = 'contained-button-file',
    handleClickProfilePic,
    btnText,
    userImage,
}: any) => {
    const { login } = useSelector((state: any) => state);
    const { authUser } = login;
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        var extension = e.target.files[0]?.name.split('.').pop().toLowerCase();
        if (files.length === 0) {
            return alert('Please select a file.');
        }
        const reader = new FileReader();
        reader.onload = () => {
            handleClickProfilePic(reader.result, extension);
        };
        reader.readAsDataURL(files[0]);
    };
    const updateImage: any = React.useMemo(
        () => (
            <div className="position-relative d-inline-block">
                <div className="mfnw__button_user text-uppercase text-base fw-bold btn btn-primary">
                    {btnText}
                </div>
            </div>
        ),

        [authUser, userImage],
    );
    return (
        <>
            <label className="label-w-20">
                <Form.Control
                    accept="image/*"
                    id={id}
                    multiple
                    name="user_profile"
                    type="file"
                    onChange={onChange}
                    className="d-none profile_text_img"
                />
                {updateImage}
            </label>
        </>
    );
};

export default ChangeImage;
