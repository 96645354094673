export const fieldNames = [
    {
        field: 'govBillOfLaden',
        name: 'GBL',
        short: 0,
        type: 'Offer Information',
    },
    {
        field: 'forwarderBillOfLaden',
        name: 'BOL',
        short: 0,
        type: 'Offer Information',
    },
    {
        field: 'offerStatus',
        name: 'Offer Status',
        short: 3,
        type: 'Offer Information',
    },
    {
        field: 'type',
        name: 'Offer Type',
        short: 1,
        type: 'Offer Information',
    },
    {
        field: 'shipper',
        name: 'Shipper',
        short: 1,
        type: 'Offer Information',
    },
    { field: 'agent', name: 'Agent', short: 1, type: 'Offer Information' },
    {
        field: 'to_agent',
        name: 'To Agent',
        short: 1,
        type: 'Offer Information',
    },
    {
        field: 'forwarder_name',
        name: 'Forwarder Name',
        short: 3,
        type: 'Offer Information',
    },

    { field: 'date_in', name: 'Date In', short: 2, type: 'Offer Dates' },
    { field: 'date_out', name: 'Date Out', short: 2, type: 'Offer Dates' },
    { field: 'packDate', name: 'Pack Date', short: 2, type: 'Offer Dates' },
    {
        field: 'pickupDate',
        name: 'Pickup Date',
        short: 2,
        type: 'Offer Dates',
    },
    {
        field: 'deliveryDate',
        name: 'Delivery Date',
        short: 2,
        type: 'Offer Dates',
    },
    {
        field: 'statusDate',
        name: 'Status Date',
        short: 2,
        type: 'Offer Dates',
    },
    {
        field: 'schedule_start_date',
        name: 'Schedule Start Date',
        short: 3,
        type: 'Offer Dates',
    },
    {
        field: 'schedule_end_date',
        name: 'Schedule End Date',
        short: 3,
        type: 'Offer Dates',
    },
    {
        field: 'driver_pickupDate',
        name: 'Driver Pickup Date',
        short: 3,
        type: 'Offer Dates',
    },
    {
        field: 'driver_deliveryDate',
        name: 'Driver Delivery Date',
        short: 3,
        type: 'Offer Dates',
    },

    {
        field: 'origin_address_street',
        name: 'Street (Origin)',
        short: 4,
        type: 'Origin Address',
    },
    {
        field: 'origin_address_additional',
        name: 'Additional (Origin)',
        short: 4,
        type: 'Origin Address',
    },
    {
        field: 'origin_address_city_id',
        name: 'City (Origin)',
        short: 4,
        type: 'Origin Address',
    },
    {
        field: 'origin_address_stateTown',
        name: 'StateTown (Origin)',
        short: 4,
        type: 'Origin Address',
    },
    {
        field: 'origin_address_postalCode',
        name: 'PostalCode (Origin)',
        short: 4,
        type: 'Origin Address',
    },
    {
        field: 'destination_address_street',
        name: 'Street (Dest.)',
        short: 5,
        type: 'Destination Address',
    },
    {
        field: 'destination_address_additional',
        name: 'Additional (Dest.)',
        short: 5,
        type: 'Destination Address',
    },
    {
        field: 'destination_address_city_id',
        name: 'City (Dest.)',
        short: 5,
        type: 'Destination Address',
    },
    {
        field: 'destination_address_city',
        name: 'City (Dest.)',
        short: 5,
        type: 'Destination Address',
    },
    {
        field: 'destination_address_stateTown',
        name: 'StateTown (Dest.)',
        short: 5,
        type: 'Destination Address',
    },
    {
        field: 'destination_address_postalCode',
        name: 'PostalCode (Dest.)',
        short: 5,
        type: 'Destination Address',
    },

    {
        field: 'miles',
        name: 'Miles',
        short: 1,
        type: 'Haul Information',
        postFix: ' Miles',
    },
    {
        field: 'notes',
        name: 'Notes',
        short: 1,
        type: 'Haul Information',
    },
    {
        field: 'estimatedWeight',
        name: 'Weight',
        short: 1,
        type: 'Haul Information',
    },
    {
        field: 'discount',
        name: 'Discount',
        short: 1,
        type: 'Haul Information',
        postFix: '%',
    },
    { field: 'sit', name: 'SIT', short: 0, type: 'Haul Information' },
    {
        field: 'SIT_status',
        name: 'SIT Status',
        short: 2,
        type: 'Haul Information',
    },
    {
        field: 'isOriginAgentOnly',
        name: 'Origin Agent (O/A)',
        short: 5,
        type: 'Haul Information',
    },
    {
        field: 'isSelfHaul',
        name: 'Haul Type',
        short: 5,
        type: 'Haul Information',
    },
    {
        field: 'ntsr',
        name: 'Non-Temp Storage (NTSR)',
        short: 5,
        type: 'Haul Information',
    },

    {
        field: 'driver',
        name: 'Driver',
        short: 0,
        type: 'Schedule Information',
    },
    {
        field: 'truck',
        name: 'Truck',
        short: 1,
        type: 'Schedule Information',
    },
    {
        field: 'trailer',
        name: 'Trailer',
        short: 2,
        type: 'Schedule Information',
    },
    {
        field: 'user_notes',
        name: 'Notes',
        short: 1,
        type: 'Notes Information',
    },
    {
        field: 'documents',
        name: 'Documents',
        short: 1,
        type: 'Documents Information',
    },
];
