import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Badge,
    Button,
    Dropdown,
    ListGroup,
    Breadcrumb,
} from 'react-bootstrap';

import InputText from '@components/InputText/InputText';

import {
    faCircleSmall,
    faMagnifyingGlass,
    faSliders,
    faTrashCan,
    faPen,
    faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const faPlusIcon = faPlus as IconProp;

const WorkFlow = () => (
    <>
        <div className="d-flex justify-content-between align-items-center py-3 mb-3">
            <h5 className="fw-semibold m-0">Work List</h5>
            <div className="d-flex gap-3 main-par">
                <div className="main-searchInput position-relative">
                    <InputText
                        className="mfnw__login-form-control mfnw__search-form-control-bg"
                        id=""
                        inputName=""
                        inputType="text"
                        placeholder="Search..."
                        errorText={''}
                    />
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        color="#b7b7b7"
                        size="lg"
                        className="position-absolute form-control-serch"
                    />
                </div>
                <Dropdown>
                    <Dropdown.Toggle
                        variant="default"
                        id="dropdown-basic"
                        className="limit m-0 p-0 border-0"
                    >
                        <div className="dropdown-block">
                            <FontAwesomeIcon
                                icon={faSliders}
                                color="#b7b7b7"
                                size="lg"
                            />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item active>5</Dropdown.Item>
                        <Dropdown.Item>6</Dropdown.Item>
                        <Dropdown.Item>7</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button
                    variant="primary"
                    className="btn_type_large fw-semibold"
                >
                    Add New WorkFlow
                </Button>
            </div>
        </div>
        <div className="bg-white rounded-4 p-4">
            <div className="d-flex justify-content-between flex-column min-vh-80">
                <Row className="g-0 mfnw__employee_bording">
                    <div className="mfnw__employee_bording-card">
                        <div className="left_content">
                            <h5>Employee Onboarding V.1.0</h5>
                            <p>Sets up accounts for new MoveFlows employees.</p>
                        </div>
                        <div className="right_content">
                            <FontAwesomeIcon
                                className="mx-2 cursor-pointer"
                                icon={faPen}
                            />
                            <FontAwesomeIcon
                                className="mx-2 cursor-pointer"
                                icon={faTrashCan}
                            />
                            <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                <FontAwesomeIcon icon={faCircleSmall} />
                                Active
                            </Badge>
                        </div>
                    </div>
                    <Row className="g-0 mt-3">
                        <Col md={6} xl={4}>
                            <div className="w-100">
                                <p className="text-sm">WORKFLOW TYPE</p>
                                <div className="d-flex flex-wrap">
                                    <Badge
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="px-2">Onboarding</span>
                                    </Badge>
                                </div>
                            </div>
                            <div className="w-100 mt-3">
                                <p className="text-sm">REQUESTERS</p>
                                <div className="d-flex flex-wrap">
                                    <Badge
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="px-2">
                                            Onboarding Team
                                        </span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="px-2">
                                            Human Resources
                                        </span>
                                    </Badge>
                                </div>
                            </div>
                            <div className="w-100 mt-3">
                                <p className="text-sm">APPROVERS</p>
                                <div className="d-flex flex-wrap">
                                    <Badge
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="px-2">Managers</span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="px-2">VPs</span>
                                    </Badge>
                                </div>
                            </div>
                            <div className="w-100 mt-3">
                                <p className="text-sm">WORKFLOW STEPS</p>
                                <Breadcrumb className="text-sm">
                                    <Breadcrumb.Item className="fw-normal">
                                        Request Made
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="fw-normal">
                                        Finance Approval
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className="fw-normal">
                                        CFO Approval
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                        <Col md={6} xl={8}>
                            <div className="d-flex justify-content-between">
                                <span className="text-sm">WORKFLOW EVENT</span>
                                <Link to="#" className="text-primary text-sm">
                                    <FontAwesomeIcon icon={faPlusIcon} />
                                    Add New Event
                                </Link>
                            </div>
                            <ListGroup className="mt-3">
                                <ListGroup.Item>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-sm">
                                            When
                                            <span className="text-pink fw-semibold">
                                                [Status]
                                            </span>
                                            is
                                            <span className="text-warning fw-semibold">
                                                [Created]
                                            </span>
                                            and changes to
                                            <span className="text-pink fw-semibold">
                                                [Submitted]
                                            </span>
                                            , notify
                                            <span className="text-primary fw-semibold">
                                                [Approver]
                                            </span>
                                        </p>
                                        <div className="right_content">
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faPen}
                                            />
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faTrashCan}
                                            />
                                            <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                />
                                                Active
                                            </Badge>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-sm">
                                            When
                                            <span className="text-pink fw-semibold">
                                                [Status]
                                            </span>
                                            is
                                            <span className="text-warning fw-semibold">
                                                [Created]
                                            </span>
                                            and changes to
                                            <span className="text-danger fw-semibold">
                                                [Withdrawn]
                                            </span>
                                            , notify
                                            <span className="text-primary fw-semibold">
                                                [Approver]
                                            </span>
                                        </p>
                                        <div className="right_content">
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faPen}
                                            />
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faTrashCan}
                                            />
                                            <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                />
                                                Active
                                            </Badge>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-0 text-sm">
                                            When
                                            <span className="text-pink fw-semibold">
                                                [Status]
                                            </span>
                                            is
                                            <span className="text-warning fw-semibold">
                                                [Created]
                                            </span>
                                            and changes to
                                            <span className="text-pink fw-semibold">
                                                [Submitted]
                                            </span>
                                            , notify
                                            <span className="text-primary fw-semibold">
                                                [Approver]
                                            </span>
                                        </p>
                                        <div className="right_content">
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faPen}
                                            />
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer"
                                                icon={faTrashCan}
                                            />
                                            <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                />
                                                Active
                                            </Badge>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Row>
            </div>
        </div>
    </>
);

export default WorkFlow;
