import React from 'react';
import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleSmall,
    faPen,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';

const PackerPopUpHeader = ({ showAlert, handleEditShow, editData }: any) => (
    <div className="d-flex align-items-center px-2 top-0 end-0 justify-content-end viewmod-icon">
        {showAlert && (
            <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                <FontAwesomeIcon
                    className="mx-2 cursor-pointer align-baseline m-auto"
                    icon={faPen}
                    onClick={() => handleEditShow(editData?.packers_nano_id)}
                />
                <FontAwesomeIcon
                    className="mx-2 cursor-pointer align-baseline m-auto"
                    onClick={() =>
                        showAlert(editData?.packers_nano_id, 'delete')
                    }
                    icon={faTrashCan}
                />
                {editData?.is_active && editData?.is_active === 1 ? (
                    <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                        />
                        Enabled
                    </Badge>
                ) : (
                    <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                        />
                        Disable
                    </Badge>
                )}
            </div>
        )}
    </div>
);

export default PackerPopUpHeader;
