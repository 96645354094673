import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneRegex,
    phoneLengthCheck,
} from '@constants/general.const';

import {
    IAgentFieldsArray,
    IContactInfoProps,
    IContactPhoneObj,
} from './agent.interface';

export const agentFieldArr: IAgentFieldsArray[] = [
    {
        colL: {
            name: 'agent_name',
            label: 'Agent Name*',
            type: 'text',
            placeholder: 'Agent Name',
            tabIndex: '1',
            popIndex: '31',
        },
        colR: {
            name: 'agent_address',
            label: 'Address*',
            type: 'text',
            placeholder: 'Address',
            tabIndex: '2',
            popIndex: '32',
        },
    },
    {
        colL: {
            name: 'agent_city',
            label: 'City*',
            type: 'text',
            placeholder: 'City',
            tabIndex: '3',
            popIndex: '33',
        },
        colR: {
            name: 'agent_state',
            label: 'State*',
            type: 'text',
            placeholder: 'State',
            tabIndex: '4',
            popIndex: '34',
        },
    },
    {
        colL: {
            name: 'agent_zip_code',
            label: 'Zip Code*',
            type: 'text',
            placeholder: 'Zip Code',
            tabIndex: '5',
            popIndex: '35',
        },
        colR: {
            name: 'agent_phone',
            label: 'Phone No.*',
            placeholder: 'Phone No.',
            tabIndex: '6',
            popIndex: '36',
        },
    },
    {
        colL: {
            name: 'agent_email',
            label: 'Agent Email*',
            type: 'text',
            placeholder: 'Agent Email',
            tabIndex: '7',
            popIndex: '37',
        },
        colR: {
            name: 'agent_fax',
            label: 'Fax',
            type: 'text',
            placeholder: 'Fax',
            tabIndex: '8',
            popIndex: '38',
        },
    },
    {
        colL: {
            name: 'is_active',
            label: 'Status',
            type: 'text',
            tabIndex: '9',
            popIndex: '39',
        },
    },
];

export const schema = Yup.object({
    agent_name: Yup.string().required('Name is a required field'),

    agent_email: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string()
                .email('Please enter a valid email')
                .required('Agent email is a required field'),
        })
        .nullable(true),

    agent_city: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string().required('City is a required field'),
        })
        .nullable(true),

    agent_address: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string().required('Address is required'),
        })
        .nullable(true),

    agent_state: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string().required('State is a required field'),
        })
        .nullable(true),

    agent_phone: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string()
                .required('Phone is a required field')
                .test(
                    'len',
                    `Phone number must be exactly ${minPhoneLength} characters`,
                    (val) => {
                        if (val) {
                            const cleanedPhone = val.replace(phoneRegex, '');
                            return cleanedPhone.length === phoneLengthCheck;
                        }
                        return false;
                    },
                )
                .min(
                    minPhoneLength,
                    `Phone number must be at least ${minPhoneLength} characters long`,
                ),
        })
        .nullable(true),
    agent_fax: Yup.string()
        .when('agent_type', {
            is: 'both',
            then: Yup.string()
                .nullable()
                .default(null)
                .transform((curr, orig) => (orig === '' ? null : curr))
                .test(
                    'len',
                    `Phone number must be exactly ${minPhoneLength} characters`,
                    (val) =>
                        val === null ||
                        val?.replace(phoneRegex, '').length ===
                            phoneLengthCheck,
                )
                .min(
                    minPhoneLength,
                    `Phone number must be at least ${minPhoneLength} characters long`,
                )
                .nullable(true),
        })
        .nullable(true),

    agent_zip_code: Yup.string()
        .nullable(true)
        .when('agent_type', {
            is: 'both',
            then: Yup.string()
                .required('Zip Code is a required field')
                .nullable(true),
        }),
    agent_scac_code: Yup.string()
        .when('agent_type', {
            is: 'issued',
            then: Yup.string().required('SCAC is a required field'),
        })
        .nullable(true),

    contact_info: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().nullable(true),
            email: Yup.string()
                .email('Please enter a valid email')
                .nullable(true),

            phone_number: Yup.array().of(
                Yup.object().shape({
                    phone_no: Yup.string()
                        .nullable()
                        .default(null)
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .test(
                            'len',
                            `Phone number must be exactly ${minPhoneLength} characters`,
                            (val) =>
                                val === null ||
                                val?.replace(phoneRegex, '').length ===
                                    phoneLengthCheck,
                        )
                        .min(
                            minPhoneLength,
                            `Phone number must be at least ${minPhoneLength} characters long`,
                        )
                        .nullable(true),
                }),
            ),
        }),
    ),
}).required();

export const contactPhoneObj: IContactPhoneObj = {
    type: 'Work',
    phone_no: '',
    is_default: '1',
};

export const contactObj: IContactInfoProps = {
    name: '',
    email: '',
    phone_number: [contactPhoneObj],
};

export const agentDefaultData = {
    agent_name: '',
    agent_type: 'both',
    agent_address: '',
    agent_email: '',
    agent_state: '',
    agent_city: '',
    agent_city_id: '',
    agent_zip_code: null,
    agent_fax: '',
    agent_phone: '',
    is_active: 1,
    contact_info: [contactObj],
    agent_scac_code: '',
};
