import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';

import { IRegionStep6FieldsArrayProps } from './step6.interface';
import {
    deleteRegion,
    getCityStateInfo,
    getRegionDataList,
    getRegionInfo,
} from './functions';
import { IListOption } from '../../../Interface/common';

export const schema = Yup.object({
    region_name: Yup.string().required('Please enter the Region Name'),
    region_state: Yup.array().min(
        1,
        'Please select at least one item for Region State',
    ),
}).required();

export const step6FieldArr: IRegionStep6FieldsArrayProps[] = [
    {
        colL: {
            name: 'region_name',
            label: 'Region Name*',
            type: 'text',
            placeholder: 'Region Name',
            tabIndex: '1',
            popIndex: '31',
        },
        colR: {
            name: 'region_type',
            label: 'Region Type*',
            type: 'text',
            placeholder: 'Region Type',
            tabIndex: '2',
            popIndex: '32',
        },
    },
    {
        colL: {
            name: 'region_state',
            label: 'Region State',
            type: 'text',
            placeholder: 'Region State',
            tabIndex: '7',
            popIndex: '37',
        },
        colR: {
            name: 'is_active',
            label: 'Status*',
            type: 'text',
            placeholder: 'Status',
            tabIndex: '2',
            popIndex: '32',
        },
    },
];

export const getRegionsData = async (
    dispatch: any,
    param: any,
    setDataList: any,
) => {
    const { payload } = await dispatch(getRegionDataList(param));
    const { data: payloadData, message: errorMessage } = payload || {};
    const { message, status, data } = payloadData || {};
    if (message && status) {
        setDataList(data);
    } else {
        toast.error(errorMessage);
    }
};

export const getCurrentRegionData = async (
    regionId: any,
    dispatch: any,
    setRegionData: any,
    navigate: any,
) => {
    const { payload } = await dispatch(getRegionInfo(regionId));
    if (payload?.data?.message !== '' && payload?.data?.status) {
        setRegionData(payload?.data?.data);
    } else {
        toast.error(payload?.data?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    }
};

export const regionDeleteById = async (
    officeId: string,
    dispatch: any,
    getData: any,
    showViewPopup: any,
    handleViewClose: any,
) => {
    Swal.fire({
        title: 'Delete Region?',
        text: "You won't be able to revert this operation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const { payload } = await dispatch(deleteRegion(officeId));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getData();
                if (showViewPopup) {
                    handleViewClose();
                }
            } else {
                toast.error(errorMessage);
            }
        }
    });
};

export const getRegionCityStateData = async (
    dispatch: any,
    getValues: any,
    searchStateText: any,
    setOptionArr: any,
) => {
    const { payload } = await dispatch(
        getCityStateInfo({
            filter: getValues('region_type') ?? 'State',
            searchStateText: searchStateText,
        }),
    );
    if (payload?.data?.status) {
        var arr: IListOption[] = [];
        if (getValues('region_type') === 'State') {
            payload?.data?.data.map((dataOption: any, index: any) => {
                arr.push({
                    value: dataOption?.id,
                    label: dataOption?.state_name,
                });
                return null;
            });
        } else {
            payload?.data?.data.map((dataOption: any, index: any) => {
                arr.push({
                    value: dataOption?.id,
                    label: `${dataOption?.city_name}, ${dataOption?.state_name}`,
                });
                return null;
            });
        }
        setOptionArr(arr);
    } else {
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
        }
    }
};

export const onPopUpClose = async (
    isDirty: any,
    handleClose: any,
    reset: any,
) => {
    if (isDirty) {
        Swal.fire({
            title: 'Warning',
            html:
                'Are you sure you want to close this form? <br/> ' +
                'All changes made will be lost.',
            allowOutsideClick: false,

            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: cancelButtonColor,
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then(async (result) => {
            if (result?.isConfirmed) {
                handleClose();
                reset();
            }
        });
    } else {
        handleClose();
        reset();
    }
};
