import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const StepperHeader = ({ Tag, handleShow, titleText, btnText }: any) => (
    <Row className="text-center align-items-center d-flex flex-wrap">
        <Col xs={12} md={6} className="text-center text-md-start">
            <Tag className="fw-semibold m-0 mb-3 mb-md-0">{titleText}</Tag>
        </Col>
        {btnText && (
            <Col xs={12} md={6} className="text-center text-md-end">
                <Button
                    variant="primary"
                    className="btn_type_large fw-semibold"
                    onClick={handleShow}
                >
                    {btnText}
                </Button>
            </Col>
        )}
    </Row>
);

export default StepperHeader;
