import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addHoliday,
    deleteHoliday,
    holidaysList,
    getHolidayInfo,
    updateHoliday,
} from './functions';

const defaultState = {
    isAddHoliday: false,
    isHolidaysList: false,
    isUpdateHoliday: false,
    isGetHolidayInfo: false,
    isDeleteHoliday: false,
};

export const holidaysSlice: any = createSlice({
    name: 'holidays',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addHoliday.pending.type]: (state: any, { payload }) => {
            state.isAddHoliday = true;
            state.registerResponse = payload;
        },
        [addHoliday.fulfilled.type]: (state: any, { payload }) => {
            state.isAddHoliday = false;
            state.registerResponse = payload;
        },
        [addHoliday.rejected.type]: (state: any, { payload }) => {
            state.isAddHoliday = false;
            state.registerResponse = payload;
        },

        [holidaysList.pending.type]: (state: any, { payload }) => {
            state.isHolidaysList = true;
            state.registerResponse = payload;
        },
        [holidaysList.fulfilled.type]: (state: any, { payload }) => {
            state.isHolidaysList = false;
            state.registerResponse = payload;
        },
        [holidaysList.rejected.type]: (state: any, { payload }) => {
            state.isHolidaysList = false;
            state.registerResponse = payload;
        },

        [updateHoliday.pending.type]: (state: any, { payload }) => {
            state.isUpdateHoliday = true;
            state.registerResponse = payload;
        },
        [updateHoliday.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateHoliday = false;
            state.registerResponse = payload;
        },
        [updateHoliday.rejected.type]: (state: any, { payload }) => {
            state.isUpdateHoliday = false;
            state.registerResponse = payload;
        },

        [getHolidayInfo.pending.type]: (state: any, { payload }) => {
            state.isGetHolidayInfo = true;
            state.registerResponse = payload;
        },
        [getHolidayInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetHolidayInfo = false;
            state.registerResponse = payload;
        },
        [getHolidayInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetHolidayInfo = false;
            state.registerResponse = payload;
        },

        [deleteHoliday.pending.type]: (state: any, { payload }) => {
            state.isDeleteHoliday = true;
            state.registerResponse = payload;
        },
        [deleteHoliday.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteHoliday = false;
            state.registerResponse = payload;
        },
        [deleteHoliday.rejected.type]: (state: any, { payload }) => {
            state.isDeleteHoliday = false;
            state.registerResponse = payload;
        },
    },
});

export {
    addHoliday,
    holidaysList,
    updateHoliday,
    getHolidayInfo,
    deleteHoliday,
};

export default holidaysSlice.reducer;
