import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { Button, Modal } from 'react-bootstrap';

import 'cropperjs/dist/cropper.css';

const ProfileCrop = ({ open, image, handleClose, saveCroppedFile }: any) => {
    const [cropper, setCropper] = useState<any>();
    const getCropData = async () => {
        if (typeof cropper !== 'undefined') {
            await saveCroppedFile(cropper.getCroppedCanvas().toDataURL());
            handleClose();
        }
    };

    const onClose = async () => {
        handleClose();
    };
    return (
        <div>
            <Modal size="lg" show={open} onHide={onClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div style={{ width: '100%' }}>
                            <br />
                            <Cropper
                                style={{ height: 400, width: '100%' }}
                                zoomTo={0.5}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                src={image}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                onInitialized={(instance: any) => {
                                    setCropper(instance);
                                }}
                                guides={true}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={getCropData}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProfileCrop;
