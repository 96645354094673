import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';
import ToastBodyContainer from '@components/ToastContainer';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';

import DepartmentPopupStep3 from '@app/CreateOrganization/step3/departmentPopupStep3';
import StepperHeader from '@app/CreateOrganization/stapperComponent/stepperHeader';
import StepperActionBtn from '@app/CreateOrganization/stapperComponent/StepperActionBtn';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';

import { departmentDeleteById, getDepartmentData } from './step3.const';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const Step2: React.FC = () => {
    const dispatch = useDispatch<any>();

    const { departmentStep3Data } = useSelector((state: any) => state);
    const { isDeleteDepartment, isGetDepartmentDataList } = departmentStep3Data;

    const [show, setShow] = useState<boolean>(false);
    const [dataList, setDataList] = useState<Array<any> | null>([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getData();
    }, []);

    const deleteById = async (officeId: string) => {
        departmentDeleteById(officeId, dispatch, getData);
    };

    const getData = async () => {
        getDepartmentData(dispatch, { nanoId: '' }, setDataList);
    };

    return (
        <section className="mfnw_create_organization mfnw_create_step2">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Organization
                        </Link>
                    </Col>
                    <Col md={12}>
                        <StepBar />
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <StepperHeader
                                        Tag={'h5'}
                                        handleShow={handleShow}
                                        btnText={'Add New Department'}
                                        titleText={'Organization Departments'}
                                    />
                                    <hr className="border-border-opacity-10" />
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="mt-3 g-0">
                                            {isDeleteDepartment ||
                                            isGetDepartmentDataList ? (
                                                <LoaderBtn />
                                            ) : (
                                                <StepBadgeComponent
                                                    dataList={dataList}
                                                    deleteById={deleteById}
                                                    name={'department_name'}
                                                    nanoId={
                                                        'department_nano_id'
                                                    }
                                                    handleViewShow={false}
                                                    className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                                />
                                            )}
                                        </Row>
                                        <StepperActionBtn
                                            dataList={dataList}
                                            nextStep={routesPath?.step4}
                                            previousStep={routesPath?.step2}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <DepartmentPopupStep3
                show={show}
                getData={getData}
                handleClose={handleClose}
            />
            <ToastBodyContainer />
        </section>
    );
};

export default Step2;
