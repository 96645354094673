import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { Container, Modal } from 'react-bootstrap';

import {
    showDeleteConfirmationDialog,
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import {
    deleteOfferNote,
    offerNotesList,
} from '@app/JobList/OfferNotes/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import AddNotePopup from '@app/JobList/OfferNotes/addNotePopup';
import NoteTable from '@app/JobList/OfferNotes/noteTable';
import DocumentFilters from '@app/JobList/Documents/documentFilters';
import { setTimeZone } from '@utils/utilFunctions';

const ViewNoteListPopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    listCall,
    isDisabled,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [resMessage, setResMessage] = useState<any>(null);
    const [filters, setFilters] = useState<any>();
    const [shipper, setShipper] = useState<any>();
    const [currentPageData, setCurrentPageData] = useState<number[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [isEditNote, setIsEditNote] = useState<any>(null);
    const [searchText, setSearchText] = useState<string | null>('');

    const [noteData, setNoteData] = useState<string | null>('');

    const [showNotePopup, setShowNotePopup] = useState<boolean>(false);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getNotesList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    useEffect(() => {
        if (showPopup) {
            getFilters();
        }
    }, [showPopup]);

    const handleNoteShow = () => {
        setShowNotePopup(true);
    };

    const handleNoteClose = () => {
        setShowNotePopup(false);
    };

    const getFilters = async () => {
        FiltersData(
            searchText,
            getNotesList,
            setFilters,
            'notes_list',
            dispatch,
            navigate,
        );
    };

    const getNotesList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            offerNotesList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
                offers_nano_id: offerDataObj?.offers_nano_id,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setResMessage(payload?.data?.message);
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setShipper(payload?.data?.shipper_name);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getNotesListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getNotesListAndUpdateFilter(updateFilter);
    };

    const getNotesListAndUpdateFilter = (updateFilter: any) => {
        setFilters(updateFilter);
        getNotesList(updateFilter.currentPage, updateFilter);
    };

    const editNote = async (noteId: any, editAction: string, note: any) => {
        setNoteData(note);
        setIsEditNote(noteId);
        handleNoteShow();
    };

    const showAlert = async (id: any) => {
        const confirmationOptions = {
            title: 'Delete Note?',
            text: "You won't be able to revert this operation!",
            icon: 'warning',
            customClass: 'z-index-2',
            confirmButtonText: 'Yes, delete it!',
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );
        if (isConfirmed) {
            const { payload } = await dispatch(deleteOfferNote(id));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                getNotesList(1, filters);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    const onClose = async () => {
        handleClose(totalDataCount);
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={() => onClose()}
                backdrop="static"
                centered
                size="lg"
                backdropClassName="modal-backdrop-upper"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Job Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="mfnw__dashboard-content pt-1">
                            <Container className="mb-5">
                                <DocumentFilters
                                    setSearchText={setSearchText}
                                    searchText={searchText}
                                    isDisabled={isDisabled}
                                    setIsEditNote={setIsEditNote}
                                    handleNoteShow={handleNoteShow}
                                    onClickAddBtn={() => {
                                        setIsEditNote(null);
                                        handleNoteShow();
                                    }}
                                    totalDataCount={totalDataCount}
                                    filters={filters}
                                    handleLimitChange={handleLimitChange}
                                    addBtnText={'ADD NOTES'}
                                    listTitleText={'NOTES LIST'}
                                    shipper={shipper}
                                />
                                <NoteTable
                                    currentPageData={currentPageData}
                                    setTimeZone={setTimeZone}
                                    editNote={editNote}
                                    isDisabled={isDisabled}
                                    showAlert={showAlert}
                                    resMessage={resMessage}
                                />
                                <div className="mt-4">
                                    {currentPageData &&
                                        currentPageData.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={'← Previous'}
                                                nextLabel={'Next →'}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={
                                                    'pagination'
                                                }
                                                previousLinkClassName={
                                                    'pagination__link'
                                                }
                                                nextLinkClassName={
                                                    'pagination__link'
                                                }
                                                disabledClassName={
                                                    'pagination__link--disabled'
                                                }
                                                activeClassName={
                                                    'pagination__link--active active'
                                                }
                                                pageRangeDisplayed={2}
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-link rounded-start"
                                                nextClassName="page-link rounded-end"
                                                forcePage={
                                                    filters?.currentPage - 1
                                                }
                                            />
                                        )}
                                </div>
                            </Container>
                        </div>
                    </>
                </Modal.Body>
            </Modal>

            {showNotePopup && (
                <AddNotePopup
                    showPopup={showNotePopup}
                    handleClose={handleNoteClose}
                    offerDataObj={offerDataObj}
                    isEditNote={isEditNote}
                    noteData={noteData}
                    getNotesList={getNotesList}
                    isDisabled={isDisabled}
                    listCall={listCall}
                />
            )}
        </>
    );
};

export default ViewNoteListPopup;
