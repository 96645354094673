import React from 'react';
import { Badge } from 'react-bootstrap';

const RegionsDiv = ({ regions, type = 'Origin' }: any) => (
    <div className="overflow-table-auto ">
        <label htmlFor="lable" className="text-uppercase text-light text-sm">
            REGION <span className="text-capitalize">({type})</span>
        </label>
        <p className="text-dark mb-0 text-base d-flex w-100 overflow-scroll-auto">
            {regions && regions.length > 0
                ? regions.map((data: any, regionsIndex: number) => (
                      <h6 className="me-2 text-capitalize" key={regionsIndex}>
                          <Badge bg="info">{data?.region_name}</Badge>
                      </h6>
                  ))
                : '(Not Set)'}
        </p>
    </div>
);
export default RegionsDiv;
