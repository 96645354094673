import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';

import LocationR from '@images/location-round.svg';
import Destination from '@images/destination.svg';

import InfoDiv from '@app/OffersList/offerContentComponent/infoDiv';
import { dateTimeZone } from '@utils/utilFunctions';

const DashboardOfferLIst = ({ handleViewShow, key, itemData }: any) => (
    <Col xs={12} key={key} className="mb-3">
        <Card
            key={key}
            className="mfnw__card-box-grid p-4"
            onClick={() => handleViewShow(itemData?.offers_nano_id)}
        >
            <div className="mfnw_top_card_content">
                <h5 className="text-uppercase">
                    {itemData?.shipperdata?.shipper_name ?? itemData?.shipper}
                </h5>
                <Row className="d-flex flex-wrap">
                    <Col className="d-flex text-truncate">
                        <Image src={LocationR} />
                        <span className="ms-2 text-truncate">
                            {`${itemData?.address_origin?.city}, ${itemData?.address_origin?.countryCode}`}
                        </span>
                    </Col>
                    <Col className="d-flex text-truncate">
                        <Image src={Destination} />
                        <span className="ms-2 text-truncate">
                            {`${itemData?.address_destination?.city}, ${itemData?.address_destination?.countryCode}`}
                        </span>
                    </Col>
                </Row>
            </div>
            <div className="mfnw__content-center">
                <Row className="mt-3">
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="Weight:"
                            fontSize={true}
                            value={` ${itemData?.estimatedWeight?.toLocaleString()} lbs`}
                        />
                    </Col>
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="Line Haul:"
                            fontSize={true}
                            value={`${itemData?.line_haule_data?.miles} miles`}
                        />
                    </Col>
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="LH Disc:"
                            fontSize={true}
                            value={`${itemData?.line_haule_data?.discount}%`}
                        />
                    </Col>
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="Pack:"
                            fontSize={true}
                            value={dateTimeZone(itemData?.packDate)}
                        />
                    </Col>
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="Pick Up:"
                            fontSize={true}
                            value={dateTimeZone(itemData?.pickupDate)}
                        />
                    </Col>
                    <Col xs={6} md={4}>
                        <InfoDiv
                            name="RDD:"
                            fontSize={true}
                            value={dateTimeZone(itemData?.deliveryDate)}
                        />
                    </Col>
                </Row>
            </div>
            <div className="row_bottom">
                <Row>
                    <Col xs={6} xxl={5}>
                        <label htmlFor="driverLineHaul">
                            <span>Pack ($):</span> N/A
                        </label>
                    </Col>
                    <Col xs={6} xxl={5}>
                        <label htmlFor="driverLineHaul">
                            <span>Haul $: </span> N/A
                        </label>
                    </Col>
                </Row>
            </div>
        </Card>
    </Col>
);

export default DashboardOfferLIst;
