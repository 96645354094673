import React from 'react';
import { ToastContainer } from 'react-toastify';

const ToastBodyContainer = () => (
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
    />
);

export default ToastBodyContainer;
