import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { routesPath } from '@constants/routesNavigate';
import {
    searchTimeOut,
    pageLimitList,
    showDeleteConfirmationDialog,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';

import { clearResponse } from '@redux/app/appSlice';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import AgentViewPopup from '@app/SettingsLists/Agents/agentViewPopup';
import AgentsAddOrEditPopup from '@app/SettingsLists/Agents/agentsAddOrEditPopup';
import AgentTable from '@app/SettingsLists/Agents/agentTable';
import {
    agentList,
    deleteAgent,
    getAgentInfo,
} from '@app/SettingsLists/Agents/agent.function';
import ListHeader from '@app/SettingsLists/listHeader';
import { confimationDialogBoxText } from '@app/SettingsLists/lists.const';

import { IFilterProps } from '../../../Interface/common';

const AgentList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common } = useSelector((state: any) => state);
    const { response } = common;

    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const handleViewClose = () => {
        setShowViewPopup(false);
        setAgentsData(null);
    };

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<any>();
    const [searchText, setSearchText] = useState<string | null>(null);
    const [agentsData, setAgentsData] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [resMessage, setResMessage] = useState<string | null>(null);

    const handleEditShow = (nanoId: string) => {
        nanoId && getAgentData(nanoId);
        setShowViewPopup(false);
        setShowEditPopup(true);
    };

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getAgentList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getFilters = async () => {
        FiltersData(
            searchText,
            getAgentList,
            setFilters,
            'agent_list',
            dispatch,
            navigate,
        );
    };

    const getAgentData = async (nanoId: string) => {
        const { payload } = await dispatch(getAgentInfo(nanoId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setAgentsData(payload?.data?.data);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getAgentList = async (currentPage: number, res: any) => {
        const newFilters: IFilterProps = {
            perPageLimit: res?.perPageLimit || 5,
            currentPage: currentPage || 1,
        };

        const { payload } = await dispatch(
            agentList({ filters: newFilters, searchText }),
        );
        if (payload?.data?.status) {
            const {
                message,
                filters: agentFiltersPayload,
                data: currentPageDataPayload,
                meta,
            } = payload.data;

            setResMessage(message);
            setFilters(agentFiltersPayload);
            setCurrentPageData(currentPageDataPayload);
            setPageCount(meta?.last_page);
            setTotalDataCount(meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handleFilterChange = async (updateFilter: any) => {
        setFilters(updateFilter);
        getAgentList(updateFilter?.currentPage, updateFilter);
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        handleFilterChange(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        let updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        handleFilterChange(updateFilter);
    };

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);
    const handleEditClose = () => setShowEditPopup(false);
    const showAlert = async (id: string, type: string) => {
        const confimaBoxText = confimationDialogBoxText(type);

        const confirmationOptions = {
            title: confimaBoxText?.swalTitle,
            text: confimaBoxText?.swalText,
            icon: 'warning',
            confirmButtonText: confimaBoxText?.confirmButtonText,
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(deleteAgent({ id, type: type }));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getAgentList(1, filters);
                setShowViewPopup(false);
            } else {
                toast.error(errorMessage);
            }
        }
    };

    const handleViewShow = (nanoId: string) => {
        setShowViewPopup(true);
        getAgentData(nanoId);
    };

    return (
        <div>
            <ListHeader
                title={'Agent List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShow}
                handleLimitChange={handleLimitChange}
                buttonText={'Add New Agent'}
            />
            <AgentTable
                currentPageData={currentPageData}
                handleViewShow={handleViewShow}
                handleEditShow={handleEditShow}
                showAlert={showAlert}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                agentFilters={filters}
                resMessage={resMessage}
            />
            {showPopup && (
                <AgentsAddOrEditPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getAgentList(1, filters)}
                />
            )}

            {showEditPopup && (
                <AgentsAddOrEditPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getAgentList(1, filters)}
                    editData={agentsData}
                />
            )}

            {showViewPopup && agentsData && (
                <AgentViewPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    handleEditShow={handleEditShow}
                    data={agentsData}
                    deleteById={showAlert}
                />
            )}
        </div>
    );
};

export default AgentList;
