import React from 'react';

import { IInfoDivProps } from '../offer.interface';

const MainInfoDiv = ({ name, value }: IInfoDivProps) => (
    <div className="mb-2 mb-md-3">
        <label htmlFor="lable" className="text-uppercase text-light text-sm">
            {name}
        </label>
        <p className="text-dark fw-semibold fs-18 mb-0 text-uppercase">
            {value}
        </p>
    </div>
);

export default MainInfoDiv;
