import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    changePassword,
    sendOtp,
    verifyUser,
} from '@app/Auth/EmailVerify/functions';

const defaultState = {
    isVerifyUser: false,
    isSendOtp: false,
    isChangePassword: false,
    verifyData: {
        OTP: '',
    },
};

export const verifySlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {
        loginChange: (state, { payload }) => {
            state.verifyData = payload;
            return state;
        },
    },
    extraReducers: {
        [verifyUser.pending.type]: (state: any, { payload }) => {
            state.isVerifyUser = true;
            state.registerResponse = payload;
        },
        [verifyUser.fulfilled.type]: (state: any, { payload }) => {
            state.isVerifyUser = false;
            state.registerResponse = payload;
        },
        [verifyUser.rejected.type]: (state: any, { payload }) => {
            state.isVerifyUser = false;
            state.registerResponse = payload;
        },

        [sendOtp.pending.type]: (state: any, { payload }) => {
            state.isSendOtp = true;
            state.registerResponse = payload;
        },
        [sendOtp.fulfilled.type]: (state: any, { payload }) => {
            state.isSendOtp = false;
            state.registerResponse = payload;
        },
        [sendOtp.rejected.type]: (state: any, { payload }) => {
            state.isSendOtp = false;
            state.registerResponse = payload;
        },

        [changePassword.pending.type]: (state: any, { payload }) => {
            state.isChangePassword = true;
            state.registerResponse = payload;
        },
        [changePassword.fulfilled.type]: (state: any, { payload }) => {
            state.isChangePassword = false;
            state.registerResponse = payload;
        },
        [changePassword.rejected.type]: (state: any, { payload }) => {
            state.isChangePassword = false;
            state.registerResponse = payload;
        },
    },
});

export const { loginChange } = verifySlice.actions;
export { verifyUser, sendOtp, changePassword };
export default verifySlice.reducer;
