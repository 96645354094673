/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from 'react';
const MediaBreakpoints = {
    downXs: 575.98,
    downSm: 767.98,
    upSm: 576,
    upMd: 768,
    upMd1: 768,
    downMd: 991.98,
    upLg: 992,
    downLg: 1199.98,
    upXl: 1200,
    downXl: 1399.98,
    upXxl: 1400,
};

export default MediaBreakpoints;

export const useDeviceDetect = () => {
    const checkForDevice = () => {
        // Check if window is defined before using it
        if (typeof window !== 'undefined') {
            const windowWidth = window.innerWidth;
            return windowWidth < MediaBreakpoints.upMd;
        }
        return false; // Default value for server-side rendering
    };

    const checkForTabDevice = () => {
        // Check if window is defined before using it
        if (typeof window !== 'undefined') {
            const windowWidth = window.innerWidth;
            return windowWidth < MediaBreakpoints.downLg;
        }
        return false; // Default value for server-side rendering
    };

    const getDeviceHeight = () => {
        // Check if window is defined before using it
        if (typeof window !== 'undefined') {
            return window.innerHeight;
        }
        return 0; // Default value for server-side rendering
    };

    const [isMobile, setIsMobile] = useState(checkForDevice());
    const [isTab, setIsTab] = useState(checkForTabDevice());
    const [orientation, setOrientation] = useState(getDeviceHeight());

    const isMobileRef = useRef(isMobile);
    const isTabRef = useRef(isTab);

    useEffect(() => {
        isMobileRef.current = isMobile;
    }, [isMobile]);

    useEffect(() => {
        isTabRef.current = isTab;
    }, [isTab]);

    useEffect(() => {
        const handlePageResized = () => {
            const checkIsMobile = checkForDevice();
            const checkIsTab = checkForTabDevice();
            if (checkIsMobile !== isMobileRef.current) {
                setIsMobile(checkIsMobile);
            }
            if (checkIsTab !== isTabRef.current) {
                setIsTab(checkIsTab);
            }
            setOrientation(getDeviceHeight());
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handlePageResized);
            window.addEventListener('orientationchange', handlePageResized);
            window.addEventListener('load', handlePageResized);
            window.addEventListener('reload', handlePageResized);

            return () => {
                window.removeEventListener('resize', handlePageResized);
                window.removeEventListener(
                    'orientationchange',
                    handlePageResized,
                );
                window.removeEventListener('load', handlePageResized);
                window.removeEventListener('reload', handlePageResized);
            };
        }
    }, []);

    return {
        isMobile,
        isTab,
        orientation,
    };
};
