import React from 'react';
import {
    Badge,
    Button,
    Col,
    Dropdown,
    Form,
    Image,
    Row,
} from 'react-bootstrap';

import { pageLimitList } from '@constants/general.const';

import Search from '@images/search.svg';
import Bin from '@images/trash-can-regular.svg';

const DocumentFilters = ({
    totalDataCount,
    deleteArr,
    showAlert,
    handleLimitChange,
    filters,
    setSearchText,
    searchText,
    isDisabled,
    onClickAddBtn,
    addBtnText,
    listTitleText,
    shipper,
}: any) => (
    <>
        <Row className="d-flex flex-wrap">
            {shipper && (
                <Col xs={12} md={6} lg={5} className="mb-3">
                    <div className="text-capitalize">
                        <b>Shipper Name:</b> {shipper}
                    </div>
                </Col>
            )}
        </Row>
        <Row className="d-flex justify-content-between align-items-center d-flex flex-wrap">
            <Col xs={12} md={6} lg={5} xl={4}>
                <div className="position-relative ms-xl-0">
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        className="mfnw__login-form-control rounded-4 mb-0 mfnw__searchbar me-2 mfnw__searchbar px-3 py-3 text-light text-base"
                        aria-label="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    {searchText ? null : (
                        <Image
                            src={Search}
                            className="position-absolute top-0 end-0 me-3 mt-3"
                        />
                    )}
                </div>
            </Col>
            <Col xs={12} md={6} lg={4} xl={3} className="mt-3 mt-md-0">
                {!isDisabled && (
                    <div className="text-end">
                        <Button
                            variant="primary"
                            className="btn-cancel1 w-100 text-base fw-bold"
                            tabIndex={5}
                            onClick={() => {
                                onClickAddBtn();
                            }}
                        >
                            {addBtnText}
                        </Button>
                    </div>
                )}
            </Col>
        </Row>
        <Row className="mt-4 justify-content-between align-items-center mb-2">
            <Col xs={7} className="title_common_main align-items-center">
                <div className="d-flex">
                    <h5 className="fw-semibold text-sm my-auto text-end text-truncate">
                        {listTitleText}
                    </h5>
                    <Badge className="d-flex align-items-center my-auto mb-1 ms-2">
                        {totalDataCount}
                    </Badge>
                    {deleteArr && deleteArr?.length !== 0 && (
                        <Image
                            src={Bin}
                            onClick={() => {
                                showAlert(null);
                            }}
                            className="text-light ms-2 mb-1"
                        />
                    )}
                </div>
            </Col>
            <Col xs={5}>
                <div className="d-flex float-end">
                    <div className="dropdown_filter-top">
                        <label
                            className="text-sm ps-3 text-light"
                            htmlFor="shipperInput"
                        >
                            Limit:
                        </label>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                className="limit"
                            >
                                {filters?.perPageLimit}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {pageLimitList?.map(
                                    (item: any, index: number) => (
                                        <Dropdown.Item
                                            active={
                                                filters?.perPageLimit === item
                                            }
                                            onClick={() => {
                                                handleLimitChange(item);
                                            }}
                                            key={index}
                                            eventKey={`#/action-${index}`}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    ),
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Col>
        </Row>
    </>
);

export default DocumentFilters;
