import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { dateTimeZone } from '../../../utils/utilFunctions';
import { useOfferPopup } from '../../../constants/offer.const';

const SITJobTable = ({
    currentPageData,
    getSITJobsList,
    resMessage,
}: // showAlert,
any) => {
    const { offerNoteData } = useSelector((state: any) => state);
    const { isDeleteOfferNote, isOfferNotesList } = offerNoteData;
    const {
        handleViewShow,
        renderViewPopup,
        renderEditPopup,
        showViewPopup,
        showEditPopup,
        showJobViewPopup,
        showJobEditPopup,
        renderJobViewPopup,
        renderJobEditPopup,
    } = useOfferPopup();
    useEffect(() => {
        getSITJobsList();
    }, [showViewPopup == false]);
    return (
        <div className="mfnw_note_table">
            <Table className="table px-4 mb-0">
                <thead>
                    <tr>
                        <th className="fw-semibold text-dark text-sm">
                            Weight
                        </th>
                        <th className="fw-semibold text-dark text-sm">
                            Shipper
                        </th>
                        <th className="fw-semibold text-dark text-sm">RDD</th>
                        <th className="fw-semibold text-dark text-sm">
                            Date In
                        </th>
                        <th className="fw-semibold text-dark text-sm">
                            Date Out
                        </th>
                        <th className="fw-semibold text-dark text-sm">
                            Destination
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {isOfferNotesList || isDeleteOfferNote ? (
                        <tr>
                            <span className="ms-5">
                                <LoaderBtn size="sm" />
                            </span>
                        </tr>
                    ) : (
                        <>
                            {currentPageData && currentPageData.length !== 0 ? (
                                currentPageData.map(
                                    (offer: any, index: number) => (
                                        <>
                                            <tr
                                                onClick={() =>
                                                    handleViewShow(
                                                        offer?.offers_nano_id,
                                                    )
                                                }
                                            >
                                                <td className="new_table_data text-truncate">
                                                    {offer?.estimatedWeight}
                                                </td>
                                                <td className="new_table_data text-truncate">
                                                    <span className="text-uppercase">
                                                        {offer?.shipperdata
                                                            ?.shipper_name ??
                                                            offer?.shipper}
                                                    </span>
                                                </td>
                                                <td className="text-dark text-base new_table_data text-truncate">
                                                    {dateTimeZone(
                                                        offer?.deliveryDate,
                                                    )}
                                                </td>
                                                <td className="text-dark text-base new_table_data text-truncate">
                                                    {dateTimeZone(
                                                        offer?.date_in,
                                                    )}
                                                </td>
                                                <td className="text-dark text-base new_table_data text-truncate">
                                                    {dateTimeZone(
                                                        offer?.date_out,
                                                    )}
                                                </td>
                                                <td className="text-dark text-base m-0 new_table_data text-truncate">
                                                    <span className="text-sm">
                                                        {`${offer?.address_destination?.city},  ${offer?.address_destination?.stateTown}`}
                                                    </span>
                                                </td>
                                            </tr>
                                        </>
                                    ),
                                )
                            ) : (
                                <tr>
                                    <td colSpan={12} className="p-0">
                                        {isOfferNotesList ? (
                                            <span className="ms-5">
                                                <LoaderBtn size="sm" />
                                            </span>
                                        ) : (
                                            <div className="text-center my-3">
                                                <h3>{resMessage}</h3>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                    {/* Add more rows as needed */}
                </tbody>
            </Table>

            {showViewPopup && renderViewPopup('View Job', true, () => {})}
            {showEditPopup &&
                renderEditPopup('Edit Offer', true, () => getSITJobsList())}

            {showJobViewPopup && renderJobViewPopup(() => getSITJobsList())}
            {showJobEditPopup && renderJobEditPopup(() => getSITJobsList())}
        </div>
    );
};

export default SITJobTable;
