import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { searchTimeOut } from '@constants/general.const';

import RolesNotFound from '@images/roles-not-found.svg';

import RolesPopupStep4 from '@app/CreateOrganization/step4/rolesPopupStep4';
import OrganizationTabHeader from '@app/CreateOrganization/Organizations/organizationTabHeader';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';
import {
    getRoleData,
    roleDeleteById,
} from '@app/CreateOrganization/step4/step4.const';

const RolesList = ({ organizationId }: any) => {
    const dispatch = useDispatch<any>();

    const { roleStep4Data } = useSelector((state: any) => state);
    const { isDeleteRole, isGetRoleDataList } = roleStep4Data;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<Array<any> | null>(null);
    const [search, setSearch] = useState<string | null>();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (search || search === '') {
            const getDataSearch: any = setTimeout(() => {
                getData();
            }, searchTimeOut);
            return () => clearTimeout(getDataSearch);
        }
    }, [search]);

    const getData = async () => {
        getRoleData(
            dispatch,
            { nanoId: organizationId, searchText: search },
            setDataList,
        );
    };

    const deleteById = async (officeId: string) => {
        roleDeleteById(officeId, dispatch, getData);
    };

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);

    return (
        <>
            <OrganizationTabHeader
                title={'Role List'}
                search={search}
                setSearch={setSearch}
                handleShow={handleShow}
                buttonText={'Add New Roles'}
            />
            <div className="d-flex flex-wrap gap-2">
                {isDeleteRole || isGetRoleDataList ? (
                    <LoaderBtn />
                ) : (
                    dataList &&
                    dataList.length > 0 && (
                        <StepBadgeComponent
                            dataList={dataList}
                            deleteById={deleteById}
                            name={'role_name'}
                            nanoId={'roles_nano_id'}
                            handleViewShow={false}
                            className="mnfw__badge_light lh-base text-dark py-2 fs-6 fw-semibold d-flex align-align-items-center bg-white border-opacity-25"
                        />
                    )
                )}
            </div>
            <NotFoundPages
                currentPageData={dataList}
                image={RolesNotFound}
                message={`Lorem Ipsum`}
            />
            <RolesPopupStep4
                show={showPopup}
                getData={getData}
                handleClose={handleClose}
                organizationId={organizationId}
            />
        </>
    );
};

export default RolesList;
