/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Row,
    Modal,
    ListGroup,
    Breadcrumb,
    Form,
} from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faPlus } from '@fortawesome/fontawesome-pro-regular';
import {
    faPen,
    faTrashCan,
    faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const faPlusIcon = faPlus as IconProp;
const Step7: React.FC = () => {
    //  ask fore type

    const [showEventPopup, setShowEvent] = useState<boolean>(false);

    const handleCloseEvent = () => setShowEvent(false);
    const handleShowEvent = () => setShowEvent(true);

    return (
        <section className="mfnw_create_organization mfnw_create_step5">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Organization
                        </Link>
                    </Col>
                    <Col md={12}>
                        <StepBar />
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <div className="d-flex justify-content-between align-items-center min-h-56">
                                        <h4 className="fw-semibold m-0">
                                            Organization Workflow
                                        </h4>
                                        <Link
                                            to="/final-step"
                                            className="btn_type_large fw-semibold btn btn-primary"
                                        >
                                            Add New Workflow
                                        </Link>
                                    </div>
                                    <hr className="border-border-opacity-10" />
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="mt-3 g-0 mfnw__employee_bording">
                                            <div className="mfnw__employee_bording-card">
                                                <div className="left_content">
                                                    <h5>
                                                        Employee Onboarding
                                                        V.1.0
                                                    </h5>
                                                    <p>
                                                        Sets up accounts for new
                                                        MoveFlows employees.
                                                    </p>
                                                </div>
                                                <div className="right_content">
                                                    <FontAwesomeIcon
                                                        className="mx-2 cursor-pointer"
                                                        icon={faPen}
                                                    />
                                                    <FontAwesomeIcon
                                                        className="mx-2 cursor-pointer"
                                                        icon={faTrashCan}
                                                    />
                                                    <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                        <FontAwesomeIcon
                                                            icon={faCircleSmall}
                                                        />
                                                        Active
                                                    </Badge>
                                                </div>
                                            </div>
                                            <Row className="g-0 mt-3">
                                                <Col md={6} xl={4}>
                                                    <div className="w-100">
                                                        <p className="text-sm">
                                                            WORKFLOW TYPE
                                                        </p>
                                                        <div className="d-flex flex-wrap">
                                                            <Badge
                                                                bg="light"
                                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                                            >
                                                                <span className="px-2">
                                                                    Onboarding
                                                                </span>
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 mt-3">
                                                        <p className="text-sm">
                                                            REQUESTERS
                                                        </p>
                                                        <div className="d-flex flex-wrap">
                                                            <Badge
                                                                bg="light"
                                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                                            >
                                                                <span className="px-2">
                                                                    Onboarding
                                                                    Team
                                                                </span>
                                                            </Badge>
                                                            <Badge
                                                                bg="light"
                                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                                            >
                                                                <span className="px-2">
                                                                    Human
                                                                    Resources
                                                                </span>
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 mt-3">
                                                        <p className="text-sm">
                                                            APPROVERS
                                                        </p>
                                                        <div className="d-flex flex-wrap">
                                                            <Badge
                                                                bg="light"
                                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                                            >
                                                                <span className="px-2">
                                                                    Managers
                                                                </span>
                                                            </Badge>
                                                            <Badge
                                                                bg="light"
                                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25"
                                                            >
                                                                <span className="px-2">
                                                                    VPs
                                                                </span>
                                                            </Badge>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 mt-3">
                                                        <p className="text-sm">
                                                            WORKFLOW STEPS
                                                        </p>
                                                        <Breadcrumb className="text-sm">
                                                            <Breadcrumb.Item className="fw-normal">
                                                                Request Made
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item className="fw-normal">
                                                                Finance Approval
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item className="fw-normal">
                                                                CFO Approval
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div>
                                                </Col>
                                                <Col md={6} xl={8}>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="text-sm">
                                                            WORKFLOW EVENT
                                                        </span>
                                                        <Link
                                                            to="#"
                                                            className="text-primary text-sm"
                                                            onClick={
                                                                handleShowEvent
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faPlusIcon
                                                                }
                                                            />
                                                            Add New Event
                                                        </Link>
                                                    </div>
                                                    <ListGroup className="mt-3">
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0 text-sm">
                                                                    When
                                                                    <span className="text-pink fw-semibold">
                                                                        [Status]
                                                                    </span>
                                                                    is
                                                                    <span className="text-warning fw-semibold">
                                                                        [Created]
                                                                    </span>
                                                                    and changes
                                                                    to
                                                                    <span className="text-pink fw-semibold">
                                                                        [Submitted]
                                                                    </span>
                                                                    , notify
                                                                    <span className="text-primary fw-semibold">
                                                                        [Approver]
                                                                    </span>
                                                                </p>
                                                                <div className="right_content">
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faPen
                                                                        }
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faTrashCan
                                                                        }
                                                                    />
                                                                    <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faCircleSmall
                                                                            }
                                                                        />
                                                                        Active
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0 text-sm">
                                                                    When
                                                                    <span className="text-pink fw-semibold">
                                                                        [Status]
                                                                    </span>
                                                                    is
                                                                    <span className="text-warning fw-semibold">
                                                                        [Created]
                                                                    </span>
                                                                    and changes
                                                                    to
                                                                    <span className="text-danger fw-semibold">
                                                                        [Withdrawn]
                                                                    </span>
                                                                    , notify
                                                                    <span className="text-primary fw-semibold">
                                                                        [Approver]
                                                                    </span>
                                                                </p>
                                                                <div className="right_content">
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faPen
                                                                        }
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faTrashCan
                                                                        }
                                                                    />
                                                                    <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faCircleSmall
                                                                            }
                                                                        />
                                                                        Active
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                        <ListGroup.Item>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0 text-sm">
                                                                    When
                                                                    <span className="text-pink fw-semibold">
                                                                        [Status]
                                                                    </span>
                                                                    is
                                                                    <span className="text-warning fw-semibold">
                                                                        [Created]
                                                                    </span>
                                                                    and changes
                                                                    to
                                                                    <span className="text-pink fw-semibold">
                                                                        [Submitted]
                                                                    </span>
                                                                    , notify
                                                                    <span className="text-primary fw-semibold">
                                                                        [Approver]
                                                                    </span>
                                                                </p>
                                                                <div className="right_content">
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faPen
                                                                        }
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        className="mx-2 cursor-pointer"
                                                                        icon={
                                                                            faTrashCan
                                                                        }
                                                                    />
                                                                    <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faCircleSmall
                                                                            }
                                                                        />
                                                                        Active
                                                                    </Badge>
                                                                </div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </Row>
                                        <Row>
                                            <Col md={6} className="mt-5">
                                                <Button
                                                    variant="default"
                                                    className="text-uppercase button__details"
                                                >
                                                    Back
                                                </Button>
                                            </Col>
                                            <Col md={6} className="mt-5">
                                                <Button
                                                    variant="primary"
                                                    className="text-uppercase button__details"
                                                >
                                                    Continue
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                show={showEventPopup}
                onHide={handleCloseEvent}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Create New Event
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Row className="justify-content-center">
                        <Col md={12} className="my-3">
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>Flow: Notify when status change</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={6}>
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>From: Created</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={6}>
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>To: Submited</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={12}>
                            <Button variant="primary w-100 mt-5 text-uppercase fw-semibold">
                                Save
                            </Button>
                            <Button className="btn-cancel mt-3 w-100 text-uppercase fw-semibold">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    );
};
export default Step7;
