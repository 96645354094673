import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Col,
    Row,
    Modal,
    Image,
    Form,
    FormLabel,
    Badge,
} from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';

import {
    searchTimeOut,
    showDeleteConfirmationDialog,
} from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import memberAdd from '@images/add-membership.svg';

import {
    addGroupMemberData,
    getUsersList,
} from '@app/UserListing/MembershipList/functions';

import { IMembershipProps } from './membership.interface';
import { IListOption } from '../../../Interface/common';
import { membershipSchema } from './membership.const';

const AddMembershipPopup = ({
    showPopup,
    handleClose,
    getDataListCall,
    groupInfo,
}: any) => {
    const dispatch = useDispatch<any>();

    const { membershipData } = useSelector((state: any) => state);
    const { isGetUsersList, isAddGroupMemberData } = membershipData;

    const [groupUserArr, setGroupUserArr] = useState<IListOption[]>([]);
    //  ask fore type

    const [searchStateText, setSearchStateText] = useState<any>(null);
    const [selectList, setSelectList] = useState<IListOption[]>([]);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
    } = useForm<IMembershipProps>({
        resolver: yupResolver(membershipSchema),
        defaultValues: {
            group_id: groupInfo?.group_name || '',
            users_id: [],
        },
        mode: 'onBlur',
    });

    useEffect(() => {
        if (groupInfo?.group_name) {
            setValue('group_id', groupInfo?.group_name);
        }
    }, [groupInfo]);

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getUserList();
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchStateText]);

    const getUserList = async () => {
        const { payload } = await dispatch(
            getUsersList({ ...groupInfo, searchStateText: searchStateText }),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            const usersArr = payload?.data?.data;
            const groupNewArr: IListOption[] =
                usersArr?.map((dataOption: any) => ({
                    label: `${dataOption?.firstName} ${dataOption?.lastName}`,
                    value: dataOption?.users_nano_id,
                })) || [];
            setGroupUserArr(groupNewArr);
        }
    };

    const updateSelectedList = async (itemId: string) => {
        const newArray = selectList.filter(
            (item: any) => item?.value !== itemId,
        );
        setSelectList(newArray);
    };

    const HandleChange = async (data: IMembershipProps) => {
        const sendData = { ...data, groupNanoId: groupInfo?.group_nano_id };
        const { payload } = await dispatch(addGroupMemberData(sendData));
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            getDataListCall();
            handleClose();
        } else {
            toast.error(payload?.message);
        }
    };

    const onClose = async () => {
        if (isDirty) {
            const confirmationOptions = {
                title: 'Warning',
                html: 'Are you sure you want to close this form?<br>All changes made will be lost.',
                icon: 'warning',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                text: '',
            };
            const isConfirmed = await showDeleteConfirmationDialog(
                confirmationOptions,
            );
            if (isConfirmed) {
                handleClose();
            }
        } else {
            handleClose();
        }
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Add Members
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {!groupInfo?.group_name ? (
                    <LoaderBtn />
                ) : (
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <div className="d-flex justify-content-center mb-4">
                            <Image src={memberAdd} alt="add-user" />
                        </div>
                        <Row className="justify-content-center">
                            <Col md={12}>
                                <div>
                                    <Controller
                                        name={`group_id`}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <>
                                                <InputGroupText
                                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                    value={value}
                                                    tabIndex={1}
                                                    id={`Requesters`}
                                                    inputType={'text'}
                                                    placeholder={'Requesters'}
                                                    disabled={true}
                                                    groupInput={false}
                                                    inputGroupClassName="mfnw__input-group"
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mt-4">
                                    <Controller
                                        name={'users_id'}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <ReactSelectGroup
                                                value={
                                                    value === selectList
                                                        ? value
                                                        : selectList
                                                }
                                                tabIndex={2}
                                                className="w-100"
                                                inputClassName="mfnw__input-group"
                                                options={groupUserArr}
                                                placeholder={'select users'}
                                                isMulti={true}
                                                onInputChange={(
                                                    valueChange: any,
                                                ) => {
                                                    if (valueChange !== '') {
                                                        setSearchStateText(
                                                            valueChange,
                                                        );
                                                    }
                                                }}
                                                onChangeFunc={(
                                                    stateValue: any,
                                                ) => {
                                                    setValue(
                                                        'users_id',
                                                        stateValue,
                                                    );
                                                    setSelectList(stateValue);
                                                }}
                                                errorText={
                                                    errors?.users_id?.message
                                                }
                                                labelShow={false}
                                                isSearchable={true}
                                                isDisabled={false}
                                                isLoading={isGetUsersList}
                                                autoFocus={true}
                                            />
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <FormLabel className="mt-3">
                                    LIST OF USERS
                                </FormLabel>
                                <Row className="mt-3 g-0">
                                    {selectList?.map(
                                        (item: any, index: number) => (
                                            <Badge
                                                key={index}
                                                bg="light"
                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                            >
                                                <span className="me-3">
                                                    {item?.label}
                                                </span>
                                                <div
                                                    onClick={() =>
                                                        updateSelectedList(
                                                            item?.value,
                                                        )
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircleXmark}
                                                        color="#b7b7b7"
                                                        size="lg"
                                                    />
                                                </div>
                                            </Badge>
                                        ),
                                    )}
                                </Row>
                            </Col>
                            <Col md={12}>
                                <Button
                                    variant="primary w-100 mt-3 text-uppercase fw-semibold"
                                    type="submit"
                                    tabIndex={2}
                                    disabled={isAddGroupMemberData}
                                >
                                    {isAddGroupMemberData ? (
                                        <LoaderBtn />
                                    ) : (
                                        'SAVE'
                                    )}
                                </Button>
                                <Button
                                    className="btn-cancel mt-3 w-100 text-uppercase fw-semibold"
                                    onClick={() => onClose()}
                                    tabIndex={2}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddMembershipPopup;
