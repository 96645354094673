import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Badge, Image, Tab, Tabs } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { faCircleSmall, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopLogo from '@images/top-logo.png';

import OrganizationPopup from '@app/CreateOrganization/Organizations/OrganizationList/organizationPopup';
import { getUserOrganizationInformation } from '@app/CreateOrganization/step1/functions';
import OfficeTabList from '@app/CreateOrganization/Organizations/officeTabList';
import DepartmentList from '@app/CreateOrganization/Organizations/departmentList';
import RolesList from '@app/CreateOrganization/Organizations/rolesList';
import JobTitleList from '@app/CreateOrganization/Organizations/jobTitleList';
import RegionsList from '@app/CreateOrganization/Organizations/regionsList';
import WorkFlow from '@app/CreateOrganization/Organizations/workFlow';

const UserOrganizationListTab = () => {
    const dispatch = useDispatch<any>();
    const { organizationId } = useParams();

    const { organizationStep1Data } = useSelector((state: any) => state);
    const { isGetUserOrganizationInformation } = organizationStep1Data;

    const [show, setShow] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>('office');
    const [organizationData, setOrganizationData] = useState<any>(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getDefaultOrganization();
    }, []);

    const getDefaultOrganization = async () => {
        const { payload } = await dispatch(
            getUserOrganizationInformation({
                organization_nano_id: organizationId,
            }),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            setOrganizationData(payload?.data?.data);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center pt-3">
                <h5 className="fw-semibold m-0">Organization</h5>
            </div>
            <Row>
                <Col md={12} className="my-4">
                    <div className="bg-white rounded-4 p-4">
                        {isGetUserOrganizationInformation ? (
                            <LoaderBtn />
                        ) : (
                            <Row>
                                <Col md={6}>
                                    <div className="mfnw__left_content">
                                        <div className="mfnw__circle-logo">
                                            {organizationData?.organization_logo ? (
                                                <Image
                                                    className="rounded-circle"
                                                    height="100px"
                                                    width="100px"
                                                    src={
                                                        organizationData?.organization_logo
                                                    }
                                                />
                                            ) : (
                                                <Image src={TopLogo} />
                                            )}
                                        </div>
                                        <div className="mfnw__right-content">
                                            <h4 className="cp_name fs-6 text-capitalize">
                                                {
                                                    organizationData?.organization_name
                                                }
                                            </h4>
                                            <p className="address-text mt-1">
                                                {`${organizationData?.organization_address}, ${organizationData?.organization_city_name}, ${organizationData?.organization_state_name}, ${organizationData?.organization_zip_code}`}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="d-flex align-organizationDatas-center justify-content-between h-100">
                                        <span className="d-flex orgInfo align-items-center me-3 me-md-1 text-truncate">
                                            {
                                                organizationData?.organization_email
                                            }
                                        </span>
                                        <span className="orgInfo align-items-center d-none d-sm-flex text-truncate">
                                            {
                                                organizationData?.organization_phone
                                            }
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <Badge className="badge bg-success align-text-bottom ms-2 px-3 py-2 rounded-pill">
                                                <FontAwesomeIcon
                                                    icon={faCircleSmall}
                                                />
                                                <span className="ms-2">
                                                    Default
                                                </span>
                                            </Badge>
                                        </div>
                                        <span className="d-flex orgInfo align-items-center">
                                            <FontAwesomeIcon
                                                className="mx-2 cursor-pointer align-baseline"
                                                icon={faPen}
                                                onClick={() => handleShow()}
                                            />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey={selectedTab}
                onSelect={(e: any) => setSelectedTab(e)}
                className="mfnw__dashboard_linetab mb-3 flex-wrap"
            >
                <Tab eventKey="office" title="Offices">
                    {selectedTab === 'office' && (
                        <OfficeTabList organizationId={organizationId} />
                    )}
                </Tab>

                <Tab eventKey="departments" title="DEPARTMENTS">
                    {selectedTab === 'departments' && (
                        <DepartmentList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="roles" title="ROLES">
                    {selectedTab === 'roles' && (
                        <RolesList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="job-title" title="JOB TITLES">
                    {selectedTab === 'job-title' && (
                        <JobTitleList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="regions" title="REGIONS">
                    {selectedTab === 'regions' && (
                        <RegionsList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="workflow" title="WORKFLOW" disabled>
                    {selectedTab === 'workflow' && <WorkFlow />}
                </Tab>
            </Tabs>
            {show && (
                <OrganizationPopup
                    show={show}
                    handleClose={handleClose}
                    getList={() => getDefaultOrganization()}
                    actinType={'edit'}
                    organizationId={organizationId}
                />
            )}
        </>
    );
};

export default UserOrganizationListTab;
