import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddGroupData,
    apiDeleteGeroup,
    apiGetGroupDataList,
} from '@services/API/apiFunctions';

const getGroupDataList = createAsyncThunk(
    'getGroupDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetGroupDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addGroupData = createAsyncThunk(
    'addGroupData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddGroupData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteGroup = createAsyncThunk(
    'deleteGroup',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteGeroup(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { getGroupDataList, addGroupData, deleteGroup };
