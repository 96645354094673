import React from 'react';
import ReactPaginate from 'react-paginate';

const ReactPagination = ({
    currentPageData,
    pageCount,
    handlePageClick,
    filters,
}: any) =>
    currentPageData &&
    currentPageData.length > 0 && (
        <ReactPaginate
            previousLabel={'← Previous'}
            nextLabel={'Next →'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active active'}
            pageRangeDisplayed={2}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageLinkClassName="page-link"
            previousClassName="page-link rounded-start"
            nextClassName="page-link rounded-end"
            forcePage={filters?.currentPage - 1}
        />
    );

export default ReactPagination;
