import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/fontawesome-pro-regular';

import { stepBarList } from './stepBar.const';
import './StepBar.scss';

const faCheckIcon = faCheck as IconProp;

const StepBar = ({ nextActive }: any) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const currentPageStep = parseInt(pathname.replace('/step', ''));
    const stepClickHendel = (stepData: any, index: any) => {
        if (stepData.step <= currentPageStep) {
            navigate(stepData.route);
        }
    };
    return (
        <>
            <div className="mfnw__stepbar_process my-3 d-flex flex-wrap gap-3 gap-sm-3 gap-md-0">
                {stepBarList?.map((item: any, index: number) => (
                    <div
                        key={index}
                        onClick={(e) => stepClickHendel(item, index)}
                        className={
                            item.step < currentPageStep
                                ? ' step_content complete_step'
                                : item.step === currentPageStep
                                ? 'step_content current_step'
                                : 'step_content'
                        }
                    >
                        <div className="icon_check d-none d-md-flex">
                            <FontAwesomeIcon icon={faCheckIcon} />
                        </div>
                        <div className="icon_check d-flex d-md-none">
                            {index + 1}
                        </div>
                        <span className="ms-3 d-none d-md-flex">
                            {item?.label}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default StepBar;
