import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addForwarder,
    deleteForwarder,
    forwarderList,
    getForwarderInfo,
    updateForwarder,
} from './functions';

const defaultState = {
    isAddForwarder: false,
    isForwarderList: false,
    isUpdateForwarder: false,
    isGetForwarderInfo: false,
    isDeleteForwarder: false,
};

export const forwarderSlice: any = createSlice({
    name: 'forwarderSlice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addForwarder.pending.type]: (state: any, { payload }) => {
            state.isAddForwarder = true;
            state.registerResponse = payload;
        },
        [addForwarder.fulfilled.type]: (state: any, { payload }) => {
            state.isAddForwarder = false;
            state.registerResponse = payload;
        },
        [addForwarder.rejected.type]: (state: any, { payload }) => {
            state.isAddForwarder = false;
            state.registerResponse = payload;
        },

        [forwarderList.pending.type]: (state: any, { payload }) => {
            state.isForwarderList = true;
            state.registerResponse = payload;
        },
        [forwarderList.fulfilled.type]: (state: any, { payload }) => {
            state.isForwarderList = false;
            state.registerResponse = payload;
        },
        [forwarderList.rejected.type]: (state: any, { payload }) => {
            state.isForwarderList = false;
            state.registerResponse = payload;
        },

        [updateForwarder.pending.type]: (state: any, { payload }) => {
            state.isUpdateForwarder = true;
            state.registerResponse = payload;
        },
        [updateForwarder.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateForwarder = false;
            state.registerResponse = payload;
        },
        [updateForwarder.rejected.type]: (state: any, { payload }) => {
            state.isUpdateForwarder = false;
            state.registerResponse = payload;
        },

        [getForwarderInfo.pending.type]: (state: any, { payload }) => {
            state.isGetForwarderInfo = true;
            state.registerResponse = payload;
        },
        [getForwarderInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetForwarderInfo = false;
            state.registerResponse = payload;
        },
        [getForwarderInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetForwarderInfo = false;
            state.registerResponse = payload;
        },

        [deleteForwarder.pending.type]: (state: any, { payload }) => {
            state.isDeleteForwarder = true;
            state.registerResponse = payload;
        },
        [deleteForwarder.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteForwarder = false;
            state.registerResponse = payload;
        },
        [deleteForwarder.rejected.type]: (state: any, { payload }) => {
            state.isDeleteForwarder = false;
            state.registerResponse = payload;
        },
    },
});

export {
    addForwarder,
    forwarderList,
    updateForwarder,
    getForwarderInfo,
    deleteForwarder,
};

export default forwarderSlice.reducer;
