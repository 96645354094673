import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import { Card, Button, Row } from 'react-bootstrap';

import { Image } from '@components-default';
import InputText from '@components/InputText/InputText';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ToastBodyContainer from '@components/ToastContainer';

import { passwordLength } from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import Logo from '@images/mf-logo.png';

import LeftSideBox from '@app/Auth/Login/leftSideBox';
import { registerChange, registerUser } from '@app/Auth/Register/registerSlice';
import { responseMessage } from '@redux/app/appSlice';

import { IRegisterProps } from './register.interface';
import { registerFieldArr } from './register.const';

import './register.scss';

const Register = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { register } = useSelector((state: any) => state);

    const { isRegisteringUser } = register;

    const schema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is a required field'),

        password: Yup.string()
            .required('Please provide a password')
            .min(
                passwordLength,
                `Password is too short - should be ${passwordLength} characters minimum.`,
            ),

        password_confirmation: Yup.string()
            .required('Please provide a password confirmation')
            .min(
                passwordLength,
                `Password is too short - should be ${passwordLength} characters minimum.`,
            )
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),

        firstName: Yup.string().required('Please provide your first name'),
        lastName: Yup.string().required('Please provide your last name'),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: '',
            password_confirmation: '',
            firstName: '',
            lastName: '',
            company_id: null,
        },
        mode: 'onBlur',
    });

    const HandleChange = async (data: IRegisterProps) => {
        if (isValid) {
            const { payload } = await dispatch(registerUser(data));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(responseMessage(payload));
                dispatch(registerChange(payload?.data));
                navigate(routesPath?.emailVerify);
            } else {
                if (payload?.message?.email?.[0] !== '') {
                    toast.error(payload?.message?.email?.[0]);
                }
            }
        }
    };

    return (
        <div>
            <ToastBodyContainer />
            <div className="login_auth_wrapper">
                <LeftSideBox />
                <Card className="rightBox">
                    <div className="mfnw__form-fixed mfnw_create_form">
                        <div className="mfnw__form-logo_respons text-center mb-3 mb-lg-5 pt-2 pt-lg-0">
                            <Image src={Logo} />
                            <h3 className="mt-4 mb-5 fw-bold">
                                Create an account
                            </h3>
                        </div>
                        <div className="text-center mfnw_create_title">
                            <h3 className="fw-bold py-4">Create an account</h3>
                        </div>
                        <Form onSubmit={handleSubmit(HandleChange)}>
                            <Row>
                                {registerFieldArr &&
                                    registerFieldArr.map(
                                        (field: any, index: number) => (
                                            <Controller
                                                key={index}
                                                name={field?.name}
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }: any) => (
                                                    <div
                                                        className={
                                                            field?.classname
                                                        }
                                                    >
                                                        <div className="login-input-group">
                                                            <InputText
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                value={value}
                                                                id={field?.name}
                                                                inputName={
                                                                    field?.name
                                                                }
                                                                inputType={
                                                                    field?.type
                                                                }
                                                                placeholder={
                                                                    field?.placeholder
                                                                }
                                                                errorText={
                                                                    errors?.[
                                                                        field
                                                                            ?.name
                                                                    ]?.message
                                                                }
                                                                iconShow={true}
                                                                icon={
                                                                    field?.icon
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        ),
                                    )}
                            </Row>
                            <Form.Group
                                className="d-flex align-items-center justify-content-between mb-2"
                                controlId="formBasicCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Remember me"
                                />
                                <div className="text-center">
                                    <Link
                                        to={routesPath?.forgotPassword}
                                        className="forgetLink"
                                    >
                                        Forgot Password ?
                                    </Link>
                                </div>
                            </Form.Group>
                            <Button
                                type="submit"
                                variant="secondary w-100"
                                className="btnLogin text-uppercase my-3 my-lg-0"
                                disabled={isRegisteringUser}
                            >
                                {isRegisteringUser ? (
                                    <LoaderBtn />
                                ) : (
                                    'CREATE ACCOUNT'
                                )}
                            </Button>
                            <div className="text-center">
                                <h6 className="text-dark fw-semibold my-3">
                                    Already have an account?
                                    <Link
                                        to={routesPath?.login}
                                        className="mx-2 text-secondary text-decoration-underline"
                                    >
                                        Login
                                    </Link>
                                </h6>
                            </div>
                        </Form>
                    </div>
                    <div className="mfnw-right-footer">
                        <span>MoveFlows © 2023</span>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Register;
