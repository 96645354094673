import React from 'react';
import DatePickerRange from 'react-datepicker';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DateSelect.scss';

const DateRangeSelect = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            className,
            errorText,
            onKeyDown,
            label,
            inputDisable,
            tabIndex,
            onChangeFunc,
            groupInput,
            inputGroupClassName,
            icon,
            selectsRange,
            startDate,
            endDate,
            ...props
        }: any,
        ref: any,
    ) => (
        <>
            <InputGroup className="mb-0">
                {groupInput && (
                    <InputGroup.Text
                        className={`${inputGroupClassName} justify-content-left`}
                    >
                        {icon ? (
                            <>
                                <FontAwesomeIcon
                                    icon={icon}
                                    className={label ? 'me-2' : ''}
                                    size="xl"
                                />{' '}
                                {label}
                            </>
                        ) : (
                            label
                        )}
                    </InputGroup.Text>
                )}
                <DatePickerRange
                    selectsRange={selectsRange}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                        onChangeFunc(update);
                    }}
                    onKeyDown={(e: any) => {
                        e.preventDefault();
                    }}
                    tabIndex={tabIndex}
                    className={className}
                    {...props}
                />
            </InputGroup>
        </>
    ),
);

DateRangeSelect.defaultProps = {
    inputName: '',
    placeholder: '',
    className: '',
    label: '',
    errorText: '',
    id: '',
};
export default DateRangeSelect;
