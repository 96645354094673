import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form } from '@components-default';

import ProfilePic from '@images/profile-select.svg';
import Camera from '@images/camera-icon.svg';

const LogoImageUpload = ({
    id = 'contained-button-file',
    handleClickProfilePic,
    userImage,
    dropAble,
    icon,
}: any) => {
    const { login } = useSelector((state: any) => state);
    const { authUser } = login;
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        var extension = e.target.files[0]?.name.split('.').pop().toLowerCase();
        if (files.length === 0) {
            return alert('Please select a file.');
        }
        const reader = new FileReader();
        reader.onload = () => {
            handleClickProfilePic(reader.result, extension);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = '';
    };
    const updateImage: any = React.useMemo(
        () => (
            <div className="d-flex flex-column flex-lg-row align-items-center">
                <div className="profile_upload position-relative m-auto m-xl-unset me-lg-4">
                    <img
                        src={userImage ? userImage : icon ? icon : ProfilePic}
                        tabIndex={0}
                        alt="profile"
                        height="125px"
                        width="125px"
                        onChange={onChange}
                        className="profile_img rounded-circle"
                    />
                    {icon ? null : (
                        <div className="circle__camera position-absolute z-2">
                            <img src={Camera} alt="camera" />
                        </div>
                    )}
                </div>
                {dropAble && (
                    <p className="text-center m-auto m-xl-unset ms-lx-4 mt-3 mt-lg-auto">
                        Drop your logo here or{' '}
                        <Link to="#" className="text-primary">
                            select a file.
                        </Link>
                    </p>
                )}
            </div>
        ),

        [authUser, userImage],
    );
    const handleDragEnter = (event: any) => {
        event.preventDefault();
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        onChange(event);
    };
    return (
        <>
            <label className="label-w-20">
                <Form.Control
                    accept="image/*"
                    id={id}
                    multiple
                    name="user_profile"
                    type="file"
                    onChange={onChange}
                    className="d-none profile_text_img"
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    autoFocus={true}
                />
                {updateImage}
            </label>
        </>
    );
};

export default LogoImageUpload;
