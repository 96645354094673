import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

import { responseOneTimeMessage } from '@redux/app/appSlice';

const StepperActionBtn = ({
    dataList,
    nextStep,
    previousStep,
    sendMsg,
}: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    return (
        <Row>
            <Col xs={12} md={6} className="mt-md-5 order-md-2">
                <Button
                    variant="primary"
                    className="text-uppercase button__details"
                    onClick={() => {
                        dataList.length > 0 && navigate(nextStep);
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                        sendMsg &&
                            dispatch(
                                responseOneTimeMessage({
                                    message: 'organization create successfully',
                                    status: 200,
                                }),
                            );
                    }}
                    disabled={dataList.length === 0}
                >
                    Continue
                </Button>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-5 order-md-1">
                <Button
                    variant="default"
                    className="text-uppercase button__details"
                    onClick={() => navigate(previousStep)}
                >
                    Back
                </Button>
            </Col>
        </Row>
    );
};

export default StepperActionBtn;
