import React, { useState } from 'react';
import { Tab, Tabs, Image } from 'react-bootstrap';

import Home from '@images/Work-Home.svg';

import WorkPackers from '@app/Schedule/workPackers';
import WorkDriver from '@app/Schedule/workDriver';

import WorkJobs from './workJobs';
import './schedule.scss';
import WorkSit from './workSit';
import WorkCompleted from './workCompleted';

const Schedule = () => {
    const [selectedTab, setSelectedTab] = useState<string>('user-list');

    return (
        <>
            <Tabs
                defaultActiveKey={selectedTab}
                onSelect={(e: any) => setSelectedTab(e)}
                className="mfnw__calander_indexing  mb-3 bg-white ps-4 position-fixed w-100"
            >
                <Tab
                    eventKey="user-list"
                    title={
                        <div>
                            <Image src={Home} className="pe-2 pb-1" />
                            <span>JOBS</span>
                        </div>
                    }
                >
                    {selectedTab === 'user-list' && <WorkJobs />}
                </Tab>
                <Tab eventKey="PACKER" title="Packers">
                    {selectedTab === 'PACKER' && <WorkPackers />}
                </Tab>
                <Tab eventKey="DRIVER" title="DRIVERS">
                    {selectedTab === 'DRIVER' && <WorkDriver />}
                </Tab>
                <Tab eventKey="SIT" title="SITS">
                    {selectedTab === 'SIT' && <WorkSit />}
                </Tab>
                <Tab eventKey="COMPLETED" title="COMPLETED">
                    {selectedTab === 'COMPLETED' && <WorkCompleted />}
                </Tab>
            </Tabs>
        </>
    );
};

export default Schedule;
