import moment from 'moment';
import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneLengthCheck,
    phoneRegex,
} from '@constants/general.const';

export const blRegex: RegExp = /^[A-Za-z0-9 -]*$/;
export const minEstimatedWeight: number = 100;
export const maxCountryCode: number = 25;
export const minMiles: number = 1;
export const maxMiles: number = 9999;
export const maxMilesText: string = '9,999';
export const maxEstimatedWeight: number = 99999;
export const maxEstimatedWeightText: string = '99,999';
export const maxDiscount: number = 100;
export const maxSIT: number = 100;

export const schema = Yup.object({
    offerId: Yup.boolean(),
    type: Yup.string(),
    shipper: Yup.string().required('Shipper is a required field'),
    email: Yup.string()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .email('Please enter a valid email'),
    govBillOfLaden: Yup.string()
        .when(
            'type',
            (
                type: string,
                schemaGovBillOfLaden: Yup.StringSchema<string | undefined>,
            ) =>
                type === 'COD'
                    ? schemaGovBillOfLaden
                    : schemaGovBillOfLaden.required(
                          'Gov Bill of Laden is a required field',
                      ),
        )
        .matches(blRegex, 'Invalid Gov Bill of Laden field.'),
    forwarder_name: Yup.string().required('TSP is a required field'),
    forwarderBillOfLaden: Yup.string()
        .when(
            'type',
            (
                type: string,
                schemaForwarderBillOfLaden: Yup.StringSchema<
                    string | undefined
                >,
            ) =>
                type === 'COD'
                    ? schemaForwarderBillOfLaden.required(
                          'Offerer Bill of Laden is a required field',
                      )
                    : schemaForwarderBillOfLaden,
        )
        .matches(blRegex, 'Invalid Offeror Bill of Laden field.'),
    estimatedWeight: Yup.number()
        .min(
            minEstimatedWeight,
            `Weight must be greater than or equal to ${minEstimatedWeight}`,
        )
        .max(
            maxEstimatedWeight,
            `Weight must be less than or equal to ${maxEstimatedWeightText}`,
        )
        .required('Weight is a required field')
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    packDate: Yup.date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
            offerId
                ? schemaPackDate.required('Pack date is a required field')
                : schemaPackDate.default(null),
        ),
    pickupDate: Yup.date()
        .when('packDate', (packDate, schemaForPickupDate) =>
            packDate !== null
                ? schemaForPickupDate.minOrEqual(
                      packDate,
                      'Pickup date must be greater than or equal to Pack date',
                  )
                : schemaForPickupDate.typeError(
                      'Please enter a valid pickup date',
                  ),
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Pickup date is a required field'),
    deliveryDate: Yup.date()
        .when(
            'pickupDate',
            (pickupDate: string | undefined, schemaForDeliveryDate: any) =>
                pickupDate
                    ? schemaForDeliveryDate.min(
                          pickupDate,
                          'RDD date must be greater than Pickup date',
                      )
                    : schemaForDeliveryDate.typeError(
                          'Please enter a valid ROD',
                      ),
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Delivery date is a required field'),
    // date_in: Yup.date()
    //     .nullable()
    //     .default(null)
    //     .transform((curr, orig) => (orig === '' ? null : curr))
    //     .typeError('Please enter a valid date')
    //     .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
    //         offerId
    //             ? schemaPackDate.required('Date In is a required field')
    //             : schemaPackDate.default(null),
    //     )
    //     .when(
    //         'line_haule_data.SIT_status',
    //         (SIT_status: boolean | undefined, schemaPackDate: any) =>
    //             SIT_status
    //                 ? schemaPackDate.required('Date In is a required field')
    //                 : schemaPackDate.default(null),
    //     ),
    date_out: Yup.date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .when(['offerStatus', 'line_haule_data.SIT_status'], {
            is: (offerStatus: string, SIT_status: any) =>
                (offerStatus === 'scheduled' ||
                    offerStatus === 'sit_delivery' ||
                    offerStatus === 'completed') &&
                SIT_status == 1,
            then: (schema2) => schema2.required('Date Out is a required field'),
            otherwise: (schema3) => schema3.default(null),
        }),
    address_origin: Yup.object().shape({
        addressType: Yup.string().required('Address Type is a required field'),
        stateTown: Yup.string().required('State/Town is a required field'),

        postalCode: Yup.string()
            .required('Zip Code is a required field')
            .nullable(true),
        countryCode: Yup.string()
            .max(maxCountryCode, `Must be exactly ${maxCountryCode} digits`)
            .required('Country Code is a required field'),
    }),
    address_destination: Yup.object().shape({
        addressType: Yup.string().required('Address Type is a required field'),
        postalCode: Yup.string()
            .required('Zip Code is a required field')
            .nullable(true),
        city: Yup.string().required('City is a required field').nullable(true),
        stateTown: Yup.string()
            .required('State is a required field')
            .nullable(true),
        countryCode: Yup.string()
            .max(maxCountryCode, `Must be exactly ${maxCountryCode} digits`)
            .required('Country Code is a required field'),
    }),
    line_haule_data: Yup.object().shape({
        SIT_status: Yup.boolean(),
        miles: Yup.number()
            .min(minMiles, `Miles must be greater than or equal to ${minMiles}`)
            .max(
                maxMiles,
                `Miles must be less than or equal to ${maxMilesText}`,
            )
            .required('Miles is a required field')
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),

        discount: Yup.number()
            .max(
                maxDiscount,
                `Discount must be less than or equal to ${maxDiscount}`,
            )
            .required('Discount is a required field')
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),

        SIT: Yup.number()
            .max(maxSIT, `SIT must be less than or equal to ${maxSIT}`)
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
}).required();

export const jobSchema = Yup.object({
    offerId: Yup.boolean(),
    type: Yup.string(),
    shipper: Yup.string().required('Shipper is a required field'),
    offerStatus: Yup.string().required('offer status is a required field'),
    email: Yup.string()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .email('Please enter a valid email'),
    govBillOfLaden: Yup.string()
        .when(
            'type',
            (
                type: string,
                schemaGovBillOfLaden: Yup.StringSchema<string | undefined>,
            ) =>
                type === 'COD'
                    ? schemaGovBillOfLaden
                    : schemaGovBillOfLaden.required(
                          'Gov Bill of Laden is a required field',
                      ),
        )
        .matches(blRegex, 'Invalid Gov Bill of Laden field.'),
    forwarder_name: Yup.string().required('TSP is a required field'),
    forwarderBillOfLaden: Yup.string()
        .when(
            'type',
            (
                type: string,
                schemaForwarderBillOfLaden: Yup.StringSchema<
                    string | undefined
                >,
            ) =>
                type === 'COD'
                    ? schemaForwarderBillOfLaden.required(
                          'Offerer Bill of Laden is a required field',
                      )
                    : schemaForwarderBillOfLaden,
        )
        .matches(blRegex, 'Invalid Offeror Bill of Laden field.'),
    estimatedWeight: Yup.number()
        .min(
            minEstimatedWeight,
            `Weight must be greater than or equal to ${minEstimatedWeight}`,
        )
        .max(
            maxEstimatedWeight,
            `Weight must be less than or equal to ${maxEstimatedWeightText}`,
        )
        .required('Weight is a required field')
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    packDate: Yup.date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
            offerId
                ? schemaPackDate.required('Pack date is a required field')
                : schemaPackDate.default(null),
        ),
    // date_in: Yup.date()
    //     .nullable()
    //     .default(null)
    //     .transform((curr, orig) => (orig === '' ? null : curr))
    //     .typeError('Please enter a valid date')
    //     .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
    //         offerId
    //             ? schemaPackDate.required('Date In is a required field')
    //             : schemaPackDate.default(null),
    //     )
    //     .when(
    //         'line_haule_data.SIT_status',
    //         (SIT_status: boolean | undefined, schemaPackDate: any) =>
    //             SIT_status
    //                 ? schemaPackDate.required('Date In is a required field')
    //                 : schemaPackDate.default(null),
    //     ),
    // date_out: Yup.date()
    //     .nullable()
    //     .default(null)
    //     .transform((curr, orig) => (orig === '' ? null : curr))
    //     .typeError('Please enter a valid date')
    //     .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
    //         offerId
    //             ? schemaPackDate.required('Date Out is a required field')
    //             : schemaPackDate.default(null),
    //     )
    //     .when(
    //         'line_haule_data.SIT_status',
    //         (SIT_status: boolean | undefined, schemaPackDate: any) =>
    //             SIT_status
    //                 ? schemaPackDate.required('Date Out is a required field')
    //                 : schemaPackDate.default(null),
    //     ),
    pickupDate: Yup.date()
        .when('packDate', (packDate, schemaForPickupDate) =>
            packDate !== null
                ? schemaForPickupDate.minOrEqual(
                      packDate,
                      'Pickup date must be greater than or equal to Pack date',
                  )
                : schemaForPickupDate.typeError(
                      'Please enter a valid pickup date',
                  ),
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Pickup date is a required field'),
    deliveryDate: Yup.date()
        .when(
            'pickupDate',
            (pickupDate: string | undefined, schemaForDeliveryDate: any) =>
                pickupDate
                    ? schemaForDeliveryDate.min(
                          pickupDate,
                          'RDD date must be greater than Pickup date',
                      )
                    : schemaForDeliveryDate.typeError(
                          'Please enter a valid ROD',
                      ),
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Delivery date is a required field'),
    address_origin: Yup.object().shape({
        addressType: Yup.string().required('Address Type is a required field'),
        stateTown: Yup.string().required('State/Town is a required field'),
        city: Yup.string().required('City is a required field').nullable(true),
        postalCode: Yup.string()
            .required('Zip Code is a required field')
            .nullable(true),
        countryCode: Yup.string()
            .max(maxCountryCode, `Must be exactly ${maxCountryCode} digits`)
            .required('Country Code is a required field'),
    }),
    address_destination: Yup.object().shape({
        addressType: Yup.string().required('Address Type is a required field'),

        postalCode: Yup.string()
            .required('Zip Code is a required field')
            .nullable(true),
        city: Yup.string().required('City is a required field').nullable(true),
        stateTown: Yup.string()
            .required('State is a required field')
            .nullable(true),
        countryCode: Yup.string()
            .max(maxCountryCode, `Must be exactly ${maxCountryCode} digits`)
            .required('Country Code is a required field'),
    }),
    line_haule_data: Yup.object().shape({
        SIT_status: Yup.boolean(),
        miles: Yup.number()
            .min(minMiles, `Miles must be greater than or equal to ${minMiles}`)
            .max(
                maxMiles,
                `Miles must be less than or equal to ${maxMilesText}`,
            )
            .required('Miles is a required field')
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),

        discount: Yup.number()
            .max(
                maxDiscount,
                `Discount must be less than or equal to ${maxDiscount}`,
            )
            .required('Discount is a required field')
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),

        SIT: Yup.number()
            .max(maxSIT, `SIT must be less than or equal to ${maxSIT}`)
            .nullable()
            .default(null)
            .transform((curr, orig) => (orig === '' ? null : curr)),
    }),
}).required();

export const pacerSchema = Yup.object({
    pack_date: Yup.date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Date is a required field'),

    packer_ids: Yup.array().min(1, 'Please select at least one Packer'),
}).required();

export const updatePackerSchema = Yup.object({
    pack_date: Yup.array()
        .nullable()
        .of(
            Yup.date()
                .nullable()
                .default(null)
                .transform((curr, orig) => (orig === '' ? null : curr))
                .typeError('Please enter a valid date')
                .required('Date is a required field'),
        )
        .test(
            'date-range',
            'End Date must be after or equal to Start Date',
            function (value) {
                if (!value || !value[0] || !value[1]) {
                    return this.createError({
                        path: 'pack_date',
                        message: 'Date range is required',
                    });
                }
                const startDate: any = new Date(value[0]);
                const endDate: any = new Date(value[1]);

                if (!isNaN(startDate) && !isNaN(endDate)) {
                    return startDate <= endDate;
                }
                return true;
            },
        ),
    packer_ids: Yup.array().min(1, 'Please select at least one Packer'),
}).required();

Yup.addMethod(Yup.date, 'minOrEqual', function (minDate, message) {
    return this.test({
        name: 'minOrEqual',
        exclusive: false,
        message: message || '${path} must be greater than or equal to ${min}',
        params: {
            min: moment(minDate).format('YYYY-MM-DD'),
        },
        test(value) {
            return moment(moment(value).format('YYYY-MM-DD')).isSameOrAfter(
                moment(moment(minDate).format('YYYY-MM-DD')),
            );
        },
    });
});

export const scheduleOfferSchema = Yup.object({
    offerId: Yup.boolean(),
    shipper: Yup.string().required('Shipper is a required field'),
    estimatedWeight: Yup.number()
        .min(
            minEstimatedWeight,
            `Weight must be greater than or equal to ${minEstimatedWeight}`,
        )
        .max(
            maxEstimatedWeight,
            `Weight must be less than or equal to ${maxEstimatedWeightText}`,
        )
        .required('Weight is a required field')
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr)),
    packDate: Yup.date()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .when('offerId', (offerId: string | undefined, schemaPackDate: any) =>
            offerId
                ? schemaPackDate.required('Pack date is a required field')
                : schemaPackDate.default(null),
        ),
    pickupDate: Yup.date()
        .when('packDate', (packDate, schemaForPickupDate) =>
            packDate !== null
                ? schemaForPickupDate.minOrEqual(
                      packDate,
                      'Pickup date must be greater than or equal to Pack date',
                  )
                : schemaForPickupDate.typeError(
                      'Please enter a valid pickup date',
                  ),
        )
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError('Please enter a valid date')
        .required('Pickup date is a required field'),
}).required();

export const shipperSchema = Yup.object({
    shipper_phone: Yup.array().of(
        Yup.object().shape({
            phone_number: Yup.array().of(
                Yup.object().shape({
                    phone_number: Yup.string()
                        .nullable()
                        .default(null)
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .test(
                            'len',
                            `Phone number must be exactly ${minPhoneLength} characters`,
                            (val) =>
                                val === null ||
                                val?.replace(phoneRegex, '').length ===
                                    phoneLengthCheck,
                        )
                        .min(
                            minPhoneLength,
                            `Phone number must be at least ${minPhoneLength} characters long`,
                        )
                        .nullable(true),
                }),
            ),
        }),
    ),
    shipper_contact_info: Yup.array().of(
        Yup.object().shape({
            email: Yup.string()
                .email('Please enter a valid email')
                .nullable(true),

            phone_number: Yup.array().of(
                Yup.object().shape({
                    phone_number: Yup.string()
                        .nullable()
                        .default(null)
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .test(
                            'len',
                            `Phone number must be exactly ${minPhoneLength} characters`,
                            (val) =>
                                val === null ||
                                val?.replace(phoneRegex, '').length ===
                                    phoneLengthCheck,
                        )
                        .min(
                            minPhoneLength,
                            `Phone number must be at least ${minPhoneLength} characters long`,
                        )
                        .nullable(true),
                }),
            ),
        }),
    ),
}).required();

export const offerFields = {
    type: 'military',
    offerId: false,
    shipper: '',
    forwarder_name: '',
    packer: '',
    driver: '',
    destination_agent: '',
    destination_agent_email: '',
    to_agent: '',
    to_agent_email: '',
    offerStatus: 'pending',
    govBillOfLaden: '',
    forwarderBillOfLaden: '',
    estimatedWeight: 0,
    packDate: null,
    deliveryDate: null,
    pickupDate: null,
    date_in: null,
    date_out: null,
    driver_pickupDate: null,
    driver_deliveryDate: null,
    truck: '',
    trailer: '',
    tariff_schedule: '',
    address_origin: {
        addressType: 'physical',
        street: '',
        additional: '',
        city: '',
        stateTown: '',
        city_id: '',
        stateTown_id: '',
        postalCode: null,
        countryCode: 'USA',
        isInternationalPostalCode: false,
    },
    address_destination: {
        addressType: 'physical',
        street: '',
        additional: '',
        city: '',
        stateTown: '',
        city_id: '',
        stateTown_id: '',
        postalCode: null,
        countryCode: 'USA',
        isInternationalPostalCode: false,
    },
    line_haule_data: {
        discount: 0,
        SIT: 0,
        SIT_status: 0,
        miles: 0,
        notes: 'None',
        isSelfHaul: 1,
        isOriginAgentOnly: 1,
        haulAuthorityType: 'prime',
        ntsr: 0,
    },
    shipper_info: {
        shipper_name: '',
        shipper_phone: [],
        shipper_contact_info: [],
        is_active: 1,
    },
};

export const selectPacers = {
    pack_date: null,
    packer_action: 'pack',
    packer_status: 'pending',
    packer_ids: [],
    offerId: false,
};

export interface IShipperField {
    shipper_name: string;
    shipper_phone: IContactInfoProps[];
    shipper_contact_info: IContactInfoProps[];
    is_active: number | boolean;
}

export interface IContactInfoProps {
    name: string;
    email: string;
    phone_number: IContactPhoneObj[];
}
export interface IContactPhoneObj {
    phoneNumberType: string;
    phone_number: string;
    is_default: string | number;
}
