import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddForwarder,
    apiDeleteForwarder,
    apiForwarderData,
    apiForwarderList,
    apiUpdateForwarder,
} from '@services/API/apiFunctions';

const addForwarder = createAsyncThunk(
    'addForwarder',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddForwarder(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const forwarderList = createAsyncThunk(
    'forwarderList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiForwarderList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateForwarder = createAsyncThunk(
    'updateForwarder',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateForwarder(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getForwarderInfo = createAsyncThunk(
    'getForwarderInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiForwarderData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteForwarder = createAsyncThunk(
    'deleteForwarder',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteForwarder(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    addForwarder,
    forwarderList,
    updateForwarder,
    getForwarderInfo,
    deleteForwarder,
};
