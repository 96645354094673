/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import FilesUpload from '@components/FileUpload/filesUpload';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import ErrorMsg from '@components/errorMsg';

import { DocumentTypes } from '@constants/formFieldsArray';
import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolderOpen } from '@fortawesome/fontawesome-pro-regular';

import {
    noteDefaultData,
    checkFileSizeValidation,
    checkFileTypeValidation,
} from '@app/JobList/Documents/document.const';
import { selectedFileUpload } from '@app/SettingsLists/Drivers/functions';
import { updateJobDocument } from '@app/JobList/Documents/functions';
import { addJobDocument } from '@app/JobList/Documents/documentSlice';

import { IDocumentFieldsProps } from './document.interface';
import { IFilesArray } from '../../SettingsLists/Drivers/driver.interface';

const faFolder = faFolderOpen as IconProp;

const AddDocumentPopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    getDocList,
    listCall,
    documentDetails,
    isEditDocument,
}: any) => {
    const dispatch = useDispatch<any>();
    let intervalProgress: any;

    const { documentData } = useSelector((state: any) => state);
    const { isAddJobDocument } = documentData;

    const [progress, setProgress] = useState<number>(0);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [preview, setPreview] = useState<any[]>([]);
    const [filesArray, setFilesArray] = useState<IFilesArray>({
        isValid: true,
        files: undefined,
    });
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const schema = Yup.object({}).required();

    const {
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        formState: { isDirty },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: noteDefaultData,
    });

    // useEffect(() => {
    //     if (documentDetails) {
    //         reset(documentDetails);
    //     }
    //     if (documentDetails && documentDetails?.file) {
    //         let File = null;

    //         if (typeof documentDetails?.file === 'string') {
    //             const jsonObject = [{ url: documentDetails?.file }];
    //             const jsonString = JSON.stringify(jsonObject);
    //             File = JSON.parse(jsonString);
    //             setValue('document', File);
    //         } else {
    //             setValue('document', documentDetails?.file);
    //         }

    //         setPreview(
    //             documentDetails?.file &&
    //                 typeof documentDetails?.file === 'string'
    //                 ? File
    //                 : documentDetails?.file,
    //         );
    //     }
    // }, [documentDetails]);

    useEffect(() => {
        if (documentDetails) {
            reset(documentDetails);
        }
        if (documentDetails && documentDetails.file) {
            let fileData = null;

            if (typeof documentDetails.file === 'string') {
                try {
                    fileData = JSON.parse(documentDetails.file);
                } catch (e) {
                    fileData = [{ url: documentDetails.file }];
                }
                setValue('document', fileData);
            } else {
                setValue('document', documentDetails.file);
                fileData = documentDetails.file;
            }

            setPreview(fileData);
        }
    }, [documentDetails]);

    const HandleChange = async (data: IDocumentFieldsProps) => {
        if (preview.length > 0) {
            setIsSubmitted(false);

            const [dispatchResult] = await Promise.all([
                dispatch(
                    isEditDocument
                        ? updateJobDocument({
                              ...data,
                              id: offerDataObj?.offers_nano_id,
                              document:
                                  getValues('document') &&
                                  typeof getValues('document') === 'string'
                                      ? JSON.parse(getValues('document'))
                                      : getValues('document'),
                          })
                        : addJobDocument({
                              data,
                              offers_nano_id: offerDataObj?.offers_nano_id,
                          }),
                ),
            ]);
            const { payload } = dispatchResult;
            if (payload?.data?.status) {
                reset();
                handleClose();
                getDocList();
                listCall();
            } else {
                if (payload?.message?.name?.[0]) {
                    toast.error(payload?.message?.name?.[0]);
                }
                toast.error(payload?.message);
            }
        } else {
            setIsSubmitted(true);
        }
    };

    useEffect(() => {
        if (progress === 100 && preview?.length > 0) {
            clearInterval(intervalProgress);
            setTimeout(() => {
                setShowProgressBar(false);
            }, 1000);
        }
    }, [progress, preview]);

    useEffect(() => {
        (async () => {
            if (filesArray.files !== undefined) {
                if (checkFileLengthValidation()) {
                    if (checkFileSizeValidation(filesArray.files)) {
                        if (checkFileTypeValidation(filesArray.files)) {
                            try {
                                setShowProgressBar(true);
                                setProgress(0);
                                const formData = new FormData();
                                formData.append('files', filesArray.files[0]);
                                formData.append(
                                    'offers_nano_id',
                                    offerDataObj?.offers_nano_id,
                                );
                                const { payload } = await dispatch(
                                    selectedFileUpload({
                                        formData: formData,
                                        func: setProgress,
                                    }),
                                );

                                if (payload?.data?.status) {
                                    setValue('document', [
                                        ...(getValues('document') &&
                                        typeof getValues('document') ===
                                            'string'
                                            ? JSON.parse(getValues('document'))
                                            : getValues('document')),
                                        {
                                            name: payload?.data?.data
                                                ?.currentFileName,
                                            url: payload?.data?.data?.url,
                                            fileSize:
                                                payload?.data?.data?.fileSize,
                                        },
                                    ]);
                                    setPreview([
                                        ...preview,
                                        {
                                            url: payload?.data?.data?.url,
                                            name: payload?.data?.data
                                                ?.currentFileName,
                                            fileSize:
                                                payload?.data?.data?.fileSize,
                                        },
                                    ]);
                                    setShowProgressBar(false);
                                }
                            } catch (error) {
                                setShowProgressBar(false);
                                toast.error(
                                    'something went wrong, please try again',
                                );
                            }
                        }
                    }
                }
            }
        })();
    }, [filesArray]);

    const checkFileLengthValidation = () => {
        if (
            preview?.length >= 5 &&
            getValues('document_types') != 'Pre-Move Survey Pics'
        ) {
            toast.error('Maximum 5 documents allowed.');
            return false;
        }
        return true;
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset();
                }
            });
        } else {
            handleClose();
            reset();
        }
    };

    return (
        <div className="modal_second">
            <Modal
                show={showPopup}
                onHide={() => onClose()}
                backdrop="static"
                centered
                className="mfnw_model_size"
                backdropClassName="modal-backdrop-upper"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        {isEditDocument ? 'Edit Documents' : 'Add Documents'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <div className="mfnw__dashboard-content">
                            <Container>
                                <Row className="mt-2">
                                    <Col sm={6}>
                                        <div className="title_common_main py-2 mb-md-0 d-flex">
                                            <label
                                                htmlFor="text"
                                                className="fw-semibold text-sm fw-semibold"
                                            >
                                                DOCUMENT TYPE
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8} className="mt-2">
                                        <Controller
                                            name={`document_types`}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <>
                                                    <ReactSelectGroup
                                                        autoFocus
                                                        value={
                                                            getValues(
                                                                'document_types',
                                                            ) && {
                                                                value: getValues(
                                                                    'document_types',
                                                                ),
                                                                label: getValues(
                                                                    'document_types',
                                                                ),
                                                            }
                                                        }
                                                        onChangeFunc={(
                                                            e: any,
                                                        ) =>
                                                            setValue(
                                                                'document_types',
                                                                e?.value,
                                                            )
                                                        }
                                                        options={DocumentTypes}
                                                        labelShow={true}
                                                        icon={faFolder}
                                                        tabIndex={90}
                                                        className="w-100"
                                                        classNamePrefix="select_control_group"
                                                        isSearchable={true}
                                                        isDisabled={false}
                                                        placeholder="Document type"
                                                    />
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <div className="main-file">
                                    <div>
                                        <FilesUpload
                                            heading={'Document'}
                                            setFilesArray={setFilesArray}
                                            preview={preview}
                                            setPreview={setPreview}
                                            setValue={setValue}
                                            getValues={getValues}
                                            progress={progress}
                                            showProgressBar={showProgressBar}
                                            forValue={
                                                getValues('document') &&
                                                typeof getValues('document') ===
                                                    'string'
                                                    ? JSON.parse(
                                                          getValues('document'),
                                                      )
                                                    : getValues('document')
                                            }
                                            isRemove={true}
                                            isEditPopup={true}
                                            tabIndex={91}
                                        />
                                        {(preview?.length === 0
                                            ? isSubmitted
                                            : false) && (
                                            <ErrorMsg
                                                errorText={
                                                    'Please upload a document file.'
                                                }
                                            ></ErrorMsg>
                                        )}
                                    </div>
                                    <Row className="d-flex justify-content-end pt-4 mfnw_main_btn">
                                        <Col md={5}>
                                            <Button
                                                className="bg-primary mt-1 btn-onlyone w-100"
                                                type="submit"
                                                tabIndex={92}
                                                disabled={isAddJobDocument}
                                            >
                                                {isAddJobDocument ? (
                                                    <LoaderBtn />
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </Col>
                                        <Col md={5}>
                                            <Button
                                                className="btn-cancel mt-1 w-100"
                                                tabIndex={93}
                                                onClick={() => onClose()}
                                            >
                                                CANCEL
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddDocumentPopup;
