import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Tab, Tabs, Row, Image } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';
import { searchTimeOut } from '@constants/general.const';
import { useOfferPopup } from '@constants/offer.const';

import OfferNotFound from '@images/offer-not-found.svg';

import { clearResponse } from '@redux/app/appSlice';

import { OfferHistory } from '@app/OffersList/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import BottomDashboardSection from '@app/Dashboard/bottomDashboardSection';
import DashboardOfferLIst from '@app/Dashboard/dashboardOfferLIst';
import DashboardJob from '@app/Dashboard/Jobtab/dashboardJob';
import OfferRejectPopup from '@app/OffersList/offerRejectPopup';
import TopDashboardBox from '@app/Dashboard/topDashboardBox';

import { dashboardTopItemArray } from './dashbord.const';

import './dashboard.scss';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { login, common, offersData } = useSelector((state: any) => state);
    const { JWTToken } = login;

    const { response, searchText } = common;
    const { isOfferHistory } = offersData;

    const [statusList, setStatusList] = useState<string>('offered');
    const [offerHistoryArr, setOfferHistoryArr] = useState<any>({});
    const [countYTD, setCountYTD] = useState<any>({});
    const [dataCount, setDataCount] = useState<Array<any> | null>([]);
    const [maxDataLoop, setMaxDataLoop] = useState<number | null>(null);
    const [maxJobDataLoop, setMaxJobDataLoop] = useState<number | null>(null);
    const [yearFilter, setYearFilter] = useState<boolean>(false);

    useEffect(() => {
        if (!JWTToken) {
            navigate(routesPath?.login);
        }
        const { message } = response;
        toast.error(message);
        dispatch(clearResponse());
    }, []);

    useEffect(() => {
        const getData: any = setTimeout(() => {
            getOfferHistory(statusList, yearFilter);
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getOfferHistory = async (status: string, dateFilter: boolean) => {
        const { payload } = await dispatch(
            OfferHistory({
                searchText: searchText ?? null,
                statusList: status ?? statusList,
                yearFilter: dateFilter ?? yearFilter,
            }),
        );

        if (payload?.data?.message !== '' && payload?.data?.status) {
            const { data, count, countYtd, jobCount } = payload?.data;
            setOfferHistoryArr(data);
            setCountYTD(countYtd);
            setMaxDataLoop(count);
            setMaxJobDataLoop(jobCount);
            setDataCount(data);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handleStatusListChange = (status: string, dateFilter: boolean) => {
        setStatusList(status);
        setYearFilter(dateFilter);
        getOfferHistory(status, dateFilter ?? yearFilter);
    };

    const {
        handleViewShow,
        handleClose,
        offerId,
        popup,
        renderViewPopup,
        renderEditPopup,
        showViewPopup,
        showEditPopup,
        handleJobViewShow,
        showJobViewPopup,
        showJobEditPopup,
        renderJobViewPopup,
        renderJobEditPopup,
    } = useOfferPopup();

    return (
        <div className="mfnw__dashboard-content">
            <Tabs
                defaultActiveKey="offer"
                className="mfnw__dashboard_linetab mb-4"
            >
                <Tab eventKey="offer" title="Offer">
                    <>
                        {isOfferHistory ? (
                            <LoaderBtn />
                        ) : maxDataLoop && maxDataLoop !== 0 ? (
                            <div className="row d-flex flex-wrap flex-xl-nowrap justify-content-space-between mb-3">
                                {dashboardTopItemArray.map(
                                    (item: any, index: number) => (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={3}
                                            key={index}
                                            className="mb-3"
                                        >
                                            <Col xs={12} className="mb-3">
                                                <TopDashboardBox
                                                    item={item}
                                                    dataCount={dataCount}
                                                />
                                            </Col>

                                            {offerHistoryArr?.[item?.count]
                                                ?.length > 0 &&
                                                offerHistoryArr?.[
                                                    item?.count
                                                ]?.map(
                                                    (
                                                        itemData: any,
                                                        dataIndex: number,
                                                    ) => (
                                                        <DashboardOfferLIst
                                                            key={dataIndex}
                                                            itemData={itemData}
                                                            item={item}
                                                            offerHistoryArr={
                                                                offerHistoryArr
                                                            }
                                                            handleViewShow={
                                                                handleViewShow
                                                            }
                                                        />
                                                    ),
                                                )}
                                        </Col>
                                    ),
                                )}
                            </div>
                        ) : (
                            <>
                                {maxDataLoop === 0 && (
                                    <Row className="justify-content-center flex-column align-items-center">
                                        <Col
                                            md={6}
                                            xl={5}
                                            className="text-center mt-5"
                                        >
                                            <Image src={OfferNotFound} />
                                            <h5 className="text-light mt-4 fw-bold">
                                                You haven&apos;t received any
                                                offers yet. <br /> Don&apos;t
                                                give up, keep searching and
                                                you&apos;ll eventually find
                                                them.
                                            </h5>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                        {!isOfferHistory &&
                            (maxDataLoop || maxDataLoop === 0) && (
                                <BottomDashboardSection
                                    dataCount={countYTD}
                                    handleStatusListChange={
                                        handleStatusListChange
                                    }
                                />
                            )}
                    </>
                </Tab>
                <Tab eventKey="job" title="Job">
                    {isOfferHistory ? (
                        <LoaderBtn />
                    ) : (
                        <DashboardJob
                            dataCount={dataCount}
                            countYTD={countYTD}
                            maxDataLoop={maxJobDataLoop}
                            handleViewShow={handleViewShow}
                            offerHistoryArr={offerHistoryArr}
                            handleJobViewShow={handleJobViewShow}
                        />
                    )}
                </Tab>
                <Tab
                    eventKey="activity-log"
                    title="Activity Log"
                    disabled
                ></Tab>
            </Tabs>

            {showViewPopup &&
                renderViewPopup('View Offer', false, () =>
                    getOfferHistory(statusList, yearFilter),
                )}

            {showEditPopup &&
                renderEditPopup('Edit Offer', false, () =>
                    getOfferHistory(statusList, yearFilter),
                )}

            {showJobViewPopup &&
                renderJobViewPopup(() =>
                    getOfferHistory(statusList, yearFilter),
                )}
            {showJobEditPopup &&
                renderJobEditPopup(() =>
                    getOfferHistory(statusList, yearFilter),
                )}

            <OfferRejectPopup
                handleClose={() => {
                    handleClose();
                    getOfferHistory(statusList, yearFilter);
                }}
                popup={popup}
                offerId={offerId}
            />
        </div>
    );
};
export default Dashboard;
