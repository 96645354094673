import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ToastBodyContainer from '@components/ToastContainer';

import { routesPath } from '@constants/routesNavigate';

import Navbar from '@app/AppLayout/NavbarTop';
import Sidebar from '@app/AppLayout/sidebar';
import { clearResponse, clearOneTimeResponse } from '@redux/app/appSlice';

import './layout.scss';

const AppLayout = ({ children, isSearch, isListView }: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { common } = useSelector((state: any) => state);

    const { oneResponse, response } = common;

    const [sideToggle, setSideToggle] = useState<boolean>(false);
    const [showHeaderContent, setShowHeaderContent] = useState<boolean>(false);

    useEffect(() => {
        pathname === routesPath?.dashboard ||
        pathname === routesPath?.userProfile
            ? setShowHeaderContent(true)
            : setShowHeaderContent(false);
    }, [pathname]);

    useEffect(() => {
        if (oneResponse?.message) {
            toast.success(oneResponse?.message);
            dispatch(clearOneTimeResponse());
        } else {
            const { message, status } = response || {};
            const toastType = status && message !== '' ? 'success' : 'error';
            toast[toastType](message);
            dispatch(clearResponse());
        }
    }, []);

    useEffect(() => {
        const navigateTo = localStorage.getItem('navigateTo');
        if (navigateTo && navigateTo !== '') {
            localStorage.setItem('navigateTo', '');
            navigate(navigateTo);
        }
    }, []);

    return (
        <div
            className={`${
                sideToggle
                    ? 'd-flex mfnw__main-wrapper sidebar_small'
                    : 'd-flex mfnw__main-wrapper'
            }`}
        >
            <div className="mfnw__sidebar_toggle">
                <Sidebar
                    setSideToggle={setSideToggle}
                    sideToggle={sideToggle}
                />
            </div>
            <div className="mfnw__wrapper-content w-100">
                <Navbar
                    isSearch={isSearch}
                    isListView={isListView}
                    showHeaderContent={showHeaderContent}
                />
                <div
                    className={`${
                        showHeaderContent
                            ? 'mfnw__wrapper-pages'
                            : 'mfnw__wrapper-small'
                    }`}
                >
                    {children}
                </div>
            </div>
            <ToastBodyContainer />
        </div>
    );
};

export default AppLayout;
