/* eslint-disable max-lines-per-function */
import React from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { Col, Row, Form, Button } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import RadioButton from '@components/RadioButton/RadioButton';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';
import DateSelect from '@components/DateSelect/DateSelect';
import ErrorMsg from '@components/errorMsg';
import FilesUpload from '@components/FileUpload/filesUpload';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { phoneFormat } from '@constants/general.const';

import {
    faEnvelope,
    faAddressCard,
    faPhone,
} from '@fortawesome/pro-light-svg-icons';

const DriverFormField = ({
    control,
    errors,
    packStartRef,
    setFilesArray2,
    preview2,
    setPreview2,
    setValue,
    getValues,
    progress2,
    showProgressBar2,
    setFilesArray,
    preview,
    setPreview,
    progress,
    showProgressBar,
    isAddDriver,
    isUpdateDriver,
    onClose,
    isEditPopup,
    isDisabled,
}: any) => (
    <>
        <h6 className="mt-3">DRIVER TYPE</h6>
        <Row className="justify-content-center">
            <Col md={12} className="py-4">
                <Form>
                    <div className="d-flex flex-wrap justify-content-start justify-content-sm-between">
                        <Controller
                            name="driver_type"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <RadioButton
                                    onChangeFunc={onChange}
                                    id="inline-radio-1"
                                    name="region_type"
                                    value={
                                        isDisabled
                                            ? value === 'Company'
                                                ? 'COMPANY'
                                                : 'CONTRACTOR'
                                            : 'Company'
                                    }
                                    classNameParentDiv="bg-icon_radio_state radio_custom"
                                    checked={
                                        isDisabled ? true : value === 'Company'
                                    }
                                    label={
                                        isDisabled
                                            ? value === 'Company'
                                                ? 'COMPANY'
                                                : 'CONTRACTOR'
                                            : 'COMPANY'
                                    }
                                    classes=""
                                    disabled={isDisabled}
                                />
                            )}
                        />
                        {!isDisabled && (
                            <Controller
                                name="driver_type"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <RadioButton
                                        onChangeFunc={onChange}
                                        id="inline-radio-2"
                                        name="region_type"
                                        value="Contractor"
                                        checked={value === 'Contractor'}
                                        classNameParentDiv="bg-icon_radio_city radio_custom"
                                        label="CONTRACTOR"
                                        classes=""
                                    />
                                )}
                            />
                        )}
                    </div>
                </Form>
            </Col>
            <h6 className="text-sm">DRIVER INFO</h6>
            <Col md={6}>
                <div className="mt-2">
                    <Controller
                        name={`first_name`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={1}
                                    id={`State`}
                                    inputType={'text'}
                                    placeholder={'First Name'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    errorText={errors?.first_name?.message}
                                    autoFocus={true}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-2">
                    <Controller
                        name={`last_name`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={2}
                                    id={`last name`}
                                    inputType={'text'}
                                    placeholder={'Last Name'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    errorText={errors?.last_name?.message}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={12}>
                <div className="mt-4">
                    <Controller
                        name={`driver_phone`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <PhoneInputMaskGroup
                                maskFormat={phoneFormat}
                                inputName="organization_phone"
                                inputType="text"
                                label="Phone No."
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                onChangeFunc={onChange}
                                id="organization_phone"
                                aria-describedby="phone"
                                tabIndex={3}
                                faphoneIcon={faPhone}
                                value={value}
                                placeholder="Phone"
                                errorText={errors?.driver_phone?.message}
                                disabled={isDisabled}
                            />
                        )}
                    />
                </div>
            </Col>
            <Col md={12}>
                <div className="mt-4">
                    <Controller
                        name={`driver_email`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={4}
                                    id={`Email`}
                                    inputType={'text'}
                                    icon={faEnvelope}
                                    placeholder={'Email'}
                                    disabled={isDisabled}
                                    groupInput={true}
                                    errorText={errors?.driver_email?.message}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={'driver_license'}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <InputGroupText
                                onChangeFunc={onChange}
                                value={value}
                                id={`license`}
                                inputType={'text'}
                                placeholder={'License #'}
                                label={'license'}
                                icon={faAddressCard}
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                disabled={isDisabled}
                                tabIndex={5}
                                errorText={errors?.driver_license?.message}
                            />
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name="driver_license_expiration_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <DateSelect
                                ref={packStartRef}
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                placeholder="MM/DD/YYYY"
                                tabIndex={6}
                                selected={
                                    typeof value === 'string'
                                        ? moment(value)
                                              .tz('America/New_York')
                                              .toDate()
                                        : value || null
                                }
                                dateFormat="MM/dd/yyyy"
                                inputName="driver_license_expiration_date"
                                onChangeFunc={({
                                    value: valueDateChange,
                                }: any) => onChange(valueDateChange)}
                                disabled={isDisabled}
                            />
                        )}
                    />
                    <ErrorMsg
                        errorText={
                            errors?.driver_license_expiration_date?.message
                        }
                    ></ErrorMsg>
                </div>
            </Col>
        </Row>
        <div className="main-file">
            <FilesUpload
                heading={`Driver's License`}
                setFilesArray={setFilesArray2}
                preview={preview2}
                setPreview={setPreview2}
                setValue={setValue}
                getValues={getValues}
                progress={progress2}
                showProgressBar={showProgressBar2}
                forValue={
                    getValues('driver_license_pdf') &&
                    typeof getValues('driver_license_pdf') === 'string'
                        ? JSON.parse(getValues('driver_license_pdf'))
                        : getValues('driver_license_pdf')
                }
                isRemove={isEditPopup}
                isEditPopup={isEditPopup}
                tabIndex={7}
            />
            <FilesUpload
                heading={'Certifications'}
                setFilesArray={setFilesArray}
                preview={preview}
                setPreview={setPreview}
                setValue={setValue}
                getValues={getValues}
                progress={progress}
                showProgressBar={showProgressBar}
                forValue={
                    getValues('driver_certificate') &&
                    typeof getValues('driver_certificate') === 'string'
                        ? JSON.parse(getValues('driver_certificate'))
                        : getValues('driver_certificate')
                }
                isRemove={isEditPopup}
                isEditPopup={isEditPopup}
                tabIndex={8}
            />
            {!isDisabled && (
                <Col md={12}>
                    <Button
                        variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                        type="submit"
                        disabled={isAddDriver || isUpdateDriver}
                        tabIndex={9}
                    >
                        {isAddDriver || isUpdateDriver ? <LoaderBtn /> : 'Save'}
                    </Button>
                    <Button
                        className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                        onClick={onClose}
                        tabIndex={10}
                    >
                        CANCEL
                    </Button>
                </Col>
            )}
        </div>
    </>
);

export default DriverFormField;
