import React from 'react';
import { Accordion, Dropdown, Image } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/fontawesome-pro-regular';
import Assign from '@images/assign.svg';
import Schedule from '@images/calendar-schedule.svg';
import Userlist from '@images/listuser.svg';
import Unschedule from '@images/unschedule.svg';
import unload from '@images/unload.svg';
import load from '@images/load.svg';
import pack from '@images/pack.svg';

const faClockProp = faClock as IconProp;

interface PackLoadDataArrays {
    [key: string]: {
        [key: string]: any[];
        offer_id?: any;
        packer_name?: any;
    };
}

const renderEventData = (
    data: any,
    type: any,
    handleViewShow: any,
    packLoadDataArrays: any,
    titleArrays: any,
    fetchData: any,
    updateCurrentPackerTitle: any,
) =>
    Object.keys(data).map((date, key2) => {
        const dataValue = data[date];
        if (date !== 'offer_id' && date !== 'packer_id') {
            return (
                <>
                    {Object.keys(dataValue).length > 0 ? (
                        Object.keys(dataValue).map((details, key3) => {
                            const packeData = dataValue[details];
                            const icon =
                                packeData?.icon === 'Schedule'
                                    ? Schedule
                                    : packeData?.icon === 'Unschedule'
                                    ? Unschedule
                                    : Assign;

                            return (
                                <div
                                    className={`element_box__two px-2 px-lg-2 pt-2  ${packeData?.class}`}
                                    key={key3}
                                    title={
                                        packeData?.titleWithDestination ??
                                        packeData?.title
                                    }
                                    onClick={() => {
                                        type === 'job' &&
                                            handleViewShow(packeData.event.id);

                                        if (type === 'packer') {
                                            const packLoadData =
                                                packLoadDataArrays &&
                                                packLoadDataArrays[
                                                    packeData?.title
                                                ];
                                            const offerID = {
                                                event: {
                                                    id: packLoadData?.offer_id,
                                                    value: packLoadData,
                                                    packer_name:
                                                        packLoadData?.packer_name,
                                                },
                                            };
                                            type === 'packer' &&
                                                offerID?.event.id &&
                                                fetchData(offerID);

                                            updateCurrentPackerTitle &&
                                                updateCurrentPackerTitle(
                                                    packeData?.title,
                                                );
                                        }
                                    }}
                                >
                                    <div className="d-flex justify-content-between mb-1">
                                        <div
                                            className={`${packeData?.badge} ps-sm-0 pe-sm-0 ps-lg-2 pe-lg-2`}
                                        >
                                            <Image
                                                src={icon}
                                                className="pe-1 text-white"
                                            />
                                            <span className="text-sm fw-semibold text-white">
                                                {packeData?.type}
                                            </span>
                                        </div>
                                        <div>
                                            {type === 'packer' &&
                                                packeData?.typeIcon.length >
                                                    0 &&
                                                packeData?.typeIcon.map(
                                                    (icons: any) => {
                                                        var imgIcon =
                                                            icons === 'pack'
                                                                ? pack
                                                                : icons ===
                                                                  'load'
                                                                ? load
                                                                : unload;
                                                        return (
                                                            <Image
                                                                src={imgIcon}
                                                                className="align-items-end pe-2"
                                                                key={icons}
                                                            />
                                                        );
                                                    },
                                                )}
                                        </div>
                                    </div>
                                    <p className="mfnw_weekly_namedetail p-0 m-0 fw-bolder text-uppercase">
                                        {packeData?.title}
                                    </p>
                                    <div className="p-0 m-0">
                                        <FontAwesomeIcon
                                            icon={faClockProp}
                                            size="sm"
                                            className="pe-1"
                                        />
                                        <span className="text-lg-base fw-normal weekly_font_size">
                                            9a - 5p
                                        </span>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className={`element_box__two px-1 px-lg-2`}></div>
                    )}
                </>
            );
        } else {
            return null;
        }
    });

const EventAccordion = ({
    data,
    title,
    type,
    fetchData,
    handleViewShow,
    updateCurrentPackerTitle,
}: any) => {
    const PackerCell = () =>
        Object.keys(data).map((dataKey, key) => {
            var titleArrays: Record<string, any[]> = {};
            var packLoadDataArrays: PackLoadDataArrays = {};

            const value = data[dataKey];
            let count = 0;
            for (const keys in value) {
                if (
                    value[keys].length > 0 &&
                    keys !== 'offer_id' &&
                    keys !== 'packer_id'
                ) {
                    count++;
                }
            }

            Object.keys(value).map((item: any) => {
                var newData = value[item];
                if (item !== 'offer_id' && item !== 'packer_id') {
                    Object.keys(newData).map((itemz: any) => {
                        const titles: any = newData[itemz]?.title;

                        if (!(titles in titleArrays)) {
                            titleArrays[titles] = [];
                            titleArrays[titles].push(newData[itemz]);
                        }
                        if (!(titles in packLoadDataArrays)) {
                            packLoadDataArrays[titles] = {} as any;
                        }

                        if (!(item in packLoadDataArrays[titles])) {
                            packLoadDataArrays[titles][item] = [];
                        }
                        packLoadDataArrays[titles][item].push(newData[itemz]);
                        packLoadDataArrays[titles].offer_id = newData[itemz]
                            ?.offer_id as any;
                        packLoadDataArrays[titles].packer_name = dataKey as any;
                        return null;
                    });
                    return null;
                } else {
                    return null;
                }
            });

            return (
                <div className="d-flex position-relative" key={key}>
                    <div
                        className="avtar element_box__two"
                        id="dropdown-item-button"
                    >
                        <Dropdown className="packer__dropdown">
                            <Dropdown.Toggle className="btn-header">
                                <div className="avtar" title={dataKey}>
                                    <div className="element_box_one ps-1 ps-lg-4">
                                        <div className="d-flex align-items-center">
                                            <div className="bg-primary rounded-pill element_box_icon py-2 px-2 text-center me-2">
                                                <img
                                                    src={Userlist}
                                                    alt="User"
                                                    className="mfnw_usericon_set"
                                                />
                                            </div>
                                            <div className="w-70 element_detailoverflow weekly_font_size_user_namedetail">
                                                <span className="fw-bolder text-base text-uppercase">
                                                    {dataKey}
                                                </span>
                                                <p className="p-0 m-0 text-sm fw-normal">
                                                    {count * 8} / 40
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            {Object.keys(titleArrays).length > 0 && (
                                <Dropdown.Menu className="mt-2">
                                    {Object.keys(titleArrays).map(
                                        (eventData: any) => (
                                            <Dropdown.Item
                                                as="button"
                                                key={eventData}
                                                className="text-uppercase"
                                                onClick={() => {
                                                    if (type === 'packer') {
                                                        const packLoadData =
                                                            packLoadDataArrays &&
                                                            packLoadDataArrays[
                                                                eventData
                                                            ];

                                                        const offerID = {
                                                            event: {
                                                                id: packLoadData?.offer_id,
                                                                value: packLoadData,
                                                                packer_name:
                                                                    packLoadData?.packer_name,
                                                            },
                                                        };

                                                        type === 'packer' &&
                                                            offerID?.event.id &&
                                                            fetchData(offerID);

                                                        updateCurrentPackerTitle &&
                                                            updateCurrentPackerTitle(
                                                                titleArrays[
                                                                    eventData
                                                                ]?.[0]?.title,
                                                            );
                                                    }
                                                }}
                                            >
                                                {
                                                    titleArrays[eventData]?.[0]
                                                        ?.title
                                                }
                                            </Dropdown.Item>
                                        ),
                                    )}
                                </Dropdown.Menu>
                            )}
                        </Dropdown>
                    </div>
                    {value &&
                        renderEventData(
                            value,
                            type,
                            handleViewShow,
                            packLoadDataArrays,
                            titleArrays,
                            fetchData,
                            updateCurrentPackerTitle,
                        )}
                </div>
            );
        });

    return (
        <>
            {type && type === 'job' ? (
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="event_box_background py-1 px-2 text-center">
                            <div className="element_boxaccordian text-center text-uppercase">
                                {title}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="mfnw_border ">
                            {Object.keys(data).length > 0 ? (
                                PackerCell()
                            ) : (
                                <div className="border text-center py-2">
                                    <p className="fs-4 fw-bolder p-0 m-0">
                                        NOT ASSIGNED
                                    </p>
                                </div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : (
                Object.keys(data).length > 0 && PackerCell()
            )}
        </>
    );
};

export default EventAccordion;
