import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';
import { reGenerateKey, updateProfile, userProfileGet } from './functions';

const defaultState = {
    isUserData: false,
    isUpdateProfile: false,
    isChangePassword: false,
    isReGenerateKey: false,
    isChangeCompany: false,
    isUserProfileGet: false,
    UserData: {
        email: '',
        firstName: '',
        lastName: '',
        company_name: '',
        user_profile: '',
        secret_key: '',
        company_address: '',
        company_city: '',
        company_state: '',
        company_zip_code: 0o0,
        company_phone: '',
        company_email: '',
        authorityNumber: '',
        SCAC: [],
        current_pass: '',
        password: '',
        password_confirmation: '',
    },
};

export const UserProfileSlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [userProfileGet.pending.type]: (state: any, { payload }) => {
            state.isUserProfileGet = true;
            state.registerResponse = payload;
        },
        [userProfileGet.fulfilled.type]: (state: any, { payload }) => {
            state.isUserProfileGet = false;
            state.UserData = payload?.data?.data;
        },
        [userProfileGet.rejected.type]: (state: any, { payload }) => {
            state.isUserProfileGet = false;
            state.registerResponse = payload;
        },

        [updateProfile.pending.type]: (state: any, { payload }) => {
            state.isUpdateProfile = true;
            state.registerResponse = payload;
        },
        [updateProfile.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateProfile = false;
            state.UserData = payload?.data?.data;
        },
        [updateProfile.rejected.type]: (state: any, { payload }) => {
            state.isUpdateProfile = false;
            state.registerResponse = payload;
        },

        [reGenerateKey.pending.type]: (state: any, { payload }) => {
            state.isReGenerateKey = true;
            state.registerResponse = payload;
        },
        [reGenerateKey.fulfilled.type]: (state: any, { payload }) => {
            state.isReGenerateKey = false;
            state.UserData = payload?.data?.data;
        },
        [reGenerateKey.rejected.type]: (state: any, { payload }) => {
            state.isReGenerateKey = false;
            state.registerResponse = payload;
        },
    },
});

export const { loginChange, updateIsLogOutUser } = UserProfileSlice.actions;

export { userProfileGet, updateProfile, reGenerateKey };

export default UserProfileSlice.reducer;
