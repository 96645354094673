import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import { Form } from '@components-default';
import ErrorMsg from '@components/errorMsg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IInputTextGroupProps } from './inputGroupText.interface';

const InputGroupText = ({
    inputName,
    placeholder,
    errorText,
    asTextarea,
    labelcontainer,
    className,
    regularExp,
    label,
    tabIndex,
    maxLength,
    title,
    icon,
    min,
    max,
    disabled,
    inputType,
    minLength,
    groupInput,
    inputGroupClassName,
    onChangeFunc,
    ...props
}: IInputTextGroupProps) => {
    const regExpArray: any = {
        number: /(^[0-9]+$|^$)/,
        letters: /^[A-za-z]+$/,
        alphanumeric: /^[ A-Za-z0-9_@.]*$/,
        regularExp: regularExp,
    };
    const typeArr = ['text', 'email', 'password', 'file'];

    const HandleChange = (e: any) => {
        var testValueType: any = regularExp === '' ? inputType : 'regularExp';
        var valueTypeMatch =
            testValueType !== '' && !typeArr.includes(testValueType)
                ? regExpArray[testValueType].test(e.target.value)
                : true;
        if (e.target.value === '' || valueTypeMatch) {
            onChangeFunc(e);
        }
    };
    return (
        <>
            {asTextarea === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    type={inputType}
                    name={inputName}
                    className={className}
                    placeholder={placeholder}
                    onChange={HandleChange}
                    tabIndex={tabIndex}
                    min={min}
                    rows="5"
                    maxLength={maxLength}
                    minLength={minLength}
                    max={max}
                    disabled={disabled}
                    autoComplete="off"
                    {...props}
                />
            ) : (
                <InputGroup title={title}>
                    {groupInput && (
                        <InputGroup.Text className={inputGroupClassName}>
                            {icon && label ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={icon}
                                        className="me-2"
                                        size="lg"
                                    />
                                    {` ${label}`}
                                </>
                            ) : icon ? (
                                <>
                                    <FontAwesomeIcon icon={icon} size="lg" />
                                </>
                            ) : (
                                label
                            )}
                        </InputGroup.Text>
                    )}
                    <FormControl
                        aria-label={label}
                        type={inputType}
                        name={inputName}
                        className={`mfnw__login-form-control mfnw__login-form-control-bg ${className}`}
                        placeholder={placeholder}
                        onChange={HandleChange}
                        tabIndex={tabIndex}
                        min={min}
                        maxLength={maxLength}
                        minLength={minLength}
                        max={max}
                        disabled={disabled}
                        autoComplete="off"
                        {...props}
                    />
                </InputGroup>
            )}

            {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
        </>
    );
};

InputGroupText.defaultProps = {
    inputName: '',
    placeholder: '',
    errorText: '',
    asTextarea: '',
    inputType: '',
    label: '',
    regularExp: '',
    tabIndex: '',
    groupInput: true,
    id: '',
    icon: '',
    labelcontainer: '',
    maxLength: null,
    className: '',
    inputGroupClassName: '',
    onChangeFunc: () => void 0,
};

export default InputGroupText;
