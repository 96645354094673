import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';

import OfferNotFound from '@images/offer-not-found.svg';

import BottomDashboardSection from '@app/Dashboard/bottomDashboardSection';
import DashboardOfferLIst from '@app/Dashboard/dashboardOfferLIst';

import { dashboardJobTopItemArray } from '../dashbord.const';

const DashboardJob = ({
    dataCount,
    maxDataLoop,
    offerHistoryArr,
    handleViewShow,
    countYTD,
}: any) => (
    <div className="mfnw_top_card_content">
        <div className="left-side-title mt-3">
            <div className="list-grid-title"></div>
        </div>
        <div className="mfnw__content-center">
            {maxDataLoop && maxDataLoop !== 0 ? (
                <div className="row d-flex flex-wrap flex-xl-nowrap justify-content-space-between">
                    {dashboardJobTopItemArray.map(
                        (item: any, index: number) => {
                            const { icon, displayName, backGround, count } =
                                item;
                            return (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={3}
                                    key={index}
                                    className="mb-3"
                                >
                                    <Col xs={12} className="mb-3">
                                        <Card
                                            className={`d-flex flex-row align-items-center mfnw__dashboar-card mb-3 bg-${backGround}`}
                                        >
                                            <div className="mf__card-content d-flex justify-content-between align-items-center w-100">
                                                <div className="text text-truncate">
                                                    <p className="m-0 fs-6 text-truncate">
                                                        {displayName} (Last 30
                                                        days)
                                                    </p>
                                                    <h1>
                                                        {dataCount?.[count]
                                                            ?.length ?? 0}
                                                    </h1>
                                                </div>
                                                <div className="icon position-absolute responsive_dashboard_firstcard">
                                                    <Image src={icon} />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    {offerHistoryArr?.[item?.count]?.length >
                                        0 &&
                                        offerHistoryArr?.[item?.count]?.map(
                                            (
                                                itemData: any,
                                                dataIndex: number,
                                            ) => (
                                                <DashboardOfferLIst
                                                    key={dataIndex}
                                                    itemData={itemData}
                                                    item={item}
                                                    offerHistoryArr={
                                                        offerHistoryArr
                                                    }
                                                    handleViewShow={
                                                        handleViewShow
                                                    }
                                                />
                                            ),
                                        )}
                                </Col>
                            );
                        },
                    )}
                </div>
            ) : (
                <>
                    {maxDataLoop === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={5} className="text-center mt-5">
                                <Image src={OfferNotFound} />
                                <h5 className="text-light mt-4 fw-bold">
                                    You haven&apos;t received any offers yet.{' '}
                                    <br /> Don&apos;t give up, keep searching
                                    and you&apos;ll eventually find them.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>

        <BottomDashboardSection dataCount={countYTD} listType={'job'} />
    </div>
);

export default DashboardJob;
