import * as Yup from 'yup';

import { IHolidayFieldsProps, holidayInfo } from './holiday.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
    showDeleteConfirmationDialog,
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    deleteHoliday,
    getHolidayInfo,
    holidaysList,
} from '@app/SettingsLists/Holidays/functions';
import { clearResponse } from '@redux/app/appSlice';
import { confimationDialogBoxText } from '../lists.const';
import { IListOption } from '../../../Interface/common';

export const holidayDataInfo: holidayInfo = {
    holiday_nano_id: null,
    type: 'Company',
    date: null,
    name: '',
};

export const holidayDefaultData: IHolidayFieldsProps = {
    year: null,
    holiday_info: [holidayDataInfo],
};

export const schema = Yup.object({
    year: Yup.string().nullable().required('Holiday Year is required'),
    holiday_info: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            date: Yup.string().nullable().required('Date is required'),
            name: Yup.string().required('Name is required'),
        }),
    ),
}).required();

export const holidayTypes: IListOption[] = [
    {
        value: 'Company',
        label: 'Company',
    },
    {
        value: 'Federal',
        label: 'Federal',
    },
    {
        value: 'Both',
        label: 'Both',
    },
];
export function useHolidayPopup() {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, holidaysData } = useSelector((state: any) => state);
    const { response } = common;
    const { isDeleteHoliday, isHolidaysList } = holidaysData;

    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [holidayData, setHolidayData] = useState<any>(null);
    const [pageCount, setPageCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [filters, setFilters] = useState<any>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [yearFilter, setYearFilter] = useState<any>(new Date());

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);
    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [yearFilter]);

    const getFilters = async () => {
        FiltersData(
            searchText,
            getList,
            setFilters,
            'holiday_list',
            dispatch,
            navigate,
        );
    };

    const getList = async (currentPage: number, res: any) => {
        console.log('yearFilter11', yearFilter);
        const year =
            yearFilter instanceof Date ? yearFilter.getFullYear() : null;
        const { payload } = await dispatch(
            holidaysList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
                yearFilter: yearFilter ? year : null,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const getHolidayData = async (holidaysId: string) => {
        const { payload } = await dispatch(getHolidayInfo(holidaysId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setHolidayData(payload?.data?.data);
        } else {
            handlePayloadError(payload);
        }
    };
    const handlePayloadError = (payload: any) => {
        toast.error(payload?.data?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getListAndUpdateFilter(updateFilter);
    };

    const getListAndUpdateFilter = (updateFilter: any) => {
        getList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };

    const showAlert = async (id: any, type: string) => {
        const confimaBoxText = confimationDialogBoxText(type);

        const confirmationOptions = {
            title: confimaBoxText?.swalTitle,
            text: confimaBoxText?.swalText,
            icon: 'warning',
            confirmButtonText: confimaBoxText?.confirmButtonText,
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(
                deleteHoliday({ id, type: type }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                handleViewClose();
                getList(1, filters);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    const handleEditShow = (nanoId: string) => {
        handleViewClose();
        getHolidayData(nanoId);
        setShowEditPopup(true);
    };

    const handleShow = () => setShowPopup(true);
    const handleClose = () => {
        setShowPopup(false);
    };

    const handleViewShow = (nanoId: string) => {
        console.log('nanoId', nanoId);
        setShowViewPopup(true);
        setIsDisabled(true);
        getHolidayData(nanoId);
    };

    const handleViewClose = () => {
        setShowViewPopup(false);
        setIsDisabled(false);
        setHolidayData(null);
    };

    const handleEditClose = () => {
        setShowEditPopup(false);
        setHolidayData(null);
    };

    return {
        showViewPopup,
        showEditPopup,
        handleViewShow,
        handleEditShow,
        handleClose,
        currentPageData,
        totalDataCount,
        pageCount,
        handleViewClose,
        handleEditClose,
        setShowViewPopup,
        setShowEditPopup,
        filters,
        setFilters,
        searchText,
        setSearchText,
        handleShow,
        isHolidaysList,
        isDeleteHoliday,
        handleLimitChange,
        showAlert,
        handlePageClick,
        showPopup,
        getFilters,
        getList,
        isDisabled,
        holidayData,
        setYearFilter,
        yearFilter,
    };
}
