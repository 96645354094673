import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addJobDocument,
    deleteJobDocument,
    jobDocumentsList,
} from '@app/JobList/Documents/functions';

const defaultState = {
    isAddJobDocument: false,
    isJobDocumentsList: false,
    isUpdateJobDocument: false,
    isGetJobDocumentInfo: false,
    isDeleteJobDocument: false,
};

export const documentSlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addJobDocument.pending.type]: (state: any, { payload }) => {
            state.isAddJobDocument = true;
            state.registerResponse = payload;
        },
        [addJobDocument.fulfilled.type]: (state: any, { payload }) => {
            state.isAddJobDocument = false;
            state.registerResponse = payload;
        },
        [addJobDocument.rejected.type]: (state: any, { payload }) => {
            state.isAddJobDocument = false;
            state.registerResponse = payload;
        },

        [jobDocumentsList.pending.type]: (state: any, { payload }) => {
            state.isJobDocumentsList = true;
            state.registerResponse = payload;
        },
        [jobDocumentsList.fulfilled.type]: (state: any, { payload }) => {
            state.isJobDocumentsList = false;
            state.registerResponse = payload;
        },
        [jobDocumentsList.rejected.type]: (state: any, { payload }) => {
            state.isJobDocumentsList = false;
            state.registerResponse = payload;
        },

        [deleteJobDocument.pending.type]: (state: any, { payload }) => {
            state.isDeleteJobDocument = true;
            state.registerResponse = payload;
        },
        [deleteJobDocument.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteJobDocument = false;
            state.registerResponse = payload;
        },
        [deleteJobDocument.rejected.type]: (state: any, { payload }) => {
            state.isDeleteJobDocument = false;
            state.registerResponse = payload;
        },
    },
});

export { addJobDocument, jobDocumentsList, deleteJobDocument };

export default documentSlice.reducer;
