import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import OtpInput from 'react18-input-otp';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Image } from '@components-default';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ToastBodyContainer from '@components/ToastContainer';

import { routesPath } from '@constants/routesNavigate';

import { clearResponse } from '@redux/app/appSlice';
import { saveLoggedInUser } from '@login/loginSlice';

import Logo from '@images/mf-logo.png';

import { sendOtp, verifyUser } from '@app/Auth/EmailVerify/functions';

import { IEmailVerifyProps } from './emailVerify.interface';

import './emailVerify.scss';

const EmailVerify = () => {
    const dispatch = useDispatch<any>();
    const { userEmail } = useParams();

    const { emailVerify, common, register } = useSelector(
        (state: any) => state,
    );
    const { verifyData, isSendOtp, isVerifyUser } = emailVerify;
    const { response } = common;
    const { email } = register;
    //  ask fore type
    const [OTP, setOTP] = useState<string>('');

    const schema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is a required field'),

        OTP: Yup.string(),

        password: Yup.string()
            .required('Please provide a password')
            .min(8, 'Password should be at least 8 characters long.'),
    }).required();

    useForm<IEmailVerifyProps>({
        resolver: yupResolver(schema),
        defaultValues: verifyData,
    });

    const handleChange = (otp: any) => {
        setOTP(otp);
    };

    useEffect(() => {
        if (response?.data?.status && response?.data?.message !== '') {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.message);
        }
        dispatch(clearResponse());
    }, []);

    const verifyOtp = async () => {
        if (OTP.length === 6) {
            const { payload } = await dispatch(verifyUser(OTP));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(saveLoggedInUser(payload));
                localStorage.setItem(
                    'accessToken',
                    payload?.data?.data?.original?.access_token,
                );

                localStorage.setItem('navigateTo', routesPath?.step1);
            } else {
                toast.error(payload?.message);
            }
        } else {
            toast.error('Please Enter valid OTP');
        }
    };

    const HandleResendOtp = async () => {
        const { payload } = await dispatch(
            sendOtp({ email: email ? email : userEmail }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            toast.success(payload?.data?.message);
        } else {
            toast.error(payload?.message);
        }
    };

    return (
        <Container className="h-100">
            <ToastBodyContainer />
            <Row className="justify-content-center align-items-center h-100">
                <Col lg={8} xl={7}>
                    <Card>
                        <div className="p-5 text-center">
                            <div className="mfnw__form-logo text-center mb-3 mb-lg-5 pt-2 pt-lg-0">
                                <Image src={Logo} />
                                <h3 className="mt-5 fw-bold">Verify Account</h3>
                            </div>
                            <div className="verifyDiv">
                                <h4 className="btn_type_large fw-semibold">
                                    An OTP has been sent to your entered email{' '}
                                    {email ? email : userEmail}
                                </h4>
                                <div className="otpElements">
                                    <p className="fs-5">Enter your Code here</p>
                                    <div className="otp">
                                        <OtpInput
                                            onChange={handleChange}
                                            value={OTP}
                                            inputStyle="inputStyle"
                                            numInputs={6}
                                            separator={<span></span>}
                                        />
                                    </div>

                                    <p className="fs-6 mt-5">
                                        Didn&apos;t receive the code?
                                    </p>

                                    <Button
                                        variant="secondary px-5 py-2 mb-5"
                                        disabled={isSendOtp}
                                        onClick={HandleResendOtp}
                                    >
                                        {isSendOtp ? <LoaderBtn /> : 'Resend'}
                                    </Button>
                                </div>
                                <Button
                                    onClick={verifyOtp}
                                    variant="primary"
                                    className="mfnw__button_small w-50"
                                    disabled={isVerifyUser}
                                    size="lg"
                                >
                                    {isVerifyUser ? <LoaderBtn /> : 'Verify'}
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EmailVerify;
