import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    pageLimitList,
    searchTimeOut,
    showDeleteConfirmationDialog,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import { deleteUser, userDataGet, usersList } from './functions';
import EditUserPopup from '@app/UserListing/Users/editUserPopup';
import ShowUserPopup from '@app/UserListing/Users/showUserPopup';
import AddUserPopup from '@app/UserListing/Users/AddUserPopup';
import UserTable from '@app/UserListing/Users/UserTable';
import ListHeader from '@app/SettingsLists/listHeader';
import { clearResponse } from '@redux/app/appSlice';

import '@app/UserListing/userList.scss';

const UserListingTab = () => {
    const getFilters = async () => {
        FiltersData(
            searchText,
            getList,
            setFilters,
            'user_list',
            dispatch,
            navigate,
        );
    };

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { common, login, inviteUsers } = useSelector((state: any) => state);
    const { response } = common;
    const { authUser } = login;
    const { isUsersList } = inviteUsers;

    const [filters, setFilters] = useState<any>();
    const [searchText, setSearchText] = useState<any>();
    //  ask fore type

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showViewUser, setShowViewUser] = useState<boolean>(false);
    const [editUserPopup, setEditUserPopup] = useState<boolean>(false);
    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [userId, setUserId] = useState<string>('');
    const [userData, setUserData] = useState<any>(null);

    const handleShowEvent = () => setShowPopup(true);
    const handleCloseEvent = () => setShowPopup(false);

    const handleViewEventUser = (nanoId: string) => {
        setShowViewUser(true);
        setUserId(nanoId);
        getUserData(nanoId);
    };

    const handleCloseViewUser = () => {
        setShowViewUser(false);
        setUserId('');
    };

    const handleViewEditUser = (nanoId: string) => {
        setEditUserPopup(true);
        setUserId(nanoId);
        setShowViewUser(false);
        getUserData(nanoId);
    };

    const handleCloseEditUser = () => {
        setEditUserPopup(false);
        setUserId('');
    };

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getUserData = async (userNanoId: string) => {
        const { payload } = await dispatch(userDataGet(userNanoId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setUserData(payload?.data?.user);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            usersList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getPackersListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getPackersListAndUpdateFilter(updateFilter);
    };

    const getPackersListAndUpdateFilter = (updateFilter: any) => {
        getList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };

    const actionUserById = async (deleteUserId: any, type: string) => {
        let swalText = '';
        let swalTitle = '';
        let confirmButtonText = '';
        if (type === 'delete') {
            swalTitle = 'Delete User';
            swalText = "You won't be able to revert this operation!";
            confirmButtonText = 'Yes, delete it!';
        } else if (type === 'Disable') {
            swalTitle = 'warning';
            swalText = 'Set Disable?';
            confirmButtonText = 'Yes, Disable it!';
        } else if (type === 'Enable') {
            swalTitle = 'warning';
            swalText = 'Set Enable?';
            confirmButtonText = 'Yes, Enable it!';
        }

        const confirmationOptions = {
            title: swalTitle,
            text: swalText,
            icon: 'warning',
            customClass: 'z-index-2',
            confirmButtonText: confirmButtonText,
        };

        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(
                deleteUser({ type: type, userId: deleteUserId }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                getList(1, filters);
                if (showViewUser || editUserPopup) {
                    getUserData(userId);
                }
                handleCloseViewUser();
            } else {
                toast.error(payload?.message);
            }
        }
    };

    return (
        <div className="mfnw__userlisting_content">
            <ListHeader
                title={'User List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShowEvent}
                handleLimitChange={handleLimitChange}
                buttonText={'Add User'}
            />
            <UserTable
                currentPageData={currentPageData}
                isUsersList={isUsersList}
                handleViewEventUser={handleViewEventUser}
                authUser={authUser}
                actionUserById={actionUserById}
                handleViewEditUser={handleViewEditUser}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                filters={filters}
            />

            {showPopup && (
                <AddUserPopup
                    showPopup={showPopup}
                    handleCloseEvent={handleCloseEvent}
                    userId={userId}
                    listApiCall={() => getList(1, filters)}
                />
            )}
            {showViewUser && (
                <ShowUserPopup
                    showPopup={showViewUser}
                    handleCloseEvent={handleCloseViewUser}
                    data={userData}
                    actionUserById={actionUserById}
                    handleViewEditUser={handleViewEditUser}
                />
            )}
            {editUserPopup && (
                <EditUserPopup
                    showPopup={editUserPopup}
                    handleCloseEvent={handleCloseEditUser}
                    editData={userData}
                    listApiCall={() => getList(1, filters)}
                />
            )}
        </div>
    );
};
export default UserListingTab;
