import { cloneDeep } from 'lodash';

import { logOutUser, loginUser } from '@app/Auth/Login/functions';
import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
    JWTToken: null,
    currentStep: 1,
    authUser: null,
    isLoginUser: false,
    isLogOutUser: false,
    loginData: {
        email: '',
        password: '',
    },
};

export const loginSlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {
        saveLoggedInUser: (state, { payload }) => {
            state.authUser = payload?.data?.user;
            if (payload?.data?.data?.original?.access_token) {
                state.JWTToken = payload?.data?.data?.original?.access_token;
                state.currentStep = payload?.data?.currentStep ?? 1;
            }
            return state;
        },
        updateCurrentStep: (state, { payload }) => {
            if (payload?.data?.currentStep) {
                state.currentStep = payload?.data?.currentStep;
            }
            return state;
        },
        updateIsLogOutUser: (state, { payload }) => {
            state.isLogOutUser = false;
            state.JWTToken = null;
            return state;
        },
    },
    extraReducers: {
        [loginUser.pending.type]: (state: any, { payload }) => {
            state.isLoginUser = true;
            state.registerResponse = payload;
        },
        [loginUser.fulfilled.type]: (state: any, { payload }) => {
            state.isLoginUser = false;
            state.registerResponse = payload;
            state.JWTToken = payload?.data?.data?.original?.access_token;
        },
        [loginUser.rejected.type]: (state: any, { payload }) => {
            state.isLoginUser = false;
            state.registerResponse = payload;
        },

        [logOutUser.pending.type]: (state: any, { payload }) => {
            state.isLogOutUser = true;
            state.registerResponse = payload;
        },
        [logOutUser.fulfilled.type]: (state: any, { payload }) => {
            state.isLogOutUser = false;
            state.registerResponse = payload;
            state.JWTToken = payload?.data?.data?.original?.access_token;
        },
        [logOutUser.rejected.type]: (state: any, { payload }) => {
            state.isLogOutUser = false;
            state.registerResponse = payload;
        },
    },
});

export const {
    saveLoggedInUser,
    loginChange,
    updateIsLogOutUser,
    updateCurrentStep,
} = loginSlice.actions;

export { loginUser, logOutUser };

export default loginSlice.reducer;
