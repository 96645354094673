import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';

import InputText from '@components/InputText/InputText';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    addOfferNote,
    updateOfferNote,
} from '@app/JobList/OfferNotes/functions';

import { INoteFieldsProps } from './notes.interface';

const AddNotePopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    isEditNote,
    noteData,
    getNotesList,
    listCall,
    isDisabled,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { offerNoteData } = useSelector((state: any) => state);
    const { isAddOfferNote, isUpdateOfferNote } = offerNoteData;
    const noteDefaultData = {
        note: '',
    };

    const schema = Yup.object({
        note: Yup.string().required('Please enter a note'),
    }).required();

    const {
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: noteDefaultData,
    });

    useEffect(() => {
        if (noteData) {
            setValue('note', noteData);
        }
    }, [noteData]);

    const HandleChange = async ({
        id,
        offers_nano_id,
        ...data
    }: INoteFieldsProps) => {
        if (isValid) {
            const { payload } = await dispatch(
                isEditNote
                    ? updateOfferNote({
                          ...data,
                          id: isEditNote,
                          offers_nano_id: offerDataObj?.offers_nano_id,
                      })
                    : addOfferNote({
                          ...data,
                          offers_nano_id: offerDataObj?.offers_nano_id,
                      }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                handleClose();
                getNotesList();
                listCall();
            } else {
                if (payload?.message?.name?.[0] !== '') {
                    toast.error(payload?.message?.name?.[0]);
                } else {
                    if (payload?.logout) {
                        localStorage.removeItem('accessToken');
                        dispatch(updateIsLogOutUser());
                        navigate(routesPath?.login);
                    }
                }
                toast.error(payload?.message);
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset();
                }
            });
        } else {
            handleClose();
            reset();
        }
    };

    return (
        <Modal
            show={showPopup}
            onHide={onClose}
            backdrop="static"
            backdropClassName="modal-backdrop-upper"
            centered
            className="mfnw_model_size"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Job Notes
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Form className="m-3" onSubmit={handleSubmit(HandleChange)}>
                    <Row className="px-4">
                        <div className="mt-3">
                            <p className="text-sm fw-bold">
                                {!isDisabled
                                    ? isEditNote
                                        ? 'EDIT NOTES'
                                        : 'ADD NOTES'
                                    : 'VIEW NOTES'}
                            </p>
                        </div>
                        <Col md={12}>
                            <Controller
                                name={'note'}
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value ? value : ''}
                                        id={'note'}
                                        inputName={'note'}
                                        inputType={'text'}
                                        placeholder={'Enter Note'}
                                        asTextarea="textarea"
                                        className="bg-unactive"
                                        errorText={errors?.note?.message}
                                        autoFocus={true}
                                        tabIndex={32}
                                        disabled={isDisabled}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    {!isDisabled && (
                        <Row className="d-flex justify-content-end px-4 py-4">
                            <Col md={5}>
                                <Button
                                    variant="primary w-100 mt-1"
                                    type="submit"
                                    tabIndex={33}
                                    disabled={
                                        isAddOfferNote || isUpdateOfferNote
                                    }
                                >
                                    {isAddOfferNote || isUpdateOfferNote ? (
                                        <LoaderBtn />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </Col>
                            <Col md={5}>
                                <Button
                                    className="btn-cancel mt-1 w-100"
                                    tabIndex={34}
                                    onClick={() => onClose()}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddNotePopup;
