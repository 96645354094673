import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    jobsCalenderList,
    jobsList,
    exportJobList,
} from '@app/JobList/functions';

const defaultState = {
    isJobsList: true,
    isJobsCalenderList: true,
    isExportJobsList: false,
    jobFilters: {
        sortValue: 'offeredOn',
        currentPage: 1,
        perPageLimit: 5,
        orderValue: 'DESC',
        sortText: 'Received Date',
        grid: false,
    },
};

export const JobSlice: any = createSlice({
    name: 'jobs',
    initialState: cloneDeep(defaultState),
    reducers: {
        jobFilterChange: (state, { payload }) => {
            state.jobFilters = payload;
        },
    },
    extraReducers: {
        [jobsList.pending.type]: (state: any, { payload }) => {
            state.isJobsList = true;
            state.registerResponse = payload;
        },
        [jobsList.fulfilled.type]: (state: any, { payload }) => {
            state.isJobsList = false;
            state.registerResponse = payload;
        },
        [jobsList.rejected.type]: (state: any, { payload }) => {
            state.isJobsList = false;
            state.registerResponse = payload;
        },
        [jobsCalenderList.pending.type]: (state: any, { payload }) => {
            state.isJobsCalenderList = true;
            state.registerResponse = payload;
        },
        [jobsCalenderList.fulfilled.type]: (state: any, { payload }) => {
            state.isJobsCalenderList = false;
            state.registerResponse = payload;
        },
        [jobsCalenderList.rejected.type]: (state: any, { payload }) => {
            state.isJobsCalenderList = false;
            state.registerResponse = payload;
        },
        [exportJobList.pending.type]: (state: any, { payload }) => {
            state.isExportJobsList = true;
            state.registerResponse = payload;
        },
        [exportJobList.fulfilled.type]: (state: any, { payload }) => {
            state.isExportJobsList = false;
            state.registerResponse = payload;
        },
        [exportJobList.rejected.type]: (state: any, { payload }) => {
            state.isExportJobsList = false;
            state.registerResponse = payload;
        },
    },
});

export const { jobFilterChange } = JobSlice.actions;
export { jobsList, jobsCalenderList, exportJobList };
export default JobSlice.reducer;
