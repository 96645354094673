import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Compressor from 'compressorjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Image } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ProfileCrop from '@components/ImageUpload/ProfileCrop';

import { showDeleteConfirmationDialog } from '@constants/general.const';

import { blobToBase64, checkFileSize } from '@utils/utilFunctions';

import { updateProfile } from '@app/Auth/UserProfile/functions';
import { schema } from '@app/Auth/UserProfile/userProfile.const';
import UserFormField from '@app/Auth/UserProfile/userFormField';
import { responseMessage } from '@redux/app/appSlice';
import { saveLoggedInUser } from '@login/loginSlice';

import { IUserProfileProps } from './userProfile.interface';

import 'react-toastify/dist/ReactToastify.css';
import './userProfile.scss';

const UserProfile = () => {
    const dispatch = useDispatch<any>();
    const refSubmitButton = useRef<HTMLButtonElement>(null);

    const { userProfile, login } = useSelector((state: any) => state);
    const { UserData, isUpdateProfile, isUserProfileGet } = userProfile;
    const { authUser } = login;

    //  ask fore type
    const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
    const [image, setImage] = useState<any>();
    const [profileImage, setProfileImage] = useState<string>('');
    const [userImage, setUserImage] = useState<any>(authUser?.user_profile);
    const [extension, setExtension] = useState<string>('');

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, isDirty },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: UserData,
        mode: 'onBlur',
    });

    useEffect(() => {
        const fieldsToSet = {
            email: authUser?.email ?? '',
            firstName: authUser?.firstName ?? '',
            lastName: authUser?.lastName ?? '',
            company_name: authUser?.company?.name ?? '',
            secret_key: authUser?.company?.secret_key ?? '',
        };

        Object.entries(fieldsToSet).forEach(([fieldName, fieldValue]) => {
            setValue(fieldName, fieldValue);
        });

        setUserImage(authUser?.user_profile);
    }, [authUser]);

    const passwordCheck = watch('current_pass');

    const HandleChange = async (data: IUserProfileProps) => {
        if (isDirty) {
            var sendData = {
                ...data,
                email: authUser?.email,
                user_profile: profileImage,
                img_extension: extension,
            };
            const { payload } = await dispatch(updateProfile(sendData));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(responseMessage(payload));
                dispatch(saveLoggedInUser(payload));
                setValue('current_pass', '');
                setValue('password', '');
                setValue('password_confirmation', '');
            } else {
                if (payload?.message?.email?.[0] !== '') {
                    toast.error(payload?.message?.email?.[0]);
                }
                if (payload?.message?.company_name?.[0] !== '') {
                    toast.error(payload?.message?.company_name?.[0]);
                }
                toast.error(payload?.message);
            }
        }
    };

    const handleClickImageChange = (img: any, extensionValueOnClick: any) => {
        setImage(img);
        setExtension(extensionValueOnClick);
        setCropModalOpen(true);
    };

    const handleDeleteImage = async () => {
        const confirmationOptions = {
            title: 'warning',
            text: "You won't be able to revert this!",
            icon: 'warning',
            confirmButtonText: 'Yes, delete it!',
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            setUserImage(null);
            setExtension('');
            var sendData = {
                user_profile: '',
                email: authUser?.email,
            };
            const { payload } = await dispatch(updateProfile(sendData));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(saveLoggedInUser(payload));
            }
        }
    };

    const saveWithCompressImage = async (blob: any) => {
        if (blob !== '') {
            new Compressor(blob, {
                quality: 0.6,
                maxHeight: 500,
                success: async (compressedResult: any) => {
                    const compressedImg: any = await blobToBase64(
                        compressedResult,
                    );
                    const isValidFileSize = checkFileSize(compressedImg);
                    if (isValidFileSize) {
                        setProfileImage(compressedImg);
                        setUserImage(compressedImg);
                        var sendData = {
                            user_profile: compressedImg,
                            email: authUser?.email,
                        };
                        const { payload } = await dispatch(
                            updateProfile(sendData),
                        );
                        if (
                            payload?.data?.message !== '' &&
                            payload?.data?.status
                        ) {
                            dispatch(saveLoggedInUser(payload));
                        }
                    }
                },
            });
        }
    };

    const handleSave = async (imageData: any) => {
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();
        saveWithCompressImage(blob);
    };

    return (
        <>
            <Form onSubmit={handleSubmit(HandleChange)}>
                <div className="mfnp_myprofile px-md-4">
                    <p className="fw-bold fs-4 ms-3 py-2 p-0 m-0 py-md-4">
                        My Profile
                    </p>
                    {isUserProfileGet || isUpdateProfile ? (
                        <LoaderBtn />
                    ) : (
                        <div className="mfnp_maincontainar">
                            <div className="mfnp_myprofile_second bg-primary position-relative">
                                <div className="mfnp_imagemaintag profile_text_img  rounded-pill position-absolute top-50 d-none d-md-flex ">
                                    <div className="d-inline-block">
                                        {userImage == null ||
                                        userImage === '' ? (
                                            <>
                                                <div className="text-center m-auto">
                                                    {authUser?.firstName
                                                        ?.toUpperCase()
                                                        .substring(0, 1) +
                                                        authUser?.lastName
                                                            ?.toUpperCase()
                                                            .substring(0, 1)}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Image
                                                    src={userImage}
                                                    className="rounded-pill"
                                                    width={154}
                                                    height={150}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="mfnp_imagemaintag profile_text_img  rounded-pill position-absolute top-100 start-50 translate-middle d-flex d-md-none">
                                    <div className="d-inline-block">
                                        {userImage == null ||
                                        userImage === '' ? (
                                            <>
                                                <div className="text-center m-auto">
                                                    {authUser?.firstName
                                                        ?.toUpperCase()
                                                        .substring(0, 1) +
                                                        authUser?.lastName
                                                            ?.toUpperCase()
                                                            .substring(0, 1)}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Image
                                                    src={userImage}
                                                    className="rounded-pill"
                                                    width={120}
                                                    height={120}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mfnp_myprofile_form mx-4 mx-md-0">
                                <UserFormField
                                    control={control}
                                    errors={errors}
                                    userImage={userImage}
                                    authUser={authUser}
                                    handleClickImageChange={
                                        handleClickImageChange
                                    }
                                    handleDeleteImage={handleDeleteImage}
                                    passwordCheck={passwordCheck}
                                    refSubmitButton={refSubmitButton}
                                    isUpdateProfile={isUpdateProfile}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {cropModalOpen && (
                    <ProfileCrop
                        open={cropModalOpen}
                        handleClose={() => setCropModalOpen(false)}
                        image={image}
                        saveCroppedFile={(imageData: any) =>
                            handleSave(imageData)
                        }
                    />
                )}
            </Form>
        </>
    );
};

export default UserProfile;
