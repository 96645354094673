import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { searchTimeOut } from '@constants/general.const';

import DepartmentNotFound from '@images/department-not-found.svg';

import OrganizationTabHeader from '@app/CreateOrganization/Organizations/organizationTabHeader';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';
import {
    getDepartmentData,
    departmentDeleteById,
} from '@app/CreateOrganization/step3/step3.const';
import DepartmentPopupStep3 from '@app/CreateOrganization/step3/departmentPopupStep3';

const DepartmentList = ({ organizationId }: any) => {
    const dispatch = useDispatch<any>();
    const { departmentStep3Data } = useSelector((state: any) => state);

    const { isDeleteDepartment, isGetDepartmentDataList } = departmentStep3Data;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<Array<any> | null>(null);
    const [search, setSearch] = useState<string | null>();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (search || search === '') {
            const getDataSearch: any = setTimeout(() => {
                getData();
            }, searchTimeOut);
            return () => clearTimeout(getDataSearch);
        }
    }, [search]);

    const getData = async () => {
        getDepartmentData(
            dispatch,
            {
                nanoId: organizationId,
                searchText: search,
            },
            setDataList,
        );
    };

    const deleteById = async (officeId: string) => {
        departmentDeleteById(officeId, dispatch, getData);
    };

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);

    return (
        <>
            <OrganizationTabHeader
                title={'Department List'}
                search={search}
                setSearch={setSearch}
                handleShow={handleShow}
                buttonText={'Add New Department'}
            />
            <div className="d-flex flex-wrap gap-2">
                {isDeleteDepartment || isGetDepartmentDataList ? (
                    <LoaderBtn />
                ) : (
                    dataList &&
                    dataList.length > 0 && (
                        <StepBadgeComponent
                            dataList={dataList}
                            deleteById={deleteById}
                            name={'department_name'}
                            nanoId={'department_nano_id'}
                            handleViewShow={false}
                            className="mnfw__badge_light lh-base text-dark py-2 fs-6 fw-semibold d-flex align-align-items-center bg-white border-opacity-25"
                        />
                    )
                )}
            </div>
            <NotFoundPages
                currentPageData={dataList}
                image={DepartmentNotFound}
                message={`Departments allow you to segment your organize into
                multiple business units.You must have at least one
                department.`}
            />
            <DepartmentPopupStep3
                show={showPopup}
                getData={getData}
                handleClose={handleClose}
                organizationId={organizationId}
            />
        </>
    );
};

export default DepartmentList;
