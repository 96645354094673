import React from 'react';
import { Card, Image, Row } from 'react-bootstrap';

import {
    dashboardBottomItemArray,
    dashboardJobBottomItemArray,
} from '@app/Dashboard/dashbord.const';

import { IDashboardTopItem } from './dashboard.interface';

const BottomDashboardSection = ({ dataCount, listType }: any) => (
    <Row>
        {(listType && listType === 'job'
            ? dashboardJobBottomItemArray
            : dashboardBottomItemArray
        )?.map((item: IDashboardTopItem, index: number) => {
            const { icon, displayName, backGround, count } = item;
            return (
                <div className="mfnw_column_bottom_tag" key={index}>
                    <Card
                        className={`d-flex flex-row align-items-center mfnw__offered-card border-${backGround}`}
                    >
                        <div className="mf__card-content w-100">
                            <div className="text">
                                <p className="m-0 fs-6 text-truncate">
                                    {displayName}
                                </p>
                                <h2 className="mb-0 text-warning">
                                    {count !== 'projected'
                                        ? dataCount?.[count]
                                        : 'N/A'}
                                </h2>
                            </div>
                            <div className="card_icon position-absolute">
                                <Image src={icon} />
                            </div>
                        </div>
                    </Card>
                </div>
            );
        })}
    </Row>
);

export default BottomDashboardSection;
