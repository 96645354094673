import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import InputGroupText from '@components/InputGroupText/InputGroupText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/fontawesome-pro-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const faPlusIcon = faPlus as IconProp;

const ScacInputField = ({
    SCACarr,
    control,
    col,
    getValues,
    setSCACarrLength,
    SCACarrLength,
    setSCACarr,
    setValue,
    tabIndex,
}: any) => {
    const AddSCAC = () => {
        const updatedSCAC = [...getValues('organization_scac'), ''];
        setSCACarr(updatedSCAC);
        setValue('organization_scac', updatedSCAC);
    };

    const deleteSCAC = (index: number) => {
        if (index !== -1) {
            const updatedSCAC = [...getValues('organization_scac')];
            updatedSCAC.splice(index, 1);
            if (
                getValues(`organization_scac[0]`).length === 0 &&
                updatedSCAC.length === 1
            ) {
                setSCACarrLength(false);
            } else {
                setSCACarrLength(true);
            }

            setSCACarr(updatedSCAC);
            setValue('organization_scac', updatedSCAC);
        }
    };

    return (
        <>
            {SCACarr &&
                SCACarr.map((field: any, index: number) => (
                    <Col lg={col} key={index} className="mt-4">
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <Controller
                                    name={`organization_scac[${index}]`}
                                    control={control}
                                    key={index}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={(e: any) => {
                                                    onChange(e.target.value);
                                                    if (
                                                        getValues(
                                                            `organization_scac[0]`,
                                                        ).length === 0 &&
                                                        SCACarr.length === 1
                                                    ) {
                                                        setSCACarrLength(false);
                                                    } else {
                                                        setSCACarrLength(true);
                                                    }
                                                }}
                                                value={getValues(
                                                    `organization_scac[${index}]`,
                                                )}
                                                id={`organization_scac[${index}]`}
                                                inputName={`organization_scac[${index}]`}
                                                inputType={'text'}
                                                placeholder={'XXXX'}
                                                className="spaced_text"
                                                inputGroupClassName="mfnw__input-group"
                                                label={`SCAC ${index + 1}`}
                                                tabIndex={tabIndex}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            {index !== 0 && (
                                <Button
                                    variant="default"
                                    className="p-2 mx-3 rounded-3 h-100 py-1"
                                    onClick={() => deleteSCAC(index)}
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </Button>
                            )}
                        </div>
                    </Col>
                ))}
            {SCACarrLength && (
                <Col md={12} className="mb-3">
                    <div
                        className="text-primary cursor-pointer mt-3"
                        onClick={() => AddSCAC()}
                    >
                        <FontAwesomeIcon icon={faPlusIcon} />
                        Add more SCAC
                    </div>
                </Col>
            )}
        </>
    );
};

export default ScacInputField;
