import React from 'react';
import { Card, Image } from 'react-bootstrap';

const TopDashboardBox = ({ item, dataCount }: any) => {
    const { icon, displayName, backGround, count } = item;

    return (
        <Card
            className={`d-flex flex-row align-items-center mfnw__dashboar-card mb-3 bg-${backGround}`}
        >
            <div className="mf__card-content d-flex justify-content-between align-items-center w-100">
                <div className="text text-truncate">
                    <p className="m-0 fs-6 text-truncate">
                        {displayName} (Last 30 days)
                    </p>
                    <h1>{dataCount?.[count]?.length ?? 0}</h1>
                </div>
                <div className="icon position-absolute responsive_dashboard_firstcard">
                    <Image src={icon} />
                </div>
            </div>
        </Card>
    );
};

export default TopDashboardBox;
