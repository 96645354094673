import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Modal, Form } from 'react-bootstrap';

import {
    searchTimeOut,
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { getRegionList } from '@app/CreateOrganization/step6/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import RegionPopupStep6 from '@app/CreateOrganization/step6/RegionPopupStep6';
import SelectRegionFormField from '@app/SettingsLists/Packers/selectRegionFormField';

import { IListOption } from '../../../Interface/common';

const SelectRegionPopup = ({
    showPopupRegion,
    handleCloseRegion,
    organizationId,
    handleChildData,
    regionList,
    setRegionList,
}: any) => {
    const dispatch = useDispatch<any>();

    const { regionsStep6Data } = useSelector((state: any) => state);
    const { regionData, isAddRegionData } = regionsStep6Data;
    const [searchStateText, setSearchStateText] = useState<any>(null);
    const [optionArr, setOptionArr] = useState<IListOption[]>([]);
    const [selectList, setSelectList] = useState<IListOption[]>([]);
    const [CityStates, setCityStates] = useState<boolean>(false);
    const [cityStateArr, setCityStateArr] = useState<any[]>([]);
    const [lable, setLable] = useState<string | null>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const handleAddRegion = () => setShowPopup(true);
    const handleClose = () => {
        setShowPopup(false);
        getData();
    };

    const regionDataValues: any = {
        region_name: '',
        region_type: 'State',
        region_state: [],
        region_city: '',
        is_active: 1,
    };
    const schema = Yup.object({
        region_state: Yup.array().min(
            1,
            'Please select at least one item for Region State',
        ),
    }).required();

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: regionData,
        mode: 'onBlur',
    });

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (regionList) {
            setSelectList(regionList);
            setValue('region_state', regionList);
        }
    }, [regionList]);

    useEffect(() => {
        const getDataSearch: any = setTimeout(() => {
            getData();
        }, searchTimeOut);
        return () => clearTimeout(getDataSearch);
    }, [searchStateText]);

    const getData = async () => {
        const { payload } = await dispatch(
            getRegionList({
                nanoId: organizationId,
                searchText: searchStateText,
            }),
        );
        if (payload?.data?.status) {
            var arr: any[] = [];
            if (getValues('region_type') === 'State') {
                payload?.data?.data.map((dataOption: any, index: any) => {
                    arr.push({
                        value: dataOption?.region_nano_id,
                        label: dataOption?.region_name,
                        cityState: dataOption?.areas_name,
                    });
                    return null;
                });
            } else {
                payload?.data?.data.map((dataOption: any, index: any) => {
                    arr.push({
                        value: dataOption?.region_nano_id,
                        label: `${dataOption?.region_name}`,
                    });
                    return null;
                });
            }
            setOptionArr(arr);
        } else {
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
            }
        }
    };

    const HandleChange = async (data: any) => {
        handleChildData(data);

        handleCloseRegion();
        reset(regionDataValues);
    };

    const updateSelectedList = async (itemId: any, label: string) => {
        setCityStates(true);
        setLable(label);
        setCityStateArr(JSON.parse(itemId));
    };

    const updateSelectedList2 = async (itemId: string) => {
        const newArray = selectList.filter(
            (item: any) => item?.label !== itemId,
        );
        setSelectList(newArray);
        setRegionList(newArray);
    };

    const onClose = async () => {
        if (!CityStates) {
            Swal.fire({
                title: 'Warning',
                text: 'Do you really want to close this?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleCloseRegion();
                    reset(regionDataValues);
                }
            });
        } else {
            setCityStates(false);
        }
    };

    return (
        <Modal
            show={showPopupRegion}
            onHide={onClose}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-upper"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {CityStates ? lable : 'Select Region'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {CityStates ? (
                    <>
                        {cityStateArr.map((area: any, index: number) => (
                            <Col md={6} key={index}>
                                <div>{area}</div>
                            </Col>
                        ))}
                    </>
                ) : (
                    <>
                        <Form onSubmit={handleSubmit(HandleChange)}>
                            <SelectRegionFormField
                                control={control}
                                optionArr={optionArr}
                                setSearchStateText={setSearchStateText}
                                setValue={setValue}
                                selectList={selectList}
                                setSelectList={setSelectList}
                                errors={errors}
                                handleAddRegion={handleAddRegion}
                                updateSelectedList={updateSelectedList}
                                updateSelectedList2={updateSelectedList2}
                                isAddRegionData={isAddRegionData}
                                onClose={onClose}
                            />
                        </Form>
                    </>
                )}
            </Modal.Body>
            <RegionPopupStep6
                showPopup={showPopup}
                handleClose={handleClose}
                getDataListCall={() => {}}
            />
        </Modal>
    );
};

export default SelectRegionPopup;
