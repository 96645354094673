/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { responseMessage } from '../redux/app/appSlice';
import { toast } from 'react-toastify';
import AddOfferPopup from '../app/OffersList/addOfferPopup';
import { useNavigate } from 'react-router-dom';
import { routesPath } from './routesNavigate';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    OfferList,
    changeOfferStatus,
    offerData,
} from '@app/OffersList/functions';
import {
    PageLimitChangeCalculation,
    PageClickCalculation,
} from '@constants/general.const';

export function useOfferPopup() {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { offersData, common } = useSelector((state: any) => state);
    const { isOfferList, isRejectOffer } = offersData;
    const { searchText } = common;

    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [offerId, setOfferId] = useState<any>(null);
    const [popup, setPopup] = useState<boolean>(false);
    const [offerDataObj, setOfferDataObj] = useState<any>();
    const [showAddPopup, setShowAddPopup] = useState<boolean>(false);

    const [showJobViewPopup, setShowJobViewPopup] = useState<boolean>(false);
    const [showJobEditPopup, setShowJobEditPopup] = useState<boolean>(false);

    const [temp, setTemp] = useState<boolean>(!searchText);
    const [resMessage, setResMessage] = useState<any>(null);
    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);

    const [filters, setFilters] = useState<any>();

    const handleAddClose = () => setShowAddPopup(false);
    const handleAddShow = () => setShowAddPopup(true);

    const handleViewClose = () => {
        setShowViewPopup(false);
    };
    const handleViewShow = (offerIdForView: string) => {
        setShowViewPopup(true);
        getOfferData(offerIdForView);
        setOfferId(offerIdForView);
    };
    const handleEditClose = () => {
        setShowEditPopup(false);
        setOfferDataObj(null);
        setOfferId(null);
    };
    const handleEditShow = (offerIdForView: string) => {
        setShowViewPopup(false);
        setShowEditPopup(true);
    };

    const handleClose = () => {
        setPopup(false);
    };

    const handleJobEditShow = (offerIdForView: string) => {
        setShowJobViewPopup(false);
        setShowJobEditPopup(true);
    };

    const handleJobViewShow = (offerIdForView: string) => {
        setShowJobViewPopup(true);
        getOfferData(offerIdForView);
        setOfferId(offerIdForView);
    };

    const handleJobEditClose = () => {
        setShowJobEditPopup(false);
        setOfferDataObj(null);
        setOfferId(null);
    };

    const handleJobViewClose = () => {
        setShowJobViewPopup(false);
    };

    const handelJobViewShow = (offerDataObjs: any) => {
        setShowJobViewPopup(true);
    };
    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getOfferListAndUpdateFilter(updateFilter);
    };

    const handleOrderChange = async (order: string) => {
        if (order) {
            var updateFilter = { ...filters, orderValue: order };
            getOfferListAndUpdateFilter(updateFilter);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getOfferListAndUpdateFilter(updateFilter);
    };

    const handleGrid = (gridStatus: boolean) => {
        var updateFilter = {
            ...filters,
            grid: gridStatus,
            compact: false,
        };
        getOfferListAndUpdateFilter(updateFilter);
    };

    const handleCompactView = (gridStatus: boolean) => {
        var updateFilter = {
            ...filters,
            grid: gridStatus ? false : true,
            compact: gridStatus,
        };
        getOfferListAndUpdateFilter(updateFilter);
    };
    const handleSortChange = async (sort: string, textShow: string) => {
        if (sort) {
            var updateFilter = {
                ...filters,
                sortValue: sort,
                sortText: textShow,
            };
            getOfferListAndUpdateFilter(updateFilter);
        }
    };

    const handleAcceptActions = async (
        offerIdForAction: any,
        types: string,
    ) => {
        if (types === 'accepted') {
            const { payload } = await dispatch(
                changeOfferStatus({ id: offerIdForAction, status: types }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(responseMessage(payload));
                navigate(routesPath?.jobs);
            } else {
                toast.error(payload?.message);
            }
        } else {
            setOfferId(offerIdForAction);
            setPopup(true);
        }
    };

    const getOfferListAndUpdateFilter = (updateFilter: any) => {
        getOfferList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };
    const getOfferList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            OfferList({
                filters: {
                    grid: res?.grid ? res?.grid : false,
                    searchText: res?.searchText ? res?.searchText : null,
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    sortValue: res?.sortValue ? res?.sortValue : 'offeredOn',
                    sortText: res?.sortText ? res?.sortText : 'Received Date',
                    orderValue: res?.orderValue ? res?.orderValue : 'DESC',
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                    compact: res?.grid ? false : res?.compact,
                },
                searchText: !temp ? searchText : null,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setResMessage(payload?.data?.message);
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };
    const getOfferData = async (offerIdForView: any) => {
        const { payload } = await dispatch(offerData(offerIdForView));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setOfferDataObj(payload?.data?.data);
        } else {
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const renderAddOfferPopup = (
        currentState: boolean,
        onclose: any,
        title: string,
        isJobForm: boolean,
        listCall: any,
    ) =>
        currentState && (
            <AddOfferPopup
                showPopup={currentState}
                handleClose={onclose}
                listCall={() => listCall()}
                isDisabled={false}
                isAcceptable={false}
                isJobForm={isJobForm}
                title={title}
            />
        );
    const renderViewPopup = (
        title: string,
        isJobForm: boolean,
        listCall: any,
    ) =>
        showViewPopup && (
            <AddOfferPopup
                showPopup={showViewPopup}
                handleClose={handleViewClose}
                handleEditShow={handleEditShow}
                editData={offerDataObj}
                isDisabled={true}
                isJobForm={isJobForm}
                isAcceptable={false}
                offerId={offerId}
                getOfferData={getOfferData}
                listCall={() => {
                    listCall();
                }}
                title={title}
                handleAcceptActions={handleAcceptActions}
                isRejectOffer={isRejectOffer}
            />
        );

    const renderJobViewPopup = (getFetchJobList: any) =>
        showJobViewPopup && (
            <AddOfferPopup
                showPopup={showJobViewPopup}
                handleClose={handleJobViewClose}
                handleEditShow={handleJobEditShow}
                editData={offerDataObj}
                isDisabled={true}
                isEditable={false}
                offerId={offerId}
                getOfferData={getOfferData}
                listCall={() => getFetchJobList()}
                title={'View Job'}
                isJobForm={true}
                isAcceptable={false}
            />
        );
    const renderJobEditPopup = (getOfferHistory: any) =>
        showJobEditPopup && (
            <AddOfferPopup
                showPopup={showJobEditPopup}
                handleClose={handleJobEditClose}
                handelViewShow={handelJobViewShow}
                editData={offerDataObj}
                isDisabled={false}
                isEditable={false}
                listCall={() => getOfferHistory()}
                offerId={offerId}
                getOfferData={getOfferData}
                title={'Edit Job'}
                isJobForm={true}
            />
        );

    const renderEditPopup = (
        title: string,
        isJobForm: boolean,
        getFetchJobList: any,
    ) =>
        showEditPopup && (
            <AddOfferPopup
                showPopup={showEditPopup}
                handleClose={handleEditClose}
                editData={offerDataObj}
                isDisabled={false}
                isJobForm={isJobForm}
                isAcceptable={false}
                listCall={() => {
                    getFetchJobList();
                }}
                offerId={offerId}
                getOfferData={getOfferData}
                title={title}
            />
        );

    return {
        showAddPopup,
        showViewPopup,
        showEditPopup,
        handleViewShow,
        handleEditShow,
        handleClose,
        handleAcceptActions,
        offerDataObj,
        offerId,
        popup,
        temp,
        setTemp,
        resMessage,
        currentPageData,
        totalDataCount,
        pageCount,
        handleViewClose,
        handleEditClose,
        getOfferData,
        setOfferDataObj,
        renderViewPopup,
        renderEditPopup,
        setOfferId,
        setPopup,
        setShowViewPopup,
        setShowEditPopup,
        handleJobViewClose,
        handleJobViewShow,
        handleJobEditClose,
        handleJobEditShow,
        showJobViewPopup,
        showJobEditPopup,
        isOfferList,
        filters,
        setFilters,
        setShowJobViewPopup,
        setShowJobEditPopup,
        renderJobViewPopup,
        renderJobEditPopup,
        handleSortChange,
        getOfferList,
        handleOrderChange,
        handlePageClick,
        handleGrid,
        handleCompactView,
        handleLimitChange,
        handleAddShow,
        handleAddClose,
        renderAddOfferPopup,
    };
}
