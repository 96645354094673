import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiGetDepartmentDataList,
    apiAddDepartmentData,
    apiDeleteDepartment,
} from '@services/API/apiFunctions';

const getDepartmentDataList = createAsyncThunk(
    'getDepartmentDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetDepartmentDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addDepartmentData = createAsyncThunk(
    'addDepartmentData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddDepartmentData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteDepartment = createAsyncThunk(
    'deleteDepartment',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteDepartment(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { getDepartmentDataList, addDepartmentData, deleteDepartment };
