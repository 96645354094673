import React from 'react';
import { Button, Row } from 'react-bootstrap';

import InputText from '@components/InputText/InputText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

const OrganizationTabHeader = ({
    title,
    search,
    setSearch,
    handleShow,
    buttonText,
}: any) => (
    <>
        <Row className="mb-3 py-3">
            <h5 className="col-12 col-md-3 fw-semibold text-center text-sm-start m-md-auto mb-3">
                {title}
            </h5>
            <div className="col-12 col-md-9 d-flex justify-content-center justify-content-sm-end ">
                <div className="d-flex gap-3 main-par flex-wrap justify-content-center">
                    <div className="row">
                        <div className="col-12 d-flex gap-3 text-center">
                            <div className="main-searchInput position-relative">
                                <InputText
                                    className="mfnw__login-form-control mfnw__search-form-control-bg pos"
                                    id=""
                                    inputName=""
                                    inputType="text"
                                    placeholder="Search..."
                                    errorText={''}
                                    value={search}
                                    onChange={(e: any) =>
                                        setSearch(e.target.value)
                                    }
                                />
                                <div>
                                    <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        color="#b7b7b7"
                                        size="lg"
                                        className="position-absolute form-control-serch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Button
                        variant="primary"
                        className="btn_type_large fw-semibold d-sm-inline"
                        onClick={handleShow}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Row>
    </>
);

export default OrganizationTabHeader;
