import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addOrganizationData,
    getUserOrganizationInformation,
    getUserOrganizationList,
    organizationDataSelectList,
    updateOrganizationData,
} from '@app/CreateOrganization/step1/functions';

const defaultState = {
    isGetUserOrganizationList: false,
    isAddOrganizationData: false,
    isGetUserOrganizationInformation: false,
    isUpdateOrganizationData: false,
    isOrganizationDataSelectList: false,
    organizationData: {
        organization_nano_id: '',
        user_id: 20,
        organization_logo: '',
        organization_name: '',
        organization_address: '',
        organization_zip_code: '',
        organization_city: '',
        organization_city_name: '',
        organization_state: '',
        organization_state_name: '',
        organization_email: '',
        organization_phone: '',
        organization_mc: '',
        organization_dot: '',
        organization_scac: [],
        is_active: '1',
    },
};

export const organizationStep1Slice: any = createSlice({
    name: 'organizationStep1',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [getUserOrganizationList.pending.type]: (state: any, { payload }) => {
            state.isGetUserOrganizationList = true;
        },
        [getUserOrganizationList.fulfilled.type]: (state: any, { payload }) => {
            state.isGetUserOrganizationList = false;
        },
        [getUserOrganizationList.rejected.type]: (state: any, { payload }) => {
            state.isGetUserOrganizationList = false;
        },
        [organizationDataSelectList.pending.type]: (
            state: any,
            { payload },
        ) => {
            state.isOrganizationDataSelectList = true;
        },
        [organizationDataSelectList.fulfilled.type]: (
            state: any,
            { payload },
        ) => {
            state.isOrganizationDataSelectList = false;
        },
        [organizationDataSelectList.rejected.type]: (
            state: any,
            { payload },
        ) => {
            state.isOrganizationDataSelectList = false;
        },

        [addOrganizationData.pending.type]: (state: any, { payload }) => {
            state.isAddOrganizationData = true;
        },
        [addOrganizationData.fulfilled.type]: (state: any, { payload }) => {
            state.isAddOrganizationData = false;
        },
        [addOrganizationData.rejected.type]: (state: any, { payload }) => {
            state.isAddOrganizationData = false;
        },

        [updateOrganizationData.pending.type]: (state: any, { payload }) => {
            state.isUpdateOrganizationData = true;
        },
        [updateOrganizationData.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateOrganizationData = false;
        },
        [updateOrganizationData.rejected.type]: (state: any, { payload }) => {
            state.isUpdateOrganizationData = false;
        },

        [getUserOrganizationInformation.pending.type]: (
            state: any,
            { payload },
        ) => {
            state.isGetUserOrganizationInformation = true;
        },
        [getUserOrganizationInformation.fulfilled.type]: (
            state: any,
            { payload },
        ) => {
            state.isGetUserOrganizationInformation = false;
        },
        [getUserOrganizationInformation.rejected.type]: (
            state: any,
            { payload },
        ) => {
            state.isGetUserOrganizationInformation = false;
        },
    },
});

export default organizationStep1Slice.reducer;
