import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import {
    deleteDepartment,
    getDepartmentDataList,
} from '@app/CreateOrganization/step3/functions';

export const step3FieldArr: any[] = [];

export const getDepartmentData = async (
    dispatch: any,
    param: any,
    setDataList: any,
) => {
    const { payload } = await dispatch(getDepartmentDataList(param));
    const { data: payloadData, message: errorMessage } = payload || {};
    const { message, status, data } = payloadData || {};
    if (message && status) {
        setDataList(data);
    } else {
        toast.error(errorMessage);
    }
};

export const departmentDeleteById = async (
    officeId: string,
    dispatch: any,
    getData: any,
) => {
    Swal.fire({
        title: 'Delete Department?',
        text: "You won't be able to revert this operation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const { payload } = await dispatch(deleteDepartment(officeId));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getData();
            } else {
                toast.error(errorMessage);
            }
        }
    });
};
