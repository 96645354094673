import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiReGenerateKey,
    apiUpdateProfile,
    apiUserProfile,
} from '@services/API/apiFunctions';

const userProfileGet = createAsyncThunk(
    'userProfileGet',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUserProfile();
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateProfile = createAsyncThunk(
    'updateProfile',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateProfile(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const reGenerateKey = createAsyncThunk(
    'reGenerateKey',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiReGenerateKey(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { userProfileGet, updateProfile, reGenerateKey };
