import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
    searchTimeOut,
} from '@constants/general.const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleSmall,
    faPen,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';

import { ZipLookUp } from '@app/SettingsLists/lists.const';
import TspFormField from '@app/SettingsLists/TSP/tspFormField';

import { IListOption } from '../../../Interface/common';
import { IContactInfoProps } from './tsp.interface';

const TspViewPopup = ({
    showPopup,
    handleClose,
    data,
    handleEditShow,
    deleteById,
}: any) => {
    const dispatch = useDispatch<any>();

    const { forwarderData } = useSelector((state: any) => state);
    const { isGetForwarderInfo } = forwarderData;

    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);

    const {
        control,
        getValues,
        setValue,
        reset,
        watch,
        formState: { isDirty },
    } = useForm<any>();
    const watchContactInfo = watch('forwarder_contact_info');

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            data?.forwarder_zip_code &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'forwarder_zip_code',
                    'forwarder_city',
                    'forwarder_city_name',
                    'forwarder_state',
                    'forwarder_state_name',
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [data?.forwarder_zip_code]);

    useEffect(() => {
        if (data) {
            reset(data);
        }
        if (
            data &&
            data.forwarder_contact_info &&
            data.forwarder_contact_info.length > 0
        ) {
            const updatedContactInfo = data.forwarder_contact_info.map(
                (contact: IContactInfoProps) => {
                    const newPhoneNumber = contact?.phone_number?.map(
                        (number: any) => ({
                            ...number,
                            type:
                                number?.phoneNumberType === 'work'
                                    ? 'Work'
                                    : 'Mobile',
                            phone_no: number?.phone_number,
                            is_default: number?.is_default === 1 ? '1' : '0',
                        }),
                    );
                    const updatedPhoneArray = newPhoneNumber;
                    return {
                        ...contact,
                        phone_number: updatedPhoneArray,
                    };
                },
            );
            setValue('forwarder_contact_info', updatedContactInfo);
        }
    }, [data]);

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">View TSP</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                    <FontAwesomeIcon
                        className="mx-2 cursor-pointer align-baseline m-auto"
                        icon={faPen}
                        onClick={() => handleEditShow()}
                    />
                    <FontAwesomeIcon
                        className="mx-2 cursor-pointer align-baseline m-auto"
                        onClick={() =>
                            deleteById(data?.forwarder_nano_id, 'delete')
                        }
                        icon={faTrashCan}
                    />
                    {data?.is_active && data?.is_active === 1 ? (
                        <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Enable
                        </Badge>
                    ) : (
                        <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Disable
                        </Badge>
                    )}
                </div>
                {isGetForwarderInfo ? (
                    <LoaderBtn />
                ) : (
                    <TspFormField
                        errors={null}
                        control={control}
                        cityArr={cityArr}
                        zipError={zipError}
                        onClose={onClose}
                        isAddForwarder={null}
                        isUpdateForwarder={null}
                        setValue={setValue}
                        getValues={getValues}
                        watchContactInfo={watchContactInfo}
                        TspId={data && data?.forwarder_nano_id}
                        isDisabled={true}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};
export default TspViewPopup;
