import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneLengthCheck,
    phoneRegex,
} from '@constants/general.const';

import {
    IContactPhoneObj,
    IPackerFieldsProps,
    IRegionObj,
} from './packer.interface';

export const contactPhoneObj: IContactPhoneObj = {
    type: 'Work',
    phone_no: '',
    is_default: '1',
};

export const RegionObj: IRegionObj = {
    value: null,
    label: null,
    packer_region_nano_id: null,
};

export const packerDefaultData: IPackerFieldsProps = {
    packer_name: '',
    packer_city: '',
    packer_city_id: '',
    packer_state: '',
    packer_state_name: '',
    packer_type: 'Company',
    packer_zip_code: null,
    packer_phone: '',
    packer_email: '',
    is_active: 1,
    regions: [RegionObj],
    phone_number: [contactPhoneObj],
    packer_certificate: [],
};

export const schema = Yup.object({
    packer_name: Yup.string().required('Packer Name is required'),
    packer_state: Yup.string()
        .required('Packer State is required')
        .nullable(true),
    packer_type: Yup.string().required('Packer Type is required'),
    packer_email: Yup.string()
        .email('Email must be a valid email')
        .nullable(true),
    packer_zip_code: Yup.string().required('Enter a zip code').nullable(true),
    phone_number: Yup.array().of(
        Yup.object().shape({
            phone_no: Yup.string()
                .default(null)
                .transform((curr, orig) => (orig === '' ? null : curr))
                .test(
                    'len',
                    `Phone number must be exactly ${minPhoneLength} characters`,
                    (val) =>
                        val === null ||
                        val?.replace(phoneRegex, '').length ===
                            phoneLengthCheck,
                )
                .min(
                    minPhoneLength,
                    `Phone number must be at least ${minPhoneLength} characters long`,
                )
                .nullable(true)
                .required('Please enter a phone number'),
        }),
    ),
}).required();
