import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { Image, Accordion } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { dateEndTimeFormat } from '@constants/general.const';

import Folder from '@images/sticky_folder.svg';
import Bin from '@images/trash-can-regular.svg';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoteTable = ({
    currentPageData,
    setTimeZone,
    editNote,
    isDisabled,
    resMessage,
    showAlert,
}: any) => {
    const { offerNoteData } = useSelector((state: any) => state);
    const { isDeleteOfferNote, isOfferNotesList } = offerNoteData;

    const [openAccordion, setOpenAccordion] = useState<any>('');

    const handleAccordionToggle = (employeeId: any) => {
        setOpenAccordion(openAccordion === employeeId ? '' : employeeId);
    };

    return (
        <div className="mfnw_note_table">
            <Table className="table px-4 mb-0">
                <thead>
                    <tr>
                        <th className="note_table_width fw-semibold text-dark text-sm">
                            NOTE
                        </th>
                        <th className="fw-semibold text-dark text-sm">USER</th>
                        <th className="fw-semibold text-dark text-sm">TIME</th>
                        <th className="fw-semibold text-dark text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    {isOfferNotesList || isDeleteOfferNote ? (
                        <tr>
                            <span className="ms-5">
                                <LoaderBtn size="sm" />
                            </span>
                        </tr>
                    ) : (
                        <>
                            {currentPageData && currentPageData.length !== 0 ? (
                                currentPageData.map(
                                    (note: any, index: number) => (
                                        <>
                                            <tr
                                                onClick={() =>
                                                    handleAccordionToggle(
                                                        `demo${index}`,
                                                    )
                                                }
                                            >
                                                <td className="new_table_data text-truncate">
                                                    <div className="d-flex">
                                                        <Image
                                                            src={Folder}
                                                            className="text-light ms-2 table_responsive_iamge"
                                                        />
                                                        <h6 className="text-sm text-base ms-3 note m-0">
                                                            {note?.note}
                                                        </h6>
                                                    </div>
                                                </td>

                                                <td className="text-dark text-base new_table_data text-truncate">
                                                    {note?.user_note_data
                                                        ? `${note?.user_note_data?.firstName} ${note?.user_note_data?.lastName}`
                                                        : ''}
                                                </td>
                                                <td className="text-dark text-base new_table_data text-truncate">
                                                    {setTimeZone(
                                                        note?.date_time,
                                                        dateEndTimeFormat,
                                                    )}
                                                </td>
                                                <td className="text-dark text-base m-0 new_table_data text-truncate">
                                                    <div
                                                        className="accordion-button"
                                                        aria-expanded={
                                                            openAccordion ===
                                                            `demo${index}`
                                                        }
                                                    ></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="p-0">
                                                    <Accordion.Collapse
                                                        eventKey={`demo${index}`}
                                                        in={
                                                            openAccordion ===
                                                            `demo${index}`
                                                        }
                                                    >
                                                        <div className="mfnw_pensil_icon">
                                                            <div className="d-flex flex-row-reverse p-2">
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer text-iconcolor"
                                                                    icon={faPen}
                                                                    onClick={() =>
                                                                        editNote(
                                                                            note?.note_nano_id,
                                                                            'edit',
                                                                            note?.note,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="p-3">
                                                                <div className="mfnw_table_note_description p-3 text-dark text-base">
                                                                    <p className="m-auto">
                                                                        {
                                                                            note?.note
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="text-dark text-base">
                                                                        {note?.user_note_data
                                                                            ? `${note?.user_note_data?.firstName} ${note?.user_note_data?.lastName}`
                                                                            : ''}
                                                                    </span>
                                                                    <Image
                                                                        src={
                                                                            Bin
                                                                        }
                                                                        className="text-light ms-2 mb-4 cursor-pointer"
                                                                        onClick={() => {
                                                                            showAlert(
                                                                                note?.note_nano_id,
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </td>
                                            </tr>
                                        </>
                                    ),
                                )
                            ) : (
                                <tr>
                                    <td colSpan={3} className="p-0">
                                        {isOfferNotesList ? (
                                            <span className="ms-5">
                                                <LoaderBtn size="sm" />
                                            </span>
                                        ) : (
                                            <div className="text-center my-3">
                                                <h3>{resMessage}</h3>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                    {/* Add more rows as needed */}
                </tbody>
            </Table>
        </div>
    );
};

export default NoteTable;
