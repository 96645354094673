import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Modal } from 'react-bootstrap';

import {
    searchTimeOut,
    showDeleteConfirmationDialog,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    deleteJobDocument,
    jobDocumentsList,
} from '@app/JobList/Documents/documentSlice';
import AddDocumentPopup from '@app/JobList/Documents/addDocumentPopup';
import DocumentTable from '@app/JobList/Documents/documentTable';
import DocumentFilters from '@app/JobList/Documents/documentFilters';

const ViewDocumentListPopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    isDisabled,
    listCall,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [resMessage, setResMessage] = useState<any>(null);
    const [filters, setFilters] = useState<any>();
    const [shipper, setShipper] = useState<any>();
    const [currentPageData, setCurrentPageData] = useState<number[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [deleteArr, setDeleteArr] = useState<number[]>([]);
    const [searchText, setSearchText] = useState<string | null>('');
    const [showAddDocumentPopup, setShowAddDocumentPopup] =
        useState<boolean>(false);
    //  ask fore type

    const [documentData, setDocumentData] = useState<string | null>('');
    const [isEditDocument, setIsEditDocument] = useState<any | null>(null);

    const handleAddDocumentShow = () => {
        setShowAddDocumentPopup(true);
    };

    const handleAddDocumentClose = () => {
        setShowAddDocumentPopup(false);
        setDocumentData('');
        setIsEditDocument(null);
    };

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getDocList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    useEffect(() => {
        getFilters();
    }, []);

    const getFilters = async () => {
        FiltersData(
            searchText,
            getDocList,
            setFilters,
            'doc_list',
            dispatch,
            navigate,
        );
    };

    const getDocList = async (currentPage: number, res: any) => {
        const newFilters = {
            perPageLimit: res?.perPageLimit || 5,
            currentPage: currentPage === 0 || !currentPage ? 1 : currentPage,
        };

        const { payload } = await dispatch(
            jobDocumentsList({
                filters: newFilters,
                searchText,
                offers_nano_id: offerDataObj?.offers_nano_id,
            }),
        );

        if (payload?.data?.message !== '' && payload?.data?.status) {
            const data = payload?.data;
            setResMessage(data.message);
            setFilters(data.filters);
            setCurrentPageData(data.data);
            setPageCount(data.meta?.last_page);
            setShipper(payload?.data?.shipper_name);
            setTotalDataCount(data.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        setFilters(updateFilter);
        getDocList(updateFilter?.currentPage, updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        let updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        setFilters(updateFilter);
        getDocList(updateFilter?.currentPage, updateFilter);
    };

    const showAlert = async (id: any, index: any = null) => {
        const confirmationOptions = {
            title: 'Delete Document?',
            text: "You won't be able to revert this operation!",
            icon: 'warning',
            customClass: 'z-index-2',
            confirmButtonText: 'Yes, delete it!',
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );
        if (isConfirmed) {
            const params = {
                id: id ?? deleteArr,
                indexToRemove: index,
            };
            const { payload } = await dispatch(deleteJobDocument(params));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message !== '' && status) {
                getDocList(1, filters);
                setDeleteArr([]);
            } else {
                toast.error(errorMessage);
            }
        }
    };

    const onClose = async () => {
        handleClose(totalDataCount);
    };

    const deleteArrayChange = (event: any, id: any) => {
        if (event?.target?.checked) {
            setDeleteArr((prevArr: any) => [...prevArr, id]);
        } else {
            setDeleteArr((prevArr: any) =>
                prevArr.filter((item: any) => item !== id),
            );
        }
    };
    const editNote = async (noteId: any, editAction: string, note: any) => {
        setDocumentData(note);
        setIsEditDocument(noteId);
        handleAddDocumentShow();
    };

    return (
        <Modal
            show={showPopup}
            onHide={() => onClose()}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-upper"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Job Documents
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="mfnw__dashboard-content pt-1">
                        <Container className="mb-5">
                            <DocumentFilters
                                totalDataCount={totalDataCount}
                                deleteArr={deleteArr}
                                showAlert={showAlert}
                                handleLimitChange={handleLimitChange}
                                filters={filters}
                                setSearchText={setSearchText}
                                searchText={searchText}
                                isDisabled={isDisabled}
                                onClickAddBtn={() => {
                                    handleAddDocumentShow();
                                }}
                                addBtnText={'ADD DOCUMENTS'}
                                listTitleText={'DOCUMENT LIST'}
                                shipper={shipper}
                            />
                            <DocumentTable
                                currentPageData={currentPageData}
                                pageCount={pageCount}
                                deleteArrayChange={deleteArrayChange}
                                deleteArr={deleteArr}
                                isDisabled={isDisabled}
                                showAlert={showAlert}
                                resMessage={resMessage}
                                filter={filters}
                                handlePageClick={handlePageClick}
                                editNote={editNote}
                            />
                        </Container>
                    </div>
                </>
            </Modal.Body>
            {showAddDocumentPopup && (
                <AddDocumentPopup
                    showPopup={showAddDocumentPopup}
                    handleClose={handleAddDocumentClose}
                    listCall={listCall}
                    offerDataObj={offerDataObj}
                    getDocList={() => {
                        getDocList(1, filters);
                    }}
                    documentDetails={documentData}
                    isEditDocument={isEditDocument}
                />
            )}
        </Modal>
    );
};

export default ViewDocumentListPopup;
