import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { deleteJobTitle, getJobTitleDataList } from './functions';

export const step5FieldArr: any[] = [];

export const getJobTitleData = async (
    dispatch: any,
    param: any,
    setDataList: any,
) => {
    const { payload } = await dispatch(getJobTitleDataList(param));

    const { data: payloadData, message: errorMessage } = payload || {};
    const { message, status, data } = payloadData || {};

    if (message && status) {
        setDataList(data);
    } else {
        toast.error(errorMessage);
    }
};

export const JobTitleDeleteById = async (
    officeId: string,
    dispatch: any,
    getData: any,
) => {
    Swal.fire({
        title: 'Delete Job Title?',
        text: "You won't be able to revert this operation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const { payload } = await dispatch(deleteJobTitle(officeId));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getData();
            } else {
                toast.error(errorMessage);
            }
        }
    });
};
