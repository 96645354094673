import { apiRegisterUser } from '@services/API/apiFunctions';

import { createAsyncThunk } from '@reduxjs/toolkit';

const registerUser = createAsyncThunk(
    'registerUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiRegisterUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { registerUser };
