import React from 'react';
import { Controller } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';

import { phoneFormat } from '@constants/general.const';

import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope, faFax } from '@fortawesome/pro-light-svg-icons';

import { contactObj, contactPhoneObj } from '@app/SettingsLists/TSP/tsp.const';
import ContactInfo from '@app/SettingsLists/contactInfo';

const TspFormField = ({
    errors,
    control,
    cityArr,
    zipError,
    onClose,
    isAddForwarder,
    isUpdateForwarder,
    setValue,
    getValues,
    watchContactInfo,
    TspId,
    isDisabled,
}: any) => (
    <>
        <h6 className="text-sm mb-0">TSP INFO</h6>
        <Row className="justify-content-center">
            <Col md={12}>
                <div className="mt-4">
                    <Controller
                        name={`name`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    value={value}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg text-dark"
                                    tabIndex={1}
                                    id={`Name`}
                                    inputType={'text'}
                                    placeholder={'Name'}
                                    disabled={false}
                                    groupInput={false}
                                    inputGroupClassName="mfnw__input-group"
                                    autoFocus={true}
                                    errorText={errors?.name?.message}
                                />
                            </>
                        )}
                    />
                </div>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_address`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    value={value}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    tabIndex={2}
                                    id={`Address`}
                                    inputType={'text'}
                                    placeholder={'Address'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    inputGroupClassName="mfnw__input-group"
                                    errorText={
                                        errors?.forwarder_address?.message
                                    }
                                />
                            </>
                        )}
                    />
                </div>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_city`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <ReactSelectGroup
                                    value={cityArr.find(
                                        (c: any) => c?.value === value,
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={cityArr}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'forwarder_city',
                                            valueStateTownChange?.value,
                                        );
                                    }}
                                    tabIndex={3}
                                    className="w-100"
                                    isSearchable={zipError ? true : false}
                                    isDisabled={
                                        isDisabled ||
                                        (!zipError &&
                                            getValues('forwarder_zip_code') ===
                                                null) ||
                                        cityArr.length === 1 ||
                                        (zipError ? true : false)
                                    }
                                    placeholder="City"
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_state_name`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <InputGroupText
                                onChangeFunc={onChange}
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                value={value}
                                tabIndex={3}
                                id={`State`}
                                inputType={'text'}
                                placeholder={'State'}
                                disabled={true}
                                groupInput={false}
                                errorText={
                                    errors?.forwarder_state_name?.message
                                }
                            />
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_zip_code`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    value={value}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    tabIndex={5}
                                    id={`Zip Code`}
                                    inputType={'text'}
                                    placeholder={'Zip Code'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    inputGroupClassName="mfnw__input-group"
                                    errorText={
                                        zipError
                                            ? zipError
                                            : errors?.forwarder_zip_code
                                                  ?.message
                                    }
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_phone`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <PhoneInputMaskGroup
                                maskFormat={phoneFormat}
                                inputName="organization_phone"
                                inputType="text"
                                label="Phone No."
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                onChangeFunc={onChange}
                                id="organization_phone"
                                aria-describedby="phone"
                                tabIndex={6}
                                faphoneIcon={faPhone}
                                value={value}
                                placeholder="Phone"
                                errorText={errors?.forwarder_phone?.message}
                                disable={isDisabled}
                            />
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_contact`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <PhoneInputMaskGroup
                                maskFormat={phoneFormat}
                                inputName="forwarder_contact"
                                inputType="text"
                                label="Phone No."
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                onChangeFunc={onChange}
                                id="forwarder_contact"
                                aria-describedby="phone"
                                tabIndex={7}
                                faphoneIcon={faFax}
                                value={value}
                                placeholder="Fax"
                                errorText={errors?.forwarder_contact?.message}
                                disable={isDisabled}
                            />
                        )}
                    />
                </div>
            </Col>
            <Col md={12}>
                <div className="mt-4">
                    <Controller
                        name={`forwarder_email`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={8}
                                    id={`Email`}
                                    inputType={'text'}
                                    icon={faEnvelope}
                                    placeholder={'Email'}
                                    disabled={isDisabled}
                                    groupInput={true}
                                    errorText={errors?.forwarder_email?.message}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <ContactInfo
                watchContactInfo={watchContactInfo}
                getValues={getValues}
                setValue={setValue}
                control={control}
                errors={errors}
                TspId={TspId}
                fieldName={'forwarder_contact_info'}
                contactObj={contactObj}
                contactPhoneObj={contactPhoneObj}
                isDisabled={isDisabled}
            />
            {!isDisabled && (
                <Col md={12}>
                    <Button
                        variant="primary w-100 mt-4 text-uppercase fw-semibold"
                        type="submit"
                        tabIndex={9}
                    >
                        {isAddForwarder || isUpdateForwarder ? (
                            <LoaderBtn />
                        ) : (
                            'SAVE'
                        )}
                    </Button>
                    <Button
                        className="btn-cancel mt-4 w-100 text-uppercase fw-semibold"
                        onClick={() => onClose()}
                        tabIndex={9}
                    >
                        CANCEL
                    </Button>
                </Col>
            )}
        </Row>
    </>
);

export default TspFormField;
