import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddDriver,
    apiDeleteDriver,
    apiDriverData,
    apiFuelSurchargeData,
    apiUpdateDriver,
    apiSelectedFileUpload,
    apiGetImportExceptions,
} from '@services/API/apiFunctions';

const addDriver = createAsyncThunk(
    'addDriver',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddDriver(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getImportExceptions = createAsyncThunk(
    'getImportExceptions',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetImportExceptions(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateDriver = createAsyncThunk(
    'updateDriver',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateDriver(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const selectedFileUpload = createAsyncThunk(
    'selectedFileUpload',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiSelectedFileUpload(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getDriverInfo = createAsyncThunk(
    'getDriverInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDriverData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getFuelSurchargeData = createAsyncThunk(
    'getDriverInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiFuelSurchargeData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteDriver = createAsyncThunk(
    'deleteDriver',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteDriver(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    addDriver,
    getImportExceptions,
    updateDriver,
    getDriverInfo,
    deleteDriver,
    getFuelSurchargeData,
    selectedFileUpload,
};
