import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { dateEndTimeFormat } from '@constants/general.const';

import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';

import InfoDiv from '@app/OffersList/offerContentComponent/infoDiv';
import AddressDiv from '@app/OffersList/offerContentComponent/addressDiv';
import PackHaulBoxDiv from '@app/OffersList/offerContentComponent/packHaulBoxDiv';
import LHBoxDiv from '@app/OffersList/offerContentComponent/LHBoxDiv';
import MainInfoDiv from '@app/OffersList/offerContentComponent/MainInfoDiv';
import { dateTimeZone, setTimeZone } from '@utils/utilFunctions';

const ListViewOffer = ({
    handleViewShow,
    offer,
    getElapsedTime,
    handleAcceptActions,
    handleAcceptViewShow,
    isRejectOffer,
}: any) => (
    <>
        <Card className="px-2 px-lg-4 my-2 my-lg-4 nrounded-4 mb-auto  key={key} offer-btn">
            <Card.Body>
                <Row>
                    <Col xs={12} xxl={6}>
                        <Row
                            onClick={() =>
                                handleViewShow(offer?.offers_nano_id)
                            }
                        >
                            <Col xs={12} sm={6}>
                                <MainInfoDiv
                                    name={'SHIPPER'}
                                    value={
                                        offer?.shipperdata?.shipper_name ??
                                        offer?.shipper
                                    }
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <MainInfoDiv
                                    name={'OFFERER'}
                                    value={offer?.forwarder_data?.name}
                                />
                            </Col>
                        </Row>
                        <Row
                            onClick={() =>
                                handleViewShow(offer?.offers_nano_id)
                            }
                        >
                            <Col xs={12} sm={6}>
                                <AddressDiv
                                    icon={OriginIcon}
                                    offerAddress={offer?.address_origin}
                                    addressName="ORIGIN"
                                    color="text-primary"
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <AddressDiv
                                    icon={Destination}
                                    offerAddress={offer?.address_destination}
                                    addressName="DESTINATION"
                                    color="text-lightpink"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={12}
                                sm={6}
                                className="text-center text-sm-start"
                            >
                                <div className="mfnp_btnpro mb-2 mb-lg-3">
                                    <Button
                                        variant="primary"
                                        className="btn_type_large fw-semibold p-lg-0"
                                        onClick={() => {
                                            handleAcceptViewShow(
                                                offer?.offers_nano_id,
                                            );
                                        }}
                                    >
                                        ACCEPT
                                    </Button>
                                </div>
                            </Col>

                            <Col
                                xs={12}
                                sm={6}
                                className="text-center text-sm-start"
                            >
                                <div className="mfnp_btnpro mb-3 mb-lg-3">
                                    <Button
                                        className="btn-cancel  btn_type_large btn-backgroud fw-semibold p-lg-0"
                                        onClick={() =>
                                            handleAcceptActions(
                                                offer?.offers_nano_id,
                                                'rejected',
                                            )
                                        }
                                    >
                                        {isRejectOffer ? (
                                            <LoaderBtn />
                                        ) : (
                                            'REJECT'
                                        )}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={12}
                        xxl={6}
                        onClick={() => handleViewShow(offer?.offers_nano_id)}
                    >
                        <Row>
                            <Col xs={6} sm={4} className="upper-part">
                                <InfoDiv
                                    name="OFFER TYPE"
                                    value={offer?.type ?? '-'}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="upper-part">
                                <>
                                    {offer?.type === 'COD' ? (
                                        <InfoDiv
                                            name="B/L"
                                            value={
                                                offer?.forwarderBillOfLaden !==
                                                ''
                                                    ? offer?.forwarderBillOfLaden
                                                    : '-'
                                            }
                                        />
                                    ) : (
                                        <InfoDiv
                                            name="GBL #"
                                            value={
                                                offer?.govBillOfLaden !== ''
                                                    ? offer?.govBillOfLaden
                                                    : '-'
                                            }
                                        />
                                    )}
                                </>
                            </Col>
                            <Col xs={6} sm={4} className="upper-part">
                                <InfoDiv
                                    name="WEIGHT"
                                    value={offer?.estimatedWeight}
                                    bold={true}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="upper-part">
                                <InfoDiv
                                    name="PACK"
                                    value={dateTimeZone(offer?.packDate)}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="upper-part">
                                <InfoDiv
                                    name="PICKUP"
                                    value={dateTimeZone(offer?.pickupDate)}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="upper-part">
                                <InfoDiv
                                    name="RDD"
                                    value={dateTimeZone(offer?.deliveryDate)}
                                />
                            </Col>
                            <Col
                                xs={6}
                                sm={4}
                                className="upper-part d-flex mt-2 mt-sm-0"
                            >
                                <LHBoxDiv
                                    firstName="LH Disc"
                                    secondName="LH Miles"
                                    jobLhData={offer?.line_haule_data}
                                />
                            </Col>
                            <Col xs={6} sm={8} className="upper-part">
                                <div className="ms-lg-1 ms-0 mb-2 mb-sm-0 mt-1">
                                    <p className="text-light mb-0 text-sm text-uppercase">
                                        Line Haul Notes
                                    </p>
                                    <p className="text-dark mb-0 text-base">
                                        {offer?.line_haule_data?.notes}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} className="d-flex mt-3">
                                <PackHaulBoxDiv
                                    firstName="PACK($)"
                                    secondName="HAUL($)"
                                    jobLhData={offer?.line_haule_data}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="pt-1 pt-sm-2">
                                <InfoDiv
                                    name="RECEIVED ON"
                                    value={setTimeZone(
                                        offer?.offeredOn,
                                        dateEndTimeFormat,
                                    )}
                                />
                            </Col>
                            <Col xs={6} sm={4} className="pt-1 pt-sm-2">
                                <InfoDiv
                                    name="ELAPSED"
                                    value={getElapsedTime(offer)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>
);
export default ListViewOffer;
