import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Accordion, Col, Form, Image, ListGroup, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import { routesPath } from '@constants/routesNavigate';
import { showDeleteConfirmationDialog } from '@constants/general.const';

import {
    faCirclePlus,
    faCircleTrash,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MembersNotFound from '@images/membership-not-found.svg';

import AddMembershipPopup from '@app/UserListing/MembershipList/AddMembershipPopup';
import {
    deleteUserInMembership,
    getMembershipList,
} from '@app/UserListing/MembershipList/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';

const MembershipList = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { membershipData } = useSelector((state: any) => state);
    const { isGetMembershipList } = membershipData;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<any[]>([]);
    const [groupInfo, setGroupInfo] = useState<any>(null);
    const [deleteArr, setDeleteArr] = useState<number[]>([]);

    const handleClose = () => setShowPopup(false);
    const handleShow = (item: any) => {
        setShowPopup(true);
        setGroupInfo(item);
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const { payload } = await dispatch(getMembershipList(''));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setMemberData(payload?.data?.data);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const deleteById = async (id: any) => {
        if (id != null || deleteArr.length > 0) {
            const confirmationOptions = {
                title: 'Delete Membership?',
                icon: 'warning',
                confirmButtonText: 'Yes, delete it!',
                text: "You won't be able to revert this operation!",
            };
            const isConfirmed = await showDeleteConfirmationDialog(
                confirmationOptions,
            );
            if (isConfirmed) {
                const { payload } = await dispatch(
                    deleteUserInMembership(id ?? deleteArr),
                );
                const { data: payloadData, message: errorMessage } =
                    payload || {};
                const { message, status } = payloadData || {};
                if (message && status) {
                    getData();
                    setDeleteArr([]);
                } else {
                    toast.error(errorMessage);
                }
            }
        }
    };

    const deleteArrayChange = (event: any, id: any) => {
        if (event?.target?.checked) {
            setDeleteArr((prevArr: any) => [...prevArr, id]);
        } else {
            setDeleteArr((prevArr: any) =>
                prevArr.filter((item: any) => item !== id),
            );
        }
    };

    return (
        <section className="mfp-main">
            <div className="mfnw__grouplisting_content py-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fw-semibold m-0">Membership List</h5>
                </div>
            </div>
            {isGetMembershipList ? (
                <LoaderBtn />
            ) : memberData && memberData.length !== 0 ? (
                memberData?.map((item: any, index: number) => (
                    <>
                        <Accordion
                            defaultActiveKey={['0']}
                            alwaysOpen
                            key={index}
                        >
                            <Accordion.Item eventKey="0" className="mb-2 ">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <p className="mb-0 text-sm fw-bold text-light overflow-text">
                                        {item?.group_name}
                                    </p>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            {index === 0 &&
                                                deleteArr.length > 0 && (
                                                    <p className="mb-0 border-line fw-bold text-light">
                                                        <FontAwesomeIcon
                                                            icon={faCircleTrash}
                                                            size="xl"
                                                            title="Delete selected users from
                                                    this group"
                                                            className="text-danger cursor-pointer mx-3"
                                                            onClick={() => {
                                                                deleteById(
                                                                    null,
                                                                );
                                                            }}
                                                        />
                                                    </p>
                                                )}
                                            <FontAwesomeIcon
                                                icon={faCirclePlus}
                                                size="xl"
                                                className="text-primary cursor-pointer mx-3"
                                                onClick={() => handleShow(item)}
                                            />
                                        </div>
                                        <Accordion.Header></Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Collapse
                                    eventKey="0"
                                    className="py-2"
                                >
                                    <ListGroup>
                                        {item?.group_users &&
                                            item?.group_users.length > 0 &&
                                            item?.group_users?.map(
                                                (
                                                    user: any,
                                                    userIndex: number,
                                                ) => (
                                                    <ListGroup.Item
                                                        as="li"
                                                        key={userIndex}
                                                        className="d-flex justify-content-between align-items-start mt-1 "
                                                    >
                                                        <Form.Check
                                                            inline
                                                            name="group1"
                                                            onChange={(e) => {
                                                                deleteArrayChange(
                                                                    e,
                                                                    user?.pivot
                                                                        ?.membership_nano_id,
                                                                );
                                                            }}
                                                            checked={deleteArr.includes(
                                                                user?.pivot
                                                                    ?.membership_nano_id,
                                                            )}
                                                        />
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold overflow-text">
                                                                {`${user?.firstName} ${user?.lastName}`}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="icon_right pe-auto cursor-pointer"
                                                            onClick={() =>
                                                                deleteById(
                                                                    user?.pivot
                                                                        ?.membership_nano_id,
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTrashCan
                                                                }
                                                                color="#b7b7b7"
                                                                size="lg"
                                                            />
                                                        </div>
                                                    </ListGroup.Item>
                                                ),
                                            )}
                                    </ListGroup>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <hr />
                    </>
                ))
            ) : (
                <>
                    {memberData && memberData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={MembersNotFound} />
                                <h5 className="text-light mt-4 fw-bold">
                                    Membership helps you organize your users to
                                    grant visibility to different parts of the
                                    system. Move users into the appropriate
                                    groups.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            {showPopup && (
                <AddMembershipPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getData()}
                    groupInfo={groupInfo}
                />
            )}
        </section>
    );
};
export default MembershipList;
