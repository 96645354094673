import React from 'react';

import './workFlow.scss';

const WorkFlow = () => {
    console.log('workflow');
    return (
        <>
            <h1>Workflow</h1>
        </>
    );
};

export default WorkFlow;
