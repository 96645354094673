import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import { getImportExceptions } from '@app/SettingsLists/ImportExceptions/functions';

const defaultState = {
    isGetImportExceptions: false,
};

export const exceptionsSlice: any = createSlice({
    name: 'driverSlice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [getImportExceptions.pending.type]: (state: any, { payload }) => {
            state.isGetImportExceptions = true;
            state.registerResponse = payload;
        },
        [getImportExceptions.fulfilled.type]: (state: any, { payload }) => {
            state.isGetImportExceptions = false;
            state.registerResponse = payload;
        },
        [getImportExceptions.rejected.type]: (state: any, { payload }) => {
            state.isGetImportExceptions = false;
            state.registerResponse = payload;
        },
    },
});

export { getImportExceptions };

export default exceptionsSlice.reducer;
