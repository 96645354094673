import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
    searchTimeOut,
} from '@constants/general.const';

import { uploadFile } from '@utils/utilFunctions';

import { addPacker, updatePacker } from '@app/SettingsLists/Packers/functions';
import { ZipLookUp } from '@app/SettingsLists/lists.const';
import PackerFormField from '@app/SettingsLists/Packers/packerFormField';
import PackerPopUpHeader from '@app/SettingsLists/Packers/packerPopUpHeader';

import { IContactInfoProps } from '../TSP/tsp.interface';
import { IPackerFieldsProps } from './packer.interface';
import { IListOption } from '../../../Interface/common';
import { IFilesArray } from '../Drivers/driver.interface';
import { contactPhoneObj, packerDefaultData, schema } from './packers.const';

const PackersAddPopup = ({
    showPopup,
    handleClose,
    handleShowRegion,
    getDataListCall,
    editData,
    RegionsData,
    regionListValue,
    setRegionList,
    isDisabled,
    modelTitle,
    handleEditShow,
    showAlert,
}: any) => {
    let intervalProgress: any;
    const packerId = editData?.packers_nano_id;

    const dispatch = useDispatch<any>();

    const { packersData } = useSelector((state: any) => state);
    const { isAddPacker, isUpdatePacker, isGetPackerInfo } = packersData;

    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);

    const [progress, setProgress] = useState<number>(0);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [preview, setPreview] = useState<any[]>([]);
    const [filesArray, setFilesArray] = useState<IFilesArray>({
        isValid: true,
        files: undefined,
    });

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors, isDirty },
    } = useForm<IPackerFieldsProps>({
        resolver: yupResolver(schema),
        defaultValues: packerDefaultData,
    });

    const organizationZipCode = watch('packer_zip_code');
    const watchContactInfo = watch('phone_number');
    const packerCertificate = watch('packer_certificate');

    useEffect(() => {
        reset(editData);
        if (editData && !editData?.packer_certificate) {
            setValue('packer_certificate', []);
        }
        if (editData && editData.contact_info) {
            if (editData.contact_info.length > 0) {
                let updatedContactInfo = editData.contact_info.map(
                    (contact: IContactInfoProps) => {
                        const newPhoneNumber = contact?.phone_number?.map(
                            (number: any) => ({
                                ...number,
                                type:
                                    number?.phoneNumberType === 'work'
                                        ? 'Work'
                                        : 'Mobile',
                                phone_no: number?.phone_number,
                                is_default:
                                    number?.is_default === 1 ? '1' : '0',
                            }),
                        );
                        const updatedPhoneArray = newPhoneNumber;
                        return {
                            phone_number: updatedPhoneArray,
                        };
                    },
                );
                updatedContactInfo = updatedContactInfo?.[0]?.phone_number;
                setValue('phone_number', updatedContactInfo);
            } else {
                let updatedContactInfo = getValues('phone_number')
                    ? [...getValues('phone_number')]
                    : [];
                updatedContactInfo = [
                    ...updatedContactInfo,
                    {
                        ...contactPhoneObj,
                        phone_no: editData?.packer_phone || ' ',
                        type: 'Work',
                        is_default: '1',
                    },
                ];
                setValue('phone_number', updatedContactInfo);
            }
        }
        if (editData && editData.regions && editData.regions.length > 0) {
            const updatedRegions = editData.regions.map((region: any) => ({
                label: region?.region_name,
                value: region?.region_nano_id,
                packer_region_nano_id: region?.pivot?.packer_region_nano_id,
                cityState: region?.areas_name,
            }));
            setRegionList(updatedRegions);
        }
    }, [editData]);

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getValues('packer_zip_code') &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'packer_zip_code',
                    'packer_city_id',
                    'packer_city',
                    'packer_state_name',
                    'packer_state',
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    useEffect(() => {
        setValue('regions', regionListValue);
    }, [regionListValue]);

    useEffect(() => {
        uploadFile(
            filesArray,
            setShowProgressBar,
            setProgress,
            setPreview,
            preview,
            'packer_certificate',
            null,
            checkFileLengthValidation,
            dispatch,
            setValue,
            getValues,
        );
    }, [filesArray]);

    useEffect(() => {
        if (progress === 100 && preview?.length > 0) {
            clearInterval(intervalProgress);
            setTimeout(() => {
                setShowProgressBar(false);
            }, 1000);
        }
    }, [progress, preview]);

    const addContactPhoneNo = async () => {
        let updatedContactInfo = getValues('phone_number')
            ? [...getValues('phone_number')]
            : [];
        updatedContactInfo = [
            ...updatedContactInfo,
            {
                ...contactPhoneObj,
                type: 'Work',
                is_default: '0',
            },
        ];

        setValue('phone_number', updatedContactInfo);
    };

    const deletePhone = (phoneIndex: number) => {
        if (phoneIndex !== -1) {
            const updatedContactInfo = [...getValues('phone_number')];

            updatedContactInfo.splice(phoneIndex, 1);

            setValue('phone_number', updatedContactInfo);
        }
    };

    const setPrimary = (phoneIndex: number) => {
        if (phoneIndex !== undefined) {
            const updatedContactInfo = [...getValues('phone_number')];
            updatedContactInfo.forEach((phoneObj: any, index: number) => {
                phoneObj.is_default = index === phoneIndex ? '1' : '0';
            });
            setValue('phone_number', updatedContactInfo);
        }
    };

    const checkFileLengthValidation = () => {
        const checkLength =
            packerCertificate && typeof packerCertificate === 'string'
                ? JSON.parse(packerCertificate)
                : packerCertificate;
        if (checkLength?.length >= 5) {
            toast.error('Maximum 5 documents allowed.');
            return false;
        }
        return true;
    };

    const HandleChange = async (data: IPackerFieldsProps) => {
        const { payload } = await dispatch(
            packerId
                ? updatePacker({
                      ...data,
                      id: packerId,
                      packer_certificate:
                          getValues('packer_certificate') &&
                          typeof getValues('packer_certificate') === 'string'
                              ? JSON.parse(getValues('packer_certificate'))
                              : getValues('packer_certificate'),
                  })
                : addPacker(data),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            handleClose();
            getDataListCall();
            reset(packerDefaultData);
        } else {
            if (payload?.message !== '') {
                Swal.fire({
                    title: 'Error',
                    text: payload?.message || '',
                    icon: 'error',
                    cancelButtonColor: cancelButtonColor,
                });
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                centered
                className="z-999"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        {modelTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {isGetPackerInfo ? (
                        <LoaderBtn />
                    ) : (
                        <>
                            <PackerPopUpHeader
                                showAlert={showAlert}
                                handleEditShow={handleEditShow}
                                editData={editData}
                            />
                            <Form onSubmit={handleSubmit(HandleChange)}>
                                <PackerFormField
                                    control={control}
                                    isDisabled={isDisabled}
                                    errors={errors}
                                    isAddPacker={isAddPacker}
                                    isUpdatePacker={isUpdatePacker}
                                    onClose={onClose}
                                    packerId={packerId}
                                    getValues={getValues}
                                    editData={editData}
                                    setValue={setValue}
                                    cityArr={cityArr}
                                    zipError={zipError}
                                    setPrimary={setPrimary}
                                    deletePhone={deletePhone}
                                    addContactPhoneNo={addContactPhoneNo}
                                    RegionsData={RegionsData}
                                    handleShowRegion={handleShowRegion}
                                    setFilesArray={setFilesArray}
                                    preview={preview}
                                    setPreview={setPreview}
                                    progress={progress}
                                    showProgressBar={showProgressBar}
                                    watchContactInfo={watchContactInfo}
                                />
                            </Form>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};
export default PackersAddPopup;
