import * as Yup from 'yup';

import { IUserProps } from './user.interface';

export const userDataObject: IUserProps = {
    email: '',
    firstName: '',
    lastName: '',
    group_id: '',
    department_id: '',
    office_id: '',
    role_id: '',
    jobTitle_id: '',
    user_type: 'user',
    company_id: '',
};

export const userSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    office_id: Yup.string().required('Please select office'),
    role_id: Yup.string().required('Please select role'),
    jobTitle_id: Yup.string().required('Please select job title'),
    department_id: Yup.string().required('Please select department'),
    group_id: Yup.string().required('Please select group'),
}).required();
