import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

import { Badge, Modal, Form } from 'react-bootstrap';

import { searchTimeOut } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPen,
    faTrashCan,
    faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';

import { updateRegion } from '@app/CreateOrganization/step6/functions';
import {
    schema,
    onPopUpClose,
    getRegionCityStateData,
} from '@app/CreateOrganization/step6/step6.const';
import RegionPopUpFormField from '@app/CreateOrganization/step6/regionPopUpFormField';

import { IStep6Props } from './step6.interface';
import { IListOption } from '../../../Interface/common';

const RegionViewOrEditPopupStep6 = ({
    showPopup,
    handleClose,
    getDataListCall,
    handleEditShow,
    organizationId,
    editData,
    isEdit,
    deleteById,
}: any) => {
    const dispatch = useDispatch<any>();

    const { regionsStep6Data } = useSelector((state: any) => state);
    const { isGetCityStateInfo } = regionsStep6Data;

    var regionId = editData?.region_nano_id;
    //  ask fore type

    const [selectList, setSelectList] = useState<IListOption[]>([]);
    const [regionError, setRegionError] = useState<any>(null);
    const [searchStateText, setSearchStateText] = useState<any>(null);
    const [optionArr, setOptionArr] = useState<IListOption[]>([]);

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors, isDirty },
    } = useForm<IStep6Props>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    useEffect(() => {
        if (searchStateText || searchStateText === '') {
            const getData: NodeJS.Timeout = setTimeout(() => {
                getCityStateData();
            }, searchTimeOut);
            return () => clearTimeout(getData);
        }
    }, [searchStateText]);

    const watchRegionType = watch('region_type');
    const prevRegionType = useRef(watch('region_type'));

    useEffect(() => {
        if (
            watchRegionType &&
            prevRegionType.current &&
            watchRegionType !== prevRegionType.current
        ) {
            setValue('region_state', []);
            isEdit && setSelectList([]);
            getCityStateData();
        }
        prevRegionType.current = watchRegionType;
    }, [watchRegionType]);

    useEffect(() => {
        if (editData) {
            reset(editData);
            var defaultSelect: IListOption[] = [];
            JSON.parse(editData?.areas_name).map((name: any, key: any) => {
                defaultSelect.push({
                    value: JSON.parse(editData?.areas_id)[key],
                    label: name,
                });
                return null;
            });
            const getRegion: any =
                defaultSelect && setSelectList(defaultSelect);
            defaultSelect && setValue('region_state', defaultSelect);
            return () => clearTimeout(getRegion);
        }
    }, [editData]);

    const getCityStateData = async () => {
        getRegionCityStateData(
            dispatch,
            getValues,
            searchStateText,
            setOptionArr,
        );
    };

    const HandleChange = async (data: IStep6Props) => {
        const sendData = { ...data, nanoId: organizationId };
        const { payload } = await dispatch(
            updateRegion({ ...sendData, id: regionId }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            getDataListCall();
            handleClose();
            reset();
        } else {
            if (payload?.message !== '') {
                setRegionError(payload?.message);
                toast.error(payload?.message);
            } else {
                setRegionError(null);
            }
        }
    };

    const updateSelectedList = async (itemId: string) => {
        const newArray = selectList.filter(
            (item: any) => item?.value !== itemId,
        );
        setSelectList(newArray);
        setValue('region_state', newArray);
    };

    const onClose = async () => {
        onPopUpClose(isDirty, handleClose, reset);
    };

    const memoizedRegionInfo = useMemo(
        () => (
            <div className="d-flex justify-content-between text-center mb-4">
                <Form.Label>REGION INFO</Form.Label>
                <div className="d-flex align-items-center">
                    {!isEdit && (
                        <>
                            <FontAwesomeIcon
                                className="mx-2 cursor-pointer align-baseline text-light"
                                icon={faPen}
                                onClick={() => handleEditShow()}
                            />
                            <FontAwesomeIcon
                                onClick={() =>
                                    deleteById(editData?.region_nano_id)
                                }
                                className="mx-2 cursor-pointer align-baseline text-light"
                                icon={faTrashCan}
                            />
                        </>
                    )}

                    {editData?.is_active && editData?.is_active === 1 ? (
                        <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Enabled
                        </Badge>
                    ) : (
                        <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Disable
                        </Badge>
                    )}
                </div>
            </div>
        ),
        [isEdit, editData],
    );

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {isEdit ? 'Edit Regions' : 'View Regions'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <RegionPopUpFormField
                        regionError={regionError}
                        control={control}
                        errors={errors}
                        selectList={selectList}
                        optionArr={optionArr}
                        setSearchStateText={setSearchStateText}
                        setValue={setValue}
                        setSelectList={setSelectList}
                        isGetCityStateInfo={isGetCityStateInfo}
                        updateSelectedList={updateSelectedList}
                        onClose={onClose}
                        isAdd={isEdit}
                        getValues={getValues}
                        memoizedRegionInfo={memoizedRegionInfo}
                        isImageShow={false}
                    />
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RegionViewOrEditPopupStep6;
