import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Compressor from 'compressorjs';
import { toast } from 'react-toastify';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, Form, Row } from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';
import ToastBodyContainer from '@components/ToastContainer';
import ImageCrop from '@components/LogoImageUpload/ImageCrop';

import { searchTimeOut } from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';

import { organizationDataObject } from '@app/CreateOrganization/step1/step1.const';
import { schema } from '@app/CreateOrganization/Organizations/OrganizationList/organization.const';
import { updateCurrentStep } from '@app/Auth/Login/loginSlice';
import { ZipLookUp } from '@app/SettingsLists/lists.const';
import {
    addOrganizationData,
    getUserOrganizationInformation,
    updateOrganizationData,
} from '@app/CreateOrganization/step1/functions';
import Step1FormField from '@app/CreateOrganization/step1/step1FormField';
import { blobToBase64, checkFileSize } from '@utils/utilFunctions';

import { IListOption } from '../../../Interface/common';
import { IStep1Props } from './step1.interface';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const Step1 = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { organizationStep1Data } = useSelector((state: any) => state);
    const { isAddOrganizationData, isUpdateOrganizationData } =
        organizationStep1Data;

    const [stateArr, setStateArr] = useState<IListOption[]>([]);
    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);
    const [SCACarr, setSCACarr] = useState<string[]>(['']);
    const [SCACarrLength, setSCACarrLength] = useState<boolean>(false);
    const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
    //  ask fore type
    const [profileImage, setProfileImage] = useState<string | null>('');
    const [organizationData, setOrganizationData] = useState<any>(
        organizationDataObject,
    );
    const [userImage, setUserImage] = useState<string | null>();
    const [extension, setExtension] = useState<string | null>('');
    const [image, setImage] = useState<string | null>('');

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: organizationData,
        mode: 'onBlur',
    });
    const fieldErrors: any = errors as FieldErrors<IStep1Props>;

    const organizationZipCode = watch('organization_zip_code');
    useEffect(() => {
        getUserOrganizationData();
    }, []);

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getValues('organization_zip_code') &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'organization_zip_code',
                    'organization_city',
                    'organization_city_name',
                    'organization_state',
                    'organization_state_name',
                    setStateArr,
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    useEffect(() => {
        if (organizationData?.organization_scac) {
            let myArray = [];
            if (typeof organizationData?.organization_scac === 'string') {
                myArray = JSON.parse(
                    organizationData?.organization_scac ?? [''],
                );
            } else {
                myArray = organizationData?.organization_scac;
            }
            setValue('organization_scac', myArray[0] ? myArray : ['']);
            setSCACarr([...(myArray[0] ? myArray : [''])]);
            myArray[0] ? setSCACarrLength(true) : setSCACarrLength(false);
        }
    }, [organizationData?.organization_scac]);

    const getUserOrganizationData = async () => {
        const { payload } = await dispatch(getUserOrganizationInformation(''));
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            reset(payload?.data?.data);
            setOrganizationData(payload?.data?.data);
            setUserImage(payload?.data?.data?.organization_logo);
            setProfileImage(payload?.data?.data?.organization_logo);
        } else {
            toast.error(payload?.message);
        }
    };

    const HandleChange = async (data: IStep1Props) => {
        var sendData = {
            ...data,
            organization_logo: profileImage ?? userImage,
            img_extension: extension,
        };
        const { payload } = await dispatch(
            organizationData?.organization_nano_id
                ? updateOrganizationData({
                      ...sendData,
                      id: organizationData?.organization_nano_id,
                  })
                : addOrganizationData(sendData),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            dispatch(updateCurrentStep(payload));
            navigate(routesPath?.step2);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            toast.error(payload?.message);
        }
    };

    const handleClickImageChange = (img: any, extensionValueOnClick: any) => {
        setImage(img);
        setExtension(extensionValueOnClick);
        setCropModalOpen(true);
    };

    const saveWithCompressImage = async (blob: any) => {
        if (blob !== '') {
            new Compressor(blob, {
                quality: 0.6,
                maxHeight: 500,
                success: async (compressedResult: any) => {
                    const compressedImg: any = await blobToBase64(
                        compressedResult,
                    );
                    const isValidFileSize = checkFileSize(compressedImg);
                    if (isValidFileSize) {
                        setProfileImage(compressedImg);
                        setUserImage(compressedImg);
                    }
                },
            });
        }
    };

    const handleSave = async (imageData: any) => {
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();
        saveWithCompressImage(blob);
    };

    return (
        <section className="mfnw_create_organization">
            <Form onSubmit={handleSubmit(HandleChange)}>
                <Container>
                    <Row className="justify-content-center pt-3">
                        <Col md={12}>
                            <Link to="/">
                                <FontAwesomeIcon
                                    icon={faArrowLeftLongIcon}
                                    className="me-2"
                                />
                                Create New Organization
                            </Link>
                        </Col>
                        <Col md={12}>
                            <StepBar />
                        </Col>
                        <Col md={12}>
                            <div className="mt-2">
                                <Step1FormField
                                    handleClickImageChange={
                                        handleClickImageChange
                                    }
                                    userImage={userImage}
                                    organizationData={organizationData}
                                    control={control}
                                    cityArr={cityArr}
                                    stateArr={stateArr}
                                    setValue={setValue}
                                    zipError={zipError}
                                    fieldErrors={fieldErrors}
                                    SCACarr={SCACarr}
                                    setSCACarrLength={setSCACarrLength}
                                    setSCACarr={setSCACarr}
                                    getValues={getValues}
                                    SCACarrLength={SCACarrLength}
                                    isAddOrganizationData={
                                        isAddOrganizationData
                                    }
                                    isUpdateOrganizationData={
                                        isUpdateOrganizationData
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                {cropModalOpen && (
                    <ImageCrop
                        open={cropModalOpen}
                        handleClose={() => {
                            setCropModalOpen(false);
                            setImage('');
                        }}
                        image={image}
                        saveCroppedFile={(imageData: any) =>
                            handleSave(imageData)
                        }
                    />
                )}
                <ToastBodyContainer />
            </Form>
        </section>
    );
};

export default Step1;
