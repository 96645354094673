import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddJobDocument,
    apiDeleteJobDocument,
    apiJobDocumentsList,
    apiUpdateJobDocument,
} from '@services/API/apiFunctions';

const jobDocumentsList = createAsyncThunk(
    'JobDocumentsList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiJobDocumentsList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addJobDocument = createAsyncThunk(
    'addJobDocument',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddJobDocument(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateJobDocument = createAsyncThunk(
    'updateJobDocument',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateJobDocument(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteJobDocument = createAsyncThunk(
    'deleteJobDocument',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteJobDocument(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    jobDocumentsList,
    deleteJobDocument,
    addJobDocument,
    updateJobDocument,
};
