import React from 'react';
import { Table } from 'react-bootstrap';

import ReactPagination from '@components/reactPagination';

import CommonActions from '@app/SettingsLists/CommonActions';

const PackerTable = ({
    currentPageData,
    handleViewShow,
    showAlert,
    handleEditShow,
    pageCount,
    handlePageClick,
    filters,
}: any) => (
    <>
        <Table
            responsive="md"
            borderless
            className="mfnw__borderless_card-table mb-0 w-100"
        >
            <thead>
                <tr>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>TYPE</th>
                    <th>CONTACT</th>
                    <th>PHONE</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {currentPageData.map((item: any, index: number) => (
                    <tr key={index}>
                        <td
                            onClick={() =>
                                handleViewShow(item?.packers_nano_id)
                            }
                            className="text-truncate"
                        >
                            {item?.packer_name || '(N/A)'}
                        </td>
                        <td
                            onClick={() =>
                                handleViewShow(item?.packers_nano_id)
                            }
                            className="text-truncate"
                        >
                            {item?.packer_email || '(N/A)'}
                        </td>
                        <td
                            onClick={() =>
                                handleViewShow(item?.packers_nano_id)
                            }
                            className="text-truncate"
                        >
                            {item?.packer_type || '(N/A)'}
                        </td>
                        <td
                            onClick={() =>
                                handleViewShow(item?.packers_nano_id)
                            }
                            className="text-truncate"
                        >
                            (N/A)
                        </td>
                        <td
                            onClick={() =>
                                handleViewShow(item?.packers_nano_id)
                            }
                            className="text-truncate"
                        >
                            {item?.packer_phone || item?.contact_info?.[0]
                                ? item?.contact_info?.[0]
                                    ? item?.contact_info?.[0]?.phone_number?.[0]
                                          ?.phone_number
                                    : item?.packer_phone
                                : '(N/A)'}
                        </td>
                        <td>
                            <div className="d-flex align-items-center justify-content-end ">
                                <CommonActions
                                    item={item}
                                    showAlert={showAlert}
                                    handleEditShow={handleEditShow}
                                    nanoId={item?.packers_nano_id}
                                    isUserList={false}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <div className="mt-3 mt-md-0">
            <ReactPagination
                currentPageData={currentPageData}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                filters={filters}
            />
        </div>
    </>
);

export default PackerTable;
