import { IListOption } from '../../Interface/common';
import { deduplicateList } from '../../constants/general.const';
import { checkZipLookUp } from '../OffersList/functions';
import { IContactInfoProps } from './TSP/tsp.interface';

export const addContactInfo = (
    fieldName: any,
    getValues: any,
    setValue: any,
    contactObj: IContactInfoProps,
) => {
    const updatedContactList = [...getValues(fieldName), contactObj];
    setValue(fieldName, updatedContactList);
};

export const addContactPhoneNo = async (
    index: number,
    getValues: any,
    setValue: any,
    contactPhoneObj: any,
    fieldName: any,
) => {
    const updatedContactInfo = [...getValues(fieldName)];
    const updatedPhoneArray = [
        ...updatedContactInfo[index]?.phone_number,
        { ...contactPhoneObj, is_default: '0' },
    ];

    updatedContactInfo[index] = {
        ...updatedContactInfo[index],
        phone_number: updatedPhoneArray,
    };
    setValue(fieldName, updatedContactInfo);
};

export const deleteContact = (
    contactIndex: number,
    getValues: any,
    setValue: any,
    fieldName: any,
) => {
    if (contactIndex !== -1) {
        const updatedContactInfo = [...getValues(fieldName)];
        updatedContactInfo.splice(contactIndex, 1);
        setValue(fieldName, updatedContactInfo);
    }
};

export const deletePhone = (
    contactIndex: number,
    phoneIndex: number,
    getValues: any,
    setValue: any,
    fieldName: any,
) => {
    if (phoneIndex !== -1) {
        const updatedContactInfo = [...getValues(fieldName)];

        const isDefaultPhone =
            updatedContactInfo[contactIndex].phone_number[phoneIndex]
                .is_default === '1' ||
            updatedContactInfo[contactIndex].phone_number[phoneIndex]
                .is_default === 1;
        if (
            isDefaultPhone &&
            updatedContactInfo[contactIndex].phone_number.length > 0
        ) {
            updatedContactInfo[contactIndex].phone_number[0].is_default = '1';
        }

        const updatedPhoneArray = [
            ...updatedContactInfo[contactIndex]?.phone_number,
        ];
        updatedPhoneArray.splice(phoneIndex, 1);
        updatedContactInfo[contactIndex] = {
            ...updatedContactInfo[contactIndex],
            phone_number: updatedPhoneArray,
        };

        setValue(fieldName, updatedContactInfo);
    }
};

export const setPrimary = (
    contactIndex: number,
    phoneIndex: number,
    getValues: any,
    setValue: any,
    fieldName: any,
) => {
    if (contactIndex !== undefined && phoneIndex !== undefined) {
        const updatedContactInfo = [...getValues(fieldName)];

        updatedContactInfo[contactIndex].phone_number.forEach(
            (phoneObj: any, index: any) => {
                phoneObj.is_default =
                    index === phoneIndex
                        ? fieldName === 'shipper_contact_info'
                            ? 1
                            : '1'
                        : fieldName === 'shipper_contact_info'
                        ? 0
                        : '0';
            },
        );
        setValue(fieldName, updatedContactInfo);
    } else if (phoneIndex !== undefined) {
        const updatedContactInfo = [...getValues(fieldName)];

        updatedContactInfo[contactIndex].phone_number.forEach(
            (phoneObj: any, index: any) => {
                phoneObj.is_default =
                    index === phoneIndex
                        ? fieldName === 'shipper_contact_info'
                            ? 1
                            : '1'
                        : fieldName === 'shipper_contact_info'
                        ? 0
                        : '0';
            },
        );
        setValue(fieldName, updatedContactInfo);
    }
};

export const ZipLookUp = async (
    type: string,
    dispatch: any,
    getValues: any,
    setValue: any,
    setCityArr: any,
    setZipError: any,
    zipCode: any,
    city: any,
    city_name: any,
    state: any,
    state_name: any,
    setStateArr?: any,
) => {
    const { payload } = await dispatch(
        checkZipLookUp({
            zip_code: getValues(zipCode),
        }),
    );

    const zipData = payload?.data?.data;
    if (payload?.data?.status) {
        const zipDataState = deduplicateList(zipData?.state, 'id');
        const zipDataCity = deduplicateList(zipData?.city, 'id');

        if (getValues(zipCode) !== '') {
            const newStateArr: IListOption[] = zipDataState.map(
                (dataOption: any) => ({
                    value: dataOption?.id,
                    label: dataOption?.state_name,
                }),
            );
            const newCityArr: IListOption[] = zipDataCity.map(
                (dataOption: any) => ({
                    value: dataOption?.id,
                    label: dataOption?.city_name,
                }),
            );
            setCityArr(newCityArr);
            if (setStateArr) {
                setStateArr(newStateArr);
            }

            const forwarderCity = getValues(city);
            var matchingCity = newCityArr.find(
                (cityDat) => cityDat.value == forwarderCity,
            );
            if (!matchingCity) {
                matchingCity = newCityArr.find(
                    (cityDat) => cityDat.label == forwarderCity,
                );
            }
            if (matchingCity) {
                setValue(city, matchingCity.value);
                setValue(city_name, matchingCity.label);
            } else {
                setValue(city, newCityArr[0]?.value);
                setValue(city_name, newCityArr[0]?.label);
            }
            state === 'agent_state'
                ? setValue([state][0], newStateArr[0]?.label)
                : setValue([state][0], newStateArr[0]?.value);
            setValue('state_name', newStateArr[0]?.label);
            setValue([state_name][0], newStateArr[0]?.label);
            // if (getValues(zipCode)) {
            //     setValue([city][0], newCityArr[0]?.value);
            //     setValue([city_name][0], newCityArr[0]?.label);
            //     state === 'agent_state'
            //         ? setValue([state][0], newStateArr[0]?.label)
            //         : setValue([state][0], newStateArr[0]?.value);
            //     setValue([state_name][0], newStateArr[0]?.label);
            // }

            setZipError(null);
        }
    } else {
        if (type === 'origin') {
            setZipError(payload?.message);
        }
    }
};

export const confimationDialogBoxText = (type: string) => {
    let swalTitle = 'warning';
    let swalText = '';
    let confirmButtonText = '';

    if (type === 'delete') {
        swalText = "You won't be able to revert this!";
        confirmButtonText = 'Yes, delete it!';
    } else if (type === 'Disable') {
        swalText = 'Set Disable?';
        confirmButtonText = 'Yes, Disable it!';
    } else if (type === 'Enable') {
        swalText = 'Set Enable?';
        confirmButtonText = 'Yes, Enable it!';
    }

    return { swalTitle, swalText, confirmButtonText };
};
