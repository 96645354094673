import React from 'react';

import { IInfoDivProps } from '../offer.interface';

const InfoDiv = ({ name, value, bold, fontSize }: IInfoDivProps) => (
    <div className="mb-2 mb-sm-0 mt-1">
        <p className="mb-0 text-sm text-light text-truncate">{name}</p>
        {!fontSize ? (
            <p
                className={`mb-0 text-base fw-normal text-dark text-capitalize text-truncate ${
                    bold ? 'fw-bold' : ''
                }`}
            >
                {value}
            </p>
        ) : (
            <p className="text-truncate">{value}</p>
        )}
    </div>
);

export default InfoDiv;
