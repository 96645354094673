import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addGroupData,
    deleteGroup,
    getGroupDataList,
} from '@app/UserListing/Groups/functions';

const defaultState = {
    isAddGroupData: false,
    isDeleteGroup: false,
    isGetGroupDataList: false,
    isUpdateOrganizationData: false,
    officeData: {
        office_name: '',
    },
};

export const groupSlice: any = createSlice({
    name: 'groupSlice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addGroupData.pending.type]: (state: any, { payload }) => {
            state.isAddGroupData = true;
        },
        [addGroupData.fulfilled.type]: (state: any, { payload }) => {
            state.isAddGroupData = false;
        },
        [addGroupData.rejected.type]: (state: any, { payload }) => {
            state.isAddGroupData = false;
        },

        [getGroupDataList.pending.type]: (state: any, { payload }) => {
            state.isGetGroupDataList = true;
        },
        [getGroupDataList.fulfilled.type]: (state: any, { payload }) => {
            state.isGetGroupDataList = false;
            if (state?.isDeleteGroup) {
                state.isDeleteGroup = false;
            }
        },
        [getGroupDataList.rejected.type]: (state: any, { payload }) => {
            state.isGetGroupDataList = false;
            if (state?.isDeleteGroup) {
                state.isDeleteGroup = false;
            }
        },

        [deleteGroup.pending.type]: (state: any, { payload }) => {
            state.isDeleteGroup = true;
        },
        [deleteGroup.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteGroup = true;
        },
        [deleteGroup.rejected.type]: (state: any, { payload }) => {
            state.isDeleteGroup = false;
        },
    },
});

export default groupSlice.reducer;
