import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addRoleData,
    deleteRole,
    getRoleDataList,
} from '@app/CreateOrganization/step4/functions';

const defaultState = {
    isGetRoleDataList: false,
    isAddRoleData: false,
    isDeleteRole: false,
    roleData: {
        role_name: '',
    },
};

export const roleStep4Slice: any = createSlice({
    name: 'roleStep4Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addRoleData.pending.type]: (state: any, { payload }) => {
            state.isAddRoleData = true;
        },
        [addRoleData.fulfilled.type]: (state: any, { payload }) => {
            state.isAddRoleData = false;
        },
        [addRoleData.rejected.type]: (state: any, { payload }) => {
            state.isAddRoleData = false;
        },

        [getRoleDataList.pending.type]: (state: any, { payload }) => {
            state.isGetRoleDataList = true;
        },
        [getRoleDataList.fulfilled.type]: (state: any, { payload }) => {
            state.isGetRoleDataList = false;
            if (state?.isDeleteRole) {
                state.isDeleteRole = false;
            }
        },
        [getRoleDataList.rejected.type]: (state: any, { payload }) => {
            state.isGetRoleDataList = false;
            if (state?.isDeleteRole) {
                state.isDeleteRole = false;
            }
        },

        [deleteRole.pending.type]: (state: any, { payload }) => {
            state.isDeleteRole = true;
        },
        [deleteRole.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteRole = true;
        },
        [deleteRole.rejected.type]: (state: any, { payload }) => {
            state.isDeleteRole = false;
        },
    },
});

export default roleStep4Slice.reducer;
