import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneLengthCheck,
    phoneRegex,
} from '@constants/general.const';

import { IContactInfoProps, IContactPhoneObj } from './tsp.interface';

export const contactPhoneObj: IContactPhoneObj = {
    type: 'Work',
    phone_no: '',
    is_default: '1',
};
export const contactObj: IContactInfoProps = {
    name: '',
    email: '',
    phone_number: [contactPhoneObj],
};

export const forwarderDefaultData = {
    name: '',
    forwarder_address: '',
    forwarder_email: '',
    forwarder_state: '',
    forwarder_city: '',
    forwarder_zip_code: null,
    forwarder_contact: '',
    forwarder_phone: '',
    forwarder_contact_info: [contactObj],
};

export const schema = Yup.object({
    name: Yup.string().required('Please enter a name'),

    forwarder_email: Yup.string()
        .email('Please enter a valid email')
        .required('Please enter a forwarder email')
        .nullable(true),

    forwarder_city: Yup.string().required('Please enter a city').nullable(true),

    forwarder_address: Yup.string()
        .required('Please enter an address')
        .nullable(true),

    forwarder_state: Yup.string()
        .required('Please enter a state')
        .nullable(true),

    forwarder_phone: Yup.string()
        .required('Please enter a phone number')
        .test(
            'len',
            `Phone number must be exactly ${minPhoneLength} characters`,
            (val) => val?.replace(phoneRegex, '').length === phoneLengthCheck,
        )
        .min(
            minPhoneLength,
            `Phone number must be at least ${minPhoneLength} characters.`,
        )
        .nullable(true),

    forwarder_zip_code: Yup.string()
        .required('Please enter a zip code')
        .nullable(true),
    forwarder_contact: Yup.string()
        .nullable()
        .default(null)
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test(
            'len',
            `Phone number must be exactly ${minPhoneLength} characters`,
            (val) =>
                val === null ||
                val?.replace(phoneRegex, '').length === phoneLengthCheck,
        )
        .min(
            minPhoneLength,
            `Phone number must be at least ${minPhoneLength} characters long`,
        )
        .nullable(true),

    forwarder_contact_info: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().nullable(true),
            email: Yup.string()
                .email('Please enter a valid email')
                .nullable(true),

            phone_number: Yup.array().of(
                Yup.object().shape({
                    phone_no: Yup.string()
                        .nullable()
                        .default(null)
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .test(
                            'len',
                            `Phone number must be exactly ${minPhoneLength} characters`,
                            (val) =>
                                val === null ||
                                val?.replace(phoneRegex, '').length ===
                                    phoneLengthCheck,
                        )
                        .min(
                            minPhoneLength,
                            `Phone number must be at least ${minPhoneLength} characters long`,
                        )
                        .nullable(true),
                }),
            ),
        }),
    ),
}).required();

export const tspTableFieldArr = [
    {
        field: 'name',
    },
    {
        field: 'forwarder_email',
    },
    {
        field: 'forwarder_contact_info?.[0]?.name',
    },
    {
        field: 'forwarder_phone',
    },
];
