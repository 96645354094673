/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
    Col,
    Row,
    Modal,
    Form,
    Image,
    Button,
    Tab,
    Tabs,
    Badge,
    OverlayTrigger,
    Tooltip,
    Container,
} from 'react-bootstrap';

import RadioButton from '@components/RadioButton/RadioButton';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import DateSelect from '@components/DateSelect/DateSelect';
import ErrorMsg from '@components/errorMsg';
import SwitchRadioButton from '@components/SwichRadioButton/switchRadioButton';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { ROUTES } from '@constants/routes';
import {
    cancelButtonColor,
    confirmButtonColor,
    offersStatsArray,
    jobStatsArray,
    searchTimeOut,
    formatDateTOCurrent,
    deduplicateList,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { yupResolver } from '@hookform/resolvers/yup';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import '@fortawesome/pro-light-svg-icons';

import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';

import {
    checkGBLValidate,
    checkZipLookUp,
    createOffer,
    getAllDataList,
    updateOffer,
} from '@app/OffersList/functions';
import AddShipperInformation from '@app/OffersList/addShipperInformation';
import { offerFields, schema, jobSchema } from '@app/OffersList/offers.const';
import ViewNoteListPopup from '@app/JobList/OfferNotes/viewNoteListPopup';
import ViewDocumentListPopup from '@app/JobList/Documents/viewDocumentListPopup';
import JobSchedulePopup from '@app/JobList/jobSchedulePopup';
import TspAddOrEditPopup from '@app/SettingsLists/TSP/tspAddOrEditPopup';
import DocumentFilters from '../../JobList/Documents/documentFilters';
import ReactPaginate from 'react-paginate';
import SITJobTable from './SITJobTable';
import { setTimeZone } from '../../../utils/utilFunctions';
import {
    FiltersData,
    PageClickCalculation,
    PageLimitChangeCalculation,
} from '../../../constants/general.const';
import { jobsSITList } from '../../JobList/functions';
import { updateIsLogOutUser } from '../../Auth/Login/loginSlice';

const SITPopup = ({
    showPopup,
    handleClose,
    getOfferData,
    handleEditShow,
    offerId,
    editData,
    listCall,
    isDisabled,
    isAcceptable,
    isJobForm,
    title,
    handleAcceptActions,
    isRejectOffer,
    isEditable,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [resMessage, setResMessage] = useState<any>(null);
    const [filters, setFilters] = useState<any>();
    const [shipper, setShipper] = useState<any>();
    const [currentPageData, setCurrentPageData] = useState<number[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [isEditNote, setIsEditNote] = useState<any>(null);
    const [searchText, setSearchText] = useState<string | null>('');
    const [temp, setTemp] = useState<boolean>(!searchText);

    const [noteData, setNoteData] = useState<string | null>('');

    const [showNotePopup, setShowNotePopup] = useState<boolean>(false);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getSITJobsList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    useEffect(() => {
        if (showPopup) {
            getFilters();
        }
    }, [showPopup]);

    const handleNoteShow = () => {
        setShowNotePopup(true);
    };

    const handleNoteClose = () => {
        setShowNotePopup(false);
    };

    const getFilters = async () => {
        FiltersData(
            searchText,
            getSITJobsList,
            setFilters,
            'SIT_list',
            dispatch,
            navigate,
        );
    };

    const getSITJobsList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            jobsSITList({
                filters: {
                    grid: res?.grid ? res?.grid : false,
                    searchText: res?.searchText ? res?.searchText : null,
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    sortValue: res?.sortValue ? res?.sortValue : 'offeredOn',
                    sortText: res?.sortText ? res?.sortText : 'Received Date',
                    recentActivityValue: res?.recentActivityValue
                        ? res?.recentActivityValue
                        : 'current_year',
                    recentActivityText: res?.recentActivityText
                        ? res?.recentActivityText
                        : 'Current Year',
                    orderValue: res?.orderValue ? res?.orderValue : 'DESC',
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                    compact: res?.grid ? false : res?.compact,
                    filterValue: res?.filterValue ? res?.filterValue : 'none',
                    filterText: res?.filterText ? res?.filterText : 'None',
                },
                searchText: searchText ? searchText : null,
                type: res?.type ? res?.type : 'all',
                // offers_nano_id: offerDataObj?.offers_nano_id,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            console.log('payload', payload);
            setResMessage(payload?.data?.message);
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setShipper(payload?.data?.shipper_name);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getSITJobsListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getSITJobsListAndUpdateFilter(updateFilter);
    };

    const getSITJobsListAndUpdateFilter = (updateFilter: any) => {
        setFilters(updateFilter);
        getSITJobsList(updateFilter.currentPage, updateFilter);
    };

    const onClose = async () => {
        handleClose(totalDataCount);
    };
    return (
        <>
            <Modal
                show={showPopup}
                onHide={() => onClose()}
                backdrop="static"
                backdropClassName="modal-backdrop-upper"
                centered
                size="xl"
                className="offer_add_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        SIT Reports
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="mfnw__dashboard-content pt-1">
                            <Container className="mb-5">
                                <DocumentFilters
                                    setSearchText={setSearchText}
                                    searchText={searchText}
                                    isDisabled={isDisabled}
                                    setIsEditNote={setIsEditNote}
                                    handleNoteShow={handleNoteShow}
                                    onClickAddBtn={() => {
                                        setIsEditNote(null);
                                        handleNoteShow();
                                    }}
                                    totalDataCount={totalDataCount}
                                    filters={filters}
                                    handleLimitChange={handleLimitChange}
                                    listTitleText={'SIT Reports'}
                                    shipper={shipper}
                                />
                                <SITJobTable
                                    currentPageData={currentPageData}
                                    setTimeZone={setTimeZone}
                                    getSITJobsList={getSITJobsList}
                                    isDisabled={isDisabled}
                                    // showAlert={showAlert}
                                    resMessage={resMessage}
                                />
                                <div className="mt-4">
                                    {currentPageData &&
                                        currentPageData.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={'← Previous'}
                                                nextLabel={'Next →'}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={
                                                    'pagination'
                                                }
                                                previousLinkClassName={
                                                    'pagination__link'
                                                }
                                                nextLinkClassName={
                                                    'pagination__link'
                                                }
                                                disabledClassName={
                                                    'pagination__link--disabled'
                                                }
                                                activeClassName={
                                                    'pagination__link--active active'
                                                }
                                                pageRangeDisplayed={2}
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-link rounded-start"
                                                nextClassName="page-link rounded-end"
                                                forcePage={
                                                    filters?.currentPage - 1
                                                }
                                            />
                                        )}
                                </div>
                            </Container>
                        </div>
                    </>
                </Modal.Body>
            </Modal>

            {/* {showPopupShipper && (
                <AddShipperInformation
                    showPopup={showPopupShipper}
                    handleClose={handleCloseShipper}
                    shipperName={shipperName}
                    setShipperNanoId={setShipperNanoId}
                    shipperNanoId={shipperNanoId}
                    setShippersData={setShippersData}
                    shippersData={shippersData}
                    isDisabled={isDisabled}
                />
            )}
            {showPopup && (
                <TspAddOrEditPopup
                    showPopup={showAddTSPPopup}
                    handleClose={handleAddTSPClose}
                    getDataListCall={() =>
                        getAllList(searchObj2, {
                            agentIssuedOffset: false,
                            agentOffset: false,
                            forwarderOffset: false,
                        })
                    }
                    editData={null}
                />
            )}

            {showNoteListPopup && (
                <ViewNoteListPopup
                    showPopup={showNoteListPopup}
                    handleClose={handleNoteListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}

            {showHistoryListPopup && (
                <ViewHistoryListPopup
                    showPopup={showHistoryListPopup}
                    handleClose={handleHistoryListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}

            {showViewListPopup && (
                <ViewDocumentListPopup
                    showPopup={showViewListPopup}
                    handleClose={handleViewListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                />
            )}
            {showScheduleListPopup && (
                <JobSchedulePopup
                    showPopup={showScheduleListPopup}
                    handleClose={handleScheduleListClose}
                    offerDataObj={editData}
                    getOfferData={getOfferData}
                    listCall={listCall}
                    isDisabled={!showEditSchedulePopup}
                    setShowEditSchedulePopup={setShowEditSchedulePopup}
                    showEditSchedulePopup={showEditSchedulePopup}
                    isEditable={isEditable}
                />
            )}
            {showAgentPopup && (
                <AgentsAddOrEditPopup
                    showPopup={showAgentPopup}
                    handleClose={handleAgentClose}
                    getDataListCall={() =>
                        getAllList(searchObj2, {
                            agentIssuedOffset: false,
                            agentOffset: false,
                            forwarderOffset: false,
                        })
                    }
                />
            )} */}
        </>
    );
};
export default SITPopup;
