import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';

import { dateEndTimeFormat } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faNote } from '@fortawesome/pro-light-svg-icons';
import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';

import AddressDiv from '@app/OffersList/offerContentComponent/addressDiv';
import InfoDiv from '@app/OffersList/offerContentComponent/infoDiv';
import LHBoxDiv from '@app/OffersList/offerContentComponent/LHBoxDiv';
import PackHaulBoxDiv from '@app/OffersList/offerContentComponent/packHaulBoxDiv';
import StatusButtons from '@app/OffersList/offerContentComponent/StatusButtons';
import RegionsDiv from '@app/OffersList/offerContentComponent/regionsDiv';
import MainInfoDiv from '@app/OffersList/offerContentComponent/MainInfoDiv';
import { setTimeZone, dateTimeZone } from '@utils/utilFunctions';

import '@app/JobList/jobList.scss';

const ListViewJob = ({ currentPageData, handleViewShow }: any) => (
    <div className="d-flex flex-column mfpro-info">
        {currentPageData && currentPageData.length > 0
            ? currentPageData.map((job: any, index: number) => (
                  <Card
                      className="position-relative border-0 p-4 rounded-4 mt-4"
                      key={index}
                      onClick={() => handleViewShow(job?.offers_nano_id)}
                  >
                      <Row>
                          <Col md={12} lg={6} className="mb-3 mb-md-4 mb-lg-0">
                              <Row>
                                  <Col xs={12} sm={6}>
                                      <MainInfoDiv
                                          name={'SHIPPER'}
                                          value={
                                              job?.shipperdata?.shipper_name ??
                                              job?.shipper
                                          }
                                      />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                      <MainInfoDiv
                                          name={'OFFERER'}
                                          value={job?.forwarder_data?.name}
                                      />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col xs={12} sm={6}>
                                      <InfoDiv
                                          name="RECEIVED"
                                          value={` Received on${' '}
                                            ${setTimeZone(
                                                job?.offeredOn,
                                                dateEndTimeFormat,
                                            )}`}
                                      />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                      <InfoDiv
                                          name="ACCEPTED"
                                          value={dateTimeZone(job?.statusDate)}
                                      />
                                  </Col>
                              </Row>
                          </Col>
                          <Col md={12} lg={6} className="mt-0 mt-md-3">
                              <Row className="align-items-start">
                                  <Col xs={12} sm={6}>
                                      <AddressDiv
                                          icon={OriginIcon}
                                          offerAddress={job?.address_origin}
                                          addressName="ORIGIN"
                                          color="text-primary"
                                      />
                                  </Col>
                                  <Col xs={12} sm={6}>
                                      <AddressDiv
                                          icon={Destination}
                                          offerAddress={
                                              job?.address_destination
                                          }
                                          addressName="DESTINATION"
                                          color="text-lightpink"
                                      />
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                      <div className="my-0 my-md-2">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="100%"
                              height="1"
                              viewBox="0 0 1620 1"
                              fill="none"
                          >
                              <path
                                  d="M0 1H1620"
                                  stroke="#898989"
                                  strokeWidth="0.8"
                                  strokeDasharray="4 4"
                              />
                          </svg>
                      </div>
                      <Row>
                          <Col md={12} lg={12}>
                              <Row>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name="OFFER TYPE"
                                          value={job?.type ?? '-'}
                                      />
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <>
                                          {job?.type === 'COD' ? (
                                              <InfoDiv
                                                  name="B/L"
                                                  value={
                                                      job?.forwarderBillOfLaden !==
                                                      ''
                                                          ? job?.forwarderBillOfLaden
                                                          : '-'
                                                  }
                                              />
                                          ) : (
                                              <InfoDiv
                                                  name="GBL #"
                                                  value={
                                                      job?.govBillOfLaden !== ''
                                                          ? job?.govBillOfLaden
                                                          : '-'
                                                  }
                                              />
                                          )}
                                      </>
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name="PACK"
                                          value={dateTimeZone(job?.packDate)}
                                      />
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name="PICKUP"
                                          value={dateTimeZone(job?.pickupDate)}
                                      />
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name="RDD"
                                          value={dateTimeZone(
                                              job?.deliveryDate,
                                          )}
                                      />
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <div className="w-100 text-start text-lg-center">
                                          <p className="text-uppercase mb-2 text-light text-sm">
                                              STATUS
                                          </p>
                                          <StatusButtons
                                              status={job?.offerStatus}
                                          />
                                      </div>
                                  </Col>
                              </Row>
                              <Row className="mt-2 mt-sm-3 align-items-center">
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name="WEIGHT"
                                          value={job?.estimatedWeight}
                                      />
                                  </Col>
                                  <Col xs={6} sm={6} md={4} lg={2}>
                                      <InfoDiv
                                          name=" HAUL TYPE"
                                          value={
                                              job?.line_haule_data?.isSelfHaul
                                                  ? 'Line Haul'
                                                  : 'Self Haul'
                                          }
                                      />
                                  </Col>
                                  <Col
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={2}
                                      className="mt-2 mt-md-0"
                                  >
                                      <LHBoxDiv
                                          firstName="LH Disc"
                                          secondName="LH Miles"
                                          jobLhData={job?.line_haule_data}
                                      />
                                  </Col>
                                  <Col xs={12} sm={6} md={4} lg={2}>
                                      <PackHaulBoxDiv
                                          firstName="PACK($)"
                                          secondName="HAUL($)"
                                          jobLhData={job?.line_haule_data}
                                      />
                                  </Col>
                                  <Col
                                      xs={6}
                                      sm={6}
                                      md={4}
                                      lg={2}
                                      className="text-truncate mt-2 mt-md-0"
                                  >
                                      <RegionsDiv
                                          regions={job?.origin_regions}
                                          type="Origin"
                                      />
                                  </Col>
                                  <Col
                                      xs={6}
                                      sm={6}
                                      md={4}
                                      lg={2}
                                      className="text-truncate mt-2 mt-md-0"
                                  >
                                      <RegionsDiv
                                          regions={job?.destination_regions}
                                          type="Destination"
                                      />
                                  </Col>
                              </Row>
                          </Col>
                      </Row>

                      <div className="position-absolute top-0 end-0 d-flex">
                          {job?.offer_notes.length > 0 && (
                              <div
                                  className="position-relative cursor-pointer mx-3 mt-3 ps-2 pt-2"
                                  title="Notes"
                              >
                                  <FontAwesomeIcon
                                      icon={faNote}
                                      size="2xl"
                                      className="form-control-serch text-primary m-auto text-light  align-self-center"
                                  />
                                  <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                      {(job?.offer_notes).length}
                                  </Badge>
                              </div>
                          )}
                          {job?.offer_documents.length > 0 && (
                              <div
                                  className="position-relative cursor-pointer mx-3 mt-3 ps-2 pt-2"
                                  title="Documents"
                              >
                                  <FontAwesomeIcon
                                      icon={faFileLines}
                                      size="2xl"
                                      className="form-control-serch text-primary m-auto text-light  align-self-center"
                                  />

                                  <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                      {(job?.offer_documents).length}
                                  </Badge>
                              </div>
                          )}
                      </div>
                  </Card>
              ))
            : null}
    </div>
);
export default ListViewJob;
