import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';

import LogoImageUpload from '@components/LogoImageUpload/LogoImageUpload';
import ImageCrop from '@components/LogoImageUpload/ImageCrop';

import {
    searchTimeOut,
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { blobToBase64, checkFileSize } from '@utils/utilFunctions';

import { addOrganizationData } from '@app/CreateOrganization/step1/functions';
import { updateCurrentStep } from '@app/Auth/Login/loginSlice';
import { organizationDataObject } from '@app/CreateOrganization/step1/step1.const';
import {
    getUserOrganizationInformation,
    updateOrganizationData,
} from '@app/CreateOrganization/step1/functions';
import OrganizationPopUpFormField from '@app/CreateOrganization/Organizations/OrganizationList/organizationPopUpFormField';
import { ZipLookUp } from '@app/SettingsLists/lists.const';
import { schema } from '@app/CreateOrganization/Organizations/OrganizationList/organization.const';

import { IListOption } from '../../../../Interface/common';
import { IStep1Props } from '../../step1/step1.interface';

const OrganizationPopup = ({
    show,
    handleClose,
    getList,
    organizationId,
    actinType,
}: any) => {
    const dispatch = useDispatch<any>();

    const { organizationStep1Data } = useSelector((state: any) => state);
    const { isAddOrganizationData, isUpdateOrganizationData } =
        organizationStep1Data;

    const [stateArr, setStateArr] = useState<IListOption[]>([]);
    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);
    const [SCACarr, setSCACarr] = useState<string[]>(['']);
    const [SCACarrLength, setSCACarrLength] = useState<boolean>(false);
    const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
    const [profileImage, setProfileImage] = useState<string>('');
    const [organizationData, setOrganizationData] = useState(
        organizationDataObject,
    );
    const [userImage, setUserImage] = useState<any>();
    const [extension, setExtension] = useState<string>('');
    const [image, setImage] = useState<any>();

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { errors, isDirty },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: organizationData,
        mode: 'onBlur',
    });
    const fieldErrors: any = errors as FieldErrors<IStep1Props>;
    const organizationZipCode = watch('organization_zip_code');

    useEffect(() => {
        if (actinType === 'edit') {
            getUserOrganizationData();
        }
    }, []);

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getValues('organization_zip_code') &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'organization_zip_code',
                    'organization_city',
                    'organization_city_name',
                    'organization_state',
                    'organization_state_name',
                    setStateArr,
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    useEffect(() => {
        if (organizationData?.organization_scac) {
            let myArray = [];
            if (typeof organizationData?.organization_scac === 'string') {
                myArray = JSON.parse(organizationData?.organization_scac);
            } else {
                myArray = organizationData?.organization_scac;
            }
            setValue('organization_scac', myArray[0] ? myArray : ['']);
            setSCACarr([...(myArray[0] ? myArray : [''])]);
            myArray[0] ? setSCACarrLength(true) : setSCACarrLength(false);
        }
    }, [organizationData?.organization_scac]);

    const getUserOrganizationData = async () => {
        const { payload } = await dispatch(
            getUserOrganizationInformation({
                organization_nano_id: organizationId,
            }),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            reset(payload?.data?.data);
            setOrganizationData(payload?.data?.data);
            setUserImage(payload?.data?.data?.organization_logo);
            setProfileImage(payload?.data?.data?.organization_logo);
        } else {
            toast.error(payload?.message);
        }
    };

    const HandleChange = async (data: IStep1Props) => {
        var sendData = {
            ...data,
            organization_logo: profileImage ?? userImage,
            img_extension: extension,
        };
        const { payload } = await dispatch(
            actinType === 'edit'
                ? updateOrganizationData(sendData)
                : addOrganizationData(sendData),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            dispatch(updateCurrentStep(payload));
            reset(organizationDataObject);
            handleClose();
            getList();
        } else {
            toast.error(payload?.message);
        }
    };

    const handleClickImageChange = (img: any, extensionValueOnClick: any) => {
        setImage(img);
        setExtension(extensionValueOnClick);
        setCropModalOpen(true);
    };

    const saveWithCompressImage = async (blob: any) => {
        if (blob !== '') {
            new Compressor(blob, {
                quality: 0.6,
                maxHeight: 500,
                success: async (compressedResult: any) => {
                    const compressedImg: any = await blobToBase64(
                        compressedResult,
                    );
                    const isValidFileSize = checkFileSize(compressedImg);
                    if (isValidFileSize) {
                        setProfileImage(compressedImg);
                        setUserImage(compressedImg);
                    }
                },
            });
        }
    };

    const handleSave = async (imageData: any) => {
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();
        saveWithCompressImage(blob);
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };

    return (
        <>
            <Modal show={show} onHide={onClose} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        {organizationId
                            ? 'Edit Organization'
                            : 'Add New Organization'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="d-flex justify-content-center">
                        <LogoImageUpload
                            handleClickProfilePic={handleClickImageChange}
                            id="contained-button-file"
                            userImage={userImage}
                            userProfileData={organizationData}
                            dropAble={false}
                        />
                    </div>
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <OrganizationPopUpFormField
                            control={control}
                            cityArr={cityArr}
                            stateArr={stateArr}
                            setValue={setValue}
                            zipError={zipError}
                            fieldErrors={fieldErrors}
                            getValues={getValues}
                            SCACarr={SCACarr}
                            setSCACarrLength={setSCACarrLength}
                            setSCACarr={setSCACarr}
                            isAddOrganizationData={isAddOrganizationData}
                            isUpdateOrganizationData={isUpdateOrganizationData}
                            SCACarrLength={SCACarrLength}
                            onClose={onClose}
                        />
                    </Form>
                </Modal.Body>
            </Modal>
            {cropModalOpen && (
                <ImageCrop
                    open={cropModalOpen}
                    handleClose={() => setCropModalOpen(false)}
                    image={image}
                    saveCroppedFile={(imageData: any) => handleSave(imageData)}
                />
            )}
        </>
    );
};

export default OrganizationPopup;
