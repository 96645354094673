import React from 'react';
import ReactInputMask from 'react-input-mask';
import { InputGroup } from 'react-bootstrap';

import ErrorMsg from '@components/errorMsg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IPhoneInputMaskGroupProps } from './phoneInputMaskGroup.interface';

const PhoneInputMaskGroup = ({
    inputName,
    placeholder,
    errorText,
    labelcontainer,
    className,
    label,
    maskFormat,
    icon,
    min,
    inputType,
    faphoneIcon,
    setPhoneRadiusClass,
    labelShow,
    groupLabelClass,
    onChangeFunc,
    ...props
}: IPhoneInputMaskGroupProps) => (
    <>
        <InputGroup>
            {labelShow === false ? null : (
                <InputGroup.Text className={groupLabelClass}>
                    <FontAwesomeIcon
                        icon={faphoneIcon}
                        rotation={90}
                        size="xl"
                    />
                </InputGroup.Text>
            )}
            <ReactInputMask
                mask={maskFormat}
                type={inputType}
                name={inputName}
                className={`form-control ${className} ${setPhoneRadiusClass}`}
                placeholder={placeholder}
                onChange={onChangeFunc}
                min={min}
                {...props}
            />
        </InputGroup>
        <ErrorMsg errorText={errorText}></ErrorMsg>
    </>
);

export default PhoneInputMaskGroup;
