import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiLoginUser, apiLogOutUser } from '@services/API/apiFunctions';

const loginUser = createAsyncThunk(
    'loginUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiLoginUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const logOutUser = createAsyncThunk(
    'logOutUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiLogOutUser();
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { loginUser, logOutUser };
