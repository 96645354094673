import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddGroupMemberData,
    apiDeleteMemberUser,
    apiGetMembershipList,
    apiGetUsersList,
} from '@services/API/apiFunctions';

const addGroupMemberData = createAsyncThunk(
    'addGroupMemberData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddGroupMemberData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getMembershipList = createAsyncThunk(
    'getMembershipList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetMembershipList('');
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getUsersList = createAsyncThunk(
    'getUsersList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetUsersList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteUserInMembership = createAsyncThunk(
    'deleteUserInMembership',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteMemberUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    getMembershipList,
    addGroupMemberData,
    getUsersList,
    deleteUserInMembership,
};
