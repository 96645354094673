import React from 'react';

import { Form } from '@components-default';
import ErrorMsg from '@components/errorMsg';

import { ICheckBoxProps } from './checkBox.interface';

const CheckBox = ({
    name,
    errorText,
    label,
    classes,
    checked,
    disabled,
    required,
    className,
    onChangeFunc,
    ...props
}: ICheckBoxProps) => (
    <>
        <Form.Group className={className} controlId="formBasicCheckbox">
            <Form.Check
                type="checkbox"
                id={props.id}
                label={label}
                value={props.value}
                onChange={onChangeFunc}
                required={required}
                checked={checked}
                disabled={disabled}
                name={name}
                {...props}
            />
        </Form.Group>
        <ErrorMsg errorText={errorText}></ErrorMsg>
    </>
);

export default CheckBox;
