import React from 'react';

import ErrorMsg from '@components/errorMsg';

import { IRadioButtonProps } from './radioButton.interface';

import './RadioButton.scss';

const RadioButton = ({
    name,
    placeholder,
    errorText,
    label,
    classes,
    checked,
    disabled,
    classNameParentDiv,
    required,
    tabindex,
    autoFocus,
    onChangeFunc,
    statusOld,
    ...props
}: IRadioButtonProps) => (
    <>
        {statusOld ? (
            <div
                className="wms-radiobtn-component"
                onClick={() => {
                    (disabled === false || !disabled) &&
                        onChangeFunc(props?.value ?? '');
                }}
            >
                <label
                    className={
                        disabled === true
                            ? 'disabled-checkbox form-check-label '
                            : ''
                    }
                    htmlFor={props.id}
                >
                    <input
                        type="radio"
                        id={props.id}
                        name={name}
                        value={props.value}
                        className={`${classes} rt-cmn-radio-btn form-check-input`}
                        onChange={onChangeFunc}
                        required={required}
                        checked={checked}
                        autoFocus={autoFocus}
                        disabled={disabled}
                    />

                    <span className="ms-2">{label}</span>
                </label>
            </div>
        ) : (
            <div
                className={`fw-semibold form-check form-check-inline custom_radio_button ${
                    checked ? 'checked' : ''
                } ${classNameParentDiv}`}
                onClick={() => {
                    (disabled === false || !disabled) &&
                        onChangeFunc(props?.value ?? '');
                }}
            >
                <label
                    className={
                        disabled === true
                            ? 'disabled-checkbox form-check-label'
                            : ''
                    }
                    htmlFor={props.id}
                >
                    <input
                        type="radio"
                        id={props.id}
                        name={name}
                        value={props.value}
                        className={`${classes} rt-cmn-radio-btn form-check-input`}
                        onChange={onChangeFunc}
                        required={required}
                        checked={checked}
                        disabled={disabled}
                        tabIndex={tabindex}
                    />

                    <span className="ms-2">{label}</span>
                </label>
            </div>
        )}

        <ErrorMsg errorText={errorText}></ErrorMsg>
        {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
    </>
);

RadioButton.defaultProps = {
    name: '',
    placeholder: '',
    errorText: '',
    inputType: '',
    label: '',
    id: '',
    labelcontainer: '',
    className: '',
    minLength: '',
    maxLength: '',
    value: '',
    classes: '',
    checked: '',
    disabled: '',
    required: '',
    onChangeFunc: () => void 0,
};

export default RadioButton;
