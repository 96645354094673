import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addPacker,
    deletePacker,
    packersList,
    getPackerInfo,
    updatePacker,
} from './functions';

const defaultState = {
    isAddPacker: false,
    isPackersList: false,
    isUpdatePacker: false,
    isGetPackerInfo: false,
    isDeletePacker: false,
};

export const packerSlice: any = createSlice({
    name: 'packers',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addPacker.pending.type]: (state: any, { payload }) => {
            state.isAddPacker = true;
            state.registerResponse = payload;
        },
        [addPacker.fulfilled.type]: (state: any, { payload }) => {
            state.isAddPacker = false;
            state.registerResponse = payload;
        },
        [addPacker.rejected.type]: (state: any, { payload }) => {
            state.isAddPacker = false;
            state.registerResponse = payload;
        },

        [packersList.pending.type]: (state: any, { payload }) => {
            state.isPackersList = true;
            state.registerResponse = payload;
        },
        [packersList.fulfilled.type]: (state: any, { payload }) => {
            state.isPackersList = false;
            state.registerResponse = payload;
        },
        [packersList.rejected.type]: (state: any, { payload }) => {
            state.isPackersList = false;
            state.registerResponse = payload;
        },

        [updatePacker.pending.type]: (state: any, { payload }) => {
            state.isUpdatePacker = true;
            state.registerResponse = payload;
        },
        [updatePacker.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdatePacker = false;
            state.registerResponse = payload;
        },
        [updatePacker.rejected.type]: (state: any, { payload }) => {
            state.isUpdatePacker = false;
            state.registerResponse = payload;
        },

        [getPackerInfo.pending.type]: (state: any, { payload }) => {
            state.isGetPackerInfo = true;
            state.registerResponse = payload;
        },
        [getPackerInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetPackerInfo = false;
            state.registerResponse = payload;
        },
        [getPackerInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetPackerInfo = false;
            state.registerResponse = payload;
        },

        [deletePacker.pending.type]: (state: any, { payload }) => {
            state.isDeletePacker = true;
            state.registerResponse = payload;
        },
        [deletePacker.fulfilled.type]: (state: any, { payload }) => {
            state.isDeletePacker = false;
            state.registerResponse = payload;
        },
        [deletePacker.rejected.type]: (state: any, { payload }) => {
            state.isDeletePacker = false;
            state.registerResponse = payload;
        },
    },
});

export { addPacker, packersList, updatePacker, getPackerInfo, deletePacker };

export default packerSlice.reducer;
