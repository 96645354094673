import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';

const DocumentTable = ({ data }: any) => (
    <>
        <h5 className="text-center mb-4 font-weight-bold text-primary">
            {data?.affected_tab} Information (
            {data?.is_created
                ? 'Created'
                : data?.is_deleted
                ? 'Deleted'
                : 'Updated'}
            )
        </h5>
        <div className="overflow-table-auto">
            <Table size="sm" className="border table_fwnp">
                <thead>
                    <tr className="table-headings">
                        <th className="text-sm fw-semibold text-center  px-4 py-3 text-truncate">
                            Document Type
                        </th>
                        <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                            Files
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        Object.entries(data?.changed_fields).map(
                            ([index, item]: any) =>
                                item?.new && item?.old ? (
                                    <React.Fragment key={index}>
                                        {renderTableRow(item?.old, index)}
                                        <tr
                                            key={`arrow-${index}`}
                                            className="text-center"
                                        >
                                            <td colSpan={4}>
                                                <FontAwesomeIcon
                                                    icon={faArrowDown}
                                                />
                                            </td>
                                        </tr>
                                        {renderTableRow(item?.new, index)}
                                    </React.Fragment>
                                ) : (
                                    renderTableRow(item, index)
                                ),
                        )}
                </tbody>
            </Table>
        </div>
    </>
);

const renderTableRow = (item: any, index: number) => (
    <tr key={index}>
        <td className="text-base fw-normal text-center py-3 text-truncate">
            {item?.document_types}
        </td>
        <td className="text-base fw-normal text-center py-3 text-capitalize text-truncate">
            {typeof item?.file === 'object'
                ? Object.keys(item.file).map((key: any) => (
                      <div key={key}>
                          <a
                              href={item.file[key]?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary me-2 mb-2"
                          >
                              {item.file[key]?.name}
                          </a>
                          <span> ({item.file[key]?.fileSize} bytes)</span>
                      </div>
                  ))
                : ''}
        </td>
    </tr>
);

export default DocumentTable;
