import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { Modal, Form, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import {
    faCircleSmall,
    faPen,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DriverFormField from '@app/SettingsLists/Drivers/driverFormField';

const DriverViewPopup = ({
    showPopup,
    handleClose,
    data,
    actionUserById,
    handleEditShow,
}: any) => {
    useEffect(() => {
        reset(data);
    }, [data]);

    const {
        control,
        reset,
        setValue,
        getValues,
        formState: { isDirty },
    } = useForm<any>();

    const packStartRef = useRef();
    const { driverData } = useSelector((state: any) => state);
    const { isGetDriverInfo } = driverData;

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                }
            });
        } else {
            handleClose();
        }
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    View Driver
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="main-bodysection">
                {isGetDriverInfo ? (
                    <LoaderBtn />
                ) : (
                    <>
                        <Form>
                            <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                                <FontAwesomeIcon
                                    className="mx-2 cursor-pointer align-baseline m-auto"
                                    icon={faPen}
                                    onClick={() => handleEditShow()}
                                />
                                <FontAwesomeIcon
                                    className="mx-2 cursor-pointer align-baseline m-auto"
                                    onClick={() =>
                                        actionUserById(
                                            data?.drivers_nano_id,
                                            'delete',
                                        )
                                    }
                                    icon={faTrashCan}
                                />
                                {data?.is_active && data?.is_active === 1 ? (
                                    <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                                        <FontAwesomeIcon
                                            icon={faCircleSmall}
                                            className="me-2"
                                        />
                                        Enable
                                    </Badge>
                                ) : (
                                    <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                                        <FontAwesomeIcon
                                            icon={faCircleSmall}
                                            className="me-2"
                                        />
                                        Disable
                                    </Badge>
                                )}
                            </div>

                            <DriverFormField
                                control={control}
                                errors={null}
                                packStartRef={packStartRef}
                                setFilesArray2={null}
                                preview2={null}
                                setPreview2={null}
                                setValue={setValue}
                                getValues={getValues}
                                progress2={null}
                                showProgressBar2={null}
                                setFilesArray={null}
                                preview={null}
                                setPreview={null}
                                progress={null}
                                showProgressBar={null}
                                isAddDriver={false}
                                isUpdateDriver={false}
                                onClose={onClose}
                                isEditPopup={false}
                                isDisabled={true}
                            />
                        </Form>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};
export default DriverViewPopup;
