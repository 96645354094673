/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import UserListingTab from '@app/UserListing/Users/userList';
import GroupListing from '@app/UserListing/Groups/groupList';
import MembershipList from '@app/UserListing/MembershipList/membershipList';

import '@app/UserListing/userList.scss';

const UserListing = () => {
    const [selectedTab, setSelectedTab] = useState<string>('user-list');

    return (
        <div className="mfnw__dashboard-content mfnw__userlisting_content">
            <Tabs
                defaultActiveKey={selectedTab}
                onSelect={(e: any) => setSelectedTab(e)}
                className="mfnw__dashboard_linetab mb-3"
            >
                <Tab eventKey="user-list" title="USER LIST">
                    {selectedTab === 'user-list' && <UserListingTab />}
                </Tab>
                <Tab eventKey="groups" title="GROUPS">
                    {selectedTab === 'groups' && <GroupListing />}
                </Tab>
                <Tab eventKey="membership" title="MEMBERSHIP">
                    {selectedTab === 'membership' && <MembershipList />}
                </Tab>
            </Tabs>
        </div>
    );
};

export default UserListing;
