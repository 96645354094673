import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import ErrorMsg from '@components/errorMsg';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';
import { forwarderListOptionArr } from '@constants/formFieldsArray';

import { rejectOffer } from '@app/OffersList/functions';
import { responseMessage } from '@redux/app/appSlice';

const OfferRejectPopup = ({ handleClose, popup, offerId }: any) => {
    const userPassDefaultData = {
        reject_reason: '',
    };

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { offersData } = useSelector((state: any) => state);
    const { isRejectOffer } = offersData;

    const schema = Yup.object({
        reject_reason: Yup.string().required(
            'Please provide a rejection reason',
        ),
    }).required();

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: userPassDefaultData,
    });

    const changeReason = async (e: any) => {
        setValue('reject_reason', e?.value);
    };

    const HandleChange = async (data: any) => {
        if (data?.reject_reason && data?.reject_reason !== '' && offerId) {
            const { payload } = await dispatch(
                rejectOffer({ ...data, offerId: offerId }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(responseMessage(payload?.data));
                handleClose();
                navigate(routesPath?.dashboard);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    return (
        <div>
            <Modal
                show={popup}
                onHide={() => {
                    handleClose();
                    reset(userPassDefaultData);
                }}
                backdropClassName="modal-backdrop-upper"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <Modal.Body>
                        <div className="mfnw__form-fixed-popup">
                            <div className="mfnw__form-logo text-center mb-3 mb-lg-5 pt-2 pt-lg-0">
                                <h4 className=" fw-bold">Rejection Reason</h4>
                            </div>
                            <Controller
                                name="reject_reason"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <>
                                        <Select
                                            value={{
                                                value: getValues(
                                                    'reject_reason',
                                                ),
                                                label: getValues(
                                                    'reject_reason',
                                                ),
                                            }}
                                            onChange={(e: any) =>
                                                changeReason(e)
                                            }
                                            options={forwarderListOptionArr}
                                            classNames={{
                                                control: (state: any) =>
                                                    state.isFocused
                                                        ? 'border-green-600'
                                                        : '',
                                            }}
                                            isSearchable={false}
                                        />
                                        {errors?.reject_reason?.message && (
                                            <ErrorMsg
                                                errorText={
                                                    errors?.reject_reason
                                                        ?.message
                                                }
                                            ></ErrorMsg>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button
                            variant="secondary"
                            className="me-3 text-uppercase mfnw__button_small"
                            onClick={() => {
                                handleClose();
                                reset(userPassDefaultData);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={isRejectOffer}
                            className="text-uppercase mfnw__button_small"
                        >
                            {isRejectOffer ? <LoaderBtn /> : 'SAVE'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default OfferRejectPopup;
