/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';
import HistoryFilters from './historyFilters';
import {
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import HistoryTable from './historyTable';
import { toast } from 'react-toastify';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import { routesPath } from '@constants/routesNavigate';
import { jobHistoryList } from './functions';

const ViewHistoryListPopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    isDisabled,
    listCall,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [filters, setFilters] = useState<any>();
    const [shipper, setShipper] = useState<any>();
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [currentPageData, setCurrentPageData] = useState<number[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);

    const onClose = async () => {
        handleClose(totalDataCount);
    };

    const handleLimitChange = async (limit: number) => {
        let updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        setFilters(updateFilter);
        getJobHistoryList(updateFilter?.currentPage, updateFilter);
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        setFilters(updateFilter);
        getJobHistoryList(updateFilter?.currentPage, updateFilter);
    };

    const handleFilterChange = async (updateFilter: any) => {
        setFilters(updateFilter);
        getJobHistoryList(updateFilter?.currentPage, updateFilter);
    };

    const handleOrderChange = async (order: string) => {
        if (order) {
            var updateFilter = { ...filters, orderValue: order };
            getJobHistoryList(updateFilter?.currentPage, updateFilter);
        }
    };

    useEffect(() => {
        getFilters();
    }, []);

    const getFilters = async () => {
        FiltersData(
            null,
            getJobHistoryList,
            setFilters,
            'job_history_list',
            dispatch,
            navigate,
        );
    };

    const getJobHistoryList = async (currentPage: number, res: any) => {
        const newFilters = {
            perPageLimit: res?.perPageLimit || 5,
            currentPage: currentPage === 0 || !currentPage ? 1 : currentPage,
            orderValue: res?.orderValue ?? 'ASC',
        };

        const { payload } = await dispatch(
            jobHistoryList({
                filters: newFilters,
                offers_nano_id: offerDataObj?.offers_nano_id,
            }),
        );

        if (payload?.data?.message !== '' && payload?.data?.status) {
            const data = payload?.data;
            setFilters(data.filters);
            setCurrentPageData(data.data);
            setPageCount(data.meta?.last_page);
            setShipper(payload?.data?.shipper_name);
            setTotalDataCount(data.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    return (
        <Modal
            show={showPopup}
            onHide={() => onClose()}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-upper"
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Job History
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="mfnw__dashboard-content pt-1">
                        <Container className="mb-5">
                            <HistoryFilters
                                handleLimitChange={handleLimitChange}
                                handleFilterChange={handleFilterChange}
                                handleOrderChange={handleOrderChange}
                                filters={filters}
                                listTitleText={'JOB HISTORY LIST'}
                                shipper={shipper}
                                totalDataCount={totalDataCount}
                            />
                            <HistoryTable
                                currentPageData={currentPageData}
                                pageCount={pageCount}
                                filter={filters}
                                handlePageClick={handlePageClick}
                            />
                        </Container>
                    </div>
                </>
            </Modal.Body>
        </Modal>
    );
};

export default ViewHistoryListPopup;
