import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

import InputText from '@components/InputText/InputText';
import DateRangeSelect from '@components/DateRengeSelect/DateRengeSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarRange } from '@fortawesome/pro-light-svg-icons';
import DateSelect from '../../components/DateSelect/DateSelect';

const ListHeader = ({
    title,
    searchText,
    setSearchText,
    filters,
    pageLimitList,
    handleShow,
    handleLimitChange,
    buttonText,
    startDate,
    endDate,
    setDateRange,
    setYearFilter,
    yearFilter,
    year,
}: any) => (
    <div className="d-flex flex-column flex-wrap flex-lg-row justify-content-between align-items-center py-3">
        <h5 className="fw-semibold m-0">{title}</h5>
        <div className="d-flex flex-column flex-sm-row align-items-center align-items-lg-stretch gap-3 main-par mt-3 mt-lg-0">
            <div className="d-flex flex-row gap-3">
                <div className="main-searchInput position-relative">
                    <InputText
                        className="mfnw__login-form-control mfnw__search-form-control-bg mb-0"
                        id=""
                        inputName=""
                        inputType="text"
                        classNameFormGroup="mb-0"
                        placeholder="Search..."
                        errorText={''}
                        value={searchText}
                        onChange={(e: any) => setSearchText(e.target.value)}
                    />
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        color="#b7b7b7"
                        size="lg"
                        className="position-absolute form-control-serch"
                    />
                </div>
                <div className="dropdown_filter-top">
                    <label htmlFor="limit" className="text-sm ps-3 text-light">
                        Limit:
                    </label>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="limit"
                        >
                            {filters?.perPageLimit}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {pageLimitList?.map((item: any, index: number) => (
                                <Dropdown.Item
                                    active={filters?.perPageLimit === item}
                                    onClick={() => {
                                        handleLimitChange(item);
                                    }}
                                    key={index}
                                    eventKey={`#/action-${index}`}
                                >
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {year && (
                <DateSelect
                    // ref={checkInRef}
                    className="mfnw__year-control-bg"
                    inputGroupClassName="mfnw__input-date-group"
                    placeholder="YYYY"
                    selected={yearFilter}
                    dateFormat="yyyy"
                    dateFormatSet="9999"
                    inputName="year"
                    icon={faCalendarRange}
                    onChangeFunc={({ value: valueYearChange }: any) => {
                        console.log('valueYearChange', valueYearChange);
                        setYearFilter(valueYearChange);

                        // const parsedYear: any =
                        //     moment(valueYearChange).format('YYYY');
                        // console.log('Parsed Year:', parsedYear);
                        // setSelectedYear(parsedYear);
                        // onChange(parsedYear);
                    }}
                    groupInput={true}
                    // disabled={modelTitle != 'Add New Holiday'}
                    showYearPicker
                    // errorText={errors?.year?.message}
                />
            )}
            {buttonText ? (
                <Button
                    variant="primary"
                    className="btn_type_large fw-semibold button-responsive"
                    onClick={handleShow}
                >
                    {buttonText}
                </Button>
            ) : (
                <div className="d-flex">
                    <DateRangeSelect
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        groupInput={true}
                        icon={faCalendarRange}
                        inputGroupClassName="mfnw__input-date-group"
                        onChangeFunc={(update: any) => {
                            setDateRange(update);
                        }}
                        className="mfnw__date-to-form-control-bg"
                    />
                </div>
            )}
        </div>
    </div>
);

export default ListHeader;
