import React from 'react';
import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

const StepBadgeComponent = ({
    dataList,
    deleteById,
    name,
    nanoId,
    handleViewShow,
    className,
}: any) =>
    dataList?.map((item: any, index: number) => (
        <Badge bg="light" key={index} className={`${className}`}>
            <span
                className="me-3"
                onClick={() => {
                    handleViewShow && handleViewShow(item?.[nanoId]);
                }}
            >
                {item?.[name]}
            </span>

            <div
                onClick={() => deleteById(item?.[nanoId])}
                className="cursor-pointer"
            >
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    color="#b7b7b7"
                    size="lg"
                />
            </div>
        </Badge>
    ));

export default StepBadgeComponent;
