import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Image, Row, Table } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactPagination from '@components/reactPagination';

import driverNotFound from '@images/driver-not-found.svg';

import CommonActions from '@app/SettingsLists/CommonActions';

const DriverTable = ({
    currentPageData,
    handleViewShow,
    handleEditShow,
    showAlert,
    pageCount,
    handlePageClick,
    offerFilters,
}: any) => {
    const { driverData } = useSelector((state: any) => state);
    const { isDeleteDriver, isDriversList } = driverData;

    return (
        <>
            {isDriversList || isDeleteDriver ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : !isDeleteDriver &&
              currentPageData &&
              currentPageData.length !== 0 ? (
                <>
                    <div className="overflow-table-auto driver_table">
                        <Table
                            borderless
                            className="mfnw__borderless_card-table mb-0 w-100"
                        >
                            <thead>
                                <tr>
                                    <th className="driver_header_name">NAME</th>
                                    <th className="driver_header_name">
                                        EMAIL
                                    </th>
                                    <th className="driver_header_name">
                                        CONTACT
                                    </th>
                                    <th className="driver_header_name">
                                        PHONE
                                    </th>
                                    <th className="driver_header_name">
                                        DRIVER TYPE
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData.map(
                                    (item: any, index: number) => (
                                        <tr key={index}>
                                            <td
                                                onClick={() =>
                                                    handleViewShow(
                                                        item?.drivers_nano_id,
                                                    )
                                                }
                                                className="text-truncate"
                                            >{`${item?.first_name} ${item?.last_name}`}</td>
                                            <td
                                                onClick={() =>
                                                    handleViewShow(
                                                        item?.drivers_nano_id,
                                                    )
                                                }
                                                className="text-truncate"
                                            >
                                                {item?.driver_email || '(N/A)'}
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleViewShow(
                                                        item?.drivers_nano_id,
                                                    )
                                                }
                                                className="text-truncate"
                                            >
                                                {item?.driver_phone || '(N/A)'}
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleViewShow(
                                                        item?.drivers_nano_id,
                                                    )
                                                }
                                                className="text-truncate"
                                            >
                                                {item?.driver_phone || '(N/A)'}
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleViewShow(
                                                        item?.drivers_nano_id,
                                                    )
                                                }
                                                className="text-truncate"
                                            >
                                                {item?.driver_type || '(N/A)'}
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-end ">
                                                    <CommonActions
                                                        item={item}
                                                        showAlert={showAlert}
                                                        handleEditShow={
                                                            handleEditShow
                                                        }
                                                        nanoId={
                                                            item?.drivers_nano_id
                                                        }
                                                        isUserList={false}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div className="mt-3 mt-md-0">
                        <ReactPagination
                            currentPageData={currentPageData}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            filters={offerFilters}
                        />
                    </div>
                </>
            ) : (
                <>
                    {currentPageData && currentPageData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={driverNotFound} fluid />
                                <h5 className="text-light mt-4 fw-bold">
                                    I am sorry to inform you but it is highly
                                    likely that you will require the assistance
                                    of a driver.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default DriverTable;
