import React, { useEffect, useState } from 'react';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'react-bootstrap';

import ImageCrop from '@components/LogoImageUpload/ImageCrop';

import { routesPath } from '@constants/routesNavigate';
import { addUserSelectFiledProps } from '@constants/formFieldsArray';
import {
    searchTimeOut,
    cancelButtonColor,
    showDeleteConfirmationDialog,
} from '@constants/general.const';

import {
    inviteUser,
    updateInfo,
    userDataGet,
} from '@app/UserListing/Users/functions';
import { organizationDataSelectList } from '@app/CreateOrganization/step1/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import { userDataObject, userSchema } from '@app/UserListing/Users/user.const';
import UserFormFields from '@app/UserListing/Users/UserFormFields';
import { blobToBase64, checkFileSize } from '@utils/utilFunctions';

import '@app/Auth/Register/register.scss';

import {
    IListOption,
    ISelectListOptionSearchObj,
} from '../../../Interface/common';
import { IUserProps } from './user.interface';

const AddUserPopup = ({
    showPopup,
    handleCloseEvent,
    userId,
    listApiCall,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { inviteUsers, organizationStep1Data } = useSelector(
        (state: any) => state,
    );
    const { isInviteUser, isUpdateInfo } = inviteUsers;
    const { isOrganizationDataSelectList } = organizationStep1Data;

    const [userData, setUserData] = useState<any>(null);
    const [groupArr, setGroupArr] = useState<IListOption[]>([]);
    const [departmentArr, setDepartmentArr] = useState<IListOption[]>([]);
    const [officeArr, setOfficeArr] = useState<IListOption[]>([]);
    const [roleArr, setRoleArr] = useState<IListOption[]>([]);
    const [jobTitleArr, setJobTitleArr] = useState<IListOption[]>([]);
    const [searchObj, setSearchObj] =
        useState<ISelectListOptionSearchObj | null>(null);
    const [profileImage, setProfileImage] = useState<any>('');
    const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
    //  ask fore type

    const [userImage, setUserImage] = useState<any>();
    const [extension, setExtension] = useState<any>('');
    const [image, setImage] = useState<any>();

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors, isDirty },
    } = useForm<any>({
        resolver: yupResolver(userSchema),
        defaultValues: userDataObject,
        mode: 'onBlur',
    });

    useEffect(() => {
        if (userId) {
            getUserData();
        }
    }, []);

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getSelectList();
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchObj]);

    const getUserData = async () => {
        const { payload } = await dispatch(userDataGet(userId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            reset(payload?.data?.user);
            setUserData(payload?.data?.user);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getSelectList = async () => {
        const { payload } = await dispatch(
            organizationDataSelectList({ searchObj: searchObj }),
        );
        const selectListData = payload?.data;

        if (selectListData?.message !== '' && selectListData?.status) {
            const mapDataOption = (
                list: any,
                valueKey: string,
                labelKey: string,
            ) =>
                list?.map((dataOption: any) => ({
                    value: dataOption?.[valueKey],
                    label: dataOption?.[labelKey],
                })) || [];

            setOfficeArr(
                mapDataOption(
                    selectListData?.data?.officeList,
                    'office_nano_id',
                    'office_name',
                ),
            );
            setDepartmentArr(
                mapDataOption(
                    selectListData?.data?.departmentList,
                    'department_nano_id',
                    'department_name',
                ),
            );
            setGroupArr(
                mapDataOption(
                    selectListData?.data?.groupList,
                    'group_nano_id',
                    'group_name',
                ),
            );
            setRoleArr(
                mapDataOption(
                    selectListData?.data?.roleList,
                    'roles_nano_id',
                    'role_name',
                ),
            );
            setJobTitleArr(
                mapDataOption(
                    selectListData?.data?.jobTitleList,
                    'job_nano_id',
                    'job_title',
                ),
            );
        } else {
            const errorMessage = payload?.message || 'An error occurred';
            toast.error(errorMessage);

            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handleSave = async (imageData: any) => {
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();
        saveWithCompressImage(blob);
    };

    const handleClickImageChange = (img: any, extensionValueOnClick: any) => {
        setImage(img);
        setExtension(extensionValueOnClick);
        setCropModalOpen(true);
    };

    const saveWithCompressImage = async (blob: any) => {
        if (blob !== '') {
            new Compressor(blob, {
                quality: 0.6,
                maxHeight: 500,
                success: async (compressedResult: any) => {
                    const compressedImg: any = await blobToBase64(
                        compressedResult,
                    );
                    const isValidFileSize = checkFileSize(compressedImg);
                    if (isValidFileSize) {
                        setProfileImage(compressedImg);
                        setUserImage(compressedImg);
                    }
                },
            });
        }
    };

    const HandleChange = async (data: IUserProps) => {
        var sendData = {
            ...data,
            user_profile: profileImage ?? userImage,
            img_extension: extension,
        };
        const { payload } = await dispatch(
            userId
                ? updateInfo({
                      ...sendData,
                      user_id: userData?.users_nano_id,
                  })
                : inviteUser(sendData),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            handleCloseEvent();
            listApiCall();
        } else {
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }

            if (payload?.message !== '' && payload?.userError) {
                Swal.fire({
                    title: 'Error',
                    text: payload?.message || '',
                    icon: 'error',
                    cancelButtonColor: cancelButtonColor,
                });
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            const confirmationOptions = {
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                icon: 'warning',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                text: '',
            };
            const isConfirmed = await showDeleteConfirmationDialog(
                confirmationOptions,
            );
            if (isConfirmed) {
                handleCloseEvent();
            }
        } else {
            handleCloseEvent();
        }
    };

    return (
        <>
            <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Add User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <UserFormFields
                            control={control}
                            errors={errors}
                            officeArr={officeArr}
                            departmentArr={departmentArr}
                            groupArr={groupArr}
                            roleArr={roleArr}
                            jobTitleArr={jobTitleArr}
                            handleClickImageChange={handleClickImageChange}
                            userImage={userImage}
                            userData={userData}
                            addUserSelectFiledProps={addUserSelectFiledProps}
                            setSearchObj={setSearchObj}
                            setValue={setValue}
                            onClose={onClose}
                            editData={false}
                            isOrganizationDataSelectList={
                                isOrganizationDataSelectList
                            }
                            isInviteUser={isInviteUser}
                            isUpdateInfo={isUpdateInfo}
                        />
                    </Form>
                </Modal.Body>
            </Modal>
            {cropModalOpen && (
                <ImageCrop
                    open={cropModalOpen}
                    handleClose={() => setCropModalOpen(false)}
                    image={image}
                    saveCroppedFile={(imageData: any) => handleSave(imageData)}
                />
            )}
        </>
    );
};

export default AddUserPopup;
