import React, { useRef, useState } from 'react';
import { Col, Row, Button, Image } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import DateSelect from '@components/DateSelect/DateSelect';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Bin from '@images/trash-can-regular.svg';

import { holidayDataInfo, holidayTypes } from './holidays.const';
import { holidayInfo } from './holiday.interface';
import LoaderBtn from '../../../components/LoaderBtn/loaderBtn';

const HolidayFormField = ({
    control,
    isDisabled,
    errors,
    isAddHoliday,
    isUpdateHoliday,
    onClose,
    modelTitle,
    getValues,
    editData,
    setValue,
    watch,
    cityArr,
    zipError,
    setPrimary,
    deletePhone,
    addContactPhoneNo,
    RegionsData,
    handleShowRegion,
    setFilesArray,
    preview,
    setPreview,
    progress,
    showProgressBar,
    watchContactInfo,
}: any) => {
    const checkInRef = useRef();
    const [selectedYear, setSelectedYear] = useState<Date | null>(null);
    const [selectedDates, setSelectedDates] = useState<holidayInfo[]>([
        { ...holidayDataInfo },
    ]);
    var holidayYearWatch = watch('year');
    var holidayInfoWatch = watch('holiday_info');
    const handleAddDate = () => {
        const currentHolidays = getValues('holiday_info') as holidayInfo[];
        setValue('holiday_info', [...currentHolidays, { ...holidayDataInfo }]);
    };

    const handleRemoveHoliday = (index: number) => {
        const currentHolidays = [...getValues('holiday_info')];
        currentHolidays.splice(index, 1); // Remove the holiday at the specified index
        setValue('holiday_info', currentHolidays);
    };
    return (
        <>
            <Row className="justify-content-center mt-3">
                <Col md={6}>
                    <Controller
                        name="year"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            // Check and format the value for the year
                            const yearValue = value
                                ? moment(value, 'YYYY').toDate()
                                : null;
                            return (
                                <DateSelect
                                    ref={checkInRef}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    inputGroupClassName="mfnw__input-group"
                                    placeholder="YYYY"
                                    label="Year"
                                    selected={yearValue}
                                    dateFormat="yyyy"
                                    dateFormatSet="9999"
                                    inputName="year"
                                    icon={faCalendar}
                                    onChangeFunc={({
                                        value: valueYearChange,
                                    }: any) => {
                                        const parsedYear: any =
                                            moment(valueYearChange).format(
                                                'YYYY',
                                            );
                                        console.log('Parsed Year:', parsedYear);
                                        setSelectedYear(parsedYear);
                                        onChange(parsedYear);
                                    }}
                                    groupInput={true}
                                    disabled={modelTitle != 'Add New Holiday'}
                                    showYearPicker
                                    errorText={errors?.year?.message}
                                />
                            );
                        }}
                    />
                </Col>
            </Row>

            <hr />
            <Row className="justify-content-left g-lg-2">
                {holidayYearWatch &&
                    holidayInfoWatch &&
                    holidayInfoWatch?.map(
                        (holiday: holidayInfo, index: any) => (
                            <>
                                <Col md={2} key={index} className="mt-3">
                                    <Controller
                                        name={`holiday_info.${index}.date`}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <DateSelect
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                inputGroupClassName="mfnw__input-group"
                                                placeholder="MM/DD"
                                                selected={(() => {
                                                    // Add a log for value before parsing it
                                                    console.log(
                                                        'Initial value:',
                                                        value,
                                                    );
                                                    console.log(
                                                        'Initial value:',
                                                        typeof value,
                                                    );
                                                    return typeof value ===
                                                        'string'
                                                        ? moment(
                                                              value,
                                                              'YYYY-MM-DD',
                                                              true,
                                                          ).toDate()
                                                        : value instanceof Date
                                                        ? moment(value).toDate()
                                                        : moment()
                                                              .year(
                                                                  moment(
                                                                      holidayYearWatch,
                                                                  ).year(),
                                                              ) // Set to the selected year
                                                              .month(0) // Set to January
                                                              .date(1) // Set to the first day of the month
                                                              .tz(
                                                                  'America/New_York',
                                                              )
                                                              .toDate();
                                                })()}
                                                dateFormat="MMM d"
                                                dateFormatSet="aaa 99"
                                                showYearDropdown={false}
                                                inputName={`date_in_month_day_${index}`}
                                                onChangeFunc={({
                                                    value: valueMonthDayChange,
                                                }: any) => {
                                                    const formattedDate =
                                                        moment(
                                                            valueMonthDayChange,
                                                            'MM/DD',
                                                            true,
                                                        );

                                                    console.log(
                                                        'first',
                                                        valueMonthDayChange,
                                                    );
                                                    console.log(
                                                        'firs2t',
                                                        formattedDate,
                                                    );

                                                    if (
                                                        formattedDate.isValid()
                                                    ) {
                                                        // Instead of using strings, set the date and year separately
                                                        const finalDate =
                                                            moment(
                                                                formattedDate,
                                                            ) // Use the formatted month/day
                                                                .year(
                                                                    holidayYearWatch,
                                                                ) // Set the correct year
                                                                .toDate();

                                                        console.log(
                                                            'finalDate',
                                                            finalDate,
                                                        );

                                                        if (
                                                            !isNaN(
                                                                finalDate.getTime(),
                                                            )
                                                        ) {
                                                            const newDates = [
                                                                ...selectedDates,
                                                            ];
                                                            newDates[index] = {
                                                                ...newDates[
                                                                    index
                                                                ],
                                                                date: finalDate.toISOString(),
                                                            };
                                                            setSelectedDates(
                                                                newDates,
                                                            );
                                                            onChange(finalDate);
                                                        } else {
                                                            console.error(
                                                                'Invalid date format',
                                                            );
                                                        }
                                                    } else {
                                                        console.error(
                                                            'Invalid month/day format',
                                                        );
                                                    }
                                                }}
                                                disabled={isDisabled}
                                                errorText={
                                                    errors?.holiday_info?.[
                                                        index
                                                    ]?.date?.message
                                                }
                                            />
                                        )}
                                    />
                                </Col>

                                <Col md={5} className="mt-3">
                                    <Controller
                                        name={`holiday_info.${index}.name`}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <>
                                                <InputGroupText
                                                    onChangeFunc={onChange}
                                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                    value={value}
                                                    tabIndex={10}
                                                    id={`holiday_name`}
                                                    inputType={'text'}
                                                    placeholder={'Holiday Name'}
                                                    disabled={isDisabled}
                                                    groupInput={false}
                                                    errorText={
                                                        errors?.holiday_info?.[
                                                            index
                                                        ]?.name?.message
                                                    }
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Controller
                                        name={`holiday_info.${index}.type`}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <ReactSelectGroup
                                                value={holidayTypes.find(
                                                    (c: any) =>
                                                        c?.value === value,
                                                )}
                                                // labelShow={true}
                                                label={'Category'}
                                                inputClassName="mfnw__input-group"
                                                // classNamePrefix="select_control_group"
                                                options={holidayTypes}
                                                // onChangeFunc={onChange}
                                                onChangeFunc={(
                                                    valueStateTownChange: any,
                                                ) => {
                                                    setValue(
                                                        `holiday_info.${index}.type`,
                                                        valueStateTownChange?.value,
                                                    );
                                                }}
                                                name={`holiday_info.${index}.type`}
                                                tabIndex={1}
                                                className="w-100"
                                                isSearchable={false}
                                                isDisabled={isDisabled}
                                                autoFocus={true}
                                            />
                                        )}
                                    />
                                </Col>
                                {/* <Col md={1} className="mt-3">
                                    <FontAwesomeIcon
                                        className="ms-3 cursor-pointer text-iconcolor mt-3"
                                        icon={faPen}
                                        // onClick={() =>
                                        //     editNote(
                                        //         data?.document_nano_id,
                                        //         'edit',
                                        //         data,
                                        //     )
                                        // }
                                    />
                                </Col> */}
                                <Col md={1} className="mt-3">
                                    {index > 0 && (
                                        <Image
                                            src={Bin}
                                            onClick={() =>
                                                handleRemoveHoliday(index)
                                            }
                                            className="text-light ms-2 mt-3"
                                        />
                                    )}
                                </Col>
                            </>
                        ),
                    )}
                <Col md={12}>
                    {selectedYear && modelTitle == 'Add New Holiday' && (
                        <Col md={12}>
                            <div
                                className="text-primary my-3 cursor-pointer"
                                onClick={handleAddDate}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <span className="fw-semibold ms-3">
                                    Add Holiday
                                </span>
                            </div>
                        </Col>
                    )}
                </Col>
                {!isDisabled && (
                    <>
                        <Col md={6}>
                            <Button
                                variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                                type="submit"
                                tabIndex={8}
                                // disabled={isAddHoliday || isUpdateHoliday}
                            >
                                {isAddHoliday || isUpdateHoliday ? (
                                    <LoaderBtn />
                                ) : (
                                    'SAVE'
                                )}
                            </Button>
                        </Col>
                        <Col md={6}>
                            <Button
                                className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                                onClick={() => onClose()}
                                tabIndex={9}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};

export default HolidayFormField;
