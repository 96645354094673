import React from 'react';

const LHBoxDiv = ({
    firstName,
    secondName,
    jobLhData,
}: {
    firstName: string;
    secondName: string;
    jobLhData: any;
}) => (
    <div className="division-section mb-2 mb-sm-0">
        <div className="d-flex flex-wrap gap-2">
            <div className="box-table text-center">
                <span className="text-light text-base">{firstName}</span>
                <p className="mb-0 text-base fw-bold text-dark">
                    {jobLhData?.discount}%
                </p>
            </div>
            <div className="box-table text-center">
                <span className="text-light text-base">{secondName}</span>
                <p className="mb-0 text-base fw-bold text-dark">
                    {jobLhData?.miles}
                </p>
            </div>
        </div>
    </div>
);

export default LHBoxDiv;
