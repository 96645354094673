import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddRoleData,
    apiDeleteRole,
    apiGetRoleDataList,
} from '@services/API/apiFunctions';

const getRoleDataList = createAsyncThunk(
    'getRoleDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetRoleDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addRoleData = createAsyncThunk(
    'addRoleData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddRoleData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteRole = createAsyncThunk(
    'deleteRole',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteRole(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { getRoleDataList, addRoleData, deleteRole };
