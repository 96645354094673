import React from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row, Badge, Button } from 'react-bootstrap';

import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';

import { contactPhoneNoTypes, phoneFormat } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/fontawesome-pro-regular';

const faphoneIcon = faPhone as IconProp;

const PackerContactInfo = ({
    packerId,
    editData,
    getValues,
    control,
    setValue,
    isDisabled,
    errors,
    setPrimary,
    deletePhone,
    addContactPhoneNo,
    watchContactInfo,
}: any) => (
    <>
        {(packerId !== '' && editData?.contact_info?.length > 0
            ? watchContactInfo &&
              getValues(`phone_number.${0}.type`) !== '' &&
              getValues(`phone_number.${0}.phone_no`) !== ''
            : watchContactInfo && getValues(`phone_number.${0}.type`) !== '') &&
            getValues('phone_number')?.map((data: any, index: number) => (
                <Row key={index} className="align-items-center gx-3 px-2">
                    <Col md={8} className="d-flex">
                        <div className="mt-3 min-w-120">
                            <Controller
                                name={`phone_number.${index}.type`}
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <ReactSelectGroup
                                        value={contactPhoneNoTypes.find(
                                            (c: any) => c?.value === value,
                                        )}
                                        labelShow={false}
                                        setBorderRadiusClass="mfnw__borderless_select"
                                        inputClassName="mfnw__input-group"
                                        options={contactPhoneNoTypes}
                                        onChangeFunc={(
                                            valueStateTownChange: any,
                                        ) => {
                                            setValue(
                                                `phone_number.${index}.type`,
                                                valueStateTownChange?.value,
                                            );
                                        }}
                                        tabIndex={4}
                                        className="w-100"
                                        isSearchable={false}
                                        isDisabled={isDisabled}
                                    />
                                )}
                            />
                        </div>
                        <div className="mt-3">
                            <Controller
                                name={`phone_number.${index}.phone_no`}
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <PhoneInputMaskGroup
                                        maskFormat={phoneFormat}
                                        inputName="organization_phone"
                                        inputType="text"
                                        label="Phone No."
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                        onChangeFunc={onChange}
                                        id="organization_phone"
                                        aria-describedby="phone"
                                        tabIndex={4}
                                        faphoneIcon={faphoneIcon}
                                        value={value}
                                        labelShow={false}
                                        setPhoneRadiusClass="mnfw_border-left"
                                        disabled={isDisabled}
                                        placeholder="Phone"
                                        errorText={
                                            errors?.phone_number?.[index]
                                                ?.phone_no?.message
                                        }
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={3} className="text-center">
                        {getValues('phone_number')?.[index]?.is_default ===
                        '1' ? (
                            <div className="mt-3">
                                <Badge
                                    className="badge bg-success text-base align-text-bottom px-3 py-2 cursor-pointer"
                                    onClick={() => {
                                        !isDisabled && setPrimary(index);
                                    }}
                                >
                                    PRIMARY
                                </Badge>
                            </div>
                        ) : (
                            <div className="mt-3">
                                <Badge
                                    className="badge bg-disable text-base align-text-bottom px-3 py-2 cursor-pointer"
                                    onClick={() => {
                                        !isDisabled && setPrimary(index);
                                    }}
                                >
                                    PRIMARY
                                </Badge>
                            </div>
                        )}
                    </Col>
                    {!isDisabled && (
                        <Col md={1}>
                            <div className="mt-3">
                                {index !== 0 && (
                                    <Button
                                        variant="default"
                                        className="p-2 rounded-3 py-1"
                                        onClick={() => deletePhone(index)}
                                    >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </Button>
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            ))}
        {!isDisabled && (
            <Col md={12}>
                <div
                    className="text-primary my-3 cursor-pointer"
                    onClick={() => addContactPhoneNo()}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="fw-semibold ms-3">
                        Add more phone numbers
                    </span>
                </div>
            </Col>
        )}
    </>
);

export default PackerContactInfo;
