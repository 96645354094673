import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddHoliday,
    apiDeleteHoliday,
    apiHolidayData,
    apiHolidaysList,
    apiUpdateHoliday,
} from '@services/API/apiFunctions';

const addHoliday = createAsyncThunk(
    'addHoliday',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddHoliday(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const holidaysList = createAsyncThunk(
    'holidaysList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiHolidaysList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateHoliday = createAsyncThunk(
    'updateHoliday',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateHoliday(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getHolidayInfo = createAsyncThunk(
    'getHolidayInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiHolidayData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteHoliday = createAsyncThunk(
    'deleteHoliday',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteHoliday(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    addHoliday,
    holidaysList,
    updateHoliday,
    getHolidayInfo,
    deleteHoliday,
};
