import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddRegionData,
    apiDeleteRegion,
    apiGetRegionDataList,
    apiGetRegionList,
    apiRegionData,
    apiUpdateRegion,
    apiGetCityStateInfo,
    apiAddRegion,
} from '@services/API/apiFunctions';

const getRegionDataList = createAsyncThunk(
    'getRegionDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetRegionDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getRegionList = createAsyncThunk(
    'getRegionDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetRegionList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getRegionInfo = createAsyncThunk(
    'getRegionInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiRegionData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addRegionData = createAsyncThunk(
    'addRegionData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddRegionData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addRegion = createAsyncThunk(
    'addRegion',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddRegion(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteRegion = createAsyncThunk(
    'deleteRegion',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteRegion(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateRegion = createAsyncThunk(
    'updateRegion',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateRegion(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getCityStateInfo = createAsyncThunk(
    'getCityStateInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetCityStateInfo(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    getRegionDataList,
    addRegionData,
    deleteRegion,
    getRegionList,
    getRegionInfo,
    updateRegion,
    getCityStateInfo,
    addRegion,
};
