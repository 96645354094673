import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Badge, Image, Row, Col } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    showDeleteConfirmationDialog,
    pageLimitList,
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import packerNotFound from '@images/packer-not-found.svg';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import PackersAddPopup from '@app/SettingsLists/Packers/packersAddPopup';
import SelectRegionPopup from '@app/SettingsLists/Packers/selectRegionPopup';
import {
    deletePacker,
    getPackerInfo,
    packersList,
} from '@app/SettingsLists/Packers/functions';
import ListHeader from '@app/SettingsLists/listHeader';
import PackerTable from '@app/SettingsLists/Packers/packerTable';
import { clearResponse } from '@redux/app/appSlice';
import { confimationDialogBoxText } from '../lists.const';
import { IListOption } from '../../../Interface/common';

const PackersList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, packersData } = useSelector((state: any) => state);
    const { response } = common;
    const { isDeletePacker, isPackersList } = packersData;

    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [packerData, setPackerData] = useState<any>(null);
    const [pageCount, setPageCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [filters, setFilters] = useState<any>();
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showPopupRegion, setShowPopupRegion] = useState<boolean>(false);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [regionList, setRegionList] = useState<IListOption[]>([]);
    const [regionListValue, setRegionListValue] = useState<IListOption[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getPackersList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getFilters = async () => {
        FiltersData(
            searchText,
            getPackersList,
            setFilters,
            'packer_list',
            dispatch,
            navigate,
        );
    };

    const getPackersList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            packersList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const getPackerData = async (packersId: string) => {
        const { payload } = await dispatch(getPackerInfo(packersId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setPackerData(payload?.data?.data);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.data?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getPackersListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getPackersListAndUpdateFilter(updateFilter);
    };

    const getPackersListAndUpdateFilter = (updateFilter: any) => {
        getPackersList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };

    const showAlert = async (id: any, type: string) => {
        const confimaBoxText = confimationDialogBoxText(type);

        const confirmationOptions = {
            title: confimaBoxText?.swalTitle,
            text: confimaBoxText?.swalText,
            icon: 'warning',
            confirmButtonText: confimaBoxText?.confirmButtonText,
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(
                deletePacker({ id, type: type }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                handleViewClose();
                getPackersList(1, filters);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    const handleEditShow = (nanoId: string) => {
        handleViewClose();
        getPackerData(nanoId);
        setShowEditPopup(true);
    };

    const handleShow = () => setShowPopup(true);
    const handleClose = () => {
        setShowPopup(false);
        setRegionList([]);
        setRegionListValue([]);
    };

    const handleViewShow = (nanoId: string) => {
        setShowViewPopup(true);
        setIsDisabled(true);
        getPackerData(nanoId);
    };

    const handleViewClose = () => {
        setShowViewPopup(false);
        setIsDisabled(false);
        setRegionList([]);
        setRegionListValue([]);
        setPackerData(null);
    };

    const handleEditClose = () => {
        setShowEditPopup(false);
        setRegionList([]);
        setRegionListValue([]);
        setPackerData(null);
    };

    const handleShowRegion = () => {
        setShowPopupRegion(true);
    };

    const handleCloseRegion = () => {
        setShowPopupRegion(false);
    };

    const handleChildData = (dataFromChild: any) => {
        setRegionList(dataFromChild?.region_state);
    };

    const handleRemoveValue = (valueToRemove: any) => {
        const updatedRegionList = regionList.filter(
            (item) => item.value !== valueToRemove,
        );
        setRegionList(updatedRegionList);
        setRegionListValue(updatedRegionList);
    };

    const RegionsData = useMemo(() => {
        setRegionListValue([...regionList]);
        return (
            regionList &&
            regionList.map((item: any, index: number) => (
                <Badge
                    key={index}
                    className="mnfw__badge_light border mt-3 lh-base text-dark py-2 fs-6 rounded-3 fw-semibold d-flex align-align-items-center bg-white border-opacity-25"
                >
                    <span className="me-3">{item?.label}</span>

                    {!isDisabled && (
                        <div className="cursor-pointer">
                            <FontAwesomeIcon
                                icon={faCircleXmark}
                                color="#b7b7b7"
                                size="lg"
                                onClick={() => handleRemoveValue(item.value)}
                            />
                        </div>
                    )}
                </Badge>
            ))
        );
    }, [regionList]);

    return (
        <div>
            <ListHeader
                title={'Packer List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShow}
                handleLimitChange={handleLimitChange}
                buttonText={'Add New Packer'}
            />
            {isPackersList ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : !isDeletePacker &&
              currentPageData &&
              currentPageData.length !== 0 ? (
                <>
                    <section className="overflow-table-auto">
                        <PackerTable
                            currentPageData={currentPageData}
                            handleViewShow={handleViewShow}
                            showAlert={showAlert}
                            handleEditShow={handleEditShow}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            filters={filters}
                        />
                    </section>
                </>
            ) : (
                <>
                    {currentPageData && currentPageData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={packerNotFound} fluid />
                                <h5 className="text-light mt-4 fw-bold">
                                    Let&rsquo;s get packing! Add at least one
                                    packer.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {showPopup && (
                <PackersAddPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    handleShowRegion={handleShowRegion}
                    getDataListCall={() => getPackersList(1, getFilters)}
                    RegionsData={RegionsData}
                    regionListValue={regionListValue}
                    isDisabled={isDisabled}
                    modelTitle={'Add New Packer'}
                />
            )}

            {showEditPopup && packerData && (
                <PackersAddPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    handleShowRegion={handleShowRegion}
                    getDataListCall={() => getPackersList(1, getFilters)}
                    editData={packerData}
                    setRegionList={setRegionList}
                    regionList={regionList}
                    RegionsData={RegionsData}
                    regionListValue={regionListValue}
                    isDisabled={isDisabled}
                    modelTitle={'Edit Packer'}
                />
            )}

            {showPopupRegion && (
                <SelectRegionPopup
                    showPopupRegion={showPopupRegion}
                    handleCloseRegion={handleCloseRegion}
                    handleChildData={handleChildData}
                    regionList={regionList}
                    setRegionList={setRegionList}
                />
            )}

            {showViewPopup && (
                <PackersAddPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    getDataListCall={() => getPackersList(1, getFilters)}
                    editData={packerData}
                    setRegionList={setRegionList}
                    regionList={regionList}
                    RegionsData={RegionsData}
                    regionListValue={regionListValue}
                    isDisabled={isDisabled}
                    modelTitle={'View Packer'}
                    handleEditShow={handleEditShow}
                    showAlert={showAlert}
                />
            )}
        </div>
    );
};

export default PackersList;
