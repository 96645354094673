import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form, Col, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    searchTimeOut,
    showDeleteConfirmationDialog,
} from '@constants/general.const';
import { ZipLookUp } from '@app/SettingsLists/lists.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';

import {
    agentDefaultData,
    schema,
} from '@app/SettingsLists/Agents/agent.const';
import {
    addAgent,
    updateAgent,
} from '@app/SettingsLists/Agents/agent.function';
import AgentFormField from '@app/SettingsLists/Agents/agentFormField';

import { IAgentFieldsProps, IContactInfoProps } from './agent.interface';
import { IListOption } from '../../../Interface/common';

const AgentsAddOrEditPopup = ({
    showPopup,
    handleClose,
    editData,
    getDataListCall,
}: any) => {
    const dispatch = useDispatch<any>();
    const { agentData } = useSelector((state: any) => state);
    const { isGetAgentInfo } = agentData;
    const agentId = editData?.agent_nano_id;

    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);
    const [showInput, setShowInput] = useState<boolean>(true);

    const {
        handleSubmit,
        control,
        reset,
        watch,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm<IAgentFieldsProps>({
        resolver: yupResolver(schema),
        defaultValues: agentDefaultData,
    });

    const organizationZipCode = watch('agent_zip_code');

    const watchContactInfo = watch('contact_info');

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            getValues('agent_zip_code') &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'agent_zip_code',
                    'agent_city',
                    'agent_city_id',
                    'agent_state',
                    'agent_state_name',
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    useEffect(() => {
        if (editData) {
            reset(editData);
            setValue('agent_city', editData?.agent_city);
            if (
                editData &&
                editData.contact_info &&
                editData.contact_info.length > 0
            ) {
                const updatedContactInfo = editData.contact_info.map(
                    (contact: IContactInfoProps) => {
                        const newPhoneNumber = contact?.phone_number?.map(
                            (number: any) => ({
                                ...number,
                                type:
                                    number?.phoneNumberType === 'work'
                                        ? 'Work'
                                        : 'Mobile',
                                phone_no: number?.phone_number,
                                is_default:
                                    number?.is_default === 1 ? '1' : '0',
                            }),
                        );
                        const updatedPhoneArray = newPhoneNumber;

                        return {
                            ...contact,
                            phone_number: updatedPhoneArray,
                        };
                    },
                );
                setValue('contact_info', updatedContactInfo);
            }
        }
    }, [editData]);

    const HandleChange = async (data: IAgentFieldsProps) => {
        let newData = { ...data };
        if (agentId) {
            if (getValues('agent_type') !== 'both') {
                newData = {
                    ...newData,
                    agent_address: '',
                    agent_city: '',
                    agent_city_id: '',
                    agent_state: '',
                    agent_state_name: '',
                    agent_zip_code: '',
                    agent_email: '',
                    agent_phone: '',
                    agent_fax: '',
                };
            }
        }

        const { payload } = await dispatch(
            agentId ? updateAgent({ ...newData, id: agentId }) : addAgent(data),
        );

        const { message, status } = payload?.data || {};

        if (message && status) {
            getDataListCall();
            handleClose();
            setShowInput(true);
            reset(agentDefaultData);
        } else {
            if (payload?.message !== '') {
                Swal.fire({
                    title: 'Error',
                    text: payload?.message || '',
                    icon: 'error',
                    cancelButtonColor: cancelButtonColor,
                });
            }
            toast.error(message);
        }
    };

    const isActiveWatch = watch('is_active');

    const onClose = async () => {
        if (isDirty) {
            const confirmationOptions = {
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                icon: 'warning',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            };
            const isConfirmed = await showDeleteConfirmationDialog(
                confirmationOptions,
            );

            if (isConfirmed) {
                handleClose();
                reset(agentDefaultData);
                setCityArr([]);
            }
        } else {
            handleClose();
            reset(agentDefaultData);
            setCityArr([]);
        }
    };

    const enableAgent = async () => {
        const isActive = getValues('is_active');
        setValue('is_active', isActive === 0 ? 1 : 0);
    };

    const popUpHeader = useMemo(
        () =>
            isActiveWatch && isActiveWatch === 1 ? (
                <Col md={12} className="d-flex justify-content-end">
                    <Badge
                        className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3"
                        onClick={enableAgent}
                    >
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                        />
                        Enabled
                    </Badge>
                </Col>
            ) : (
                <Col md={12} className="d-flex justify-content-end">
                    <Badge
                        className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3"
                        onClick={enableAgent}
                    >
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                            onClick={enableAgent}
                        />
                        Disable
                    </Badge>
                </Col>
            ),
        [isActiveWatch],
    );

    return (
        <Modal
            show={showPopup}
            onHide={onClose}
            backdrop="static"
            backdropClassName="modal-backdrop-upper"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {agentId ? 'Edit Agent' : 'Add New Agent'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {isGetAgentInfo ? (
                    <LoaderBtn />
                ) : (
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <AgentFormField
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            setShowInput={setShowInput}
                            showInput={showInput}
                            errors={errors}
                            cityArr={cityArr}
                            zipError={zipError}
                            agentId={agentId}
                            onClose={onClose}
                            watchContactInfo={watchContactInfo}
                            popUpHeader={popUpHeader}
                        />
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AgentsAddOrEditPopup;
