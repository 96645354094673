import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs, Row, Col, Badge } from 'react-bootstrap';

import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';
import {
    faPen,
    faTrashCan,
    faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';

import HeaderTopContent from '@app/AppLayout/HeaderTopContent';
import OfficeTabList from '@app/CreateOrganization/Organizations/officeTabList';
import DepartmentList from '@app/CreateOrganization/Organizations/departmentList';
import RolesList from '@app/CreateOrganization/Organizations/rolesList';
import JobTitleList from '@app/CreateOrganization/Organizations/jobTitleList';
import RegionsList from '@app/CreateOrganization/Organizations/regionsList';
import OrganizationPopup from '@app/CreateOrganization/Organizations/OrganizationList/organizationPopup';
import WorkFlow from '@app/CreateOrganization/Organizations/workFlow';

import './organization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;

const Organizations = () => {
    const { organizationId } = useParams();

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>('office');

    const handleShow = () => setShowPopup(true);
    const handleClose = () => setShowPopup(false);

    return (
        <div className="mfnw__dashboard-content">
            <section>
                <Row>
                    <Col md={12}>
                        <Link to={routesPath.organizations}>
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Organization
                        </Link>
                    </Col>
                    <Col md={12} className="my-4">
                        <div className="bg-white rounded-4 p-4 d-flex justify-content-between">
                            <div>
                                <HeaderTopContent
                                    organizationId={organizationId}
                                    showPopup={showPopup}
                                />
                            </div>
                            <div className="right_content">
                                <FontAwesomeIcon
                                    className="mx-2 cursor-pointer align-baseline"
                                    icon={faPen}
                                    onClick={() => handleShow()}
                                />
                                <FontAwesomeIcon
                                    className="mx-2 cursor-pointer align-baseline"
                                    icon={faTrashCan}
                                />
                                <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                                    <FontAwesomeIcon
                                        icon={faCircleSmall}
                                        className="me-2"
                                    />
                                    Active
                                </Badge>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <Tabs
                defaultActiveKey={selectedTab}
                onSelect={(e: any) => setSelectedTab(e)}
                className="mfnw__dashboard_linetab mb-3"
            >
                <Tab eventKey="office" title="Offices">
                    {selectedTab === 'office' && (
                        <OfficeTabList organizationId={organizationId} />
                    )}
                </Tab>

                <Tab eventKey="departments" title="DEPARTMENTS">
                    {selectedTab === 'departments' && (
                        <DepartmentList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="roles" title="ROLES">
                    {selectedTab === 'roles' && (
                        <RolesList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="job-title" title="JOB TITLES">
                    {selectedTab === 'job-title' && (
                        <JobTitleList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="regions" title="REGIONS">
                    {selectedTab === 'regions' && (
                        <RegionsList organizationId={organizationId} />
                    )}
                </Tab>
                <Tab eventKey="workflow" title="WORKFLOW">
                    {selectedTab === 'workflow' && <WorkFlow />}
                </Tab>
            </Tabs>
            {showPopup && (
                <OrganizationPopup
                    show={showPopup}
                    handleClose={handleClose}
                    getList={() => null}
                    actinType={'edit'}
                    organizationId={organizationId}
                />
            )}
        </div>
    );
};
export default Organizations;
