import { AxiosResponse } from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddAgent,
    apiDeleteAgent,
    apiAgentData,
    apiAgentList,
    apiUpdateAgent,
} from '@services/API/apiFunctions';

import { IAgentFieldsProps } from './agent.interface';

const addAgent = createAsyncThunk(
    'addAgent',
    async (payload: IAgentFieldsProps, { rejectWithValue }) => {
        try {
            const data: AxiosResponse<any, any> = await apiAddAgent(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const agentList = createAsyncThunk(
    'agentList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAgentList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateAgent = createAsyncThunk(
    'updateAgent',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateAgent(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getAgentInfo = createAsyncThunk(
    'getAgentInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAgentData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteAgent = createAsyncThunk(
    'deleteAgent',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteAgent(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { addAgent, agentList, updateAgent, getAgentInfo, deleteAgent };
