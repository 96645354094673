import React, { useState } from 'react';

import JobTabCalender from '@app/Schedule/JobCalender/jobTabCalender';

const WorkSit = () => {
    //  ask fore type

    const [viewName, setViewName] = useState<string>('Weekly SIT View');

    return (
        <div className="px-4 mt-5">
            <JobTabCalender
                type={'sit'}
                setViewName={setViewName}
                viewName={viewName}
            />
        </div>
    );
};

export default WorkSit;
