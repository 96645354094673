/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Row,
    Modal,
    ListGroup,
    Form,
    FormLabel,
} from 'react-bootstrap';

import InputText from '@components/InputText/InputText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faPlus } from '@fortawesome/fontawesome-pro-regular';
import {
    faPen,
    faTrashCan,
    faCircleSmall,
} from '@fortawesome/pro-solid-svg-icons';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const faPlusIcon = faPlus as IconProp;
const FinalStep: React.FC = () => {
    //  ask fore type
    const [showEventPopup, setShowEvent] = useState<boolean>(false);

    const handleCloseEvent = () => setShowEvent(false);
    const handleShowEvent = () => setShowEvent(true);

    return (
        <section className="mfnw_create_organization mfnw_create_step5">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Workflow
                        </Link>
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="g-0">
                                            <Col md={12}>
                                                <span className="text-sm">
                                                    WORKFLOW DETAILS
                                                </span>
                                                <Row>
                                                    <Col md={6}>
                                                        <InputText
                                                            onChangeFunc={''}
                                                            value={
                                                                'Version 1.0'
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg my-3"
                                                            id=""
                                                            inputName=""
                                                            tabIndex="1"
                                                            inputType="text"
                                                            placeholder=""
                                                            errorText={''}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputText
                                                            onChangeFunc={''}
                                                            value={
                                                                'Employee Onboarding'
                                                            }
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg my-3"
                                                            id=""
                                                            inputName=""
                                                            tabIndex="1"
                                                            inputType="text"
                                                            placeholder=""
                                                            errorText={''}
                                                        />
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg mb-3"
                                                            value={
                                                                'Sets up account for new Employees.'
                                                            }
                                                            rows={3}
                                                        />
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg mb-3"
                                                        >
                                                            <option>
                                                                Workflow Type:
                                                                Onboarding
                                                            </option>
                                                            <option value="1">
                                                                One
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg mb-3"
                                                        >
                                                            <option>
                                                                Requesters:
                                                                Onboarding Team,
                                                                Human Res…
                                                            </option>
                                                            <option value="1">
                                                                One
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            className="mfnw__login-form-control mfnw__login-form-control-bg mb-3"
                                                        >
                                                            <option>
                                                                Approvers:
                                                                Managers, VPs
                                                            </option>
                                                            <option value="1">
                                                                One
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="mb-0 text-sm">
                                                            When
                                                            <span className="text-pink fw-semibold">
                                                                [Status]
                                                            </span>
                                                            is
                                                            <span className="text-warning fw-semibold">
                                                                [Created]
                                                            </span>
                                                            and changes to
                                                            <span className="text-pink fw-semibold">
                                                                [Submitted]
                                                            </span>
                                                            , notify
                                                            <span className="text-primary fw-semibold">
                                                                [Approver]
                                                            </span>
                                                        </p>
                                                        <p className="mb-0 text-sm">
                                                            When
                                                            <span className="text-pink fw-semibold">
                                                                [Status]
                                                            </span>
                                                            is
                                                            <span className="text-warning fw-semibold">
                                                                [Created]
                                                            </span>
                                                            and changes to
                                                            <span className="text-pink fw-semibold">
                                                                [Submitted]
                                                            </span>
                                                            , notify
                                                            <span className="text-primary fw-semibold">
                                                                [Approver]
                                                            </span>
                                                        </p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p className="mb-0 text-sm">
                                                            When
                                                            <span className="text-pink fw-semibold">
                                                                [Status]
                                                            </span>
                                                            is
                                                            <span className="text-warning fw-semibold">
                                                                [Created]
                                                            </span>
                                                            and changes to
                                                            <span className="text-pink fw-semibold">
                                                                [Submitted]
                                                            </span>
                                                            , notify
                                                            <span className="text-primary fw-semibold">
                                                                [Approver]
                                                            </span>
                                                        </p>
                                                        <p className="mb-0 text-sm">
                                                            When
                                                            <span className="text-pink fw-semibold">
                                                                [Status]
                                                            </span>
                                                            is
                                                            <span className="text-warning fw-semibold">
                                                                [Created]
                                                            </span>
                                                            and changes to
                                                            <span className="text-pink fw-semibold">
                                                                [Submitted]
                                                            </span>
                                                            , notify
                                                            <span className="text-primary fw-semibold">
                                                                [Approver]
                                                            </span>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12} className="mt-5">
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-sm">
                                                        WORKFLOW EVENT
                                                    </span>
                                                    <Link
                                                        to="#"
                                                        className="text-primary text-sm"
                                                        onClick={
                                                            handleShowEvent
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlusIcon}
                                                        />
                                                        Add New Event
                                                    </Link>
                                                </div>
                                                <ListGroup className="mt-3">
                                                    <ListGroup.Item>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0 text-sm">
                                                                When
                                                                <span className="text-pink fw-semibold">
                                                                    [Status]
                                                                </span>
                                                                is
                                                                <span className="text-warning fw-semibold">
                                                                    [Created]
                                                                </span>
                                                                and changes to
                                                                <span className="text-pink fw-semibold">
                                                                    [Submitted]
                                                                </span>
                                                                , notify
                                                                <span className="text-primary fw-semibold">
                                                                    [Approver]
                                                                </span>
                                                            </p>
                                                            <div className="right_content">
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={faPen}
                                                                />
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={
                                                                        faTrashCan
                                                                    }
                                                                />
                                                                <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCircleSmall
                                                                        }
                                                                    />
                                                                    Active
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0 text-sm">
                                                                When
                                                                <span className="text-pink fw-semibold">
                                                                    [Status]
                                                                </span>
                                                                is
                                                                <span className="text-warning fw-semibold">
                                                                    [Created]
                                                                </span>
                                                                and changes to
                                                                <span className="text-danger fw-semibold">
                                                                    [Withdrawn]
                                                                </span>
                                                                , notify
                                                                <span className="text-primary fw-semibold">
                                                                    [Approver]
                                                                </span>
                                                            </p>
                                                            <div className="right_content">
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={faPen}
                                                                />
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={
                                                                        faTrashCan
                                                                    }
                                                                />
                                                                <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCircleSmall
                                                                        }
                                                                    />
                                                                    Active
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="mb-0 text-sm">
                                                                When
                                                                <span className="text-pink fw-semibold">
                                                                    [Status]
                                                                </span>
                                                                is
                                                                <span className="text-warning fw-semibold">
                                                                    [Created]
                                                                </span>
                                                                and changes to
                                                                <span className="text-pink fw-semibold">
                                                                    [Submitted]
                                                                </span>
                                                                , notify
                                                                <span className="text-primary fw-semibold">
                                                                    [Approver]
                                                                </span>
                                                            </p>
                                                            <div className="right_content">
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={faPen}
                                                                />
                                                                <FontAwesomeIcon
                                                                    className="mx-2 cursor-pointer"
                                                                    icon={
                                                                        faTrashCan
                                                                    }
                                                                />
                                                                <Badge className="badge bg-success align-text-bottom ms-2 px-3">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCircleSmall
                                                                        }
                                                                    />
                                                                    Active
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                            <Col md={12} className="mt-5">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-sm">
                                                        WORKFLOW ACTIVATION
                                                    </span>

                                                    <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Basic radio toggle button group"
                                                    >
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="btnradio"
                                                            id="btnradio1"
                                                            checked
                                                        />
                                                        <FormLabel
                                                            className="btn btn-outline-success btn__radio-success"
                                                            for="btnradio1"
                                                        >
                                                            No
                                                        </FormLabel>

                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="btnradio"
                                                            id="btnradio2"
                                                        />
                                                        <FormLabel
                                                            className="btn btn-outline-success btn__radio-success"
                                                            for="btnradio2"
                                                        >
                                                            Yes
                                                        </FormLabel>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6} className="mt-5">
                                                <Button
                                                    variant="default"
                                                    className="text-uppercase button__details"
                                                >
                                                    Back
                                                </Button>
                                            </Col>
                                            <Col md={6} className="mt-5">
                                                <Button
                                                    variant="primary"
                                                    className="text-uppercase button__details"
                                                >
                                                    Continue
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal
                show={showEventPopup}
                onHide={handleCloseEvent}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Create New Event
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Row className="justify-content-center">
                        <Col md={12} className="my-3">
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>Flow: Notify when status change</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={6}>
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>From: Created</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={6}>
                            <Form.Select
                                aria-label="Default select example"
                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                            >
                                <option>To: Submited</option>
                                <option value="1">One</option>
                            </Form.Select>
                        </Col>
                        <Col md={12}>
                            <Button variant="primary w-100 mt-5 text-uppercase fw-semibold">
                                Save
                            </Button>
                            <Button className="btn-cancel mt-3 w-100 text-uppercase fw-semibold">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    );
};
export default FinalStep;
