/* eslint-disable max-lines-per-function */
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import UserOrganizationListTab from '@app/CreateOrganization/Organizations/OrganizationList/userOrganizationListTab';

import '../organization.scss';

const UserOrganization = () => (
    <div className="mfnw__dashboard-content">
        <Tabs
            defaultActiveKey="organization"
            className="mfnw__dashboard_linetab mb-3"
        >
            <Tab eventKey="organization" title="ORGANIZATION">
                <UserOrganizationListTab />
            </Tab>
            <Tab eventKey="notification" title="NOTIFICATION" disabled></Tab>
            <Tab eventKey="integration" title="INTEGRATION" disabled></Tab>
        </Tabs>
    </div>
);
export default UserOrganization;
