import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { searchTimeOut } from '@constants/general.const';

import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { faPen, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ZipLookUp } from '@app/SettingsLists/lists.const';
import AgentFormField from '@app/SettingsLists/Agents/agentFormField';

import { IListOption } from '../../../Interface/common';
import { IContactInfoProps } from './agent.interface';

const AgentViewPopup = ({
    showPopup,
    handleClose,
    handleEditShow,
    data,
    deleteById,
}: any) => {
    const dispatch = useDispatch<any>();
    const { agentData } = useSelector((state: any) => state);
    const { isGetAgentInfo } = agentData;
    const agentId = data?.agent_nano_id;

    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);
    //  ask fore type
    const [showInput, setShowInput] = useState<boolean>(true);

    const onClose = async () => {
        handleClose();
    };

    const { control, setValue, watch, reset, getValues } = useForm<any>();
    const organizationZipCode = watch('agent_zip_code');
    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            ZipLookUp(
                'origin',
                dispatch,
                getValues,
                setValue,
                setCityArr,
                setZipError,
                'agent_zip_code',
                'agent_city',
                'agent_city_id',
                'agent_state',
                'agent_state_name',
            );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    useEffect(() => {
        if (data) {
            reset(data);
        }
        if (data && data.contact_info && data.contact_info.length > 0) {
            const updatedContactInfo = data.contact_info.map(
                (contact: IContactInfoProps) => {
                    const newPhoneNumber = contact?.phone_number?.map(
                        (number: any) => ({
                            number,
                            type:
                                number?.phoneNumberType === 'work'
                                    ? 'Work'
                                    : 'Mobile',
                            phone_no: number?.phone_number,
                            is_default: number?.is_default === 1 ? '1' : '0',
                        }),
                    );
                    const updatedPhoneArray = newPhoneNumber;
                    return {
                        ...contact,
                        phone_number: updatedPhoneArray,
                    };
                },
            );
            setValue('contact_info', updatedContactInfo);
        }
    }, [data]);

    const watchContactInfo = watch('contact_info');

    const popUpHeader = useMemo(
        () => (
            <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                <FontAwesomeIcon
                    className="mx-2 cursor-pointer align-baseline m-auto"
                    icon={faPen}
                    onClick={() => handleEditShow()}
                />
                <FontAwesomeIcon
                    className="mx-2 cursor-pointer align-baseline m-auto"
                    onClick={() => deleteById(data?.agent_nano_id, 'delete')}
                    icon={faTrashCan}
                />
                {data?.is_active && data?.is_active === 1 ? (
                    <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                        />
                        Enabled
                    </Badge>
                ) : (
                    <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                        <FontAwesomeIcon
                            icon={faCircleSmall}
                            className="me-2"
                        />
                        Disable
                    </Badge>
                )}
            </div>
        ),
        [],
    );

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    View Agent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {isGetAgentInfo ? (
                    <LoaderBtn />
                ) : (
                    <Form>
                        <AgentFormField
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            setShowInput={setShowInput}
                            showInput={showInput}
                            errors={null}
                            cityArr={cityArr}
                            zipError={zipError}
                            agentId={agentId}
                            onClose={onClose}
                            watchContactInfo={watchContactInfo}
                            isDisabled={true}
                            popUpHeader={popUpHeader}
                        />
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};
export default AgentViewPopup;
