import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiChangePassword,
    apiSendOtp,
    apiVerifyUser,
} from '@services/API/apiFunctions';

const verifyUser = createAsyncThunk(
    'verifyUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiVerifyUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const sendOtp = createAsyncThunk(
    'sendOtp',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiSendOtp(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const changePassword = createAsyncThunk(
    'changePassword',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiChangePassword(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { verifyUser, sendOtp, changePassword };
