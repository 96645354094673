import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createMigrate from 'redux-persist/es/createMigrate';
import appReducer from '@redux/app/appSlice';

import loginReducer from '@app/Auth/Login/loginSlice';
import registerReducer from '@app/Auth/Register/registerSlice';
import EmailVerifyReducer from '@app/Auth/EmailVerify/verifySlice';
import UserProfileSlice from '@app/Auth/UserProfile/UserProfileSlice';
import OfferSlice from '@app/OffersList/offerSlice';
import packersSlice from '@app/SettingsLists/Packers/packersSlice';
import holidaysSlice from '@app/SettingsLists/Holidays/holidaysSlice';
import organizationStep1Slice from '@app/CreateOrganization/step1/step1Slice';
import officeStep2Slice from '@app/CreateOrganization/step2/step2Slice';
import departmentStep3Slice from '@app/CreateOrganization/step3/step3Slice';
import roleStep4Slice from '@app/CreateOrganization/step4/step4Slice';
import jobTitleStep5Slice from '@app/CreateOrganization/step5/step5Slice';
import regionsStep6Slice from '@app/CreateOrganization/step6/step6Slice';
import groupSlice from '@app/UserListing/Groups/groupSlice';
import InviteUserSlice from '@app/UserListing/Users/inviteUserSlice';
import membershipSlice from '@app/UserListing/MembershipList/membershipSlice';
import forwarderSlice from '@app/SettingsLists/TSP/forwarderSlice';
import agentSlice from '@app/SettingsLists/Agents/agentSlice';
import driverSlice from '@app/SettingsLists/Drivers/driverSlice';
import documentSlice from '@app/JobList/Documents/documentSlice';
import historySlice from '@app/JobList/History/historySlice';
import offerNoteSlice from '@app/JobList/OfferNotes/offerNoteSlice';
import JobSlice from '@app/JobList/jobSlice';
import exceptionsSlice from '@app/SettingsLists/ImportExceptions/importExceptionsSlice';

const rootReducer = combineReducers({
    common: appReducer,
    login: loginReducer,
    register: registerReducer,
    emailVerify: EmailVerifyReducer,
    inviteUsers: InviteUserSlice,
    userProfile: UserProfileSlice,
    offersData: OfferSlice,
    jobsData: JobSlice,
    forwarderData: forwarderSlice,
    driverData: driverSlice,
    packersData: packersSlice,
    holidaysData: holidaysSlice,
    agentData: agentSlice,
    offerNoteData: offerNoteSlice,
    documentData: documentSlice,
    historyData: historySlice,
    organizationStep1Data: organizationStep1Slice,
    officeStep2Data: officeStep2Slice,
    departmentStep3Data: departmentStep3Slice,
    roleStep4Data: roleStep4Slice,
    jobTitleStep5Data: jobTitleStep5Slice,
    regionsStep6Data: regionsStep6Slice,
    groupData: groupSlice,
    membershipData: membershipSlice,
    exceptionsData: exceptionsSlice,
});

const migrations = {
    9: (state: any) => ({
        ...state,
        jobsData: {
            ...state.jobsData,
            isJobsList: false,
            jobFilters: {
                sortValue: 'offeredOn',
                currentPage: 1,
                perPageLimit: 5,
                orderValue: 'DESC',
                sortText: 'Received Date',
                grid: false,
            },
        },
        offersData: {
            ...state.offersData,
            isOfferList: false,
            isOfferHistory: false,
            isAcceptOffer: false,
            isRejectOffer: false,
            isOfferData: false,
            isUpdateOffer: false,
            isCreateOffer: false,
            offerFilters: {
                sortValue: 'offeredOn',
                currentPage: 1,
                perPageLimit: 5,
                orderValue: 'DESC',
                sortText: 'Received Date',
                grid: false,
            },
            offerHistoryFilters: {
                sortValue: 'offeredOn',
                currentPage: 1,
                perPageLimit: 5,
                orderValue: 'DESC',
                sortText: 'Received Date',
                grid: false,
            },
            driverData: {
                isAddDriver: false,
                isDriversList: true,
                isUpdateDriver: false,
                isGetDriverInfo: false,
                isDeleteDriver: false,
            },
            packersData: {
                isAddPacker: false,
                isPackersList: false,
                isUpdatePacker: false,
                isGetPackerInfo: false,
                isDeletePacker: false,
            },
        },
        common: {
            authUser: null,
            searchText: null,
            profilePopup: false,
            response: {
                message: null,
                status: null,
            },
        },
    }),
};

const persistConfig = {
    key: 'root',
    storage,
    version: 9,
    timeout: 0,
    migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
