import React from 'react';
import { Image } from 'react-bootstrap';

const AddressDiv = ({
    icon,
    addressName,
    offerAddress,
    color,
    iconBgColor,
}: any) => (
    <>
        <div className="d-flex only-one-offer">
            <div className="form-control-serch1">
                <Image src={icon} />
            </div>
            <div className="my-2">
                <p className={`mb-0 ${color} text-sm fw-bold`}>{addressName}</p>
                <address className="text-base text-uppercase text-dark fw-medium mfnw_address mt-2">
                    {offerAddress?.street}{' '}
                    {offerAddress?.additional ? (
                        <>{offerAddress?.additional}</>
                    ) : (
                        ''
                    )}
                    {offerAddress?.street || offerAddress?.additional ? (
                        <br />
                    ) : null}
                    {offerAddress?.city}, {offerAddress?.stateTown}
                    {offerAddress?.postalCode
                        ? ` ${offerAddress?.postalCode}`
                        : ''}
                </address>
            </div>
        </div>
    </>
);

export default AddressDiv;
