import React from 'react';
import DatePicker from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/InputGroup';

import ErrorMsg from '@components/errorMsg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IDateSelectProps } from './dateSelect.interface';
import './DateSelect.scss';

const DateSelect = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            className,
            errorText,
            onKeyDown,
            label,
            tabIndex,
            onChangeFunc,
            dateFormatSet,
            dateFormat,
            groupInput,
            inputGroupClassName,
            icon,
            ...props
        }: IDateSelectProps,
        ref: any,
    ) => {
        const onChangeDate = (
            date: Date | null,
            event: React.SyntheticEvent<any, Event> | undefined,
        ) => {
            let customEvent: any = event;
            customEvent = {
                event,
                ...{ value: date, id: props.id, name: inputName },
            };
            onChangeFunc(customEvent);
        };
        return (
            <>
                <InputGroup>
                    {groupInput && (
                        <InputGroup.Text
                            className={`${inputGroupClassName} justify-content-left`}
                        >
                            {icon ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={icon}
                                        className={label ? 'me-2' : ''}
                                        size="xl"
                                    />{' '}
                                    {label}
                                </>
                            ) : (
                                label
                            )}
                        </InputGroup.Text>
                    )}
                    <DatePicker
                        ref={ref}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={className}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e) => {
                            if (e?.keyCode === 9 || e?.which === 9) {
                                ref.current.setOpen(false);
                            }
                        }}
                        autoComplete="off"
                        name={inputName}
                        dateFormat={dateFormat}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                            dateFormat === 'MMM d' ? (
                                false
                            ) : (
                                <ReactInputMask
                                    ref={ref}
                                    mask={dateFormatSet ?? '99/99/9999'}
                                    placeholder={placeholder}
                                    className={className}
                                />
                            )
                        }
                        {...props}
                    />
                </InputGroup>
                {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
            </>
        );
    },
);

DateSelect.defaultProps = {
    inputName: '',
    placeholder: '',
    className: '',
    label: '',
    errorText: '',
    dateFormatSet: '99/99/9999',
    id: '',
};
export default DateSelect;
