import React, { Fragment, useState } from 'react';
import { Button, Image, Row, Table, Badge, Spinner } from 'react-bootstrap';

import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { faFileLines, faNote } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FollowIconFrom from '@images/Shape.svg';
import JobNotFound from '@images/job-not-found.svg';

import { dateTimeZone } from '@utils/utilFunctions';

import '@app/JobList/jobList.scss';
import { useDispatch } from 'react-redux';
import { exportJobList } from './functions';
import { faFileCsv, faFilePdf } from '@fortawesome/pro-solid-svg-icons';

const JobCollapse = ({
    currentPageData,
    handleViewShow,
    isExport,
    filters,
    isExportJobsList,
    handlePayloadError,
}: any) => {
    const dispatch = useDispatch<any>();

    const [isCSV, setIsCSV] = useState(false);
    const [isPDF, setIsPDF] = useState(false);

    const handleExportCsv = async (type: any) => {
        const { payload } = await dispatch(exportJobList({ filters, type }));

        if (payload && payload?.data) {
            if (type === 'csv') {
                const blob = new Blob([payload.data], {
                    type: 'text/csv',
                });
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'incompleteOffers.csv';
                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                const url = payload?.data;
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        } else {
            handlePayloadError(payload);
        }
    };
    return (
        <Row>
            {isExport && currentPageData && currentPageData.length > 0 && (
                <div className="position-relative cursor-pointer mx-3 mt-1 ps-2 pt-2 d-flex justify-content-end">
                    <div title="Export csv">
                        <Button
                            className="me-4 py-2 px-4"
                            onClick={() => {
                                handleExportCsv('csv');
                                setIsCSV(true);
                                setIsPDF(false);
                            }}
                            disabled={isExportJobsList && isCSV}
                        >
                            {isExportJobsList && isCSV ? (
                                <span className="d-flex align-items-center justify-content-center">
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size={'sm'}
                                        className="ml-2"
                                    ></Spinner>
                                </span>
                            ) : (
                                <FontAwesomeIcon
                                    icon={faFileCsv}
                                    size="xl"
                                    className={`form-control-serch text-white align-self-center`}
                                />
                            )}
                        </Button>
                    </div>
                    <div title="View PDF">
                        <Button
                            className="me-4 py-2 px-4"
                            onClick={() => {
                                handleExportCsv('viewPdf');
                                setIsCSV(false);
                                setIsPDF(true);
                            }}
                            disabled={isExportJobsList && isPDF}
                        >
                            {isExportJobsList && isPDF ? (
                                <span className="d-flex align-items-center justify-content-center">
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        size={'sm'}
                                        className="ml-2"
                                    ></Spinner>
                                </span>
                            ) : (
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    size="xl"
                                    className={`form-control-serch text-white align-self-center`}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            )}
            <div className="overflow-table-auto">
                {currentPageData && currentPageData.length > 0 && (
                    <Table
                        borderless
                        className="mfnw__borderless_card-table mb-0 table-one align-middle"
                    >
                        <thead>
                            <tr>
                                <th className="text-sm">SHIPPER</th>
                                <th className="text-sm text-center">WEIGHT</th>
                                <th className="text-sm text-center">
                                    LINE HAUL
                                </th>
                                <th className="text-sm text-center">
                                    HAUL TYPE
                                </th>
                                <th className="text-sm text-center">PACK</th>
                                <th className="text-sm text-center">PICKUP</th>
                                <th className="text-sm text-center">RDD</th>
                                <th className="text-sm text-center">REGION</th>
                                <th className="text-sm text-center">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData && currentPageData.length > 0
                                ? currentPageData.map(
                                      (job: any, index: number) => (
                                          <Fragment key={index}>
                                              <tr
                                                  onClick={() =>
                                                      handleViewShow(
                                                          job?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  <td className="text-truncate">
                                                      <span className="text-uppercase">
                                                          {job?.shipperdata
                                                              ?.shipper_name ??
                                                              job?.shipper}
                                                      </span>
                                                      <div className="d-flex table-font">
                                                          <span className="text-sm">
                                                              {`${job?.address_origin?.city},  ${job?.address_origin?.stateTown}`}
                                                          </span>
                                                          <Image
                                                              className="mx-1"
                                                              src={
                                                                  FollowIconFrom
                                                              }
                                                          />
                                                          <span className="text-sm">
                                                              {`${job?.address_destination?.city},  ${job?.address_destination?.stateTown}`}
                                                          </span>
                                                      </div>
                                                  </td>
                                                  <td className="text-dark text-base fw-bold text-center text-truncate">
                                                      {job?.estimatedWeight}
                                                  </td>
                                                  <td className="text-truncate">
                                                      {' '}
                                                      <div className="tbl-box1  text-center text-dark md-w-100">
                                                          <span className="text">
                                                              {
                                                                  job
                                                                      ?.line_haule_data
                                                                      ?.discount
                                                              }
                                                              % disc
                                                          </span>
                                                          <p className="mb-0 text-detail1">
                                                              {
                                                                  job
                                                                      ?.line_haule_data
                                                                      ?.miles
                                                              }{' '}
                                                              miles
                                                          </p>
                                                      </div>
                                                  </td>
                                                  <td className="text-center text-truncate">
                                                      {' '}
                                                      {job?.line_haule_data
                                                          ?.isOriginAgentOnly
                                                          ? 'OA '
                                                          : ''}
                                                      {job?.line_haule_data
                                                          ?.isSelfHaul
                                                          ? ' LH'
                                                          : ' SH'}
                                                  </td>
                                                  <td className="text-base fw-semi-bold text-center text-truncate">
                                                      {dateTimeZone(
                                                          job?.packDate,
                                                      )}
                                                  </td>
                                                  <td className="text-base fw-semi-bold text-center text-truncate">
                                                      {dateTimeZone(
                                                          job?.pickupDate,
                                                      )}
                                                  </td>
                                                  <td className="text-base fw-semi-bold text-center text-truncate">
                                                      {dateTimeZone(
                                                          job?.deliveryDate,
                                                      )}
                                                  </td>
                                                  <td className="text-base fw-semi-bold text-center text-truncate">
                                                      {job?.regions?.[0] ? (
                                                          <Button className="mfnp_overflowbtn p-2 px-4 border-1 text-success bg-white border-light border-opacity-50 rounded-pill fw-bold text-capitalize text-overflow">
                                                              {job?.regions?.[0]
                                                                  ? job
                                                                        ?.regions?.[0]
                                                                        ?.region_name
                                                                  : '(N/A)'}
                                                          </Button>
                                                      ) : (
                                                          '(N/A)'
                                                      )}
                                                  </td>
                                                  {job?.offer_notes.length ||
                                                  job?.offer_documents
                                                      .length ? (
                                                      <td className="text-base fw-semi-bold text-center text-truncate">
                                                          <div className="top-0 end-0 d-flex">
                                                              {job?.offer_notes
                                                                  .length >
                                                                  0 && (
                                                                  <div
                                                                      className="position-relative cursor-pointer mx-3 mt-1 ps-2 pt-2"
                                                                      title="Notes"
                                                                  >
                                                                      <FontAwesomeIcon
                                                                          icon={
                                                                              faNote
                                                                          }
                                                                          size="2xl"
                                                                          className="form-control-serch text-primary m-auto text-light  align-self-center"
                                                                      />
                                                                      <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                                                          {
                                                                              (job
                                                                                  ?.offer_notes)
                                                                                  .length
                                                                          }
                                                                      </Badge>
                                                                  </div>
                                                              )}
                                                              {job
                                                                  ?.offer_documents
                                                                  .length >
                                                                  0 && (
                                                                  <div
                                                                      className="position-relative cursor-pointer mx-3 mt-1 ps-2 pt-2"
                                                                      title="Documents"
                                                                  >
                                                                      <FontAwesomeIcon
                                                                          icon={
                                                                              faFileLines
                                                                          }
                                                                          size="2xl"
                                                                          className="form-control-serch text-primary m-auto text-light  align-self-center"
                                                                      />

                                                                      <Badge bg="primary position-absolute top-0 start-0 rounded-circle">
                                                                          {
                                                                              (job
                                                                                  ?.offer_documents)
                                                                                  .length
                                                                          }
                                                                      </Badge>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      </td>
                                                  ) : (
                                                      <td className="text-base fw-semi-bold text-center text-truncate">
                                                          (N/A)
                                                      </td>
                                                  )}
                                              </tr>
                                          </Fragment>
                                      ),
                                  )
                                : null}
                        </tbody>
                    </Table>
                )}
            </div>
            <NotFoundPages
                currentPageData={currentPageData}
                image={JobNotFound}
                message={`Exciting opportunities are on the horizon and will be available soon.`}
            />
        </Row>
    );
};

export default JobCollapse;
