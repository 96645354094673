import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dropdown } from 'react-bootstrap';

import InputText from '@components/InputText/InputText';
import {
    pageLimitList,
    PageLimitChangeCalculation,
    searchTimeOut,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';

import { routesPath } from '@constants/routesNavigate';

import {
    faMagnifyingGlass,
    faBars,
    faSliders,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    changeDefaultOrganization,
    getUserOrganizationList,
} from '@app/CreateOrganization/step1/functions';

import OrganizationPopup from './organizationPopup';
import OrganizationTable from './organizationTable';

const OrganizationListTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, organizationStep1Data } = useSelector(
        (state: any) => state,
    );
    const { searchText } = common;
    const { isGetUserOrganizationList } = organizationStep1Data;

    const [show, setShow] = useState(false);
    const [filters, setFilters] = useState<any>();
    const [currentPageData, setCurrentPageData] = useState<number[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [resMessage, setResMessage] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [userOrganizationArray, setUserOrganizationArray] = useState<
        any[] | null
    >([]);
    const [search, setSearch] = useState<any>();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        const getDataSearch: any = setTimeout(() => {
            filters && getList(1, filters);
        }, searchTimeOut);
        return () => clearTimeout(getDataSearch);
    }, [search]);

    const getFilters = async () => {
        FiltersData(
            searchText,
            getList,
            setFilters,
            'organization_list',
            dispatch,
            navigate,
        );
    };

    const getList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            getUserOrganizationList({
                filters: {
                    grid: res?.grid ? res?.grid : false,
                    searchText: res?.searchText ? res?.searchText : null,
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    sortValue: res?.sortValue ? res?.sortValue : 'offeredOn',
                    sortText: res?.sortText ? res?.sortText : 'Received Date',
                    orderValue: res?.orderValue ? res?.orderValue : 'DESC',
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: search,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setResMessage(payload?.data?.message);
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
            setUserOrganizationArray(payload?.data?.userOrganization);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const changeOrganization = async (nanoId: any) => {
        const { payload } = await dispatch(changeDefaultOrganization(nanoId));
        if (payload?.data?.status) {
            toast.success(payload?.data?.message);
            getList(1, payload?.data?.filters);
        } else {
            toast.error(payload?.message);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        getPackersListAndUpdateFilter(updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        getPackersListAndUpdateFilter(updateFilter);
    };

    const getPackersListAndUpdateFilter = (updateFilter: any) => {
        getList(updateFilter.currentPage, updateFilter);
        setFilters(updateFilter);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center pt-3">
                <h5 className="fw-semibold m-0">Organization List</h5>
                <div className="d-flex gap-3 main-par">
                    <div className="main-searchInput position-relative">
                        <InputText
                            className="mfnw__login-form-control mfnw__search-form-control-bg"
                            id=""
                            inputName=""
                            inputType="text"
                            placeholder="Search..."
                            errorText={''}
                            value={search}
                            onInput={(e: any) => setSearch(e.target.value)}
                        />
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            color="#b7b7b7"
                            size="lg"
                            className="position-absolute form-control-serch"
                        />
                    </div>
                    <div className="dropdown-block float-end">
                        <FontAwesomeIcon
                            icon={faBars}
                            color="#0270B2"
                            size="lg"
                        />
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="limit m-0 p-0 border-0"
                        >
                            <div className="dropdown-block">
                                <FontAwesomeIcon
                                    icon={faSliders}
                                    color="#b7b7b7"
                                    size="lg"
                                />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {pageLimitList?.map((item: any, index: number) => (
                                <Dropdown.Item
                                    active={filters?.perPageLimit === item}
                                    onClick={() => {
                                        handleLimitChange(item);
                                    }}
                                    key={index}
                                    eventKey={`#/action-${index}`}
                                >
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        variant="primary"
                        className="fw-semibold btn_type_large h-fit"
                        onClick={handleShow}
                    >
                        Add Organization
                    </Button>
                </div>
            </div>
            <section>
                <OrganizationTable
                    currentPageData={currentPageData}
                    userOrganizationArray={userOrganizationArray}
                    changeOrganization={changeOrganization}
                    isGetUserOrganizationList={isGetUserOrganizationList}
                    resMessage={resMessage}
                    pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    filters={filters}
                />
            </section>
            {show && (
                <OrganizationPopup
                    show={show}
                    handleClose={handleClose}
                    getList={() => getList(1, filters)}
                    organizationId={''}
                    actinType={'add'}
                />
            )}
        </>
    );
};

export default OrganizationListTab;
