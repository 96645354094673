import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Form, Card, Button } from 'react-bootstrap';

import { Image } from '@components-default';
import InputText from '@components/InputText/InputText';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ToastBodyContainer from '@components/ToastContainer';

import { passwordLength } from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faEyeSlash,
    faEye,
    faEnvelope,
} from '@fortawesome/fontawesome-pro-regular';

import Logo from '@images/mf-logo.png';

import LeftSideBox from '@app/Auth/Login/leftSideBox';
import { clearResponse, responseMessage } from '@redux/app/appSlice';
import { registerChange } from '@register/registerSlice';

import { loginUser, saveLoggedInUser } from './loginSlice';
import { ILoginProps } from './login.interface';

import './login.scss';

const faEnvelopeProp = faEnvelope as IconProp;
const faEyeSlashProp = faEyeSlash as IconProp;
const faEyeProp = faEye as IconProp;

const Login = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { login, common } = useSelector((state: any) => state);

    const { isLoginUser } = login;

    const { response } = common;
    //  ask fore type
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);

    const schema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is a required field'),

        password: Yup.string()
            .required('Please provide a password.')
            .min(
                passwordLength,
                `Password must be at least ${passwordLength} characters.`,
            ),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<ILoginProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: localStorage.getItem('username') ?? '',
            password: localStorage.getItem('password') ?? '',
        },
    });

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
    }, []);

    const HandleChange = async (data: ILoginProps) => {
        if (isValid) {
            if (rememberMe) {
                localStorage.setItem('username', data?.email);
                localStorage.setItem('password', data?.password);
            } else {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
            }
            const { payload } = await dispatch(loginUser(data));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                dispatch(saveLoggedInUser(payload));
                localStorage.setItem(
                    'accessToken',
                    payload?.data?.data?.original?.access_token,
                );
                if (payload?.data?.currentStep === 7) {
                    localStorage.setItem('navigateTo', routesPath?.dashboard);
                } else {
                    localStorage.setItem(
                        'navigateTo',
                        routesPath?.[`step${payload?.data?.currentStep}`],
                    );
                }
            } else {
                if (payload?.verified) {
                    dispatch(responseMessage(payload));
                    dispatch(registerChange(payload));
                    navigate(routesPath?.emailVerify);
                }
                toast.error(payload?.message);
            }
        }
    };

    const handleRememberMeChange = (event: any) => {
        setRememberMe(event.target.checked);
    };

    return (
        <div className="p-0">
            <ToastBodyContainer />
            <div className="login_auth_wrapper mobile_login_wrapper">
                <LeftSideBox />
                <Card className="rightBox">
                    <div className="mfnw__form-fixed">
                        <div className="mfnw__form-logo text-center mb-3 mb-lg-5 pt-2 pt-lg-0 mfnw_form-logoresponsive">
                            <Image src={Logo} />
                            <h3 className="mf-welcome-text">
                                Welcome to the MoveFlows Portal
                            </h3>
                        </div>
                        <Form onSubmit={handleSubmit(HandleChange)}>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <div className="login-input-group">
                                        <InputText
                                            onChangeFunc={onChange}
                                            value={value}
                                            id="email"
                                            inputName="email"
                                            inputType="email"
                                            placeholder="your@email.com"
                                            errorText={errors.email?.message}
                                            iconShow={true}
                                            icon={faEnvelopeProp}
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <div className="login-input-group">
                                        <InputText
                                            onChangeFunc={onChange}
                                            value={value}
                                            id="password"
                                            inputName="password"
                                            placeholder="******"
                                            onIconClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                            inputType={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            iconShow={true}
                                            icon={
                                                showPassword
                                                    ? faEyeProp
                                                    : faEyeSlashProp
                                            }
                                            errorText={errors.password?.message}
                                        />
                                    </div>
                                )}
                            />
                            <Form.Group
                                className="d-flex align-items-center justify-content-between"
                                controlId="formBasicCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Remember me"
                                    checked={rememberMe}
                                    className="form_check_secondary"
                                    onChange={handleRememberMeChange}
                                />
                                <div className="text-center">
                                    <Link
                                        to={routesPath?.forgotPassword}
                                        className="forgetLink"
                                    >
                                        Forgot Password ?
                                    </Link>
                                </div>
                            </Form.Group>
                            <Button
                                type="submit"
                                className="btnLogin text-uppercase mt-5"
                                variant="secondary w-100"
                                disabled={isLoginUser}
                            >
                                {isLoginUser ? <LoaderBtn /> : 'LogIn'}
                            </Button>
                            <div className="text-center mt-4">
                                <h6 className="registerLink">
                                    <span>
                                        Don&apos;t have an account?&nbsp;
                                    </span>
                                    <a
                                        href={routesPath?.register}
                                        className="text-secondary regLink"
                                    >
                                        Create Account Now
                                    </a>
                                </h6>
                            </div>
                        </Form>
                    </div>
                </Card>
                <div className="mfnw-right-footer">
                    <span>MoveFlows © 2023</span>
                </div>
            </div>
        </div>
    );
};
export default Login;
