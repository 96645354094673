import React from 'react';
import { Container } from 'react-bootstrap';

import './404.scss';

const PageNotFound = () => (
    <div className="page-not-found">
        <Container>
            <div className="content">
                <h4 className="title">Page Not Found</h4>
                <p className="message">
                    The requested page could not be found. Please check the URL
                    and try again.
                </p>
            </div>
        </Container>
    </div>
);

export default PageNotFound;
