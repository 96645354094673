import React from 'react';

const PackHaulBoxDiv = ({
    firstName,
    secondName,
    jobLhData,
}: {
    firstName: string;
    secondName: string;
    jobLhData: any;
}) => (
    <div className="division-section mb-2 mb-sm-0">
        <div className="d-flex flex-wrap gap-2">
            <div className="box-table text-center">
                <span className="text-light text-sm">{secondName}</span>
                <h6 className="mb-0 text-success text-base tetx-bold text-center">
                    (N/A)
                </h6>
            </div>
            <div className="box-table text-center">
                <span className="text-light text-sm">{firstName}</span>
                <h6 className="mb-0 text-success text-base tetx-bold">(N/A)</h6>
            </div>
        </div>
    </div>
);

export default PackHaulBoxDiv;
