import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    changeOfferStatus,
    createOffer,
    offerData,
    OfferHistory,
    OfferList,
    rejectOffer,
    selectPackersLoad,
    updateOffer,
    updateOfferPacker,
} from '@app/OffersList/functions';

const defaultState = {
    isOfferList: true,
    isOfferHistory: true,
    isAcceptOffer: false,
    isRejectOffer: false,
    isOfferData: false,
    isUpdateOffer: false,
    isCreateOffer: false,
    isUpdateOfferPacker: false,
    isSelectPackersLoad: false,
    offerFilters: {
        sortValue: 'offeredOn',
        currentPage: 1,
        perPageLimit: 5,
        orderValue: 'DESC',
        sortText: 'Received Date',
        grid: false,
    },
    offerHistoryFilters: {
        sortValue: 'offeredOn',
        currentPage: 1,
        perPageLimit: 5,
        orderValue: 'DESC',
        sortText: 'Received Date',
        grid: false,
    },
};

export const OfferSlice: any = createSlice({
    name: 'offersData',
    initialState: cloneDeep(defaultState),
    reducers: {
        offerFilterChange: (state, { payload }) => {
            state.offerFilters = payload;
        },
        offerHistoryFilterChange: (state, { payload }) => {
            state.offerHistoryFilters = payload;
        },
    },
    extraReducers: {
        [OfferList.pending.type]: (state: any, { payload }) => {
            state.isOfferList = true;
            state.registerResponse = payload;
        },
        [OfferList.fulfilled.type]: (state: any, { payload }) => {
            state.isOfferList = false;
            state.registerResponse = payload;
        },
        [OfferList.rejected.type]: (state: any, { payload }) => {
            state.isOfferList = false;
            state.registerResponse = payload;
        },
        [changeOfferStatus.pending.type]: (state: any, { payload }) => {
            state.isAcceptOffer = true;
            state.registerResponse = payload;
        },
        [changeOfferStatus.fulfilled.type]: (state: any, { payload }) => {
            state.isAcceptOffer = false;
            state.registerResponse = payload;
        },
        [changeOfferStatus.rejected.type]: (state: any, { payload }) => {
            state.isAcceptOffer = false;
            state.registerResponse = payload;
        },
        [rejectOffer.pending.type]: (state: any, { payload }) => {
            state.isRejectOffer = true;
            state.registerResponse = payload;
        },
        [rejectOffer.fulfilled.type]: (state: any, { payload }) => {
            state.isRejectOffer = false;
            state.registerResponse = payload;
        },
        [rejectOffer.rejected.type]: (state: any, { payload }) => {
            state.isRejectOffer = false;
            state.registerResponse = payload;
        },
        [OfferHistory.pending.type]: (state: any, { payload }) => {
            state.isOfferHistory = true;
            state.registerResponse = payload;
        },
        [OfferHistory.fulfilled.type]: (state: any, { payload }) => {
            state.isOfferHistory = false;
            state.registerResponse = payload;
        },
        [OfferHistory.rejected.type]: (state: any, { payload }) => {
            state.isOfferHistory = false;
            state.registerResponse = payload;
        },
        [offerData.pending.type]: (state: any, { payload }) => {
            state.isOfferData = true;
            state.registerResponse = payload;
        },
        [offerData.fulfilled.type]: (state: any, { payload }) => {
            state.isOfferData = false;
            state.registerResponse = payload;
        },
        [offerData.rejected.type]: (state: any, { payload }) => {
            state.isOfferData = false;
            state.registerResponse = payload;
        },
        [updateOffer.pending.type]: (state: any, { payload }) => {
            state.isUpdateOffer = true;
            state.registerResponse = payload;
        },
        [updateOffer.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateOffer = false;
            state.registerResponse = payload;
        },
        [updateOffer.rejected.type]: (state: any, { payload }) => {
            state.isUpdateOffer = false;
            state.registerResponse = payload;
        },
        [createOffer.pending.type]: (state: any, { payload }) => {
            state.isCreateOffer = true;
            state.registerResponse = payload;
        },
        [createOffer.fulfilled.type]: (state: any, { payload }) => {
            state.isCreateOffer = false;
            state.registerResponse = payload;
        },
        [createOffer.rejected.type]: (state: any, { payload }) => {
            state.isCreateOffer = false;
            state.registerResponse = payload;
        },
        [updateOfferPacker.pending.type]: (state: any, { payload }) => {
            state.isUpdateOfferPacker = true;
            state.registerResponse = payload;
        },
        [updateOfferPacker.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateOfferPacker = false;
            state.registerResponse = payload;
        },
        [updateOfferPacker.rejected.type]: (state: any, { payload }) => {
            state.isUpdateOfferPacker = false;
            state.registerResponse = payload;
        },
        [selectPackersLoad.pending.type]: (state: any, { payload }) => {
            state.isSelectPackersLoad = true;
            state.registerResponse = payload;
        },
        [selectPackersLoad.fulfilled.type]: (state: any, { payload }) => {
            state.isSelectPackersLoad = false;
            state.registerResponse = payload;
        },
        [selectPackersLoad.rejected.type]: (state: any, { payload }) => {
            state.isSelectPackersLoad = false;
            state.registerResponse = payload;
        },
    },
});

export const { offerFilterChange, offerHistoryFilterChange } =
    OfferSlice.actions;

export {
    OfferList,
    offerData,
    updateOffer,
    createOffer,
    changeOfferStatus,
    rejectOffer,
    OfferHistory,
    updateOfferPacker,
    selectPackersLoad,
};

export default OfferSlice.reducer;
