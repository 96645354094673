import React from 'react';
import { Controller } from 'react-hook-form';
import { Badge, Button, Col, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import InputText from '@components/InputText/InputText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import LogoImageUpload from '@components/LogoImageUpload/LogoImageUpload';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/fontawesome-pro-regular';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import addUser from '@images/AddUser.svg';

const faEnvelopeIcon = faEnvelope as IconProp;

const UserFormFields = ({
    control,
    errors,
    officeArr,
    departmentArr,
    groupArr,
    roleArr,
    jobTitleArr,
    handleClickImageChange,
    userImage,
    userData,
    addUserSelectFiledProps,
    setSearchObj,
    setValue,
    onClose,
    editData,
    isOrganizationDataSelectList,
    isUpdateInfo,
    isInviteUser,
}: any) => {
    const renderSelectField = () =>
        addUserSelectFiledProps.map((field: any, index: number) => {
            const { name, searchKey, placeholder, col } = field;
            var optionsArr: any = [];

            switch (name) {
                case 'group_id':
                    optionsArr = groupArr;
                    break;
                case 'department_id':
                    optionsArr = departmentArr;
                    break;
                case 'office_id':
                    optionsArr = officeArr;
                    break;
                case 'role_id':
                    optionsArr = roleArr;
                    break;
                case 'jobTitle_id':
                    optionsArr = jobTitleArr;
                    break;
                default:
                    optionsArr = officeArr;
            }
            const divData = () => (
                <div className="mt-4">
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <ReactSelectGroup
                                value={optionsArr?.find(
                                    (c: any) => c?.value === value,
                                )}
                                tabIndex={0}
                                className="w-100"
                                inputClassName="mfnw__input-group"
                                options={optionsArr}
                                placeholder={placeholder}
                                labelShow={editData ? true : false}
                                classNamePrefix={
                                    editData ? 'select_control_group' : ''
                                }
                                label={placeholder}
                                onInputChange={(valueChange: any) => {
                                    if (valueChange !== '') {
                                        var set: any = {
                                            [searchKey]: valueChange,
                                        };
                                        setSearchObj(set);
                                    } else {
                                        setSearchObj(set);
                                    }
                                }}
                                onChangeFunc={(valueChange: any) => {
                                    if (name === 'group_id') {
                                        setValue(
                                            'group_id',
                                            valueChange?.value,
                                        );
                                    } else if (name === 'department_id') {
                                        setValue(
                                            'department_id',
                                            valueChange?.value,
                                        );
                                    } else if (name === 'office_id') {
                                        setValue(
                                            'office_id',
                                            valueChange?.value,
                                        );
                                    } else if (name === 'role_id') {
                                        setValue('role_id', valueChange?.value);
                                    } else {
                                        setValue(
                                            'jobTitle_id',
                                            valueChange?.value,
                                        );
                                    }
                                }}
                                errorText={errors?.[name]?.message}
                                isSearchable={true}
                                isDisabled={false}
                                isLoading={isOrganizationDataSelectList}
                            />
                        )}
                    />
                </div>
            );
            return editData ? (
                divData()
            ) : (
                <Col md={col} key={index}>
                    {divData()}
                </Col>
            );
        });

    return (
        <>
            <div className="d-flex justify-content-center position-relative">
                <LogoImageUpload
                    handleClickProfilePic={handleClickImageChange}
                    id="contained-button-file"
                    userImage={userImage}
                    userProfileData={userData ? userData : editData}
                    dropAble={false}
                    icon={addUser}
                />
                {editData &&
                    (editData?.is_active === 1 ? (
                        <Badge className="badge bg-success text-sm align-text-bottom ms-2 px-2 py-1 cursor-pointer position-absolute top-0 end-0">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Enabled
                        </Badge>
                    ) : (
                        <Badge className="badge bg-disable text-sm align-text-bottom ms-2 px-2 py-1 cursor-pointer position-absolute top-0 end-0">
                            <FontAwesomeIcon
                                icon={faCircleSmall}
                                className="me-2"
                            />
                            Disable
                        </Badge>
                    ))}
            </div>
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="mt-4">
                        <Controller
                            name={'firstName'}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <InputText
                                    onChangeFunc={onChange}
                                    value={value}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    id={`First Name`}
                                    inputType={'text'}
                                    placeholder={'First Name'}
                                    label={''}
                                    disabled={false}
                                    tabIndex={0}
                                    autoFocus={true}
                                    errorText={errors?.firstName?.message}
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mt-4">
                        <Controller
                            name={'lastName'}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <InputText
                                    onChangeFunc={onChange}
                                    value={value}
                                    id={`Last Name`}
                                    inputType={'text'}
                                    placeholder={'Last Name'}
                                    label={''}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    disabled={false}
                                    tabIndex={0}
                                    errorText={errors?.lastName?.message}
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mt-4">
                        <Controller
                            name={'email'}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    value={value}
                                    id={`Email`}
                                    inputType={'text'}
                                    placeholder={'Email'}
                                    label={''}
                                    icon={faEnvelopeIcon}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    disabled={editData ? true : false}
                                    tabIndex={0}
                                    errorText={errors?.email?.message}
                                />
                            )}
                        />
                    </div>
                </Col>
                {editData ? (
                    <div className={editData && 'd-flex flex-column list_user'}>
                        {renderSelectField()}
                    </div>
                ) : (
                    renderSelectField()
                )}

                <Col md={12}>
                    <Button
                        variant="primary w-100 mt-3 text-uppercase fw-semibold"
                        type="submit"
                        className="rounded-2"
                        tabIndex={0}
                        disabled={isUpdateInfo || isInviteUser}
                    >
                        {isUpdateInfo || isInviteUser ? <LoaderBtn /> : 'Save'}
                    </Button>

                    <Button
                        className="btn-cancel mt-3 w-100 text-uppercase fw-semibold"
                        tabIndex={0}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default UserFormFields;
