/* eslint-disable max-lines-per-function */
import React from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';

import { agentTypes, phoneFormat } from '@constants/general.const';

import { faFax, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faPhone } from '@fortawesome/pro-light-svg-icons';

import AgentContactInfoFields from '@app/SettingsLists/Agents/agentContactInfoFields';

const AgentFormField = ({
    control,
    setValue,
    getValues,
    setShowInput,
    showInput,
    errors,
    cityArr,
    zipError,
    agentId,
    onClose,
    watchContactInfo,
    isDisabled,
    popUpHeader,
}: any) => {
    const { agentData } = useSelector((state: any) => state);
    const { isAddAgent, isUpdateAgent } = agentData;

    return (
        <>
            {popUpHeader}
            <Row className="mt-3 mt-md-0">
                <Col md={6} className="align-self-center">
                    <h6 className="text-center mb-0 fw-medium">AGENT TYPE</h6>
                </Col>
                <Col md={6}>
                    <div className="mt-3">
                        <Controller
                            name={`agent_type`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <ReactSelectGroup
                                    value={agentTypes.find(
                                        (c: any) => c?.value === value,
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={agentTypes}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'agent_type',
                                            valueStateTownChange?.value,
                                        );
                                        setShowInput(!showInput);
                                    }}
                                    tabIndex={1}
                                    className="w-100"
                                    isSearchable={false}
                                    isDisabled={isDisabled}
                                    autoFocus={true}
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <div className="mt-3">
                        <Controller
                            name={`agent_name`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg text-dark"
                                        value={value}
                                        tabIndex={1}
                                        id={`Name`}
                                        inputType={'text'}
                                        placeholder={'Name'}
                                        disabled={isDisabled}
                                        groupInput={false}
                                        errorText={errors?.agent_name?.message}
                                        autoFocus={true}
                                    />
                                </>
                            )}
                        />
                    </div>
                    {getValues('agent_type') !== 'issued' && (
                        <>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_address`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={1}
                                                id={`Address`}
                                                inputType={'text'}
                                                placeholder={'Address'}
                                                disabled={isDisabled}
                                                groupInput={false}
                                                errorText={
                                                    errors?.agent_address
                                                        ?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_city`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <ReactSelectGroup
                                            value={cityArr.find(
                                                (c: any) => c?.value === value,
                                            )}
                                            labelShow={false}
                                            inputClassName="mfnw__input-group"
                                            options={cityArr}
                                            placeholder={'City'}
                                            onChangeFunc={(
                                                valueStateTownChange: any,
                                            ) => {
                                                setValue(
                                                    'agent_city',
                                                    valueStateTownChange?.value,
                                                );
                                                setValue(
                                                    'agent_city_id',
                                                    valueStateTownChange?.label,
                                                );
                                            }}
                                            tabIndex={3}
                                            className="w-100"
                                            isSearchable={
                                                zipError ? true : false
                                            }
                                            isDisabled={
                                                isDisabled ||
                                                (!zipError &&
                                                    getValues(
                                                        'agent_zip_code',
                                                    ) === null) ||
                                                cityArr.length === 1 ||
                                                (zipError ? true : false)
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </Col>
                {getValues('agent_type') !== 'issued' && (
                    <>
                        <Col md={6}>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_state`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <InputGroupText
                                            onChangeFunc={onChange}
                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                            value={value}
                                            tabIndex={3}
                                            id={`State`}
                                            inputType={'text'}
                                            placeholder={'State'}
                                            disabled={true}
                                            groupInput={false}
                                            errorText={
                                                errors?.agent_state?.message
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_zip_code`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={3}
                                                id={`Zip Code`}
                                                inputType={'text'}
                                                placeholder={'Zip Code'}
                                                disabled={isDisabled}
                                                groupInput={false}
                                                maxLength={5}
                                                errorText={
                                                    zipError
                                                        ? zipError
                                                        : errors?.agent_zip_code
                                                              ?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_phone`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <PhoneInputMaskGroup
                                            maskFormat={phoneFormat}
                                            inputName="agent_phone"
                                            inputType="text"
                                            label="Phone No."
                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                            onChangeFunc={onChange}
                                            id="agent_phone"
                                            aria-describedby="phone"
                                            tabIndex={5}
                                            faphoneIcon={faPhone}
                                            value={value}
                                            placeholder="Phone"
                                            errorText={
                                                errors?.agent_phone?.message
                                            }
                                            disabled={isDisabled}
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_fax`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <PhoneInputMaskGroup
                                            maskFormat={phoneFormat}
                                            inputName="agent_fax"
                                            inputType="text"
                                            label="Phone No."
                                            className="mfnw__login-form-control mfnw__login-form-control-bg"
                                            onChangeFunc={onChange}
                                            id="agent_fax"
                                            aria-describedby="phone"
                                            tabIndex={5}
                                            faphoneIcon={faFax}
                                            value={value}
                                            placeholder="Fax"
                                            errorText={
                                                errors?.agent_fax?.message
                                            }
                                            disabled={isDisabled}
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mt-3">
                                <Controller
                                    name={`agent_email`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={7}
                                                id={`Email`}
                                                inputType={'text'}
                                                icon={faEnvelope}
                                                placeholder={'Email'}
                                                disabled={isDisabled}
                                                groupInput={true}
                                                errorText={
                                                    errors?.agent_email?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                    </>
                )}
                <Col md={6}>
                    <div className="mt-3">
                        <Controller
                            name={`agent_scac_code`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputGroupText
                                        onChangeFunc={onChange}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg text-uppercase"
                                        value={value}
                                        tabIndex={8}
                                        id={`SCAC`}
                                        inputType={'text'}
                                        label={'SCAC'}
                                        placeholder={'SCAC'}
                                        disabled={isDisabled}
                                        groupInput={true}
                                        inputGroupClassName="mfnw__input-group"
                                        errorText={
                                            errors?.agent_scac_code?.message
                                        }
                                    />
                                </>
                            )}
                        />
                    </div>
                </Col>
                <Col md={6} className="align-self-center">
                    <div className="mt-4 text-light">
                        {getValues('agent_type') !== 'issued'
                            ? 'Optional'
                            : 'Required'}
                    </div>
                </Col>
                {getValues('agent_type') !== 'issued' ? (
                    <AgentContactInfoFields
                        getValues={getValues}
                        agentId={agentId}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        watchContactInfo={watchContactInfo}
                        isDisabled={isDisabled}
                    />
                ) : null}

                {!isDisabled && (
                    <Col md={12}>
                        <Button
                            variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                            type="submit"
                            tabIndex={10}
                            disabled={isAddAgent || isUpdateAgent}
                        >
                            {isAddAgent || isUpdateAgent ? (
                                <LoaderBtn />
                            ) : (
                                'SAVE'
                            )}
                        </Button>
                        <Button
                            className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                            onClick={() => onClose()}
                            tabIndex={11}
                        >
                            CANCEL
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
};

export default AgentFormField;
