import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiGetUserOrganizationInformation,
    apiAddOrganizationData,
    apiUpdateOrganizationData,
    apiChangeDefaultOrganization,
    apiGetUserOrganizationList,
    apiOrganizationDataSelectList,
} from '@services/API/apiFunctions';

const getUserOrganizationList = createAsyncThunk(
    'getUserOrganizationInformation',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetUserOrganizationList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const changeDefaultOrganization = createAsyncThunk(
    'getUserOrganizationInformation',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiChangeDefaultOrganization(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getUserOrganizationInformation = createAsyncThunk(
    'getUserOrganizationInformation',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetUserOrganizationInformation(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addOrganizationData = createAsyncThunk(
    'addOrganizationData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddOrganizationData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateOrganizationData = createAsyncThunk(
    'updateOrganizationData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateOrganizationData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const organizationDataSelectList = createAsyncThunk(
    'organizationDataSelectList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOrganizationDataSelectList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    getUserOrganizationList,
    changeDefaultOrganization,
    getUserOrganizationInformation,
    addOrganizationData,
    updateOrganizationData,
    organizationDataSelectList,
};
