import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAcceptOffer,
    apiCheckGBLValidate,
    apiCheckZipLookUp,
    apiCreateOffer,
    apiGetDefaultFilters,
    apiOfferData,
    apiOfferHistory,
    apiOfferList,
    apiRejectOffer,
    apiUpdateOffer,
} from '@services/API/offerFunctions';

import {
    apiDeleteSelectedPackerLoad,
    apiOfferPackerData,
    apiSelectPackerLoad,
    apiUpdateOfferPcaker,
    apiAllDataList,
    apiDeleteScheduledPackerLoad,
} from '@services/API/apiFunctions';

const OfferList = createAsyncThunk(
    'OfferList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getDefaultFilters = createAsyncThunk(
    'getDefaultFilters',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetDefaultFilters(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const offerData = createAsyncThunk(
    'offerData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateOffer = createAsyncThunk(
    'updateOffer',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateOffer(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getAllDataList = createAsyncThunk(
    'getAllDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAllDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const createOffer = createAsyncThunk(
    'createOffer',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiCreateOffer(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const checkGBLValidate = createAsyncThunk(
    'checkGBLvalidate',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiCheckGBLValidate(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const changeOfferStatus = createAsyncThunk(
    'acceptOffer',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAcceptOffer(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const rejectOffer = createAsyncThunk(
    'rejectOffer',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiRejectOffer(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const OfferHistory = createAsyncThunk(
    'OfferHistory',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferHistory(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const checkZipLookUp = createAsyncThunk(
    'checkZipLookUp',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiCheckZipLookUp(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateOfferPacker = createAsyncThunk(
    'updateOfferPacker',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateOfferPcaker(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
const selectPackersLoad = createAsyncThunk(
    'selectPackersLoad',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiSelectPackerLoad(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getOfferPackerInfo = createAsyncThunk(
    'getOfferPackerInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferPackerData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteSelectedPackersLoad = createAsyncThunk(
    'deleteSelectedPackersLoad',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteSelectedPackerLoad(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
const deleteScheduledPackersLoad = createAsyncThunk(
    'deleteSelectedPackersLoad',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteScheduledPackerLoad(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    OfferList,
    offerData,
    createOffer,
    updateOffer,
    changeOfferStatus,
    rejectOffer,
    OfferHistory,
    getDefaultFilters,
    checkGBLValidate,
    checkZipLookUp,
    updateOfferPacker,
    selectPackersLoad,
    getAllDataList,
    getOfferPackerInfo,
    deleteSelectedPackersLoad,
    deleteScheduledPackersLoad,
};
