import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Image, Row, Table } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactPagination from '@components/reactPagination';
import agentNotFound from '@images/agent-not-found.svg';

import CommonActions from '@app/SettingsLists/CommonActions';

const AgentTable = ({
    currentPageData,
    handleViewShow,
    handleEditShow,
    showAlert,
    pageCount,
    handlePageClick,
    agentFilters,
    resMessage,
}: any) => {
    const { agentData } = useSelector((state: any) => state);
    const { isDeleteAgent, isAgentList } = agentData;

    return isAgentList ? (
        <span className="ms-5">
            <LoaderBtn size="sm" />
        </span>
    ) : !isDeleteAgent && currentPageData && currentPageData.length !== 0 ? (
        <>
            <div className="overflow-table-auto agent_table">
                <Table
                    borderless
                    className="mfnw__borderless_card-table mb-0 w-100"
                >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>TYPE</th>
                            <th>CONTACT</th>
                            <th>PHONE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isDeleteAgent &&
                        currentPageData &&
                        currentPageData.length !== 0 ? (
                            currentPageData.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td
                                        onClick={() =>
                                            handleViewShow(item?.agent_nano_id)
                                        }
                                        className="text-truncate"
                                    >
                                        {item?.agent_name || '-'}
                                    </td>
                                    <td
                                        onClick={() =>
                                            handleViewShow(item?.agent_nano_id)
                                        }
                                        className="text-truncate"
                                    >
                                        {item?.agent_email || '-'}
                                    </td>
                                    <td
                                        onClick={() =>
                                            handleViewShow(item?.agent_nano_id)
                                        }
                                        className="text-truncate text-capitalize"
                                    >
                                        {item?.agent_type || '-'}
                                    </td>
                                    <td
                                        onClick={() =>
                                            handleViewShow(item?.agent_nano_id)
                                        }
                                        className="text-truncate"
                                    >
                                        {item?.contact_info?.[0]?.name ||
                                            '(N/A)'}
                                    </td>
                                    <td
                                        onClick={() =>
                                            handleViewShow(item?.agent_nano_id)
                                        }
                                        className="text-truncate"
                                    >
                                        {item?.agent_phone}
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-end ">
                                            <CommonActions
                                                item={item}
                                                showAlert={showAlert}
                                                handleEditShow={handleEditShow}
                                                nanoId={item?.agent_nano_id}
                                                isUserList={false}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                {isAgentList || isDeleteAgent ? (
                                    <span className="ms-5">
                                        <LoaderBtn size="sm" />
                                    </span>
                                ) : (
                                    <td>
                                        <h3>{resMessage}</h3>
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div>
                <Row>
                    <Col className="mt-3 mt-md-0p-md-5 overflow-auto overflow-y-hidden flex-nowrap">
                        <ReactPagination
                            currentPageData={currentPageData}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            filters={agentFilters}
                        />
                    </Col>
                </Row>
            </div>
        </>
    ) : (
        <>
            {currentPageData && currentPageData.length === 0 && (
                <Row className="justify-content-center flex-column align-items-center">
                    <Col md={6} xl={3} className="text-center mt-5">
                        <Image src={agentNotFound} fluid />
                        <h5 className="text-light mt-4 fw-bold">
                            No agents have been added.
                            <br />
                            Don&rsquo;t worry, we will ask you to enter one when
                            needed.
                        </h5>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default AgentTable;
