import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
    searchTimeOut,
} from '@constants/general.const';

import {
    addForwarder,
    updateForwarder,
} from '@app/SettingsLists/TSP/functions';
import { ZipLookUp } from '@app/SettingsLists/lists.const';
import { forwarderDefaultData, schema } from '@app/SettingsLists/TSP/tsp.const';
import TspFormField from '@app/SettingsLists/TSP/tspFormField';

import { IListOption } from '../../../Interface/common';
import { IContactInfoProps, IForwarderFieldsProps } from './tsp.interface';

const TspAddOrEditPopup = ({
    showPopup,
    handleClose,
    editData,
    getDataListCall,
}: any) => {
    const dispatch = useDispatch<any>();

    const { forwarderData } = useSelector((state: any) => state);
    const { isAddForwarder, isUpdateForwarder, isGetForwarderInfo } =
        forwarderData;

    const [cityArr, setCityArr] = useState<IListOption[]>([]);
    const [zipError, setZipError] = useState<string | null>(null);

    const TspId = editData?.forwarder_nano_id;

    const {
        handleSubmit,
        control,
        reset,
        watch,
        getValues,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm<IForwarderFieldsProps>({
        resolver: yupResolver(schema),
        defaultValues: forwarderDefaultData,
    });

    useEffect(() => {
        reset(editData);
        if (
            editData &&
            editData.forwarder_contact_info &&
            editData.forwarder_contact_info.length > 0
        ) {
            const updatedContactInfo = editData.forwarder_contact_info.map(
                (contact: IContactInfoProps) => {
                    const newPhoneNumber = contact?.phone_number?.map(
                        (number: any) => ({
                            ...number,
                            type:
                                number?.phoneNumberType === 'work'
                                    ? 'Work'
                                    : 'Mobile',
                            phone_no: number?.phone_number,
                            is_default: number?.is_default === 1 ? '1' : '0',
                        }),
                    );
                    const updatedPhoneArray = newPhoneNumber;
                    return {
                        ...contact,
                        phone_number: updatedPhoneArray,
                    };
                },
            );
            setValue('forwarder_contact_info', updatedContactInfo);
        }
    }, [editData]);

    const organizationZipCode = watch('forwarder_zip_code');

    useEffect(() => {
        const getData: NodeJS.Timeout = setTimeout(() => {
            organizationZipCode &&
                ZipLookUp(
                    'origin',
                    dispatch,
                    getValues,
                    setValue,
                    setCityArr,
                    setZipError,
                    'forwarder_zip_code',
                    'forwarder_city',
                    'forwarder_city_name',
                    'forwarder_state',
                    'forwarder_state_name',
                );
        }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [organizationZipCode]);

    const HandleChange = async (data: IForwarderFieldsProps) => {
        if (!isValid) return;
        const { payload } = await dispatch(
            TspId ? updateForwarder(data) : addForwarder(data),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            getDataListCall();
            handleClose();
            reset(forwarderDefaultData);
        } else if (payload?.message !== '') {
            Swal.fire({
                title: 'Error',
                text: payload?.message || '',
                icon: 'error',
                cancelButtonColor: cancelButtonColor,
            });
        }
    };

    const watchContactInfo = watch('forwarder_contact_info');

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset(forwarderDefaultData);
                    setCityArr([]);
                }
            });
        } else {
            handleClose();
            reset(forwarderDefaultData);
            setCityArr([]);
        }
    };

    return (
        <Modal
            show={showPopup}
            onHide={onClose}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-upper"
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {TspId ? 'Edit TSP' : 'Add New TSP'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {isGetForwarderInfo ? (
                    <LoaderBtn />
                ) : (
                    <Form className="m-3" onSubmit={handleSubmit(HandleChange)}>
                        <TspFormField
                            errors={errors}
                            control={control}
                            cityArr={cityArr}
                            zipError={zipError}
                            onClose={onClose}
                            isAddForwarder={isAddForwarder}
                            isUpdateForwarder={isUpdateForwarder}
                            setValue={setValue}
                            getValues={getValues}
                            watchContactInfo={watchContactInfo}
                            TspId={TspId}
                            isDisabled={false}
                        />
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default TspAddOrEditPopup;
