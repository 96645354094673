import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import { addOfficeData, deleteOffice, getOfficeDataList } from './functions';

const defaultState = {
    isAddOfficeData: false,
    isDeleteOffice: false,
    isGetOfficeDataList: false,
    isUpdateOrganizationData: false,
    officeData: {
        office_name: '',
    },
};

export const officeStep2Slice: any = createSlice({
    name: 'officeStep2Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addOfficeData.pending.type]: (state: any, { payload }) => {
            state.isAddOfficeData = true;
        },
        [addOfficeData.fulfilled.type]: (state: any, { payload }) => {
            state.isAddOfficeData = false;
        },
        [addOfficeData.rejected.type]: (state: any, { payload }) => {
            state.isAddOfficeData = false;
        },

        [getOfficeDataList.pending.type]: (state: any, { payload }) => {
            state.isGetOfficeDataList = true;
        },
        [getOfficeDataList.fulfilled.type]: (state: any, { payload }) => {
            state.isGetOfficeDataList = false;
            if (state?.isDeleteOffice) {
                state.isDeleteOffice = false;
            }
        },
        [getOfficeDataList.rejected.type]: (state: any, { payload }) => {
            state.isGetOfficeDataList = false;
            if (state?.isDeleteOffice) {
                state.isDeleteOffice = false;
            }
        },

        [deleteOffice.pending.type]: (state: any, { payload }) => {
            state.isDeleteOffice = true;
        },
        [deleteOffice.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteOffice = true;
        },
        [deleteOffice.rejected.type]: (state: any, { payload }) => {
            state.isDeleteOffice = false;
        },
    },
});

export default officeStep2Slice.reducer;
