import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
    Badge,
    Button,
    Col,
    Row,
    Image,
    Form,
    FormLabel,
} from 'react-bootstrap';

import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import InputText from '@components/InputText/InputText';
import RadioButton from '@components/RadioButton/RadioButton';
import ErrorMsg from '@components/errorMsg';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import Region from '@images/region.svg';

const RegionPopUpFormField = ({
    regionError,
    control,
    errors,
    selectList,
    optionArr,
    setSearchStateText,
    setValue,
    setSelectList,
    isGetCityStateInfo,
    updateSelectedList,
    onClose,
    isAdd,
    getValues,
    isImageShow,
    memoizedRegionInfo,
}: any) => {
    const { regionsStep6Data } = useSelector((state: any) => state);
    const { isAddRegion, isUpdateRegion, isDeleteRegion, isGetRegionInfo } =
        regionsStep6Data;

    return (
        <Row className="justify-content-center">
            {isImageShow && (
                <Col md={12} className="text-center">
                    <Image src={Region} alt="building icon" />
                </Col>
            )}
            <div className="mb-3 text-center">
                {regionError && <ErrorMsg errorText={regionError}></ErrorMsg>}
            </div>
            {isDeleteRegion || isGetRegionInfo ? (
                <LoaderBtn />
            ) : (
                <>
                    <Col md={12} className="my-3">
                        {memoizedRegionInfo && memoizedRegionInfo}
                        <Form.Label>REGION TYPE</Form.Label>
                        <div className="d-flex justify-content-between">
                            {(isAdd ||
                                getValues('region_type') === 'State') && (
                                <Controller
                                    name="region_type"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <RadioButton
                                            onChangeFunc={onChange}
                                            id="inline-radio-1"
                                            name="region_type"
                                            value="State"
                                            classNameParentDiv="bg-icon_radio_state"
                                            checked={value === 'State'}
                                            label="STATE"
                                            classes=""
                                            tabindex={0}
                                            autoFocus={true}
                                        />
                                    )}
                                />
                            )}
                            {(isAdd || getValues('region_type') === 'City') && (
                                <Controller
                                    name="region_type"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <RadioButton
                                            onChangeFunc={onChange}
                                            id="inline-radio-2"
                                            name="region_type"
                                            value="City"
                                            checked={value === 'City'}
                                            classNameParentDiv="bg-icon_radio_city"
                                            label="CITY"
                                            classes=""
                                            tabindex={0}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="mt-4">
                                    <Controller
                                        name="region_name"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <InputText
                                                label="REGION INFO"
                                                onChangeFunc={onChange}
                                                value={value}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                id="region name"
                                                inputName="region_name"
                                                inputType="text"
                                                placeholder="Name"
                                                tabIndex={0}
                                                errorText={
                                                    errors?.region_name?.message
                                                }
                                                disabled={!isAdd}
                                            />
                                        )}
                                    />
                                </div>
                                {isAdd && (
                                    <div className="mt-4">
                                        <Controller
                                            name="region_state"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <ReactSelectGroup
                                                    value={
                                                        value === selectList
                                                            ? value
                                                            : selectList
                                                    }
                                                    name={'region_state'}
                                                    inputClassName="mfnw__input-group"
                                                    options={optionArr}
                                                    onInputChange={(
                                                        searchStateValue: any,
                                                    ) => {
                                                        setSearchStateText(
                                                            searchStateValue,
                                                        );
                                                    }}
                                                    isMulti={true}
                                                    labelShow={false}
                                                    tabIndex={0}
                                                    onChangeFunc={(
                                                        stateValue: any,
                                                    ) => {
                                                        setValue(
                                                            'region_state',
                                                            stateValue,
                                                        );
                                                        setSelectList(
                                                            stateValue,
                                                        );
                                                    }}
                                                    isLoading={
                                                        isGetCityStateInfo
                                                    }
                                                    label={''}
                                                    isSearchable={true}
                                                    errorText={
                                                        errors?.region_state
                                                            ?.message
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col md={12}>
                                <FormLabel className="mt-3">
                                    LIST OF STATES
                                </FormLabel>
                                <Row className="mt-3 g-0">
                                    {selectList?.map(
                                        (item: any, index: number) => (
                                            <Badge
                                                key={index}
                                                bg="light"
                                                className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                            >
                                                <span className="me-3">
                                                    {item?.label}
                                                </span>
                                                {isAdd && (
                                                    <div
                                                        onClick={() =>
                                                            updateSelectedList(
                                                                item?.value,
                                                            )
                                                        }
                                                        className="cursor-pointer"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faCircleXmark}
                                                            color="#b7b7b7"
                                                            size="lg"
                                                        />
                                                    </div>
                                                )}
                                            </Badge>
                                        ),
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {isAdd && (
                        <Col md={12}>
                            <Button
                                className="button__details bg-primary w-100 mt-3 text-uppercase"
                                type="submit"
                                tabIndex={0}
                                disabled={isAddRegion || isUpdateRegion}
                            >
                                {isAddRegion || isUpdateRegion ? (
                                    <LoaderBtn />
                                ) : (
                                    'SAVE'
                                )}
                            </Button>
                            <Button
                                className="btn-cancel mt-4 w-100 text-uppercase fw-semibold"
                                onClick={() => onClose()}
                            >
                                CANCEL
                            </Button>
                        </Col>
                    )}
                </>
            )}
        </Row>
    );
};

export default RegionPopUpFormField;
