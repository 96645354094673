import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import { jobHistoryList } from '@app/JobList/History/functions';

const defaultState = {
    isJobHistoryList: false,
};

export const historySlice: any = createSlice({
    name: 'login',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [jobHistoryList.pending.type]: (state: any, { payload }) => {
            state.isJobHistoryList = true;
            state.registerResponse = payload;
        },
        [jobHistoryList.fulfilled.type]: (state: any, { payload }) => {
            state.isJobHistoryList = false;
            state.registerResponse = payload;
        },
        [jobHistoryList.rejected.type]: (state: any, { payload }) => {
            state.isJobHistoryList = false;
            state.registerResponse = payload;
        },
    },
});

export { jobHistoryList };

export default historySlice.reducer;
