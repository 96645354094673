import React from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';

import InfoDiv from '@app/OffersList/offerContentComponent/infoDiv';
import AddressDiv from '@app/OffersList/offerContentComponent/addressDiv';
import MainInfoDiv from '@app/OffersList/offerContentComponent/MainInfoDiv';
import { dateTimeZone } from '@utils/utilFunctions';

const GridViewOffer = ({
    handleViewShow,
    offer,
    handleAcceptActions,
    handleAcceptViewShow,
    isRejectOffer,
}: any) => (
    <>
        <Col xl={6}>
            <Card className="border-0 p-4 rounded-4 mb-4 cursor-pointer offer-btn">
                <Row onClick={() => handleViewShow(offer?.offers_nano_id)}>
                    <Col xs={12} sm={6}>
                        <MainInfoDiv
                            name={'SHIPPER'}
                            value={
                                offer?.shipperdata?.shipper_name ??
                                offer?.shipper
                            }
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <MainInfoDiv
                            name={'OFFERER'}
                            value={offer?.forwarder_data?.name}
                        />
                    </Col>
                </Row>
                <Row
                    className="align-items-start"
                    onClick={() => handleViewShow(offer?.offers_nano_id)}
                >
                    <Col xs={12} sm={6}>
                        <AddressDiv
                            icon={OriginIcon}
                            offerAddress={offer?.address_origin}
                            addressName="ORIGIN"
                            color="text-primary"
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <AddressDiv
                            icon={Destination}
                            offerAddress={offer?.address_destination}
                            addressName="DESTINATION"
                            color="text-lightpink"
                        />
                    </Col>
                </Row>
                <div
                    className="mb-0 mb-sm-3"
                    onClick={() => handleViewShow(offer?.offers_nano_id)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="1"
                        viewBox="0 0 1620 1"
                        fill="none"
                    >
                        <path
                            d="M0 1H1620"
                            stroke="#898989"
                            strokeWidth="0.8"
                            strokeDasharray="8 8"
                        />
                    </svg>
                </div>
                <Row onClick={() => handleViewShow(offer?.offers_nano_id)}>
                    <Col xs={6} sm={4} md={3} className="order-md-0">
                        <div className="mb-2 mb-lg-4">
                            <InfoDiv
                                name="WEIGHT"
                                value={offer?.estimatedWeight}
                                bold={true}
                            />
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3} className="order-md-0">
                        {offer?.type === 'COD' ? (
                            <InfoDiv
                                name="B/L"
                                value={
                                    offer?.forwarderBillOfLaden !== ''
                                        ? offer?.forwarderBillOfLaden
                                        : '-'
                                }
                            />
                        ) : (
                            <InfoDiv
                                name="GBL #"
                                value={
                                    offer?.govBillOfLaden !== ''
                                        ? offer?.govBillOfLaden
                                        : '-'
                                }
                            />
                        )}
                    </Col>
                    <Col xs={6} sm={4} md={3} className="order-md-0">
                        {offer?.line_haule_data?.discount ? (
                            <InfoDiv
                                name="LH(DISC & MILES)"
                                value={`  ${
                                    offer?.line_haule_data?.discount
                                }% |${' '}
                            ${offer?.line_haule_data?.miles} miles`}
                            />
                        ) : (
                            <InfoDiv
                                name="LINE HAUL"
                                value={`${offer?.line_haule_data?.miles} miles`}
                            />
                        )}
                    </Col>
                    <Col xs={6} sm={4} md={3} className="order-md-2">
                        <InfoDiv
                            name="PACK"
                            value={dateTimeZone(offer?.packDate)}
                        />
                    </Col>
                    <Col xs={6} sm={4} md={3} className="order-md-3">
                        <InfoDiv
                            name="PICKUP"
                            value={dateTimeZone(offer?.pickupDate)}
                        />
                    </Col>
                    <Col xs={6} sm={4} md={3} className="order-md-4">
                        <InfoDiv
                            name="RDD"
                            value={dateTimeZone(offer?.deliveryDate)}
                        />
                    </Col>
                    <Col
                        xs={6}
                        sm={4}
                        md={3}
                        className="order-md-1 pt-1 pt-sm-0"
                    >
                        <div className="division-section mb-2 mb-sm-0">
                            <div className="d-flex flex-wrap gap-2">
                                <div className="box-table text-center">
                                    <span className="text-light text-sm">
                                        PACK($)
                                    </span>
                                    <h6 className="mb-0 text-success text-base tetx-bold text-center">
                                        (N/A)
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={6}
                        sm={4}
                        md={3}
                        className="order-md-5 pt-1 pt-sm-0"
                    >
                        <div className="division-section mb-2 mb-sm-0">
                            <div className="d-flex flex-wrap gap-2">
                                <div className="box-table text-center">
                                    <span className="text-light text-sm">
                                        HAUL($)
                                    </span>
                                    <h6 className="mb-0 text-success text-base tetx-bold text-center">
                                        (N/A)
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div
                    className="mb-0 mb-sm-3"
                    onClick={() => handleViewShow(offer?.offers_nano_id)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="1"
                        viewBox="0 0 1620 1"
                        fill="none"
                    >
                        <path
                            d="M0 1H1620"
                            stroke="#898989"
                            strokeWidth="0.8"
                            strokeDasharray="8 8"
                        />
                    </svg>
                </div>
                <Row>
                    <Col xs={12} sm={6} className="text-center">
                        <Button
                            variant="primary"
                            className="btn_type_large fw-semibold m-auto mb-3 mb-lg-0"
                            onClick={() => {
                                handleAcceptViewShow(offer?.offers_nano_id);
                            }}
                        >
                            ACCEPT
                        </Button>
                    </Col>
                    <Col xs={12} sm={6} className="text-center">
                        <Button
                            className="btn-cancel btn_type_large btn-backgroud fw-semibold m-auto mb-lg-0"
                            onClick={() =>
                                handleAcceptActions(
                                    offer?.offers_nano_id,
                                    'rejected',
                                )
                            }
                        >
                            {isRejectOffer ? <LoaderBtn /> : 'REJECT'}
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Col>
    </>
);

export default GridViewOffer;
