import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addOfferNote,
    deleteOfferNote,
    getOfferNoteInfo,
    offerNotesList,
    updateOfferNote,
} from '@app/JobList/OfferNotes/functions';

const defaultState = {
    isAddOfferNote: false,
    isOfferNotesList: false,
    isUpdateOfferNote: false,
    isGetOfferNoteInfo: false,
    isDeleteOfferNote: false,
};

export const offerNoteSlice: any = createSlice({
    name: 'offerNotes',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addOfferNote.pending.type]: (state: any, { payload }) => {
            state.isAddOfferNote = true;
            state.registerResponse = payload;
        },
        [addOfferNote.fulfilled.type]: (state: any, { payload }) => {
            state.isAddOfferNote = false;
            state.registerResponse = payload;
        },
        [addOfferNote.rejected.type]: (state: any, { payload }) => {
            state.isAddOfferNote = false;
            state.registerResponse = payload;
        },

        [offerNotesList.pending.type]: (state: any, { payload }) => {
            state.isOfferNotesList = true;
            state.registerResponse = payload;
        },
        [offerNotesList.fulfilled.type]: (state: any, { payload }) => {
            state.isOfferNotesList = false;
            state.registerResponse = payload;
        },
        [offerNotesList.rejected.type]: (state: any, { payload }) => {
            state.isOfferNotesList = false;
            state.registerResponse = payload;
        },

        [updateOfferNote.pending.type]: (state: any, { payload }) => {
            state.isUpdateOfferNote = true;
            state.registerResponse = payload;
        },
        [updateOfferNote.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateOfferNote = false;
            state.registerResponse = payload;
        },
        [updateOfferNote.rejected.type]: (state: any, { payload }) => {
            state.isUpdateOfferNote = false;
            state.registerResponse = payload;
        },

        [getOfferNoteInfo.pending.type]: (state: any, { payload }) => {
            state.isGetOfferNoteInfo = true;
            state.registerResponse = payload;
        },
        [getOfferNoteInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetOfferNoteInfo = false;
            state.registerResponse = payload;
        },
        [getOfferNoteInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetOfferNoteInfo = false;
            state.registerResponse = payload;
        },

        [deleteOfferNote.pending.type]: (state: any, { payload }) => {
            state.isDeleteOfferNote = true;
            state.registerResponse = payload;
        },
        [deleteOfferNote.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteOfferNote = false;
            state.registerResponse = payload;
        },
        [deleteOfferNote.rejected.type]: (state: any, { payload }) => {
            state.isDeleteOfferNote = false;
            state.registerResponse = payload;
        },
    },
});

export {
    addOfferNote,
    offerNotesList,
    updateOfferNote,
    getOfferNoteInfo,
    deleteOfferNote,
};

export default offerNoteSlice.reducer;
