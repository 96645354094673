import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import Arrow from '@images/replace.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';

const PackLoadUnloadTable = ({ data }: any) => (
    <>
        <h5 className="text-center mb-4 font-weight-bold text-primary">
            {data?.affected_tab} Information (
            {data?.is_created
                ? 'Created'
                : data?.is_deleted
                ? 'Deleted'
                : 'Updated'}
            )
        </h5>
        <div className="overflow-table-auto">
            <Table size="sm" className="border table_fwnp">
                <thead>
                    <tr className="table-headings">
                        <th className="text-sm fw-semibold text-center  px-4 py-3 text-truncate">
                            DATE
                        </th>
                        <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                            ACTION
                        </th>
                        <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                            CREWED BY
                        </th>
                        <th className="text-sm fw-semibold text-center text-center py-3 text-center text-truncate">
                            STATUS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        Object.entries(data?.changed_fields).map(
                            ([index, item]: any) =>
                                item?.new && item?.old ? (
                                    <React.Fragment key={index}>
                                        {renderTableRow(item?.old, index)}
                                        <tr
                                            key={`arrow-${index}`}
                                            className="text-center"
                                        >
                                            <td colSpan={4}>
                                                <FontAwesomeIcon
                                                    icon={faArrowDown}
                                                />
                                            </td>
                                        </tr>
                                        {renderTableRow(item?.new, index)}
                                    </React.Fragment>
                                ) : (
                                    renderTableRow(item, index)
                                ),
                        )}
                </tbody>
            </Table>
        </div>
    </>
);

const renderTableRow = (item: any, index: number) => (
    <tr key={index}>
        <td className="text-base fw-normal text-center py-3 text-truncate">
            {item?.pack_date}
        </td>
        <td className="text-base fw-normal text-center py-3 text-capitalize text-truncate">
            {item?.packer_action}
        </td>
        <td className="text-base fw-normal py-3 text-truncate">
            <p className="space_overflow text-center p-0 m-0 text-break text-wrap">
                {item?.packer_name &&
                    Object.keys(item?.packer_name).map((key: any) => (
                        <span key={key} className="text-break">
                            {item?.packer_name[key]}
                            {key < Object.keys(item?.packer_name).length - 1 &&
                                ', '}
                        </span>
                    ))}
            </p>
        </td>
        <td className="text-truncate">
            <div className="d-flex justify-content-between">
                {item?.packer_status && item?.packer_status === 'assigned' ? (
                    <Badge className="bg-primary text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                        Assigned
                        <img src={Arrow} alt="" className="ps-2" />
                    </Badge>
                ) : (
                    <>
                        {item?.packer_status === 'pending' ? (
                            <Badge className="bg-active text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                                Pending
                                <img src={Arrow} alt="" className="ps-2" />
                            </Badge>
                        ) : (
                            <Badge className="bg-success text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                                Confirmed
                                <img src={Arrow} alt="" className="ps-2" />
                            </Badge>
                        )}
                    </>
                )}
            </div>
        </td>
    </tr>
);

export default PackLoadUnloadTable;
