/* eslint-disable complexity */
import { dateFormat, dateFormatPlaceholder } from '@constants/general.const';
import { toast } from 'react-toastify';
import __ from 'lodash';

import {
    countingForMb,
    uploadDocumentSizeLimit,
    validType,
} from '@constants/general.const';
import moment from 'moment-timezone';
import { selectedFileUpload } from '../app/SettingsLists/Drivers/functions';

export const checkFileSize = (file: string): boolean => {
    const MAX_FILE_SIZE = 5242880; //bytes
    const stringLength = file.length - 'data:image/png; base64,'.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    return Math.abs(sizeInBytes) <= MAX_FILE_SIZE;
};

export const blobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });

// export const setLocalDate = (date: any, format: string = '') =>
//     date
//         ? moment
//               .utc(date)
//               .local()
//               .format(format ? format : dateFormat)
//         : dateFormatPlaceholder;

export const setTimeZone = (date: any, format: string = '') =>
    date
        ? moment
              .utc(date)
              .tz('America/New_York')
              .format(format ? format : dateFormat)
        : dateFormatPlaceholder;

export const dateTimeZone = (date: any) =>
    date
        ? moment(moment(date).tz('America/New_York').toDate()).format(
              dateFormat,
          )
        : '-';

export const checkFileSizeValidation = (uploadedFile: Array<File>) => {
    const fileSizeValidation = __.map(uploadedFile, (file: File) => {
        if (file.size === 0) {
            toast.error('Files must be greater than 0 bytes');
        }
        return (
            file.size !== 0 &&
            file.size / countingForMb / countingForMb <= uploadDocumentSizeLimit
        );
    });
    if (!__.every(fileSizeValidation, (value: boolean) => value === true)) {
        toast.error(`Max image size is ${uploadDocumentSizeLimit}MB.`);
        return false;
    }
    return true;
};

export const checkFileTypeValidation = (uploadedFile: Array<File>) => {
    const fileTypeValidation = __.map(uploadedFile, (file: File) =>
        validType.includes(file.type),
    );
    if (!__.every(fileTypeValidation, (value: boolean) => value === true)) {
        toast.error('Upload failed. Document type not supported.');
        return false;
    }
    return true;
};

export const getElapsedTime = (offer: any) => {
    var a = moment().utc();
    var b = moment.utc(offer?.offeredOn).local();
    var years = a.diff(b, 'year');
    b.add(years, 'years');

    var months = a.diff(b, 'months');
    b.add(months, 'months');

    var week = a.diff(b, 'week');
    b.add(week, 'week');

    var days = a.diff(b, 'days');
    b.add(days, 'days');

    var hours = a.diff(b, 'hours');
    b.add(hours, 'hours');

    var yearText = years > 1 ? ' Years' : ' Year';
    var monthText = months > 1 ? ' Months' : ' Month';
    var weekText = week > 1 ? ' Weeks' : ' Week';
    var daysText = days > 1 ? ' Days' : ' Day';
    var hoursText = hours > 1 ? ' Hours' : ' Hour';
    if (years !== 0 && months !== 0) {
        return `${years + yearText}, ${months}${monthText}`;
    }

    if (years !== 0 && months === 0) {
        return years + yearText;
    }

    if (months !== 0 && week !== 0) {
        return `${months + monthText}, ${week}${weekText}`;
    }

    if (months !== 0 && week === 0) {
        return months + monthText;
    }

    if (week !== 0 && days !== 0) {
        return `${week + weekText}, ${days}${daysText}`;
    }

    if (week !== 0 && days === 0) {
        return week + weekText;
    }

    if (days !== 0 && hours !== 0) {
        return `${days + daysText}, ${hours}${hoursText}`;
    }

    if (days !== 0 && hours === 0) {
        return days + daysText;
    } else {
        return hours > 0 ? hours + hoursText : `< ${1}${hoursText}`;
    }
};

export const uploadFile = async (
    fileArray: any,
    setProgressBar: any,
    setFileProgress: any,
    setFilePreview: any,
    filePreview: any,
    currentField: any,
    secondField: any,
    checkFileLengthValidation: any,
    dispatch: any,
    setValue: any,
    getValues: any,
) => {
    if (!fileArray.files) return;

    const validFileLength = checkFileLengthValidation(currentField);
    const validFileSize =
        validFileLength && checkFileSizeValidation(fileArray.files);
    const validFileType =
        validFileSize && checkFileTypeValidation(fileArray.files);

    if (validFileType) {
        setProgressBar(true);
        setFileProgress(0);

        const formData = new FormData();
        formData.append('files', fileArray.files[0]);

        const { payload } = await dispatch(
            selectedFileUpload({
                formData,
                func: setFileProgress,
            }),
        );

        if (payload?.data?.status) {
            const newCertificateValue = [
                ...(typeof getValues(currentField) === 'string'
                    ? JSON.parse(getValues(currentField))
                    : getValues(currentField)),
                {
                    url: payload.data.data.url,
                    name: payload.data.data.currentFileName,
                    fileSize: payload.data.data.fileSize,
                },
            ];

            setValue(currentField, newCertificateValue);
            if (secondField) {
                setValue(secondField, getValues(secondField));
            }

            const newPreview = [
                ...filePreview,
                {
                    url: payload.data.data.url,
                    name: payload.data.data.currentFileName,
                    fileSize: payload.data.data.fileSize,
                },
            ];

            setFilePreview(newPreview);
            setProgressBar(false);
        }
    }
};
