import React, { useState } from 'react';

import JobTabCalender from '@app/Schedule/JobCalender/jobTabCalender';

import '../Schedule/schedule.scss';

const WorkJobs = () => {
    //  ask fore type

    const [viewName, setViewName] = useState<string>('Monthly Job View');

    return (
        <div className="px-4 mt-5 ">
            <JobTabCalender
                type={'job'}
                setViewName={setViewName}
                viewName={viewName}
            />
        </div>
    );
};

export default WorkJobs;
