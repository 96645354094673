export const apiRoutesPath = {
    login: '/login',
    logout: '/logout',
    register: '/register',
    verifyUser: '/user/verify/',
    forgotPassword: '/forgot/password',

    inviteUser: '/invite/user',
    reInviteUser: '/reinvite/user/',
    inviteCancelUser: '/invite-cancel/user/',
    deleteUser: '/delete/user',
    updateUser: '/update/user-info',
    getUserList: '/users/list?page=',
    editUser: '/user/edit/',
    getUserInfo: '/user/profile',
    updateProfile: '/update/profile',
    generateKey: '/generate-key',

    sendOtp: '/sendOtp',

    getForwarders: '/forwarders/',
    deleteForwarders: '/forwarders/',
    addForwarders: '/forwarders',
    updateForwarders: '/update/forwarder',
    listForwarders: '/forwarders/list?page=',

    getAgent: '/agents/',
    deleteAgent: '/agents/',
    addAgent: '/agents',
    updateAgent: '/update/agent',
    agentList: '/agents/list?page=',

    allDataList: '/all-data-list',

    addDrivers: '/drivers',
    updateDrivers: '/drivers/',
    listDrivers: '/drivers/list?page=',
    getDriver: '/drivers/',
    deleteDriver: '/drivers/',

    listFuleSurcharge: '/fuel-surcharge/list',

    addOfferNote: '/offer-notes',
    updateOfferNote: '/offer-notes/',
    listOfferNote: '/offer-notes/list?page=',
    getOfferNote: '/offer-notes/',
    deleteOfferNote: '/offer-notes/',

    addDocument: '/documents',
    updateDocument: '/documents/',
    listDocuments: '/documents/list?page=',
    getDocumentData: '/documents/',
    deleteDocumentData: '/documents/',

    listJobHistory: '/job-history?page=',

    addPackers: '/packers',
    updatePackers: '/packers/',
    listPackers: '/packers/list?page=',
    getPackerData: '/packers/',
    deletePackerData: '/packers/',

    addHolidays: '/holidays',
    updateHolidays: '/holidays/',
    listHolidays: '/holidays/list?page=',
    getHolidayData: '/holidays/',
    deleteHolidayData: '/holidays/',

    getCityStateData: '/city-state',
    addRegion: '/regions',
    listRegion: '/regions/list?page=',
    getRegionData: '/regions/',
    deleteRegionData: '/regions/',
    updateRegionData: '/regions/',
    step1: '/step1',
    step2: '/step2',
    step3: '/step3',
    step4: '/step4',
    step5: '/step5',
    step6: '/step6',

    organization: '/organizations/list?page=',
    addOrganization: '/organizations',
    updateOrganization: '/organizations/',
    getUserOrganizationData: '/user-organization-data',
    setDefaultOrganizationData: '/set-default-organization/',
    organizationDataSelectList: '/organization/select-list',

    officesSearch: '/office/search',
    addOffice: '/offices',
    getOfficeList: '/offices',
    deleteOffice: '/offices/',

    departmentSearch: '/department/search',
    getDepartmentsList: '/departments',
    addDepartments: '/departments',
    deleteDepartments: '/departments/',

    organizationRoleSearch: '/organization-role/search',
    getRolesList: '/organization-roles',
    addRoles: '/organization-roles',
    deleteRoles: '/organization-roles/',

    jobTitleSearch: '/job-title/search',
    getJobTitlesList: '/job-titles',
    addJobTitles: '/job-titles',
    deleteJobTitles: '/job-titles/',

    regionSearch: '/region/search',
    regionLists: '/region-list',
    getRegionsList: '/regions',
    addRegions: '/regions',
    deleteRegions: '/regions/',

    groupsSearch: '/group/search',
    getGroupsList: '/groups',
    addGroups: '/groups',
    deleteGroups: '/groups/',

    membershipSearch: '/membership/search',
    addGroupMembers: '/memberships',
    getGroupUsers: '/membership/usersList/',
    deleteGroupUsers: 'memberships/',

    selectedFileUpload: '/documents/select/upload',

    getOfferPacker: '/offer-packer/',
    selectPacker: '/offer-packer',
    deleteSelectedPacker: '/offer-packer/',
    deleteScheduledPacker: '/delete/packer/',
    updateOfferPacker: '/offer-packer/',

    importException: '/import-exception?page=',
};
