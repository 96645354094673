import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Table, Image } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactPagination from '@components/reactPagination';

import {
    pageLimitList,
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
    showDeleteConfirmationDialog,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import tspNotFound from '@images/tsp-not-found.svg';

import { clearResponse } from '@redux/app/appSlice';

import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    deleteForwarder,
    forwarderList,
    getForwarderInfo,
} from '@app/SettingsLists/TSP/functions';
import TspAddOrEditPopup from '@app/SettingsLists/TSP/tspAddOrEditPopup';
import ListHeader from '@app/SettingsLists/listHeader';
import CommonActions from '@app/SettingsLists/CommonActions';
import TspViewPopup from '@app/SettingsLists/TSP/tspViewPopup';
import { tspTableFieldArr } from '@app/SettingsLists/TSP/tsp.const';
import { confimationDialogBoxText } from '@app/SettingsLists/lists.const';

const TspListPage = () => {
    const getFilters = async () => {
        FiltersData(
            searchText,
            getForwarderList,
            setFilters,
            'TSP_list',
            dispatch,
            navigate,
        );
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, forwarderData } = useSelector((state: any) => state);
    const { response } = common;
    const { isDeleteForwarder, isForwarderList } = forwarderData;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);
    const [isNanoId, setIsNanoId] = useState<boolean>(false);
    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<any>();
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [forwardersData, setForwardersData] = useState<any>(null);
    const [searchText, setSearchText] = useState<any>(null);

    const handleClose = () => setShowPopup(false);
    const handleShow = () => {
        setIsNanoId(true);
        setShowPopup(true);
    };
    const handleEditClose = () => setShowEditPopup(false);
    const handleEditShow = (nanoId: string) => {
        nanoId && getUserData(nanoId);
        nanoId ? setIsNanoId(true) : setIsNanoId(false);
        setShowViewPopup(false);
        setShowEditPopup(true);
    };
    const handleViewClose = () => setShowViewPopup(false);
    const handleViewShow = (nanoId: string) => {
        getUserData(nanoId);
        setShowViewPopup(true);
    };

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getForwarderList(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getUserData = async (tspId: string) => {
        const { payload } = await dispatch(getForwarderInfo(tspId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setForwardersData(payload?.data?.data);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const getForwarderList = async (currentPage: number, res: any) => {
        const { payload } = await dispatch(
            forwarderList({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            handlePayloadError(payload);
        }
    };

    const handlePayloadError = (payload: any) => {
        toast.error(payload?.message);
        if (payload?.logout) {
            localStorage.removeItem('accessToken');
            dispatch(updateIsLogOutUser());
            navigate(routesPath?.login);
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        setFilters(updateFilter);
        getForwarderList(updateFilter?.currentPage, updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        setFilters(updateFilter);
        getForwarderList(updateFilter?.currentPage, updateFilter);
    };

    const showAlert = async (id: any, type: string) => {
        const confimaBoxText = confimationDialogBoxText(type);

        const confirmationOptions = {
            title: confimaBoxText?.swalTitle,
            text: confimaBoxText?.swalText,
            icon: 'warning',
            confirmButtonText: confimaBoxText?.confirmButtonText,
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );

        if (isConfirmed) {
            const { payload } = await dispatch(
                deleteForwarder({ id, type: type }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                getForwarderList(1, filters);
                setShowViewPopup(false);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    return (
        <div>
            <ListHeader
                title={'Transportation Service Provider List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShow}
                handleLimitChange={handleLimitChange}
                buttonText={'Add New TSP'}
            />
            {isForwarderList ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : !isDeleteForwarder &&
              currentPageData &&
              currentPageData.length !== 0 ? (
                <>
                    <div className="overflow-table-auto tsp_table">
                        <Table
                            borderless
                            className="mfnw__borderless_card-table mb-0 w-100"
                        >
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>CONTACT</th>
                                    <th>PHONE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData.map(
                                    (item: any, index: number) => (
                                        <tr key={index}>
                                            {tspTableFieldArr.map(
                                                (
                                                    data: any,
                                                    dataIndex: number,
                                                ) => {
                                                    const fieldName =
                                                        data?.field;
                                                    return (
                                                        <td
                                                            onClick={() =>
                                                                handleViewShow(
                                                                    item?.forwarder_nano_id,
                                                                )
                                                            }
                                                            className="text-truncate"
                                                            key={dataIndex}
                                                        >
                                                            {item?.[
                                                                fieldName
                                                            ] || '(N/A)'}
                                                        </td>
                                                    );
                                                },
                                            )}
                                            <td>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <CommonActions
                                                        item={item}
                                                        showAlert={showAlert}
                                                        handleEditShow={
                                                            handleEditShow
                                                        }
                                                        nanoId={
                                                            item?.forwarder_nano_id
                                                        }
                                                        isUserList={false}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Row>
                            <Col className="mt-3 mt-md-0 px-5 overflow-auto overflow-y-hidden flex-nowrap">
                                <ReactPagination
                                    currentPageData={currentPageData}
                                    pageCount={pageCount}
                                    handlePageClick={handlePageClick}
                                    filters={filters}
                                />
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <>
                    {currentPageData && currentPageData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={tspNotFound} fluid />
                                <h5 className="text-light mt-4 fw-bold">
                                    No TSPs!!! Go ahead and add one.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {showPopup && (
                <TspAddOrEditPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getForwarderList(1, filters)}
                    editData={null}
                    isNanoId={isNanoId}
                />
            )}
            {showEditPopup && (
                <TspAddOrEditPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getForwarderList(1, filters)}
                    editData={forwardersData}
                    isNanoId={isNanoId}
                />
            )}

            {showViewPopup && (
                <TspViewPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    data={forwardersData}
                    handleEditShow={handleEditShow}
                    deleteById={showAlert}
                />
            )}
        </div>
    );
};
export default TspListPage;
