import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import TopLogo from '@images/top-logo.png';
import Location from '@images/location.svg';
import Envelope from '@images/envelope.svg';
import Phone from '@images/phone.svg';

import { getUserOrganizationInformation } from '@app/CreateOrganization/step1/functions';

const HeaderTopContent = ({ organizationId, showPopup }: any) => {
    const dispatch = useDispatch<any>();
    const { organizationStep1Data } = useSelector((state: any) => state);
    const { isGetUserOrganizationInformation } = organizationStep1Data;

    const [organizationData, setOrganizationData] = useState<any>(null);

    useEffect(() => {
        getDefaultOrganization();
    }, [showPopup]);

    const getDefaultOrganization = async () => {
        const { payload } = await dispatch(
            getUserOrganizationInformation({
                organization_nano_id: organizationId,
            }),
        );
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            setOrganizationData(payload?.data?.data);
        }
    };

    return (
        <div className="mfnw__left_content d-none d-md-flex">
            {isGetUserOrganizationInformation ? (
                <LoaderBtn />
            ) : (
                <>
                    {organizationData?.organization_name && (
                        <>
                            <div className="mfnw__circle-logo ms-5 ms-lg-0">
                                {organizationData?.organization_logo ? (
                                    <Image
                                        className="rounded-circle"
                                        height="100px"
                                        width="100px"
                                        src={
                                            organizationData?.organization_logo
                                        }
                                    />
                                ) : (
                                    <Image src={TopLogo} />
                                )}
                            </div>
                            <div className="mfnw__right-content text-capitalize">
                                <h4 className="cp_name">
                                    {organizationData?.organization_name}
                                </h4>
                                <p className="address mt-1">
                                    <Image src={Location} className="me-2" />
                                    {`${organizationData?.organization_address}, ${organizationData?.organization_city_name}, ${organizationData?.organization_state_name}, ${organizationData?.organization_zip_code}`}
                                </p>
                                <div className="d-flex justify-content-center mt-1 mfnw_navrespons">
                                    <a
                                        href="mailto:dispatch@crmover.com"
                                        className="firstblock fw-normal"
                                    >
                                        <Image
                                            src={Envelope}
                                            className="me-2"
                                        />
                                        {organizationData?.organization_email}
                                    </a>
                                    <div className="scndblock ms-md-4">
                                        <Image src={Phone} className="me-2" />
                                        {organizationData?.organization_phone}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default HeaderTopContent;
