import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ScacInputField from '@components/ScacInputField/ScacInputField';

import { phoneFormat } from '@constants/general.const';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/fontawesome-pro-regular';

import { organizationPopupStep1FieldArr } from '../../step1/step1.const';

const faphoneIcon = faPhone as IconProp;

const OrganizationPopUpFormField = ({
    control,
    cityArr,
    stateArr,
    setValue,
    zipError,
    fieldErrors,
    getValues,
    SCACarr,
    setSCACarrLength,
    isAddOrganizationData,
    isUpdateOrganizationData,
    SCACarrLength,
    onClose,
    setSCACarr,
}: any) => (
    <Row>
        {organizationPopupStep1FieldArr &&
            organizationPopupStep1FieldArr.map((field: any, index: number) => {
                const {
                    name,
                    label,
                    type,
                    placeholder,
                    col,
                    icon,
                    groupInput,
                    ClassNameCustom,
                    spacedText,
                    tabIndex,
                } = field;

                return (
                    <>
                        {name === 'organization_mc' && <hr className="mt-4" />}
                        <Col md={col} key={index} className={ClassNameCustom}>
                            <Controller
                                name={name}
                                control={control}
                                key={index}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <Fragment key={index}>
                                        {name === 'organization_city' ||
                                        name === 'organization_state' ? (
                                            <ReactSelectGroup
                                                value={(name ===
                                                'organization_city'
                                                    ? cityArr
                                                    : stateArr
                                                ).find(
                                                    (c: any) =>
                                                        c?.value === value,
                                                )}
                                                labelShow={groupInput}
                                                inputClassName="mfnw__input-group"
                                                options={
                                                    name === 'organization_city'
                                                        ? cityArr
                                                        : stateArr
                                                }
                                                onChangeFunc={(
                                                    valueStateTownChange: any,
                                                ) => {
                                                    if (
                                                        name ===
                                                        'organization_city'
                                                    ) {
                                                        setValue(
                                                            'organization_city',
                                                            valueStateTownChange?.value,
                                                        );
                                                        setValue(
                                                            'organization_city_name',
                                                            valueStateTownChange?.label,
                                                        );
                                                    } else {
                                                        setValue(
                                                            'organization_state',
                                                            valueStateTownChange?.value,
                                                        );
                                                        setValue(
                                                            'organization_state_name',
                                                            valueStateTownChange?.label,
                                                        );
                                                    }
                                                }}
                                                tabIndex={tabIndex}
                                                className="ss"
                                                label={label}
                                                isSearchable={
                                                    zipError ? true : false
                                                }
                                                isDisabled={
                                                    (!zipError &&
                                                    name === 'organization_city'
                                                        ? cityArr.length
                                                        : stateArr.length) === 1
                                                }
                                                placeholder={
                                                    name === 'organization_city'
                                                        ? 'City'
                                                        : 'State'
                                                }
                                            />
                                        ) : (
                                            <>
                                                {name ===
                                                'organization_phone' ? (
                                                    <PhoneInputMaskGroup
                                                        maskFormat={phoneFormat}
                                                        inputName="organization_phone"
                                                        inputType="text"
                                                        label="Phone No."
                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                        onChangeFunc={onChange}
                                                        id="organization_phone"
                                                        aria-describedby="phone"
                                                        tabIndex={tabIndex}
                                                        faphoneIcon={
                                                            faphoneIcon
                                                        }
                                                        value={value}
                                                        placeholder="Phone"
                                                        errorText={
                                                            fieldErrors?.[name]
                                                                ?.message
                                                        }
                                                    />
                                                ) : (
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        value={value}
                                                        tabIndex={tabIndex}
                                                        disabled={false}
                                                        id={name}
                                                        inputName={name}
                                                        inputType={type}
                                                        icon={icon}
                                                        placeholder={
                                                            placeholder
                                                        }
                                                        label={label}
                                                        className={spacedText}
                                                        groupInput={groupInput}
                                                        inputGroupClassName={
                                                            type === 'email'
                                                                ? ''
                                                                : 'mfnw__input-group'
                                                        }
                                                        errorText={
                                                            name ===
                                                                'organization_zip_code' &&
                                                            zipError
                                                                ? zipError
                                                                : fieldErrors?.[
                                                                      name
                                                                  ]?.message
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Fragment>
                                )}
                            />
                        </Col>
                    </>
                );
            })}
        <ScacInputField
            SCACarr={SCACarr}
            control={control}
            col={9}
            getValues={getValues}
            setSCACarrLength={setSCACarrLength}
            SCACarrLength={SCACarrLength}
            setSCACarr={setSCACarr}
            setValue={setValue}
            tabIndex={9}
        />

        <Col md={12}>
            <Button
                variant="primary w-100 mt-3 text-uppercase"
                className="button__details"
                type="submit"
                disabled={isAddOrganizationData || isUpdateOrganizationData}
            >
                {isAddOrganizationData || isUpdateOrganizationData ? (
                    <LoaderBtn />
                ) : (
                    'Save'
                )}
            </Button>
            <Button
                variant="default"
                className="btn-cancel mt-3 w-100 button__details"
                onClick={() => onClose()}
            >
                Cancel
            </Button>
        </Col>
    </Row>
);

export default OrganizationPopUpFormField;
