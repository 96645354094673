import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    getMembershipList,
    getUsersList,
    addGroupMemberData,
} from '@app/UserListing/MembershipList/functions';

const defaultState = {
    isGetMembershipList: false,
    isGetUsersList: true,
    isAddGroupMemberData: true,
};

export const membershipSlice: any = createSlice({
    name: 'regionsStep6Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [getMembershipList.pending.type]: (state: any) => {
            state.isGetMembershipList = true;
        },
        [getMembershipList.fulfilled.type]: (state: any) => {
            state.isGetMembershipList = false;
            if (state?.isDeleteRegion) {
                state.isDeleteRegion = false;
            }
        },
        [getMembershipList.rejected.type]: (state: any) => {
            state.isGetMembershipList = false;
            if (state?.isDeleteRegion) {
                state.isDeleteRegion = false;
            }
        },
        [getUsersList.pending.type]: (state: any) => {
            state.isGetUsersList = true;
        },
        [getUsersList.fulfilled.type]: (state: any) => {
            state.isGetUsersList = false;
        },
        [getUsersList.rejected.type]: (state: any) => {
            state.isGetUsersList = false;
        },

        [addGroupMemberData.pending.type]: (state: any) => {
            state.isAddGroupMemberData = true;
        },
        [addGroupMemberData.fulfilled.type]: (state: any) => {
            state.isAddGroupMemberData = false;
        },
        [addGroupMemberData.rejected.type]: (state: any) => {
            state.isAddGroupMemberData = false;
        },
    },
});

export const { saveLoggedInUser, loginChange } = membershipSlice.actions;

export default membershipSlice.reducer;
