/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Button, Table, Badge, Modal, Form } from 'react-bootstrap';

import Arrow from '@images/replace.svg';
import Clock from '@images/Clock.svg';

import DateSelect from '@components/DateSelect/DateSelect';
import DateRangeSelect from '@components/DateRengeSelect/DateRengeSelect';
import InputGroupText from '@components/InputGroupText/InputGroupText';
import ErrorMsg from '@components/errorMsg';

import {
    cancelButtonColor,
    confirmButtonColor,
    formatDateTOCurrent,
} from '@constants/general.const';

import { faLocationDot, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCalendarRange } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { scheduleOfferSchema } from '@app/OffersList/offers.const';
import { dateTimeZone } from '@utils/utilFunctions';
import SelectPackersPopup from '@app/JobList/selectPackersPopup';

import {
    deleteScheduledPackersLoad,
    updateOffer,
} from '../OffersList/functions';

import '@fortawesome/pro-light-svg-icons';

const faPlusIcon = faPlus as IconProp;

const JobInfo = ({
    showPopup,
    onClose,
    offerDataObj,
    packLoadUnloadData,
    packerName,
    isDisabled,
    handleEditShow,
    getOfferData,
    getFetchJobList,
    packerArrData,
    currentPackerTitle,
}: any) => {
    const dispatch = useDispatch<any>();

    const {
        control,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(scheduleOfferSchema),
    });

    const watchPackDate = watch('packDate');
    const watchPickupDate = watch('pickupDate');

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        null,
        null,
    ]);
    const [startDate, endDate] = dateRange;
    const [scheduleError, setScheduleError] = useState<any>(null);
    const [packLoadData, setSetPackLoadData] = useState<any>(null);
    const [showSelectPackerPopup, setShowSelectPackerPopup] =
        useState<boolean>(false);
    const [packerPopUpTitle, setPackerPopUpTitle] = useState<any>(null);
    const [packerPopUpDateRange, setPackerPopUpDateRange] = useState<
        any | null
    >(null);

    const handleSelectPackerClose = () => setShowSelectPackerPopup(false);

    useEffect(() => {
        if (offerDataObj) {
            reset(offerDataObj);
            setDateRange([
                new Date(offerDataObj.schedule_start_date),
                new Date(offerDataObj.schedule_end_date),
            ]);
        }
        if (packerName) {
            setValue('packer', packerName);
        }
    }, [offerDataObj]);

    useEffect(() => {
        if (packerArrData?.[packerName]) {
            setSetPackLoadData(packerArrData?.[packerName]);
        }
    }, [packerArrData]);

    const handleSelectPackerShow = (title: any, date: any) => {
        setPackerPopUpTitle(title);
        setPackerPopUpDateRange(date);
        setShowSelectPackerPopup(true);
    };

    const HandleChange = async (data: any) => {
        if (!startDate || !endDate) {
            setScheduleError('This field is require');
        } else {
            setScheduleError(null);
            const selectedDate: any = [
                moment.utc(startDate).local().format('MM-DD-Y') !==
                'Invalid date'
                    ? moment.utc(startDate).local().format('MM-DD-Y')
                    : moment(),
                moment.utc(endDate).local().format('MM-DD-Y') !== 'Invalid date'
                    ? moment.utc(endDate).local().format('MM-DD-Y')
                    : moment(),
            ];
            const sendObj = {
                ...data,
                schedule_date: selectedDate,
                driver_pickupDate: data?.driver_pickupDate
                    ? formatDateTOCurrent(data?.driver_pickupDate)
                    : null,
                driver_deliveryDate: data?.driver_deliveryDate
                    ? formatDateTOCurrent(data?.driver_deliveryDate)
                    : null,
                packDate: data?.packDate
                    ? formatDateTOCurrent(data?.packDate)
                    : null,
                pickupDate: data?.pickupDate
                    ? formatDateTOCurrent(data?.pickupDate)
                    : null,
                deliveryDate: data?.deliveryDate
                    ? formatDateTOCurrent(data?.deliveryDate)
                    : null,
            };
            const { payload } = await dispatch(updateOffer(sendObj));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                onClose();
                toast.success(payload?.data?.message);
                getFetchJobList();
            }
        }
    };

    const packUpStartRef = useRef();
    const packStartRef = useRef();

    const packDate: any = useMemo(
        () => (
            <Controller
                name={`packDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={packStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Pack"
                        tabIndex={2}
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="packDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPackDate],
    );

    const deliveryDate: any = useMemo(
        () => (
            <Controller
                name={`pickupDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={packUpStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Pickup"
                        tabIndex={3}
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="pickupDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPickupDate],
    );

    const showAlert = (id: any, name: any) => {
        Swal.fire({
            title: 'Delete Note?',
            text: "You won't be able to revert this operation!",
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                container: 'z-index-2', // Set the custom class for the container
            },
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result?.isConfirmed) {
                const { payload } = await dispatch(
                    deleteScheduledPackersLoad({
                        pack_load_unload_id: id,
                        packer_name: name,
                    }),
                );

                if (payload?.data?.status) {
                    getFetchJobList();
                }
            }
        });
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                centered
                className="mfnw_model_size"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Job Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="d-flex justify-content-between viewmod-icon">
                        <div>
                            <p className="text-sm fw-semibold">
                                OFFER INFORMATION
                            </p>
                        </div>
                        {isDisabled && (
                            <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                                <FontAwesomeIcon
                                    className="mx-1 cursor-pointer align-baseline m-auto"
                                    icon={faPen}
                                    onClick={() => {
                                        onClose();
                                        handleEditShow();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <div className="mfnw_schedule">
                            <Row className="title-icon">
                                <Col md={6}>
                                    <Controller
                                        name={'shipper'}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                value={value}
                                                className="mfnw__offer_form-control text-capitalize"
                                                id="shipper"
                                                inputName="shipper"
                                                label="Shipper"
                                                tabIndex={1}
                                                inputType="text"
                                                inputGroupClassName="mfnw__input-group job_info_modal"
                                                placeholder="Shipper"
                                                disabled={isDisabled}
                                                errorText={
                                                    errors.shipper?.message
                                                }
                                            />
                                        )}
                                    />
                                    <div className="mfnw_mainaddress d-flex mt-4 job_content_right">
                                        <div>
                                            <div className="origin">
                                                <FontAwesomeIcon
                                                    icon={faLocationDot}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-lightpink fw-bold mb-0 text-sm mt-2">
                                                ORIGIN
                                            </p>
                                            {/* <div className="mt-4">
                                            <address className="text-base text-uppercase text-dark fw-medium mfnw_address">
                                                971 washington ave FAIRLAWN,
                                                Ohio 44333 USA
                                            </address>
                                        </div> */}
                                            <div className="mt-2 mt-lg-3">
                                                <address className="text-base text-uppercase text-dark fw-medium mfnw_address">
                                                    {`${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.street
                                                    }, ${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.additional
                                                            ? `${offerDataObj?.address_origin?.additional}, `
                                                            : ''
                                                    }${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.city
                                                    },  ${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.stateTown
                                                    }${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.postalCode
                                                            ? ` ${offerDataObj?.address_origin?.postalCode}, `
                                                            : ', '
                                                    }${
                                                        offerDataObj
                                                            ?.address_origin
                                                            ?.countryCode
                                                    }`}
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="pb-4">
                                        <Controller
                                            name={`estimatedWeight`}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }: any) => (
                                                <>
                                                    <InputGroupText
                                                        onChangeFunc={onChange}
                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                        value={value}
                                                        tabIndex={1}
                                                        id={`estimatedWeight`}
                                                        inputType={'text'}
                                                        label="Weight"
                                                        placeholder={'Weight'}
                                                        disabled={isDisabled}
                                                        groupInput={true}
                                                        errorText={
                                                            errors
                                                                ?.estimatedWeight
                                                                ?.message
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="pb-4">
                                        {packDate}
                                        <ErrorMsg
                                            errorText={
                                                errors?.packDate?.message
                                            }
                                        />
                                    </div>
                                    <div className="pb-4">
                                        {deliveryDate}
                                        <ErrorMsg
                                            errorText={
                                                errors?.packDate?.message
                                            }
                                        />
                                    </div>
                                    <p className="text-dark text-base fw-normal">
                                        Region
                                    </p>
                                    <div className="d-flex">
                                        {offerDataObj?.regions &&
                                        (offerDataObj?.regions).length > 0
                                            ? (offerDataObj?.regions).map(
                                                  (
                                                      data: any,
                                                      regionsIndex: number,
                                                  ) => (
                                                      <div
                                                          className="ms-3"
                                                          key={regionsIndex}
                                                      >
                                                          <button
                                                              type="button"
                                                              className="p-2 px-4 border-1 text-success bg-white border-light border-opacity-50 rounded-pill fw-bold"
                                                          >
                                                              {
                                                                  data?.region_name
                                                              }
                                                          </button>
                                                      </div>
                                                  ),
                                              )
                                            : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="text-dark text-sm fw-semibold mt-2">
                                        EMPLOYEE INFORMATION
                                    </p>
                                    <Controller
                                        name={`packer`}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <>
                                                <InputGroupText
                                                    onChangeFunc={onChange}
                                                    className="mfnw__login-form-control mfnw__login-form-control-bg text-capitalize"
                                                    value={value}
                                                    tabIndex={1}
                                                    id={`State`}
                                                    inputType={'text'}
                                                    label="Employee"
                                                    placeholder={'Employee'}
                                                    disabled={true}
                                                    groupInput={true}
                                                    inputGroupClassName="job_info_modal"
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <p className="text-dark text-sm fw-semibold mt-2">
                                        SCHEDULE RANGE
                                    </p>
                                    <DateRangeSelect
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        groupInput={true}
                                        icon={faCalendarRange}
                                        onChangeFunc={(update: any) => {
                                            setDateRange(update);
                                        }}
                                        disabled={true}
                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    />
                                    {scheduleError && (
                                        <ErrorMsg errorText={scheduleError} />
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <p className="text-dark text-sm fw-semibold mt-4">
                                        SCHEDULE TIME
                                    </p>
                                    <Table
                                        size="sm"
                                        className="border table_fwnp"
                                    >
                                        <thead>
                                            <tr className="table-headings">
                                                <th className="text-sm fw-semibold px-3 py-3">
                                                    DATE
                                                </th>
                                                <th className="text-sm fw-semibold px-2 py-3">
                                                    FROM
                                                </th>
                                                <th className="text-sm fw-semibold px-2 py-3">
                                                    TO
                                                </th>
                                                <th className="text-sm fw-semibold px-2  py-3">
                                                    ACTION
                                                </th>
                                                <th className="text-sm fw-semibold px-2 py-3">
                                                    STATUS
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {packLoadData &&
                                                Object.keys(packLoadData).map(
                                                    (
                                                        date: any,
                                                        index: number,
                                                    ) => {
                                                        if (
                                                            date !==
                                                                'offer_id' &&
                                                            date !==
                                                                'packer_id' &&
                                                            'offer_id' &&
                                                            date !==
                                                                'packer_name' &&
                                                            packLoadData[date]
                                                                ?.length > 0 &&
                                                            packLoadData[
                                                                date
                                                            ]?.[0]?.title ===
                                                                currentPackerTitle
                                                        ) {
                                                            const item =
                                                                packLoadData[
                                                                    date
                                                                ];

                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-base fw-normal px-2 py-3">
                                                                        {dateTimeZone(
                                                                            date,
                                                                        )}
                                                                    </td>
                                                                    <td className="text-base fw-normal px-2 py-3">
                                                                        <div className="d-flex">
                                                                            <img
                                                                                src={
                                                                                    Clock
                                                                                }
                                                                                alt=""
                                                                                className="ps-2"
                                                                            />
                                                                            <span className="ps-1">
                                                                                9a
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-base fw-normal px-2 py-3">
                                                                        <div className="d-flex">
                                                                            <img
                                                                                src={
                                                                                    Clock
                                                                                }
                                                                                alt=""
                                                                                className="ps-2"
                                                                            />
                                                                            <span className="ps-1">
                                                                                5p
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-base fw-normal px-2 py-3 text-capitalize">
                                                                        <div className="d-flex">
                                                                            {
                                                                                item?.[0]
                                                                                    ?.typeIcon?.[0]
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-contant-beetween text-center">
                                                                            {item?.[0]
                                                                                ?.packer_status &&
                                                                            item?.[0]
                                                                                ?.packer_status ===
                                                                                'assigned' ? (
                                                                                <Badge className="bg-primary text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                    Assigned
                                                                                    <img
                                                                                        src={
                                                                                            Arrow
                                                                                        }
                                                                                        alt=""
                                                                                        className="ps-2"
                                                                                    />
                                                                                </Badge>
                                                                            ) : (
                                                                                <>
                                                                                    {item?.[0]
                                                                                        .packer_status ===
                                                                                    'pending' ? (
                                                                                        <Badge className="bg-active text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                            PENDING
                                                                                            <img
                                                                                                src={
                                                                                                    Arrow
                                                                                                }
                                                                                                alt=""
                                                                                                className="ps-2"
                                                                                            />
                                                                                        </Badge>
                                                                                    ) : (
                                                                                        <Badge className="bg-success text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                            Confirmed
                                                                                            <img
                                                                                                src={
                                                                                                    Arrow
                                                                                                }
                                                                                                alt=""
                                                                                                className="ps-2"
                                                                                            />
                                                                                        </Badge>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {!isDisabled && (
                                                                                <div
                                                                                    className="mx-3"
                                                                                    onClick={() => {
                                                                                        showAlert(
                                                                                            item?.[0]
                                                                                                ?.packer_id,
                                                                                            packerName,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                faXmark
                                                                                            }
                                                                                            size="lg"
                                                                                            className="form-control-search text-light rounded-2"
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    },
                                                )}
                                        </tbody>
                                    </Table>
                                    {!isDisabled && (
                                        <div
                                            className="text-primary cursor-pointe d-flex"
                                            onClick={() => {
                                                handleSelectPackerShow(
                                                    'Packers',
                                                    [startDate, endDate],
                                                );
                                            }}
                                        >
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={faPlusIcon}
                                                    className="pe-3 "
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="text"
                                                    className="text-sm fw-semibold"
                                                >
                                                    Add more action
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            {!isDisabled && (
                                <>
                                    <Row className="justify-content-center">
                                        <Col md={10}>
                                            <Button
                                                variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col md={10}>
                                            <Button
                                                className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                                                onClick={() => onClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {showSelectPackerPopup && (
                <SelectPackersPopup
                    showPopup={showSelectPackerPopup}
                    handleClose={handleSelectPackerClose}
                    offerId={offerDataObj?.offers_nano_id}
                    getOfferData={getOfferData}
                    offerDataObj={offerDataObj}
                    isDisabled={isDisabled}
                    listCall={() => {
                        getFetchJobList();
                    }}
                    PackerName={packerName}
                    packerPopUpTitle={packerPopUpTitle}
                    packerPopUpDateRange={packerPopUpDateRange}
                />
            )}
        </>
    );
};

export default JobInfo;
