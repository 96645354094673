import { apiRoutesPath } from '@constants/apiRoutesNavigate';
import { axiosInterceptor } from './interceptors';
import { IAgentFieldsProps } from '../../app/SettingsLists/Agents/agent.interface';

// User API

// Register a new user
export const apiRegisterUser = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.register, data);
    return response;
};

// Verify user
export const apiVerifyUser = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.verifyUser}${data}`,
    );
    return response;
};

// User login
export const apiLoginUser = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.login, data);
    return response;
};

// User logout
export const apiLogOutUser = async () => {
    const response = await axiosInterceptor.post(apiRoutesPath.logout);
    return response;
};

// Send OTP to the user
export const apiSendOtp = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.sendOtp, data);
    return response;
};

// Change user's password
export const apiChangePassword = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.forgotPassword,
        data,
    );
    return response;
};

// Invite a user
export const apiInviteUser = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.inviteUser,
        data,
    );
    return response;
};

// Delete a user
export const apiDeleteUser = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.deleteUser,
        data,
    );
    return response;
};

// Update user information
export const apiUpdateInfo = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.updateUser,
        data,
    );
    return response;
};

// Get a list of users
// export const apiUsersList = async (data: any) => {
//     const response = await axiosInterceptor.post(
//         apiRoutesPath.getUserList,
//         data,
//     );
//     return response;
// };

export const apiUsersList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.getUserList}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Reinvite users
export const apiReinviteUsers = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.reInviteUser}${data}`,
    );
    return response;
};

// Cancel user invitation
export const apiCancelInviteUsers = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.inviteCancelUser}${data}`,
    );
    return response;
};

// Get user data for editing
export const apiUserDataGet = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.editUser}${data}`,
    );
    return response;
};

// Get user profile
export const apiUserProfile = async () => {
    const response = await axiosInterceptor.get(apiRoutesPath.getUserInfo);
    return response;
};

// Update user profile
export const apiUpdateProfile = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.updateProfile,
        data,
    );
    return response;
};

// Re-generate user's key
export const apiReGenerateKey = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.generateKey,
        data,
    );
    return response;
};

// Forwarders API

// Get forwarder data
export const apiForwarderData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getForwarders}${data}`,
    );
    return response;
};

// Delete a forwarder
export const apiDeleteForwarder = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteForwarders}${data?.id}/${data?.type}`,
    );
    return response;
};

// Add a new forwarder
export const apiAddForwarder = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addForwarders,
        data,
    );
    return response;
};

// Update a forwarder
export const apiUpdateForwarder = async (data: any) => {
    const response = await axiosInterceptor.put(
        apiRoutesPath.updateForwarders,
        data,
    );
    return response;
};

export const apiForwarderList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listForwarders}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Agents API

// Get agent data
export const apiAgentData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getAgent}${data}`,
    );
    return response;
};

// Delete an agent
export const apiDeleteAgent = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteAgent}${data?.id}/${data?.type}`,
    );
    return response;
};

// Add a new agent
export const apiAddAgent = async (data: IAgentFieldsProps) => {
    const response = await axiosInterceptor.post(apiRoutesPath.addAgent, data);
    return response;
};

// Update an agent
export const apiUpdateAgent = async (data: any) => {
    const response = await axiosInterceptor.put(
        apiRoutesPath.updateAgent,
        data,
    );
    return response;
};

// Get a list of agents
export const apiAgentList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.agentList}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Data Lists API

// Get all data list
export const apiAllDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.allDataList,
        data,
    );
    return response;
};
// select packers load

// get selected offer-packer

export const apiOfferPackerData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getOfferPacker}${data}`,
    );
    return response;
};

export const apiSelectPackerLoad = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.selectPacker,
        data,
    );
    return response;
};

// Update a offerPAcker
export const apiUpdateOfferPcaker = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateOfferPacker}${data?.offerPackerId}`,
        data,
    );
    return response;
};

// delete selected packers load
export const apiDeleteSelectedPackerLoad = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteSelectedPacker}${data}`,
    );
    return response;
};
export const apiDeleteScheduledPackerLoad = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteScheduledPacker}${data?.pack_load_unload_id}/${data?.packer_name}`,
    );
    return response;
};

// Drivers API

// Add a new driver
export const apiAddDriver = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addDrivers,
        data,
    );
    return response;
};

// Get a list of ImportExceptions
export const apiGetImportExceptions = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.importException}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Update a driver
export const apiUpdateDriver = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateDrivers}${data?.id}`,
        data,
    );
    return response;
};

export const apiSelectedFileUpload = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.selectedFileUpload}`,
        data?.formData,
        {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                data?.func(percent);
            },
        },
    );
    return response;
};
// Get a list of drivers
export const apiDriversList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listDrivers}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get driver data
export const apiDriverData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getDriver}${data}`,
    );
    return response;
};

// Get fuel surcharge data
export const apiFuelSurchargeData = async (data: any) => {
    const response = await axiosInterceptor.get(
        apiRoutesPath.listFuleSurcharge,
    );
    return response;
};

// Delete a driver
export const apiDeleteDriver = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteDriver}${data?.id}/${data?.type}`,
    );
    return response;
};

// OfferNote API

// Add a new offer note
export const apiAddOfferNote = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addOfferNote,
        data,
    );
    return response;
};

// Update an offer note
export const apiUpdateOfferNote = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateOfferNote}${data?.id}`,
        data,
    );
    return response;
};

// Get a list of offer notes
export const apiOfferNotesList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listOfferNote}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get offer note data
export const apiOfferNoteData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getOfferNote}${data}`,
    );
    return response;
};

// Delete an offer note
export const apiDeleteOfferNote = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteOfferNote}${data}`,
    );
    return response;
};

// Job Document API

// Add a new job document
export const apiAddJobDocument = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addDocument,
        data,
    );
    return response;
};

// Update a job document
export const apiUpdateJobDocument = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateDocument}${data?.id}`,
        data,
    );
    return response;
};

// Get a list of job documents
export const apiJobDocumentsList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listDocuments}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};
// Get a list of job history
export const apiJobHistoryList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listJobHistory}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get job document data
export const apiJobDocumentData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getDocumentData}${data}`,
    );
    return response;
};

// Delete a job document
export const apiDeleteJobDocument = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteDocumentData}${data?.id}/${data?.indexToRemove}`,
    );
    return response;
};

// Packers API

// Add a new packer
export const apiAddPacker = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addPackers,
        data,
    );
    return response;
};

// Update a packer
export const apiUpdatePacker = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updatePackers}${data?.id}`,
        data,
    );
    return response;
};

// Get a list of packers
export const apiPackersList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listPackers}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get packer data
export const apiPackerData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getPackerData}${data}`,
    );
    return response;
};

// Delete a packer
export const apiDeletePacker = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deletePackerData}${data?.id}/${data?.type}`,
    );
    return response;
};

// Holiday API

export const apiAddHoliday = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addHolidays,
        data,
    );
    return response;
};

export const apiHolidaysList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listHolidays}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get holiday data
export const apiHolidayData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getHolidayData}${data}`,
    );
    return response;
};

// Update a holiday
export const apiUpdateHoliday = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateHolidays}${data?.id}`,
        data,
    );
    return response;
};

// Delete a holiday
export const apiDeleteHoliday = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteHolidayData}${data?.id}/${data?.type}`,
    );
    return response;
};

// Regions API

// Get city and state information
export const apiGetCityStateInfo = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.getCityStateData,
        data,
    );
    return response;
};

// Add a new region
export const apiAddRegion = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.addRegion, data);
    return response;
};

// Get a list of regions
export const apiRegionsList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.listRegion}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};

// Get region data
export const apiRegionData = async (data: any) => {
    const response = await axiosInterceptor.get(
        `${apiRoutesPath.getRegionData}${data}`,
    );
    return response;
};

// Update a region
export const apiUpdateRegion = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateRegionData}${data?.id}`,
        data,
    );
    return response;
};

// Organization API (steps)

// Add a new Organization
export const apiGetUserOrganizationList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.organization}${data?.filters?.currentPage}`,
        data,
    );
    return response;
};
export const apiGetUserOrganizationInformation = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.getUserOrganizationData}`,
        data,
    );
    return response;
};

export const apiAddOrganizationData = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addOrganization,
        data,
    );
    return response;
};

export const apiUpdateOrganizationData = async (data: any) => {
    const response = await axiosInterceptor.put(
        `${apiRoutesPath.updateOrganization}${data?.organization_nano_id}`,
        data,
    );
    return response;
};

export const apiChangeDefaultOrganization = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.setDefaultOrganizationData}${data}`,
        data,
    );
    return response;
};

export const apiOrganizationDataSelectList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.organizationDataSelectList}`,
        data,
    );
    return response;
};

// office API (steps)

// Add a new office
export const apiAddOfficeData = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.addOffice, data);
    return response;
};

export const apiGetOfficeDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.officesSearch}`,
        data,
    );
    return response;
};

export const apiDeleteOffice = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteOffice}${data}`,
    );
    return response;
};

// Department API (steps)

// Add a new Department
export const apiAddDepartmentData = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addDepartments,
        data,
    );
    return response;
};

export const apiGetDepartmentDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.departmentSearch}`,
        data,
    );
    return response;
};

export const apiDeleteDepartment = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteDepartments}${data}`,
    );
    return response;
};

// Role API (steps)

// Add a new Role
export const apiAddRoleData = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.addRoles, data);
    return response;
};

export const apiGetRoleDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.organizationRoleSearch}`,
        data,
    );
    return response;
};

export const apiDeleteRole = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteRoles}${data}`,
    );
    return response;
};

// JobTitle API (steps)

// Add a new JobTitle
export const apiGetJobTitleDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.jobTitleSearch}`,
        data,
    );
    return response;
};

export const apiAddJobTitleData = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addJobTitles,
        data,
    );
    return response;
};

export const apiDeleteJobTitle = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteJobTitles}${data}`,
    );
    return response;
};

// Regions API (steps)

// Add a new Regions
export const apiAddRegionData = async (data: any) => {
    const response = await axiosInterceptor.post(
        apiRoutesPath.addRegions,
        data,
    );
    return response;
};

export const apiGetRegionDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.regionSearch}`,
        data,
    );
    return response;
};
export const apiGetRegionList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.regionLists}`,
        data,
    );
    return response;
};

export const apiDeleteRegion = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteRegions}${data}`,
    );
    return response;
};

// Group API (steps)

// Add a new Group
export const apiAddGroupData = async (data: any) => {
    const response = await axiosInterceptor.post(apiRoutesPath.addGroups, data);
    return response;
};

export const apiGetGroupDataList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.groupsSearch}`,
        data,
    );
    return response;
};

export const apiDeleteGeroup = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteGroups}${data}`,
    );
    return response;
};

// MembershipList

export const apiGetMembershipList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.membershipSearch}${data}`,
    );
    return response;
};

export const apiAddGroupMemberData = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.addGroupMembers}`,
        data,
    );
    return response;
};

export const apiGetUsersList = async (data: any) => {
    const response = await axiosInterceptor.post(
        `${apiRoutesPath.getGroupUsers}${data?.group_nano_id}`,
        data,
    );
    return response;
};

export const apiDeleteMemberUser = async (data: any) => {
    const response = await axiosInterceptor.delete(
        `${apiRoutesPath.deleteGroupUsers}${data}`,
        data,
    );
    return response;
};
