import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddOfficeData,
    apiGetOfficeDataList,
    apiDeleteOffice,
} from '@services/API/apiFunctions';

const getOfficeDataList = createAsyncThunk(
    'getOfficeDataList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiGetOfficeDataList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const addOfficeData = createAsyncThunk(
    'addOfficeData',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddOfficeData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteOffice = createAsyncThunk(
    'deleteOffice',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteOffice(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { getOfficeDataList, addOfficeData, deleteOffice };
