/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { setTimeZone } from '@utils/utilFunctions';

import ReactPagination from '@components/reactPagination';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    dateEndTimeFormat,
    humanReadableDateFormat,
} from '@constants/general.const';

import { fieldNames } from './history.const';
import PackLoadUnloadTable from './packLoadUnloadTable';
import { offersStatsArray } from '../../../constants/general.const';
import DocumentTable from './documentTable';

const HistoryTable = ({
    currentPageData,
    pageCount,
    filter,
    handlePageClick,
}: any) => {
    const { historyData } = useSelector((state: any) => state);
    const { isJobHistoryList } = historyData;

    const [openAccordion, setOpenAccordion] = useState<any>('');

    const handleAccordionToggle = (employeeId: any) => {
        setOpenAccordion(openAccordion === employeeId ? '' : employeeId);
    };

    const getFieldSortOrder = (field: any) => {
        const fieldInfo = fieldNames.find((item) => item.field === field);
        return fieldInfo ? fieldInfo.short : 99;
    };

    const sortChangedFields = (changedFields: any) =>
        Object.entries(changedFields)
            .sort(
                ([aField], [bField]) =>
                    getFieldSortOrder(aField) - getFieldSortOrder(bField),
            )
            .map(([field, values]: any) => ({ field, ...values }));

    const groupFieldsByType = (changedFields: any) => {
        const grouped: any = {};
        for (const {
            field,
            old,
            new: newValue,
            value,
            statusChangeBySystem,
        } of sortChangedFields(changedFields)) {
            const fieldInfo: any = fieldNames.find(
                (item) => item.field === field,
            );
            if (fieldInfo) {
                if (!grouped[fieldInfo.type]) {
                    grouped[fieldInfo.type] = [];
                }
                grouped[fieldInfo.type].push({
                    ...fieldInfo,
                    old,
                    newValue,
                    value,
                    statusChangeBySystem,
                });
            }
        }
        return grouped;
    };

    const getSelfHaulValue = (value: any) =>
        value === 1 || value === '1' || value === true
            ? 'Line Haul'
            : 'Self Haul';

    const getYesNoValue = (value: any) =>
        value === 1 || value === '1' || value === true ? 'Yes' : 'No';

    const getFieldDisplayValue = (field: any, old: any) => {
        if (
            ['ntsr', 'SIT_status', 'isSelfHaul', 'isOriginAgentOnly'].includes(
                field,
            )
        ) {
            if (field === 'isSelfHaul') {
                return getSelfHaulValue(old);
            }
            return getYesNoValue(old);
        }

        if (
            [
                'date_in',
                'date_out',
                'packDate',
                'pickupDate',
                'deliveryDate',
                'schedule_start_date',
                'schedule_end_date',
                'driver_pickupDate',
                'driver_deliveryDate',
            ].includes(field) &&
            old !== 'empty'
        ) {
            return setTimeZone(old, humanReadableDateFormat);
        }

        if (field === 'offerStatus') {
            var data = offersStatsArray.find(
                (element: any) => element.value === old,
            );

            return data?.label ? data?.label : old;
        }

        return old ? old : 'empty';
    };

    return (
        <>
            <div className="mfnw_note_table">
                <Table className="table px-2 mb-0">
                    <thead>
                        <tr>
                            <th className="note_table_width text-sm fw-semibold text-dark text-center">
                                USER
                            </th>
                            <th className="text-sm fw-semibold text-dark text-center">
                                Affected Tab
                            </th>
                            <th className="text-sm fw-semibold text-dark text-center">
                                TIME
                            </th>
                            <th className="text-sm fw-semibold text-dark"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {isJobHistoryList ? (
                            <tr>
                                <td colSpan={4} className="p-0">
                                    <div className="text-center my-3"></div>
                                    <span className="ms-5">
                                        <LoaderBtn size="sm" />
                                    </span>
                                </td>
                            </tr>
                        ) : currentPageData && currentPageData.length > 0 ? (
                            currentPageData.map((data: any, index: number) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td
                                            className="text-sm text-dark text-base mfnw_document_table_widtd text-center cursor-pointer"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {data?.user?.firstName}{' '}
                                            {data?.user?.lastName}
                                        </td>
                                        <td
                                            className="text-sm text-dark text-base text-capitalize text-center cursor-pointer"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {data?.affected_tab} Tab
                                        </td>
                                        <td
                                            className="text-sm text-dark text-base text-center cursor-pointer"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {setTimeZone(
                                                data?.changed_at,
                                                dateEndTimeFormat,
                                            )}
                                        </td>
                                        <td
                                            className="text-sm fw-semibold text-dark cursor-pointer"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            <div
                                                className="accordion-button"
                                                aria-expanded={
                                                    openAccordion ===
                                                    `demo${index}`
                                                }
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="p-0">
                                            <Accordion.Collapse
                                                eventKey={`demo${index}`}
                                                in={
                                                    openAccordion ===
                                                    `demo${index}`
                                                }
                                                className="mfnw_detail_history_table"
                                            >
                                                <div className="mfnw_pensil_icon p-3 d-flex justify-content-center">
                                                    <div className="row d-flex justify-content-center w-75">
                                                        {data.affected_tab ===
                                                            'Pack & Load' ||
                                                        data.affected_tab ===
                                                            'Unload' ? (
                                                            <PackLoadUnloadTable
                                                                data={data}
                                                            />
                                                        ) : data.affected_tab ===
                                                          'Document' ? (
                                                            <DocumentTable
                                                                data={data}
                                                            />
                                                        ) : (
                                                            Object.entries(
                                                                groupFieldsByType(
                                                                    data?.changed_fields,
                                                                ),
                                                            ).map(
                                                                ([
                                                                    type,
                                                                    fields,
                                                                ]: any) => (
                                                                    <div
                                                                        key={`${type}-${fields}`}
                                                                        className="mb-5 p-4 border rounded shadow-sm bg-white"
                                                                    >
                                                                        <h5 className="text-center mb-4 text-uppercase font-weight-bold text-primary">
                                                                            {
                                                                                type
                                                                            }
                                                                        </h5>
                                                                        {fields.map(
                                                                            (
                                                                                {
                                                                                    field,
                                                                                    name,
                                                                                    value,
                                                                                    old,
                                                                                    newValue,
                                                                                    postFix,
                                                                                    statusChangeBySystem,
                                                                                }: any,
                                                                                idx: any,
                                                                            ) => (
                                                                                <div
                                                                                    key={`${field}-${idx}`}
                                                                                    className="row mb-2"
                                                                                >
                                                                                    <div className="col-sm-1 text-center text-capitalize"></div>
                                                                                    <div className="col-sm-3 text-center text-capitalize">
                                                                                        <strong>
                                                                                            {
                                                                                                name
                                                                                            }

                                                                                            :
                                                                                        </strong>
                                                                                    </div>
                                                                                    {(data &&
                                                                                        data?.is_created) ||
                                                                                    data?.is_deleted ? (
                                                                                        <>
                                                                                            <div className="col-sm-3 text-center">
                                                                                                {
                                                                                                    value
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-sm-3 text-center">
                                                                                                {data?.is_created
                                                                                                    ? '(Created)'
                                                                                                    : '(Deleted)'}
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="col-sm-3 text-center">
                                                                                                {getFieldDisplayValue(
                                                                                                    field,
                                                                                                    old,
                                                                                                )}
                                                                                                {old
                                                                                                    ? postFix &&
                                                                                                      postFix
                                                                                                    : ''}
                                                                                            </div>
                                                                                            <div className="col-sm-1 text-center">
                                                                                                →
                                                                                            </div>
                                                                                            <div className="col-sm-3 text-center">
                                                                                                {getFieldDisplayValue(
                                                                                                    field,
                                                                                                    newValue,
                                                                                                )}
                                                                                                {field ===
                                                                                                    'offerStatus' &&
                                                                                                    statusChangeBySystem &&
                                                                                                    ' (By System)'}
                                                                                                {newValue
                                                                                                    ? postFix &&
                                                                                                      postFix
                                                                                                    : ''}
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    <div className="col-sm-1 text-center text-capitalize"></div>
                                                                                </div>
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} className="p-0">
                                    <div className="text-center my-3">
                                        {isJobHistoryList ? (
                                            <span className="ms-5">
                                                <LoaderBtn size="sm" />
                                            </span>
                                        ) : (
                                            <h3>No History Found</h3>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div className="pagination_of_document">
                <div className="mt-4">
                    <ReactPagination
                        currentPageData={currentPageData}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        filters={filter}
                    />
                </div>
            </div>
        </>
    );
};

export default HistoryTable;
