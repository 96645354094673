/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Col, Row, Button, Table, Badge, Modal, Form } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import DateSelect from '@components/DateSelect/DateSelect';
import ErrorMsg from '@components/errorMsg';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import DateRangeSelect from '@components/DateRengeSelect/DateRengeSelect';

import {
    cancelButtonColor,
    confirmButtonColor,
    formatDateTOCurrent,
    searchTimeOut,
} from '@constants/general.const';
import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/fontawesome-pro-regular';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarRange } from '@fortawesome/pro-light-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import {
    faCalendar,
    faTruckFront,
    faSteeringWheel,
    faTruckContainer,
} from '@fortawesome/pro-light-svg-icons';
import Destination from '@images/destination1.svg';
import OriginIcon from '@images/origin-icon.svg';
import Arrow from '@images/replace.svg';

import SelectPackersPopup from '@app/JobList/selectPackersPopup';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';
import {
    deleteSelectedPackersLoad,
    getAllDataList,
    getOfferPackerInfo,
} from '@app/OffersList/functions';
import { offerFields, schema } from '@app/OffersList/offers.const';
import { updateOffer } from '@app/OffersList/functions';
import AddressDiv from '@app/OffersList/offerContentComponent/addressDiv';
import { dateTimeZone } from '@utils/utilFunctions';

import { IOfferProps } from '../OffersList/offer.interface';
import { IListOption, IListOptionSearchObj2 } from '../../Interface/common';

const faPlusIcon = faPlus as IconProp;

// eslint-disable-next-line complexity
const JobSchedulePopup = ({
    showPopup,
    handleClose,
    offerDataObj,
    isDisabled,
    listCall,
    getOfferData,
    setShowEditSchedulePopup,
    showEditSchedulePopup,
    isEditable,
}: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { offersData } = useSelector((state: any) => state);
    const { isGetAllDataList, isUpdateOffer } = offersData;
    const offerId = offerDataObj?.offers_nano_id;

    const [showSelectPackerPopup, setShowSelectPackerPopup] =
        useState<boolean>(false);

    const [packerPopUpTitle, setPackerPopUpTitle] = useState<any | null>(null);
    const [packerPopUpDateRange, setPackerPopUpDateRange] = useState<
        any | null
    >(null);
    const [saveState, setSaveState] = useState<any>(false);
    const [originAgentOnly, setOriginAgentOnly] = useState<any>(true);
    const [sit, setSit] = useState<any>(null);

    const handleSelectPackerShow = (title: any, date: any) => {
        setPackerPopUpTitle(title);
        setPackerPopUpDateRange(date);
        setShowSelectPackerPopup(true);
        formEl?.current?.click();
    };

    const handleSelectPackerClose = () => setShowSelectPackerPopup(false);

    const [showEditSelectPackerPopup, setShowEditSelectPackerPopup] =
        useState<boolean>(false);

    const handleEditSelectPackerShow = (
        nanoId: any,
        title: string,
        date: any,
    ) => {
        setPackerPopUpTitle(title);
        setPackerPopUpDateRange(date);
        setShowEditSelectPackerPopup(true);
        getOfferPackerData(nanoId);
        formEl?.current?.click();
    };

    const handleEditSelectPackerClose = () =>
        setShowEditSelectPackerPopup(false);

    const [driverListOptionArr, setDriverListOptionArr] = useState<
        IListOption[]
    >([]);
    const [driverListCount, setDriverListCount] = useState<number>(0);
    const [offerPackerData, setofferPackerData] = useState<any>(null);
    const [scheduleError, setScheduleError] = useState<any>(null);

    const [packLoadData, setPackLoadData] = useState<any>([]);
    const [packUnloadData, setPackUnloadData] = useState<any>([]);
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;

    const [searchObj2, setSearchObj2] = useState<IListOptionSearchObj2 | null>(
        null,
    );
    const prevSearchObj2 = useRef<IListOptionSearchObj2 | null>({
        ...searchObj2,
        packerSearch: null,
    });

    const formEl: any = useRef();

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors, isDirty },
    } = useForm<IOfferProps>({
        resolver: yupResolver(schema),
        defaultValues: { ...offerFields, offerId: offerId ? true : false },
    });

    useEffect(() => {
        getAllList(searchObj2);
    }, []);

    useEffect(() => {
        reset(offerDataObj);
        setValue('forwarder_name', offerDataObj?.forwarder_data?.name ?? '');
        if (offerDataObj) {
            setOriginAgentOnly(
                offerDataObj?.line_haule_data?.isOriginAgentOnly === 0
                    ? false
                    : true,
            );
            setSit(
                offerDataObj?.line_haule_data?.SIT_status === 0 ? false : true,
            );
            setValue(
                'destination_agent',
                offerDataObj?.agents_data?.agent_name ?? '',
            );
            setValue(
                'destination_agent_nano_id',
                offerDataObj?.agents_data?.agent_nano_id ?? '',
            );
            setValue(
                'to_agent',
                offerDataObj?.to_agents_data?.agent_name ?? '',
            );
            setValue(
                'to_agent_nano_id',
                offerDataObj?.to_agents_data?.agent_nano_id ?? '',
            );

            if (offerDataObj?.shipperdata?.shipper_name) {
                setValue('shipper', offerDataObj?.shipperdata?.shipper_name);
            }
            setValue(
                'forwarderBillOfLaden',
                offerDataObj?.forwarderBillOfLaden,
            );

            if (offerDataObj?.drivers_data?.first_name) {
                setValue('driver', offerDataObj?.drivers_data?.first_name);
            }

            if (offerDataObj?.pack_load_unload.length > 0) {
                const filteredPackLoadData =
                    offerDataObj?.pack_load_unload.filter(
                        (item: any) => item?.packer_action !== 'unloading',
                    );
                setPackLoadData(filteredPackLoadData);

                const filteredPackUnloadData =
                    offerDataObj?.pack_load_unload.filter(
                        (item: any) => item?.packer_action === 'unloading',
                    );
                setPackUnloadData(filteredPackUnloadData);
            } else {
                setPackUnloadData([]);
                setPackLoadData([]);
            }
        }
        if (
            offerDataObj &&
            offerDataObj?.packDate &&
            offerDataObj?.pickupDate
        ) {
            setDateRange([
                new Date(offerDataObj?.packDate),
                new Date(offerDataObj?.pickupDate),
            ]);
        }
    }, [offerDataObj]);

    useEffect(() => {
        if (
            searchObj2 &&
            prevSearchObj2?.current?.driveSearch !== searchObj2?.driveSearch
        ) {
            const getData: NodeJS.Timeout = setTimeout(() => {
                getAllList(searchObj2);
            }, searchTimeOut);
            return () => clearTimeout(getData);
        }
    }, [searchObj2]);

    const HandleChange = async (data: IOfferProps) => {
        if (!startDate || !endDate) {
            setScheduleError('This field is require');
        } else {
            setScheduleError(null);
            const selectedDate: any = [
                moment.utc(startDate).local().format('MM-DD-Y') !==
                'Invalid date'
                    ? moment.utc(startDate).local().format('MM-DD-Y')
                    : moment(),
                moment.utc(endDate).local().format('MM-DD-Y') !== 'Invalid date'
                    ? moment.utc(endDate).local().format('MM-DD-Y')
                    : moment(),
            ];
            const sendObj = {
                ...data,
                schedule_date: selectedDate,
                driver_pickupDate: !sit
                    ? data?.driver_pickupDate
                        ? formatDateTOCurrent(data?.driver_pickupDate)
                        : null
                    : null,
                driver_deliveryDate: !sit
                    ? data?.driver_deliveryDate
                        ? formatDateTOCurrent(data?.driver_deliveryDate)
                        : null
                    : null,
                packDate: data?.packDate
                    ? formatDateTOCurrent(data?.packDate)
                    : null,
                pickupDate: data?.pickupDate
                    ? formatDateTOCurrent(data?.pickupDate)
                    : null,
                deliveryDate: data?.deliveryDate
                    ? formatDateTOCurrent(data?.deliveryDate)
                    : null,
                date_in: data?.date_in
                    ? formatDateTOCurrent(data?.date_in)
                    : null,
                date_out: data?.date_out
                    ? formatDateTOCurrent(data?.date_out)
                    : null,
                tab_type: 'scheduled',
            };
            const { payload } = await dispatch(updateOffer(sendObj));
            if (payload?.data?.message !== '' && payload?.data?.status) {
                listCall();
                getOfferData(offerId);
                if (saveState) {
                    handleClose();
                    if (!isEditable) {
                        setShowEditSchedulePopup(false);
                    }
                } else {
                    setSaveState(false);
                }
            }
        }
    };

    const getAllList = async (searchObject: any) => {
        const type = { driver: 'driver' };
        const { payload } = await dispatch(
            getAllDataList({
                type: type,
                searchArr: searchObject,
                offset: driverListOptionArr?.length,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            var allDataList = payload?.data?.dataList;
            var driversData: IListOption[];
            if (searchObject && searchObject?.driveSearch !== '') {
                driversData = [];
            } else {
                driversData = driverListOptionArr ?? [];
            }
            allDataList?.driversData.map((dataOption: any) => {
                if (dataOption?.is_active) {
                    driversData.push({
                        value: dataOption.first_name,
                        label: `${dataOption?.last_name}, ${dataOption?.first_name} ${dataOption?.middle_name}`,
                    });
                }
                return null;
            });
            setDriverListOptionArr(driversData);
            setDriverListCount(payload?.data?.dataList?.driversDataCount);
        } else {
            toast.error(payload?.message);
        }
    };

    const onChangeSelect = async (e: any, fieldName: any) => {
        if (fieldName === 'driver') {
            if (e === null) {
                setValue(fieldName, e?.value);
                setValue('truck', '');
                setValue('trailer', '');
                setValue('driver_pickupDate', null);
                setValue('driver_deliveryDate', null);
            } else {
                setValue(fieldName, e?.value);
            }
        } else if (fieldName === 'destination_agent') {
            setValue('destination_agent_nano_id', e?.value);
            setValue('destination_agent', e?.label);
        } else if (fieldName === 'to_agent') {
            setValue('to_agent_nano_id', e?.value);
            setValue('to_agent', e?.label);
        } else {
            setValue(fieldName, e?.label);
        }
    };

    const packStartRef: any = useRef();
    const pickUpRef = useRef();
    const packUpStartRef = useRef();
    const checkInRef = useRef();
    const checkOutRef = useRef();
    const rddStartRef = useRef();
    const driverrddStartRef = useRef();

    const watchDriverPickupDate = watch('driver_pickupDate');
    const watchDriverDeliveryDate = watch('driver_deliveryDate');
    const watchPackDate = watch('packDate');
    const watchPickupDate = watch('pickupDate');
    const watchdeliveryDate = watch('deliveryDate');
    const watchCheckInDate = watch('date_in');
    const watchCheckOutDate = watch('date_out');

    const packerStartDate = watchPickupDate && new Date(watchPickupDate);
    const packerEndDate = watchdeliveryDate && new Date(watchdeliveryDate);

    useEffect(() => {
        if (!isEditable && !isDisabled) {
            if (packStartRef.current) {
                packStartRef.current.setOpen(true);
            }
        }
        if (isEditable) {
            packStartRef.current.setOpen(true);
        }

        setPackerPopUpDateRange([packerStartDate, packerEndDate]);
        setPackerPopUpTitle('Select Packers');
    }, [isDisabled]);

    const packDate: any = useMemo(
        () => (
            <Controller
                name={`pickupDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={pickUpRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Pickup"
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="pickupDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPackDate, isDisabled],
    );

    const scheduleDateRange: any = useMemo(() => {
        var startDateRange = watchPackDate && new Date(watchPackDate);
        var endDateRange = watchPickupDate && new Date(watchPickupDate);
        setDateRange([startDateRange, endDateRange]);
        return (
            <DateRangeSelect
                selectsRange={true}
                startDate={startDateRange}
                endDate={endDateRange}
                groupInput={true}
                icon={faCalendarRange}
                inputGroupClassName="mfnw__input-date-group h-auto"
                onChangeFunc={(update: any) => {
                    setDateRange(update);
                }}
                disabled={true}
                className="mfnw__login-form-control mfnw__login-form-control-bg me-4"
            />
        );
    }, [watchPackDate, watchPickupDate]);

    const deliveryDate: any = useMemo(
        () => (
            <Controller
                name={`deliveryDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={rddStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="RDD"
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="deliveryDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [watchPickupDate, isDisabled],
    );

    const driverPickupDate: any = useMemo(() => {
        var minDate = getValues('pickupDate') ? getValues('pickupDate') : null;
        var maxDate = getValues('deliveryDate')
            ? getValues('deliveryDate')
            : null;
        return (
            <Controller
                name={`driver_pickupDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={packUpStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Pickup"
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        minDate={new Date(`${minDate}`)}
                        maxDate={new Date(`${maxDate}`)}
                        dateFormat="MM/dd/yyyy"
                        inputName="driver_pickupDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled || !getValues('driver')}
                    />
                )}
            />
        );
    }, [
        watchDriverPickupDate,
        watchPickupDate,
        watchdeliveryDate,
        isDisabled,
        getValues('driver'),
        sit,
    ]);

    const dateIn: any = useMemo(
        () => (
            <Controller
                name={`date_in`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={checkInRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Date In"
                        selected={
                            typeof getValues('date_in') === 'string'
                                ? moment(getValues('date_in'))
                                      .tz('America/New_York')
                                      .toDate()
                                : getValues('date_in') || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="date_in"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [isDisabled, sit, watchCheckInDate],
    );
    const dateOut: any = useMemo(
        () => (
            <Controller
                name={`date_out`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={checkOutRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Date Out"
                        selected={
                            typeof getValues('date_out') === 'string'
                                ? moment(getValues('date_out'))
                                      .tz('America/New_York')
                                      .toDate()
                                : getValues('date_out') || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="date_out"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                    />
                )}
            />
        ),
        [isDisabled, sit, watchCheckOutDate],
    );

    const driverDeliveryDate: any = useMemo(() => {
        var minDate = getValues('pickupDate') ? getValues('pickupDate') : null;
        var maxDate = getValues('deliveryDate')
            ? getValues('deliveryDate')
            : null;
        return (
            <Controller
                name={`driver_deliveryDate`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={driverrddStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="ETA"
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="driver_deliveryDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        minDate={new Date(`${minDate}`)}
                        maxDate={new Date(`${maxDate}`)}
                        groupInput={true}
                        disabled={isDisabled || !getValues('driver')}
                    />
                )}
            />
        );
    }, [
        watchDriverDeliveryDate,
        watchPickupDate,
        watchdeliveryDate,
        isDisabled,
        getValues('driver'),
        sit,
    ]);

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    if (!isEditable) {
                        setShowEditSchedulePopup(false);
                    }
                }
            });
        } else {
            handleClose();
            if (!isEditable) {
                setShowEditSchedulePopup(false);
            }
        }
    };

    const getOfferPackerData = async (nanoId: string) => {
        const { payload } = await dispatch(getOfferPackerInfo(nanoId));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            setofferPackerData(payload?.data?.data);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const showAlert = (id: any) => {
        Swal.fire({
            title: 'Delete Note?',
            text: "You won't be able to revert this operation!",
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                container: 'z-index-2', // Set the custom class for the container
            },
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result?.isConfirmed) {
                const { payload } = await dispatch(
                    deleteSelectedPackersLoad(id),
                );
                if (payload?.data?.status) {
                    listCall(offerId);
                    getOfferData(offerId);
                }
            }
        });
    };

    return (
        <>
            <Modal
                show={showPopup}
                onHide={onClose}
                backdrop="static"
                backdropClassName="modal-backdrop-upper"
                centered
                size="xl"
                className="offer_add_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5 fw-semibold">
                        Schedule
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    {isGetAllDataList && !showSelectPackerPopup ? (
                        <LoaderBtn />
                    ) : (
                        <>
                            <div
                                className={`d-flex ${
                                    offerDataObj && offerDataObj?.shipper
                                        ? 'justify-content-between'
                                        : 'justify-content-end'
                                } me-1`}
                            >
                                {offerDataObj && offerDataObj?.shipper && (
                                    <div className="text-capitalize">
                                        <b>Shipper Name:</b>{' '}
                                        {offerDataObj?.shipper ?? ''}
                                    </div>
                                )}
                                {!isEditable && !showEditSchedulePopup && (
                                    <FontAwesomeIcon
                                        className="mx-2 cursor-pointer text-iconcolor"
                                        icon={faPen}
                                        onClick={() => {
                                            setShowEditSchedulePopup(true);
                                        }}
                                    />
                                )}
                            </div>
                            <Form onSubmit={handleSubmit(HandleChange)}>
                                <div className="mfnw_schedule mfpro-info">
                                    <Row className="title-icon">
                                        <Col xs={12} lg={6}>
                                            <p className="text-sm fw-semibold mt-4">
                                                PACKERS
                                            </p>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <AddressDiv
                                                        icon={OriginIcon}
                                                        offerAddress={
                                                            offerDataObj?.address_origin
                                                        }
                                                        addressName="ORIGIN"
                                                        color="text-primary"
                                                    />
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <div className="mb-3">
                                                        <Controller
                                                            name={`packDate`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <DateSelect
                                                                    ref={
                                                                        packStartRef
                                                                    }
                                                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                    inputGroupClassName="mfnw__input-group"
                                                                    placeholder="MM/DD/YYYY"
                                                                    label="Pack"
                                                                    selected={
                                                                        typeof value ===
                                                                        'string'
                                                                            ? moment
                                                                                  .utc(
                                                                                      value,
                                                                                  )
                                                                                  .local()
                                                                                  .toDate()
                                                                            : value ||
                                                                              null
                                                                    }
                                                                    dateFormat="MM/dd/yyyy"
                                                                    inputName="packDate"
                                                                    icon={
                                                                        faCalendar
                                                                    }
                                                                    onChangeFunc={({
                                                                        value: valueDateChange,
                                                                    }: any) =>
                                                                        onChange(
                                                                            valueDateChange,
                                                                        )
                                                                    }
                                                                    groupInput={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        !originAgentOnly ||
                                                                        isDisabled
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMsg
                                                            errorText={
                                                                errors?.packDate
                                                                    ?.message
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        {packDate}
                                                        <ErrorMsg
                                                            errorText={
                                                                errors
                                                                    ?.pickupDate
                                                                    ?.message
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        {deliveryDate}
                                                        <ErrorMsg
                                                            errorText={
                                                                errors
                                                                    ?.deliveryDate
                                                                    ?.message
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <p className="text-sm fw-semibold mt-4">
                                                DRIVERS
                                            </p>
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <AddressDiv
                                                        icon={Destination}
                                                        offerAddress={
                                                            offerDataObj?.address_destination
                                                        }
                                                        addressName="DESTINATION"
                                                        color="text-lightpink"
                                                    />
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <div className="mb-3">
                                                        <Controller
                                                            name={`driver`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <>
                                                                    <ReactSelectGroup
                                                                        value={driverListOptionArr.find(
                                                                            (
                                                                                c: any,
                                                                            ) =>
                                                                                c?.value ===
                                                                                value,
                                                                        )}
                                                                        onChangeFunc={(
                                                                            e: any,
                                                                        ) =>
                                                                            onChangeSelect(
                                                                                e,
                                                                                'driver',
                                                                            )
                                                                        }
                                                                        onInputChange={(
                                                                            valueChange: any,
                                                                        ) => {
                                                                            if (
                                                                                valueChange !==
                                                                                ''
                                                                            ) {
                                                                                var set: any =
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        driveSearch:
                                                                                            valueChange,
                                                                                    };
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                                setSearchObj2(
                                                                                    set,
                                                                                );
                                                                            } else {
                                                                                prevSearchObj2.current =
                                                                                    searchObj2;
                                                                                setSearchObj2(
                                                                                    {
                                                                                        ...searchObj2,
                                                                                        driveSearch:
                                                                                            '',
                                                                                    },
                                                                                );
                                                                            }
                                                                        }}
                                                                        options={
                                                                            driverListOptionArr
                                                                        }
                                                                        className="w-100"
                                                                        classNamePrefix="select_control_group"
                                                                        inputClassName="mfnw__input-group"
                                                                        isSearchable={
                                                                            true
                                                                        }
                                                                        labelShow={
                                                                            true
                                                                        }
                                                                        icon={
                                                                            faSteeringWheel
                                                                        }
                                                                        isDisabled={
                                                                            isDisabled
                                                                        }
                                                                        placeholder="Driver Name"
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        onMenuScrollToBottom={() => {
                                                                            if (
                                                                                driverListOptionArr &&
                                                                                !(
                                                                                    driverListOptionArr?.length >=
                                                                                    driverListCount
                                                                                )
                                                                            ) {
                                                                                getAllList(
                                                                                    null,
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Controller
                                                            name={`truck`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <>
                                                                    <InputGroupText
                                                                        onChangeFunc={
                                                                            onChange
                                                                        }
                                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                        value={
                                                                            value
                                                                        }
                                                                        id={`truck`}
                                                                        inputType={
                                                                            'text'
                                                                        }
                                                                        icon={
                                                                            faTruckFront
                                                                        }
                                                                        label="Truck#"
                                                                        placeholder={
                                                                            '# # #'
                                                                        }
                                                                        disabled={
                                                                            isDisabled ||
                                                                            !getValues(
                                                                                'driver',
                                                                            )
                                                                        }
                                                                        groupInput={
                                                                            true
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Controller
                                                            name={`trailer`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }: any) => (
                                                                <>
                                                                    <InputGroupText
                                                                        onChangeFunc={
                                                                            onChange
                                                                        }
                                                                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                        value={
                                                                            value
                                                                        }
                                                                        id={`trailer`}
                                                                        inputType={
                                                                            'text'
                                                                        }
                                                                        icon={
                                                                            faTruckContainer
                                                                        }
                                                                        label="Trailer"
                                                                        placeholder={
                                                                            '# # #'
                                                                        }
                                                                        disabled={
                                                                            isDisabled ||
                                                                            !getValues(
                                                                                'driver',
                                                                            )
                                                                        }
                                                                        groupInput={
                                                                            true
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="mb-3">
                                                            {sit
                                                                ? dateIn
                                                                : driverPickupDate}
                                                            <ErrorMsg
                                                                errorText={
                                                                    sit
                                                                        ? errors
                                                                              ?.date_in
                                                                              ?.message
                                                                        : errors
                                                                              ?.driver_pickupDate
                                                                              ?.message
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="mb-3">
                                                            {sit
                                                                ? dateOut
                                                                : driverDeliveryDate}
                                                            <ErrorMsg
                                                                errorText={
                                                                    sit
                                                                        ? errors
                                                                              ?.date_out
                                                                              ?.message
                                                                        : errors
                                                                              ?.driver_deliveryDate
                                                                              ?.message
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex mt-4 job_content_right"></div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            lg={5}
                                            xl={3}
                                            className="mb-4"
                                        >
                                            <p className="text-dark text-sm fw-semibold mt-4">
                                                SCHEDULE DATES
                                            </p>
                                            {scheduleDateRange}
                                            {scheduleError && (
                                                <ErrorMsg
                                                    errorText={scheduleError}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dateRange[0] && dateRange[1] && (
                                            <>
                                                <Col xs={12} xl={6}>
                                                    <p className="text-dark text-sm fw-semibold mt-4">
                                                        PACK & LOAD
                                                    </p>
                                                    <div className="overflow-table-auto">
                                                        <Table
                                                            size="sm"
                                                            className="border"
                                                        >
                                                            <thead>
                                                                <tr className="table-headings">
                                                                    <th className="text-sm fw-semibold text-center px-4 py-3 text-truncate">
                                                                        DATE
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                                                                        ACTION
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                                                                        CREWED
                                                                        BY
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center py-3 text-truncate">
                                                                        STATUS
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {packLoadData.length >
                                                                0 ? (
                                                                    packLoadData.map(
                                                                        (
                                                                            item: any,
                                                                            index: number,
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <td
                                                                                    className="text-base fw-normal text-center py-3 text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Packers',
                                                                                            [
                                                                                                startDate,
                                                                                                endDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {dateTimeZone(
                                                                                        item?.pack_date,
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className="text-base fw-normal text-center py-3 text-capitalize text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Packers',
                                                                                            [
                                                                                                startDate,
                                                                                                endDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item?.packer_action
                                                                                    }
                                                                                </td>
                                                                                <td
                                                                                    className="text-base fw-normal py-3 text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Packers',
                                                                                            [
                                                                                                startDate,
                                                                                                endDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <p className="space_overflow text-center p-0 m-0">
                                                                                        {item?.offerpacker &&
                                                                                            item?.offerpacker.map(
                                                                                                (
                                                                                                    packer: any,
                                                                                                    dataIndex: number,
                                                                                                ) => (
                                                                                                    <span
                                                                                                        key={
                                                                                                            dataIndex
                                                                                                        }
                                                                                                    >
                                                                                                        {dataIndex !==
                                                                                                        0
                                                                                                            ? `${', '}`
                                                                                                            : null}
                                                                                                        {
                                                                                                            packer?.packer_name
                                                                                                        }
                                                                                                    </span>
                                                                                                ),
                                                                                            )}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="text-truncate">
                                                                                    <div className="d-flex justify-contant-beetween text-center">
                                                                                        {item?.packer_status &&
                                                                                        item?.packer_status ===
                                                                                            'assigned' ? (
                                                                                            <Badge className="bg-primary text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                                Assigned
                                                                                                <img
                                                                                                    src={
                                                                                                        Arrow
                                                                                                    }
                                                                                                    alt=""
                                                                                                    className="ps-2"
                                                                                                />
                                                                                            </Badge>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item?.packer_status ===
                                                                                                'pending' ? (
                                                                                                    <Badge className="bg-active text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                                        PENDING
                                                                                                        <img
                                                                                                            src={
                                                                                                                Arrow
                                                                                                            }
                                                                                                            alt=""
                                                                                                            className="ps-2"
                                                                                                        />
                                                                                                    </Badge>
                                                                                                ) : (
                                                                                                    <Badge className="bg-success text-sm align-text-bottom ms-2 px-3 py-2 cursor-pointer w-100 text-uppercase">
                                                                                                        Confirmed
                                                                                                        <img
                                                                                                            src={
                                                                                                                Arrow
                                                                                                            }
                                                                                                            alt=""
                                                                                                            className="ps-2"
                                                                                                        />
                                                                                                    </Badge>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                        {!isDisabled && (
                                                                                            <div
                                                                                                className="mx-3"
                                                                                                onClick={() => {
                                                                                                    showAlert(
                                                                                                        item?.pack_load_unload_nano_id,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faXmark
                                                                                                        }
                                                                                                        size="lg"
                                                                                                        className="form-control-serch1 text-light rounded-2"
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ),
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td
                                                                            className="text-center my-5"
                                                                            colSpan={
                                                                                4
                                                                            }
                                                                        >
                                                                            <h5>
                                                                                Pack
                                                                                &
                                                                                Load
                                                                                data
                                                                                not
                                                                                found
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    {!isDisabled && (
                                                        <div
                                                            className="text-primary cursor-pointe d-flex justify-content-beetwin"
                                                            onClick={() => {
                                                                handleSelectPackerShow(
                                                                    'Packers',
                                                                    [
                                                                        startDate,
                                                                        endDate,
                                                                    ],
                                                                );
                                                            }}
                                                        >
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPlusIcon
                                                                    }
                                                                    className="pe-3 "
                                                                />
                                                            </div>
                                                            <div>
                                                                <label
                                                                    htmlFor="text"
                                                                    className="text-sm fw-semibold"
                                                                >
                                                                    Add more
                                                                    actions
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col xs={12} xl={6}>
                                                    <p className="text-dark text-sm fw-semibold mt-4 text-uppercase">
                                                        UNLOADING
                                                    </p>
                                                    <div className="overflow-table-auto">
                                                        <Table
                                                            size="sm"
                                                            className="border table_fwnp"
                                                        >
                                                            <thead>
                                                                <tr className="table-headings">
                                                                    <th className="text-sm fw-semibold text-center  px-4 py-3 text-truncate">
                                                                        DATE
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                                                                        ACTION
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center  px-2 py-3 text-truncate">
                                                                        CREWED
                                                                        BY
                                                                    </th>
                                                                    <th className="text-sm fw-semibold text-center text-center py-3 text-center text-truncate">
                                                                        STATUS
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {packUnloadData.length >
                                                                0 ? (
                                                                    packUnloadData.map(
                                                                        (
                                                                            item: any,
                                                                            index: number,
                                                                        ) => (
                                                                            <tr
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <td
                                                                                    className="text-base fw-normal text-center py-3 text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Unloaders',
                                                                                            [
                                                                                                packerStartDate,
                                                                                                packerEndDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {dateTimeZone(
                                                                                        item?.pack_date,
                                                                                    )}
                                                                                </td>
                                                                                <td
                                                                                    className="text-base fw-normal text-center py-3 text-capitalize text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Unloaders',
                                                                                            [
                                                                                                packerStartDate,
                                                                                                packerEndDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item?.packer_action
                                                                                    }
                                                                                </td>
                                                                                <td
                                                                                    className="text-base fw-normal py-3 text-truncate"
                                                                                    onClick={() => {
                                                                                        handleEditSelectPackerShow(
                                                                                            item?.pack_load_unload_nano_id,
                                                                                            'Unloaders',
                                                                                            [
                                                                                                packerStartDate,
                                                                                                packerEndDate,
                                                                                            ],
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <p className="space_overflow text-center p-0 m-0">
                                                                                        {item?.offerpacker &&
                                                                                            item?.offerpacker.map(
                                                                                                (
                                                                                                    packer: any,
                                                                                                    dataIndex: number,
                                                                                                ) => (
                                                                                                    <span
                                                                                                        key={
                                                                                                            dataIndex
                                                                                                        }
                                                                                                    >
                                                                                                        {dataIndex !==
                                                                                                        0
                                                                                                            ? `${', '}`
                                                                                                            : null}
                                                                                                        {
                                                                                                            packer?.packer_name
                                                                                                        }
                                                                                                    </span>
                                                                                                ),
                                                                                            )}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="text-truncate">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        {item?.packer_status &&
                                                                                        item?.packer_status ===
                                                                                            'assigned' ? (
                                                                                            <Badge className="bg-primary text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                                                                                                Assigned
                                                                                                <img
                                                                                                    src={
                                                                                                        Arrow
                                                                                                    }
                                                                                                    alt=""
                                                                                                    className="ps-2"
                                                                                                />
                                                                                            </Badge>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item?.packer_status ===
                                                                                                'pending' ? (
                                                                                                    <Badge className="bg-active text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                                                                                                        PENDING
                                                                                                        <img
                                                                                                            src={
                                                                                                                Arrow
                                                                                                            }
                                                                                                            alt=""
                                                                                                            className="ps-2"
                                                                                                        />
                                                                                                    </Badge>
                                                                                                ) : (
                                                                                                    <Badge className="bg-success text-sm align-text-bottom ms-2 text-center py-2 cursor-pointer w-100 text-uppercase">
                                                                                                        Confirmed
                                                                                                        <img
                                                                                                            src={
                                                                                                                Arrow
                                                                                                            }
                                                                                                            alt=""
                                                                                                            className="ps-2"
                                                                                                        />
                                                                                                    </Badge>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                        {!isDisabled && (
                                                                                            <div
                                                                                                className="mx-3"
                                                                                                onClick={() => {
                                                                                                    showAlert(
                                                                                                        item?.pack_load_unload_nano_id,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <span>
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={
                                                                                                            faXmark
                                                                                                        }
                                                                                                        size="lg"
                                                                                                        className="form-control-search text-light rounded-2"
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ),
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td
                                                                            className="text-center my-5"
                                                                            colSpan={
                                                                                4
                                                                            }
                                                                        >
                                                                            <h5>
                                                                                Unload
                                                                                data
                                                                                not
                                                                                found
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    {!isDisabled && (
                                                        <div
                                                            className="text-primary cursor-pointe d-flex"
                                                            onClick={() => {
                                                                handleSelectPackerShow(
                                                                    'Unloaders',
                                                                    [
                                                                        packerStartDate,
                                                                        packerEndDate,
                                                                    ],
                                                                );
                                                            }}
                                                        >
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPlusIcon
                                                                    }
                                                                    className="pe-3 "
                                                                />
                                                            </div>
                                                            <div>
                                                                <label
                                                                    htmlFor="text"
                                                                    className="text-sm fw-semibold"
                                                                >
                                                                    Add more
                                                                    action
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    {!isDisabled && (
                                        <Row className="justify-content-center">
                                            <Col md={5} className="order-md-2">
                                                <Button
                                                    variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                                                    type="submit"
                                                    ref={formEl}
                                                    onClick={() => {
                                                        setSaveState(true);
                                                    }}
                                                    disabled={isUpdateOffer}
                                                >
                                                    {isUpdateOffer ? (
                                                        <LoaderBtn />
                                                    ) : (
                                                        'Save'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col md={5} className="order-md-1">
                                                <Button
                                                    className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                                                    onClick={() => onClose()}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </>
                    )}
                </Modal.Body>
            </Modal>
            {showSelectPackerPopup && (
                <SelectPackersPopup
                    showPopup={showSelectPackerPopup}
                    handleClose={handleSelectPackerClose}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    offerDataObj={offerDataObj}
                    isDisabled={isDisabled}
                    listCall={listCall}
                    packerPopUpTitle={packerPopUpTitle}
                    packerPopUpDateRange={packerPopUpDateRange}
                />
            )}

            {showEditSelectPackerPopup && (
                <SelectPackersPopup
                    showPopup={showEditSelectPackerPopup}
                    handleClose={handleEditSelectPackerClose}
                    offerId={offerId}
                    getOfferData={getOfferData}
                    offerDataObj={offerDataObj}
                    offerPackerData={offerPackerData}
                    isDisabled={isDisabled}
                    listCall={listCall}
                    packerPopUpTitle={packerPopUpTitle}
                    packerPopUpDateRange={packerPopUpDateRange}
                />
            )}
        </>
    );
};

export default JobSchedulePopup;
