export const APP_ROOT = '';

export const AUTH_ROUTES = {
    LOGIN: `${APP_ROOT}/login`,
    REGISTER: `${APP_ROOT}/register`,
    EMAIL_VERIFY: `${APP_ROOT}/email/verify/:userEmail?`,
    FORGOT_PASSWORD: `${APP_ROOT}/forgot/password/:userEmail?`,
};

export const ROUTES = {
    DASHBOARD: `${APP_ROOT}/dashboard`,
    USERS: `${APP_ROOT}/users`,
    USERPROFILE: `${APP_ROOT}/user/profile`,
    COMPANY: `${APP_ROOT}/company`,
    OFFERS: `${APP_ROOT}/offers`,
    OFFERS_LIST: `${APP_ROOT}/offers-list`,
    JOBS: `${APP_ROOT}/jobs`,
    JOB_LISTS: `${APP_ROOT}/jobList`,
    CREATE_TSP: `${APP_ROOT}/create/tsp`,
    TSP: `${APP_ROOT}/tsp`,
    UPDATE_TSP: `${APP_ROOT}/update/tsp/:tspId`,

    CREATE_OFFER: `${APP_ROOT}/create/offer`,
    ACCEPT_OFFER: `${APP_ROOT}/accept/offer/:offerId`,
    UPDATE_OFFER: `${APP_ROOT}/update/offer/:offerId`,
    UPDATE_JOB: `${APP_ROOT}/update/job/:offerId`,
    VIEW_OFFER: `${APP_ROOT}/view/offer/:offerId`,
    VIEW_JOB: `${APP_ROOT}/view/job/:offerId`,

    DRIVERS: `${APP_ROOT}/drivers`,
    CREATE_DRIVER: `${APP_ROOT}/create/driver`,
    UPDATE_DRIVER: `${APP_ROOT}/update/driver/:driverId`,

    PACKERS: `${APP_ROOT}/packers`,
    CREATE_PACKER: `${APP_ROOT}/create/packer`,
    UPDATE_PACKER: `${APP_ROOT}/update/packer/:packerId`,

    AGENTS: `${APP_ROOT}/agents`,
    CREATE_AGENTS: `${APP_ROOT}/create/agent`,
    UPDATE_AGENT: `${APP_ROOT}/update/agent/:agentId`,

    REGIONS: `${APP_ROOT}/regions`,
    CREATE_REGIONS: `${APP_ROOT}/create/regions`,
    UPDATE_REGION: `${APP_ROOT}/update/regions/:regionId`,

    STEP_1: `${APP_ROOT}/step1`,
    STEP_2: `${APP_ROOT}/step2`,
    STEP_3: `${APP_ROOT}/step3`,
    STEP_4: `${APP_ROOT}/step4`,
    STEP_5: `${APP_ROOT}/step5`,
    STEP_6: `${APP_ROOT}/step6`,
    STEP_7: `${APP_ROOT}/step7`,
    FINAL_STEP: `${APP_ROOT}/final-step`,
    ORGANIZATIONS: `${APP_ROOT}/organizations`,
    UPDATE_ORGANIZATIONS: `${APP_ROOT}/update/organizations/:organizationId`,
    UPDATE_USER_ORGANIZATIONS: `${APP_ROOT}/user/organizations/:organizationId`,

    LISTING: `${APP_ROOT}/lists`,
    WORKFLOW: `${APP_ROOT}/workFlow`,
    SCHEDULE: `${APP_ROOT}/schedule`,
};
