import { routesPath } from '@constants/routesNavigate';

import Dashboard from '@images/dashboard.svg';
import OfferIcon from '@images/offer-icon.svg';
import UserIcon from '@images/user-icon.svg';
import offers from '@images/offers.svg';
import JobsIcon from '@images/jobs-icon.svg';
import Jobs from '@images/jobs.svg';
import Setting from '@images/setting.svg';
import Users from '@images/users.svg';
import OrganizationIcon from '@images/organization-icon.svg';
import ListIcon from '@images/list-icon.svg';
import Schedule from '@images/schedule.svg';
import ScheduleIcon from '@images/schedule-icon.svg';

import { ISideItem } from './appLayout.interface';

export const sideBarItemArray: ISideItem[] = [
    {
        path: routesPath.dashboard,
        displayName: 'Dashboard',
        icon: Dashboard,
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.offers,
        displayName: 'Offers',
        icon: OfferIcon,
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.jobs,
        displayName: 'Jobs',
        icon: JobsIcon,
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.users,
        displayName: 'Users',
        icon: UserIcon,
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.schedule,
        displayName: 'Schedule',
        icon: ScheduleIcon,
        accessible: ['user', 'admin'],
    },
];
export const sideBarSubItemItemArray: ISideItem[] = [
    {
        path: routesPath.updateUserOrganizations,
        displayName: 'Organization',
        icon: OrganizationIcon,
        accessible: ['user', 'admin'],
    },
    {
        path: routesPath.listing,
        displayName: 'List',
        icon: ListIcon,
        accessible: ['user', 'admin'],
    },
];

export const iconsObj: any = {
    [routesPath.offers]: offers,
    [routesPath?.organizations]: Setting,
    [routesPath?.updateOrganizations]: Setting,
    [routesPath?.updateUserOrganizations]: Setting,
    [routesPath?.jobs]: Jobs,
    [routesPath?.jobList]: Jobs,
    [routesPath.users]: Users,
    [routesPath.schedule]: Schedule,
};
