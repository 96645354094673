import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddOfferNote,
    apiDeleteOfferNote,
    apiOfferNoteData,
    apiOfferNotesList,
    apiFuelSurchargeData,
    apiUpdateOfferNote,
} from '@services/API/apiFunctions';

const addOfferNote = createAsyncThunk(
    'addOfferNote',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddOfferNote(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const offerNotesList = createAsyncThunk(
    'offerNotesList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferNotesList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateOfferNote = createAsyncThunk(
    'updateOfferNote',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateOfferNote(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getOfferNoteInfo = createAsyncThunk(
    'getOfferNoteInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiOfferNoteData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getFuelSurchargeData = createAsyncThunk(
    'getOfferNoteInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiFuelSurchargeData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteOfferNote = createAsyncThunk(
    'deleteOfferNote',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteOfferNote(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export {
    addOfferNote,
    offerNotesList,
    updateOfferNote,
    getOfferNoteInfo,
    deleteOfferNote,
    getFuelSurchargeData,
};
