import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { CommonState } from './app.interface';

const defaultState: CommonState = {
    authUser: null,
    searchText: null,
    isSearch: false,
    profilePopup: false,
    response: {
        message: null,
        status: null,
    },
    oneResponse: {
        message: null,
        status: null,
    },
};

const appSlice = createSlice({
    name: 'common',
    initialState: cloneDeep(defaultState),
    reducers: {
        clearStatecommon: (state) => {
            state = cloneDeep(defaultState);
        },
        setAuthUser: (state, { payload }: PayloadAction<any>) => {
            state.authUser = payload;
        },
        responseMessage: (
            state,
            {
                payload,
            }: PayloadAction<{ message: string | null; status: number | null }>,
        ) => {
            state.response = payload;
        },
        responseOneTimeMessage: (
            state,
            {
                payload,
            }: PayloadAction<{ message: string | null; status: number | null }>,
        ) => {
            state.oneResponse = payload;
        },
        toggleProfilePopup: (state, { payload }: PayloadAction<boolean>) => {
            state.profilePopup = payload;
        },
        clearResponse: (state) => {
            state.response = {
                message: null,
                status: null,
            };
        },
        clearOneTimeResponse: (state) => {
            state.oneResponse = {
                message: null,
                status: null,
            };
        },
        setSearchText: (state, { payload }: PayloadAction<string>) => {
            state.searchText = payload;
        },
        setIsSearch: (state, { payload }: PayloadAction<boolean>) => {
            state.isSearch = payload;
        },
    },
    extraReducers: {},
});

export const {
    clearStatecommon,
    responseMessage,
    clearResponse,
    responseOneTimeMessage,
    clearOneTimeResponse,
    toggleProfilePopup,
    setAuthUser,
    setSearchText,
    setIsSearch,
} = appSlice.actions;

export default appSlice.reducer;
