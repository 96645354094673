import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Image, Nav, Badge, Dropdown, Form } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';

import Notification from '@images/notification.svg';
import Setting from '@images/setting.svg';
import Search from '@images/search.svg';

import { logOutUser } from '@app/Auth/Login/functions';
import HeaderTopContent from '@app/AppLayout/HeaderTopContent';
import { iconsObj } from '@app/AppLayout/sidebarList';
import { responseMessage, setSearchText } from '@redux/app/appSlice';

const NavbarTop = ({ isListView, isSearch, showHeaderContent }: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { organizationId } = useParams();

    const { login, common } = useSelector((state: any) => state);

    const { isLogOutUser, authUser } = login;
    const { searchText } = common;

    const [showSearch, setShowSearch] = useState<boolean>(false);

    useEffect(() => {
        setShowSearch(searchText ? true : false);
    }, []);

    const updateSearchText = async (text: string) => {
        await dispatch(setSearchText(text));
    };

    const LogOutUser = async () => {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            return;
        }

        const { payload } = await dispatch(logOutUser(''));

        if (payload?.data?.status) {
            dispatch(responseMessage(payload));
            localStorage.removeItem('accessToken');
            navigate(routesPath?.login);
        } else {
            toast.error(payload?.message);
        }
    };

    const updateImage: JSX.Element = useMemo(() => {
        const {
            user_profile: userProfile,
            firstName,
            lastName,
        } = authUser || {};
        if (userProfile) {
            return (
                <Image
                    src={userProfile}
                    height="50px"
                    width="50px"
                    className="rounded-circle"
                />
            );
        } else {
            const initials = `${(firstName?.charAt(0) || '').toUpperCase()}${(
                lastName?.charAt(0) || ''
            ).toUpperCase()}`;
            return <div className="right_top_img">{initials}</div>;
        }
    }, [authUser]);

    const updateSearch: any = useMemo(() => {
        if (searchText) {
            return (
                isSearch && (
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2 mf__searchbar"
                        aria-label="Search"
                        value={searchText}
                        autoFocus={isSearch}
                        onChange={(e) => updateSearchText(e.target.value)}
                    />
                )
            );
        } else {
            setShowSearch(false);
            return (
                isSearch && (
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2 mf__searchbar"
                        aria-label="Search"
                        value={''}
                        autoFocus={isSearch}
                        onChange={(e) => updateSearchText(e.target.value)}
                    />
                )
            );
        }
    }, [searchText, pathname]);

    return (
        <Nav
            className={`justify-content-between mfnw__navigation-top ${
                pathname === routesPath?.schedule ? '' : 'shadow-sm'
            } flex-wrap align-items-center  bg-white w-full ps-4 pe-md-5 ${
                showHeaderContent && 'p-3'
            }`}
        >
            <div className="d-flex align-items-center">
                {showHeaderContent ? (
                    <HeaderTopContent />
                ) : (
                    <>
                        <Image
                            src={iconsObj?.[pathname] || Setting}
                            alt="settingIcon"
                            className="ms-5 ms-lg-0"
                        />
                        <h6 className="m-0 mx-2 fw-semibold  text-capitalize">
                            {organizationId
                                ? 'Settings'
                                : isListView
                                ? pathname.replace(/^\//, '')
                                : ''}
                        </h6>
                    </>
                )}
            </div>
            <div className="mfnw__content_right d-flex align-items-center">
                {showSearch && <div className="ms-2">{updateSearch}</div>}
                {isSearch && (
                    <Image
                        src={Search}
                        onClick={() => setShowSearch(!showSearch)}
                        className="cursor-pointer ms-2"
                    />
                )}

                <div className="position-relative cursor-pointer mx-3 me-md-4">
                    <Image src={Notification} />
                    <Badge bg="primary position-absolute top-0 start-50 rounded-circle">
                        9
                    </Badge>
                </div>

                <div className="avtar" id="dropdown-item-button">
                    <Dropdown className="profile__dropdown">
                        <Dropdown.Toggle className="btn-header">
                            <div className="avtar">
                                <Link to="#" className="text-dark">
                                    {updateImage}
                                </Link>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="mt-2">
                            <Link to="/user/profile" className="text-dark">
                                <Dropdown.Item as="button">
                                    My Profile
                                </Dropdown.Item>
                            </Link>
                            <Link to="#" className="text-dark">
                                <Dropdown.Item as="button">
                                    My Settings
                                </Dropdown.Item>
                            </Link>
                            <Link to="#" className="text-dark">
                                <Dropdown.Item as="button">
                                    Privacy Policy
                                </Dropdown.Item>
                            </Link>
                            <Dropdown.Item
                                as="button"
                                disabled={isLogOutUser}
                                onClick={LogOutUser}
                            >
                                {isLogOutUser ? <LoaderBtn /> : 'Logout'}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </Nav>
    );
};

export default NavbarTop;
