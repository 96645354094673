/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Image, Form, Accordion, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ReactPagination from '@components/reactPagination';

import { dateEndTimeFormat } from '@constants/general.const';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import User from '@images/users.svg';
import Bin from '@images/trash-can-regular.svg';

import { setTimeZone } from '@utils/utilFunctions';

const DocumentTable = ({
    currentPageData,
    pageCount,
    deleteArrayChange,
    deleteArr,
    isDisabled,
    showAlert,
    resMessage,
    filter,
    handlePageClick,
    editNote,
}: any) => {
    const { documentData } = useSelector((state: any) => state);
    const { isJobDocumentsList, isDeleteJobDocument } = documentData;

    const [openAccordion, setOpenAccordion] = useState<any>('');

    const handleAccordionToggle = (employeeId: any) => {
        setOpenAccordion(openAccordion === employeeId ? '' : employeeId);
    };

    // const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <>
            <div className="mfnw_document_table">
                <Table className="table px-2 mb-0">
                    <thead>
                        <tr>
                            <th className="text-sm fw-semibold text-dark mfnw_document_table_width">
                                FILE
                            </th>
                            <th className="text-sm fw-semibold text-dark">
                                FILE TYPE
                            </th>
                            <th className="text-sm fw-semibold text-dark">
                                {' '}
                                USER
                            </th>
                            <th className="text-sm fw-semibold text-dark">
                                TIME
                            </th>
                            <th className="text-sm fw-semibold text-dark"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {isJobDocumentsList || isDeleteJobDocument ? (
                            <tr>
                                <span className="ms-5">
                                    <LoaderBtn size="sm" />
                                </span>
                            </tr>
                        ) : currentPageData && currentPageData.length !== 0 ? (
                            currentPageData.map((data: any, index: number) => (
                                <>
                                    <tr>
                                        <td className="text-truncate cursor-pointer">
                                            <div className="d-flex">
                                                <Form.Group
                                                    className=""
                                                    controlId="formBasicCheckbox"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            deleteArrayChange(
                                                                e,
                                                                data?.document_nano_id,
                                                            );
                                                        }}
                                                        checked={deleteArr.includes(
                                                            data?.document_nano_id,
                                                        )}
                                                        disabled={isDisabled}
                                                    />
                                                </Form.Group>
                                                <h6
                                                    className="text-sm text-base ps-3 m-0 pt-1 text-truncate"
                                                    onClick={() =>
                                                        handleAccordionToggle(
                                                            `demo${index}`,
                                                        )
                                                    }
                                                >
                                                    {data?.file?.[0] === '['
                                                        ? JSON.parse(
                                                              data?.file,
                                                          )?.[0]?.name
                                                        : '(N/A)'}
                                                </h6>
                                            </div>
                                        </td>
                                        <td
                                            className="text-dark text-base m-0 new_table_data text-truncate"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {data?.document_types}
                                        </td>
                                        <td
                                            className="text-dark text-base m-0 new_table_data text-truncate"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {data?.user_document_data
                                                ? `${data?.user_document_data?.firstName} ${data?.user_document_data?.lastName}`
                                                : ''}
                                        </td>
                                        <td
                                            className="text-dark text-base m-0 new_table_data text-truncate"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            {setTimeZone(
                                                data?.date_time,
                                                dateEndTimeFormat,
                                            )}
                                        </td>
                                        <td
                                            className="text-dark text-base m-0 new_table_data text-truncate cursor-pointer"
                                            onClick={() =>
                                                handleAccordionToggle(
                                                    `demo${index}`,
                                                )
                                            }
                                        >
                                            <div
                                                className="accordion-button"
                                                aria-expanded={
                                                    openAccordion ===
                                                    `demo${index}`
                                                }
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="p-0">
                                            <Accordion.Collapse
                                                eventKey={`demo${index}`}
                                                in={
                                                    openAccordion ===
                                                    `demo${index}`
                                                }
                                            >
                                                <div className="mfnw_pensil_icon">
                                                    <div className="d-flex flex-row-reverse p-2">
                                                        <FontAwesomeIcon
                                                            className="mx-2 cursor-pointer text-iconcolor"
                                                            icon={faPen}
                                                            onClick={() =>
                                                                editNote(
                                                                    data?.document_nano_id,
                                                                    'edit',
                                                                    data,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {data?.file?.[0] === '[' ? (
                                                        JSON.parse(
                                                            data?.file,
                                                        ).map(
                                                            (
                                                                image: any,
                                                                dataIndex: number,
                                                            ) => (
                                                                <div
                                                                    className={`mfnw_detail_class ${
                                                                        image?.url
                                                                            .split(
                                                                                '.',
                                                                            )
                                                                            .pop()
                                                                            .toLowerCase() ===
                                                                        'pdf'
                                                                            ? 'w-90'
                                                                            : ''
                                                                    }`}
                                                                    key={
                                                                        dataIndex
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`mfnw_detail ${
                                                                            image?.url
                                                                                .split(
                                                                                    '.',
                                                                                )
                                                                                .pop()
                                                                                .toLowerCase() ===
                                                                            'pdf'
                                                                                ? 'jobDocument_preview'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <div className="mfnw_detail_second">
                                                                            <NavLink
                                                                                to={
                                                                                    image?.url
                                                                                }
                                                                                target="_blank"
                                                                            >
                                                                                <div className="mfnw_detail_three">
                                                                                    <div className="mfnw_detail_for justify-content-center rounded-2 d-flex">
                                                                                        {image?.url
                                                                                            .split(
                                                                                                '.',
                                                                                            )
                                                                                            .pop()
                                                                                            .toLowerCase() ===
                                                                                        'pdf' ? (
                                                                                            <div className="w-100 text-center">
                                                                                                <iframe
                                                                                                    src={
                                                                                                        image?.url
                                                                                                    }
                                                                                                    className="w-75 h-90"
                                                                                                    title={`PDF Viewer`}
                                                                                                />
                                                                                                <div className="mt-2">
                                                                                                    <Button
                                                                                                        onClick={() => {
                                                                                                            window.open(
                                                                                                                image?.url,
                                                                                                                '_blank',
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        Open
                                                                                                        PDF
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <Image
                                                                                                src={
                                                                                                    image?.url ??
                                                                                                    User
                                                                                                }
                                                                                                className="text-light ms-2 w-100"
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </NavLink>

                                                                            <div className="mx-4 d-flex mt-3">
                                                                                <p className="text-base text-dark">
                                                                                    {image?.name ??
                                                                                        '(N/A)'}

                                                                                    <span className="text-light text-base px-2">
                                                                                        {image?.fileSize ??
                                                                                            '(N/A)'}
                                                                                    </span>
                                                                                </p>
                                                                                {!isDisabled && (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            showAlert(
                                                                                                data?.document_nano_id,
                                                                                                dataIndex,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <Image
                                                                                            src={
                                                                                                Bin
                                                                                            }
                                                                                            className="text-light ms-2 mb-4"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )
                                                    ) : (
                                                        <div className="main_document_detail">
                                                            <div className="py-4 px-4 m-auto ">
                                                                <div
                                                                    className={`mfnw_detail new_table_data ${
                                                                        data?.file
                                                                            .split(
                                                                                '.',
                                                                            )
                                                                            .pop()
                                                                            .toLowerCase() ===
                                                                        'pdf'
                                                                            ? 'jobDocument_preview'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div className="mfnw_detail_second">
                                                                        <NavLink
                                                                            to={
                                                                                data?.file
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <div className="mfnw_detail_three">
                                                                                <div className="mfnw_detail_for justify-content-center rounded-2 d-flex">
                                                                                    {data?.file
                                                                                        .split(
                                                                                            '.',
                                                                                        )
                                                                                        .pop()
                                                                                        .toLowerCase() ===
                                                                                    'pdf' ? (
                                                                                        <div className="w-100 text-center">
                                                                                            <iframe
                                                                                                src={
                                                                                                    data?.file
                                                                                                }
                                                                                                className="w-75 h-90"
                                                                                                title={`PDF Viewer`}
                                                                                            />
                                                                                            <div className="mt-2">
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        window.open(
                                                                                                            data?.file,
                                                                                                            '_blank',
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Open
                                                                                                    PDF
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <Image
                                                                                            src={
                                                                                                data?.file ??
                                                                                                User
                                                                                            }
                                                                                            className="text-light ms-2 w-100"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>

                                                                        <div className="mx-4 d-flex mt-3">
                                                                            <p className="text-base text-dark">
                                                                                {
                                                                                    '(N/A)'
                                                                                }

                                                                                <span className="text-light text-base px-2">
                                                                                    {
                                                                                        '(N/A)'
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                            {!isDisabled && (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        showAlert(
                                                                                            data?.document_nano_id,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <Image
                                                                                        src={
                                                                                            Bin
                                                                                        }
                                                                                        className="text-light ms-2 mb-4"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Accordion.Collapse>
                                        </td>
                                    </tr>
                                </>
                            ))
                        ) : (
                            <>
                                <tr>
                                    <td colSpan={5}>
                                        {isJobDocumentsList ? (
                                            <span className="ms-5">
                                                <LoaderBtn size="sm" />
                                            </span>
                                        ) : (
                                            <div className="text-center my-3">
                                                <h3>{resMessage}</h3>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </div>
            <div className="pagination_of_document">
                <div className="mt-4">
                    <ReactPagination
                        currentPageData={currentPageData}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        filters={filter}
                    />
                </div>
            </div>
        </>
    );
};

export default DocumentTable;
