import React, { Fragment } from 'react';
import { Col, Image, Row, Table, Button } from 'react-bootstrap';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import RejectIcon from '@images/reject-icon-action.svg';
import AcceptIcon from '@images/accept-icon-action.svg';
import Followiconfrom from '@images/Shape.svg';
import OfferNotFound from '@images/offer-not-found.svg';

import { dateTimeZone } from '@utils/utilFunctions';
const CompactViewOffer = ({
    currentPageData,
    getElapsedTime,
    handleViewShow,
    handleAcceptActions,
    handleAcceptViewShow,
    isRejectOffer,
}: any) => (
    <>
        <div className="overflow-offer-table-auto">
            {currentPageData && currentPageData.length > 0 && (
                <Table
                    borderless
                    className="mfnw__borderless_card-table mb-0 table-one align-middle"
                >
                    <thead>
                        <tr>
                            <th className="text-sm">SHIPPER</th>
                            <th className="text-sm">WEIGHT</th>
                            <th className="text-sm text-center">LINE HAUL</th>
                            <th className="text-sm text-center">PACK ($)</th>
                            <th className="text-sm text-center">HAUL ($)</th>
                            <th className="text-sm">PACK</th>
                            <th className="text-sm">PICKUP</th>
                            <th className="text-sm">RDD</th>
                            <th className="text-sm">ELAPSED</th>
                            <th className="text-sm text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData && currentPageData.length > 0
                            ? currentPageData.map(
                                  (offer: any, index: number) => (
                                      <Fragment key={index}>
                                          <tr className="cursor-pointer text-truncate">
                                              <td
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  <span className="text-uppercase">
                                                      {offer?.shipperdata
                                                          ?.shipper_name ??
                                                          offer?.shipper}
                                                  </span>
                                                  <div className="d-flex table-font">
                                                      <span className="text-sm">
                                                          {`${offer?.address_origin?.city},  ${offer?.address_origin?.stateTown}`}
                                                      </span>
                                                      <Image
                                                          className="mx-1"
                                                          src={Followiconfrom}
                                                      />
                                                      <span className="text-sm">
                                                          {`${offer?.address_destination?.city},  ${offer?.address_destination?.stateTown}`}
                                                      </span>
                                                  </div>
                                              </td>
                                              <td
                                                  className="text-dark text-base fw-bold text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {offer?.estimatedWeight}
                                              </td>
                                              <td
                                                  className="text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  <div className="tbl-box-responsive text-center text-dark">
                                                      <span className="text">
                                                          {
                                                              offer
                                                                  ?.line_haule_data
                                                                  ?.discount
                                                          }
                                                          % disc
                                                      </span>
                                                      <p className="mb-0 text-detail1">
                                                          {
                                                              offer
                                                                  ?.line_haule_data
                                                                  ?.miles
                                                          }
                                                          miles
                                                      </p>
                                                  </div>
                                              </td>
                                              <td
                                                  className="text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {' '}
                                                  <div className="tbl-box1 text-center text-success tbl-box">
                                                      <span className="text">
                                                          (N/A)
                                                      </span>
                                                  </div>
                                              </td>
                                              <td
                                                  className="text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  <div className="tbl-box1 text-center text-success tbl-box">
                                                      <span className="text">
                                                          (N/A)
                                                      </span>
                                                  </div>
                                              </td>
                                              <td
                                                  className="text-base fw-semi-bold text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {dateTimeZone(
                                                      offer?.packDate,
                                                  )}
                                              </td>
                                              <td
                                                  className="text-base fw-semi-bold text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {dateTimeZone(
                                                      offer?.pickupDate,
                                                  )}
                                              </td>
                                              <td
                                                  className="text-base fw-semi-bold text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {dateTimeZone(
                                                      offer?.deliveryDate,
                                                  )}
                                              </td>
                                              <td
                                                  className="text-base fw-semi-bold text-truncate"
                                                  onClick={() =>
                                                      handleViewShow(
                                                          offer?.offers_nano_id,
                                                      )
                                                  }
                                              >
                                                  {getElapsedTime(offer)}
                                              </td>
                                              <td className="text-base fw-semi-bold text-center text-truncate">
                                                  <Button
                                                      variant="primary"
                                                      className="fw-semibold m-auto p-1"
                                                      onClick={() => {
                                                          handleAcceptViewShow(
                                                              offer?.offers_nano_id,
                                                          );
                                                      }}
                                                      title="Accept Offer"
                                                      size="sm"
                                                  >
                                                      <Image
                                                          height={20}
                                                          src={AcceptIcon}
                                                      />
                                                  </Button>
                                                  <Button
                                                      className="btn-cancel btn-backgroud fw-semibold m-auto ms-2"
                                                      onClick={() =>
                                                          handleAcceptActions(
                                                              offer?.offers_nano_id,
                                                              'rejected',
                                                          )
                                                      }
                                                      title="Rejected Offer"
                                                      size="sm"
                                                  >
                                                      {isRejectOffer ? (
                                                          <LoaderBtn />
                                                      ) : (
                                                          <Image
                                                              height={22}
                                                              src={RejectIcon}
                                                          />
                                                      )}
                                                  </Button>
                                              </td>
                                          </tr>
                                      </Fragment>
                                  ),
                              )
                            : null}
                    </tbody>
                </Table>
            )}
        </div>
        {currentPageData && currentPageData.length === 0 && (
            <Row className="justify-content-center flex-column align-items-center">
                <Col md={6} xl={5} className="text-center mt-5">
                    <Image src={OfferNotFound} />
                    <h5 className="text-light mt-4 fw-bold">
                        You haven&apos;t received any offers yet. <br />{' '}
                        Don&apos;t give up, keep searching and you&apos;ll
                        eventually find them.
                    </h5>
                </Col>
            </Row>
        )}
    </>
);

export default CompactViewOffer;
