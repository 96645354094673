import * as Yup from 'yup';

import {
    minPhoneLength,
    phoneLengthCheck,
    phoneRegex,
} from '@constants/general.const';

export const schema = Yup.object({
    organization_name: Yup.string().required('Name is a required field'),
    organization_address: Yup.string().required('Address is a required field'),
    organization_zip_code: Yup.string()
        .required('Zip Code is a required field')
        .nullable(true),
    organization_city: Yup.string().required('City is a required field'),
    organization_state: Yup.string().required('State is a required field'),
    organization_email: Yup.string()
        .email('Please enter a valid email')
        .required('Email is a required field'),
    organization_phone: Yup.string()
        .required('Phone is a required field')
        .test(
            'len',
            `Phone number must be exactly ${minPhoneLength} characters`,
            (val) => {
                if (val) {
                    const cleanedPhone = val.replace(phoneRegex, '');
                    return cleanedPhone.length === phoneLengthCheck;
                }
                return false;
            },
        )
        .min(
            minPhoneLength,
            `Phone number must be at least ${minPhoneLength} characters long`,
        )
        .nullable(true),
}).required();
