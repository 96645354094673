import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import { searchTimeOut } from '@constants/general.const';

import RegionsNotFound from '@images/regions-not-found.svg';

import RegionPopupStep6 from '@app/CreateOrganization/step6/RegionPopupStep6';
import RegionViewOrEditPopupStep6 from '@app/CreateOrganization/step6/regionViewOrEditPopupStep6';
import OrganizationTabHeader from '@app/CreateOrganization/Organizations/organizationTabHeader';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';
import {
    getRegionsData,
    regionDeleteById,
    getCurrentRegionData,
} from '@app/CreateOrganization/step6/step6.const';

const RegionsList = ({ organizationId }: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { regionsStep6Data } = useSelector((state: any) => state);
    const { isDeleteRegion, getRegionDataList } = regionsStep6Data;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<Array<any> | null>(null);
    const [search, setSearch] = useState<any>();
    const [regionData, setRegionData] = useState<any>();
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (search || search === '') {
            const getDataSearch: any = setTimeout(() => {
                getData();
            }, searchTimeOut);
            return () => clearTimeout(getDataSearch);
        }
    }, [search]);

    const getData = async () => {
        getRegionsData(
            dispatch,
            { nanoId: organizationId, searchText: search },
            setDataList,
        );
    };

    const handleEditClose = () => {
        setShowEditPopup(false);
        setRegionData(null);
    };

    const handleEditShow = () => {
        setShowEditPopup(true);
        setShowViewPopup(false);
    };

    const handleViewClose = () => {
        setShowViewPopup(false);
        setRegionData(null);
    };
    const handleViewShow = (nanoId: any) => {
        setShowViewPopup(true);
        getRegionData(nanoId);
    };

    const getRegionData = async (regionId: any) => {
        getCurrentRegionData(regionId, dispatch, setRegionData, navigate);
    };

    const deleteById = async (officeId: string) => {
        regionDeleteById(
            officeId,
            dispatch,
            getData,
            showViewPopup,
            handleViewClose,
        );
    };

    return (
        <>
            <OrganizationTabHeader
                title={'Region List'}
                search={search}
                setSearch={setSearch}
                handleShow={handleShow}
                buttonText={'Add New Regions'}
            />
            <div className="d-flex flex-wrap gap-2">
                {isDeleteRegion || getRegionDataList ? (
                    <LoaderBtn />
                ) : (
                    dataList &&
                    dataList.length > 0 && (
                        <StepBadgeComponent
                            dataList={dataList}
                            deleteById={deleteById}
                            name={'region_name'}
                            nanoId={'region_nano_id'}
                            handleViewShow={handleViewShow}
                            className="mnfw__badge_light lh-base text-dark py-2 fs-6 fw-semibold d-flex align-align-items-center bg-white border-opacity-25"
                        />
                    )
                )}
            </div>
            <NotFoundPages
                currentPageData={dataList}
                image={RegionsNotFound}
                message={`Lorem Ipsum`}
            />
            {showPopup && (
                <RegionPopupStep6
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getData()}
                    organizationId={organizationId}
                />
            )}
            {showEditPopup && (
                <RegionViewOrEditPopupStep6
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getData()}
                    editData={regionData}
                    isEdit={true}
                />
            )}
            {showViewPopup && (
                <RegionViewOrEditPopupStep6
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    handleEditShow={handleEditShow}
                    getDataListCall={() => getData()}
                    editData={regionData}
                    isEdit={false}
                    deleteById={deleteById}
                />
            )}
        </>
    );
};

export default RegionsList;
