import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    deleteUser,
    inviteUser,
    updateInfo,
    userDataGet,
    usersList,
} from '@app/UserListing/Users/functions';

const defaultState = {
    JWTToken: null,
    isUserDataGet: false,
    isUsersList: false,
    isUpdateInfo: false,
    isInviteUser: false,
    isReinviteUsers: false,
    isCancelInviteUser: false,
};

export const InviteUserSlice: any = createSlice({
    name: 'inviteUserSlice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [userDataGet.pending.type]: (state: any, { payload }) => {
            state.isUserDataGet = true;
            state.registerResponse = payload;
        },
        [userDataGet.fulfilled.type]: (state: any, { payload }) => {
            state.isUserDataGet = false;
            state.registerResponse = payload;
        },
        [userDataGet.rejected.type]: (state: any, { payload }) => {
            state.isUserDataGet = false;
            state.registerResponse = payload;
        },
        [usersList.pending.type]: (state: any, { payload }) => {
            state.isUsersList = true;
            state.registerResponse = payload;
        },
        [usersList.fulfilled.type]: (state: any, { payload }) => {
            state.isUsersList = false;
            state.registerResponse = payload;
        },
        [usersList.rejected.type]: (state: any, { payload }) => {
            state.isUsersList = false;
            state.registerResponse = payload;
        },
        [inviteUser.pending.type]: (state: any, { payload }) => {
            state.isInviteUser = true;
            state.registerResponse = payload;
        },
        [inviteUser.fulfilled.type]: (state: any, { payload }) => {
            state.isInviteUser = false;
            state.registerResponse = payload;
        },
        [inviteUser.rejected.type]: (state: any, { payload }) => {
            state.isInviteUser = false;
            state.registerResponse = payload;
        },

        [deleteUser.pending.type]: (state: any, { payload }) => {
            state.isDeleteUserUser = true;
            state.registerResponse = payload;
        },
        [deleteUser.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteUserUser = false;
            state.registerResponse = payload;
        },
        [deleteUser.rejected.type]: (state: any, { payload }) => {
            state.isDeleteUserUser = false;
            state.registerResponse = payload;
        },

        [updateInfo.pending.type]: (state: any, { payload }) => {
            state.isUpdateInfo = true;
            state.registerResponse = payload;
        },
        [updateInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateInfo = false;
            state.registerResponse = payload;
        },
        [updateInfo.rejected.type]: (state: any, { payload }) => {
            state.isUpdateInfo = false;
            state.registerResponse = payload;
        },
    },
});

export { inviteUser, updateInfo, deleteUser, usersList };

export default InviteUserSlice.reducer;
