import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addDriver,
    deleteDriver,
    driversList,
    getDriverInfo,
    updateDriver,
} from './functions';

const defaultState = {
    isAddDriver: false,
    isDriversList: false,
    isUpdateDriver: false,
    isGetDriverInfo: false,
    isDeleteDriver: false,
};

export const driverSlice: any = createSlice({
    name: 'driverSlice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addDriver.pending.type]: (state: any, { payload }) => {
            state.isAddDriver = true;
            state.registerResponse = payload;
        },
        [addDriver.fulfilled.type]: (state: any, { payload }) => {
            state.isAddDriver = false;
            state.registerResponse = payload;
        },
        [addDriver.rejected.type]: (state: any, { payload }) => {
            state.isAddDriver = false;
            state.registerResponse = payload;
        },
        [driversList.pending.type]: (state: any, { payload }) => {
            state.isDriversList = true;
            state.registerResponse = payload;
        },
        [driversList.fulfilled.type]: (state: any, { payload }) => {
            state.isDriversList = false;
            state.registerResponse = payload;
        },
        [driversList.rejected.type]: (state: any, { payload }) => {
            state.isDriversList = false;
            state.registerResponse = payload;
        },
        [updateDriver.pending.type]: (state: any, { payload }) => {
            state.isUpdateDriver = true;
            state.registerResponse = payload;
        },
        [updateDriver.fulfilled.type]: (state: any, { payload }) => {
            state.isUpdateDriver = false;
            state.registerResponse = payload;
        },
        [updateDriver.rejected.type]: (state: any, { payload }) => {
            state.isUpdateDriver = false;
            state.registerResponse = payload;
        },
        [getDriverInfo.pending.type]: (state: any, { payload }) => {
            state.isGetDriverInfo = true;
            state.registerResponse = payload;
        },
        [getDriverInfo.fulfilled.type]: (state: any, { payload }) => {
            state.isGetDriverInfo = false;
            state.registerResponse = payload;
        },
        [getDriverInfo.rejected.type]: (state: any, { payload }) => {
            state.isGetDriverInfo = false;
            state.registerResponse = payload;
        },
        [deleteDriver.pending.type]: (state: any, { payload }) => {
            state.isDeleteDriver = true;
            state.registerResponse = payload;
        },
        [deleteDriver.fulfilled.type]: (state: any, { payload }) => {
            state.isDeleteDriver = false;
            state.registerResponse = payload;
        },
        [deleteDriver.rejected.type]: (state: any, { payload }) => {
            state.isDeleteDriver = false;
            state.registerResponse = payload;
        },
    },
});

export { addDriver, driversList, updateDriver, getDriverInfo, deleteDriver };

export default driverSlice.reducer;
