import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import OtpInput from 'react18-input-otp';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Card, Button } from 'react-bootstrap';

import { Image } from '@components-default';
import InputText from '@components/InputText/InputText';
import ErrorMsg from '@components/errorMsg';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ToastBodyContainer from '@components/ToastContainer';

import { routesPath } from '@constants/routesNavigate';
import { passwordLength } from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/fontawesome-pro-regular';
import { faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import Fingerprint from '@images/fingerprint.svg';

import LeftSideBox from '@app/Auth/Login/leftSideBox';
import { changePassword, sendOtp } from '@app/Auth/EmailVerify/functions';
import { clearResponse } from '@redux/app/appSlice';
import { saveLoggedInUser } from '@login/loginSlice';

import { IForgotPasswordProps } from './forgotpassword.interface';
import './forgotPassword.scss';

const faEnvelopeProp = faEnvelope as IconProp;
const ForgotPassword = () => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const { userEmail } = useParams();

    const { common, emailVerify } = useSelector((state: any) => state);
    const { response } = common;
    const { isChangePassword, isSendOtp } = emailVerify;

    const [createPass, setCreatePass] = useState<boolean>(
        userEmail ? true : false,
    );

    const usernameSchema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is a required field'),
    }).required();

    const passSchema = Yup.object({
        password: Yup.string()
            .required('Please provide a password.')
            .min(
                passwordLength,
                `Password is too short - should be ${passwordLength} characters minimum.`,
            ),

        password_confirmation: Yup.string()
            .required('Please provide a password confirmation.')
            .min(
                passwordLength,
                `Password is too short - should be ${passwordLength} characters minimum.`,
            )
            .oneOf([Yup.ref('password'), null], 'Passwords must match.'),

        OTP: Yup.string().required('OTP is a required field.'),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        getValues,
    } = useForm<IForgotPasswordProps>({
        resolver: yupResolver(createPass ? passSchema : usernameSchema),
        defaultValues: {
            email: userEmail ?? '',
            password: '',
            password_confirmation: '',
            OTP: '',
        },
    });

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
    }, []);

    const HandleChange = async (data: IForgotPasswordProps) => {
        if (isValid) {
            if (createPass) {
                const { payload } = await dispatch(changePassword(data));
                if (payload?.data?.message !== '' && payload?.data?.status) {
                    dispatch(saveLoggedInUser(payload));
                    localStorage.setItem(
                        'accessToken',
                        payload?.data?.data?.original?.access_token,
                    );
                    navigate(routesPath?.dashboard);
                } else {
                    toast.error(payload?.message);
                }
            } else {
                const { payload } = await dispatch(
                    sendOtp({ ...data, emailType: 'forgot_pass' }),
                );
                if (payload?.data?.message !== '' && payload?.data?.status) {
                    setCreatePass(true);
                    toast.success(payload?.data?.message);
                } else {
                    toast.error(payload?.message ?? payload?.data?.message);
                }
            }
        }
    };

    const HandleResendOtp = async () => {
        if (errors.email?.message || getValues('email') === '') {
            toast.error('please enter email');
        } else {
            const { payload } = await dispatch(
                sendOtp({
                    email: getValues('email'),
                    emailType: 'forgot_pass',
                }),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                setCreatePass(true);
                toast.success(payload?.data?.message);
            } else {
                toast.error(payload?.message);
            }
        }
    };

    return (
        <div>
            <ToastBodyContainer />
            <div className="login_auth_wrapper forgot_pass_wrapper">
                <LeftSideBox />
                <Card className="rightBox">
                    <div className="mfnw__form-fixed">
                        <div className="mfnw__form-logo text-center mb-lg-3">
                            <Image src={Fingerprint} />
                            <h3 className="fw-bold pt-4 pt-lg-0">
                                {createPass
                                    ? 'Create New Password'
                                    : 'Forgot Password'}
                            </h3>
                            <p className="text-center">
                                Enter the email address associated with your
                                account.
                            </p>
                        </div>
                        <Form onSubmit={handleSubmit(HandleChange)}>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <div className="login-input-group">
                                        <InputText
                                            onChangeFunc={onChange}
                                            value={value}
                                            id="email"
                                            inputName="email"
                                            inputType="email"
                                            placeholder="your@email.com"
                                            disabled={createPass}
                                            errorText={errors.email?.message}
                                            iconShow={true}
                                            icon={faEnvelopeProp}
                                        />
                                    </div>
                                )}
                            />
                            {createPass && (
                                <>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <div className="login-input-group">
                                                <InputText
                                                    onChangeFunc={onChange}
                                                    value={value}
                                                    id={'password'}
                                                    inputName="password"
                                                    placeholder="******"
                                                    inputType="password"
                                                    errorText={
                                                        errors.password?.message
                                                    }
                                                />
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="password_confirmation"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }: any) => (
                                            <div className="login-input-group">
                                                <InputText
                                                    onChangeFunc={onChange}
                                                    value={value}
                                                    id="password_confirmation"
                                                    inputName="password_confirmation"
                                                    placeholder="******"
                                                    inputType="password"
                                                    errorText={
                                                        errors
                                                            ?.password_confirmation
                                                            ?.message
                                                    }
                                                />
                                            </div>
                                        )}
                                    />
                                    <div className="verifyDiv text-center">
                                        <div className="otpElements">
                                            <p className="fs-5">
                                                Enter your Code here
                                            </p>
                                            <div className="otp">
                                                <Controller
                                                    name="OTP"
                                                    control={control}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }: any) => (
                                                        <OtpInput
                                                            onChange={onChange}
                                                            value={value}
                                                            inputStyle="inputStyle"
                                                            numInputs={6}
                                                            separator={
                                                                <span></span>
                                                            }
                                                        />
                                                    )}
                                                />
                                                <ErrorMsg
                                                    errorText={
                                                        errors?.OTP?.message
                                                    }
                                                ></ErrorMsg>
                                            </div>

                                            <p className="fs-6 mt-3">
                                                Didn&apos;t receive the code?
                                            </p>
                                            <Button
                                                variant="secondary w-100"
                                                className="btnLogin text-uppercase new_btnrespon"
                                                onClick={HandleResendOtp}
                                            >
                                                {isSendOtp ? (
                                                    <LoaderBtn />
                                                ) : (
                                                    'Resend'
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="verifyDiv text-center">
                                <div className="otpElements">
                                    <Button
                                        type="submit"
                                        variant="secondary w-100"
                                        className="btnLogin text-uppercase new_btnrespon my-3 my-lg-1"
                                        disabled={isChangePassword}
                                    >
                                        {(createPass && isChangePassword) ||
                                        (!createPass && isSendOtp) ? (
                                            <LoaderBtn />
                                        ) : createPass ? (
                                            'Create Password'
                                        ) : (
                                            'Send'
                                        )}
                                    </Button>
                                </div>
                            </div>

                            <div className="text-center">
                                <NavLink
                                    className="mb-1 mb-lg-3"
                                    to={routesPath?.login}
                                >
                                    <Button type="submit" variant="w-100">
                                        <FontAwesomeIcon
                                            icon={faArrowLeftLong}
                                            className="text-secondary me-2"
                                        />
                                        Back to Login
                                    </Button>
                                </NavLink>
                            </div>
                        </Form>
                    </div>
                    <div className="mfnw-right-footer">
                        <span>MoveFlows © 2023</span>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ForgotPassword;
