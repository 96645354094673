/* eslint-disable max-lines-per-function */
import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Badge, Button, Col, Row } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';

import {
    contactPhoneNoTypes,
    contactPhoneNoTypes2,
    phoneFormat,
} from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope, faUser } from '@fortawesome/pro-light-svg-icons';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import {
    addContactInfo,
    addContactPhoneNo,
    deleteContact,
    deletePhone,
    setPrimary,
} from './lists.const';

const ContactInfo = ({
    watchContactInfo,
    getValues,
    setValue,
    control,
    errors,
    TspId,
    fieldName,
    contactObj,
    contactPhoneObj,
    isDisabled,
    firstPhoneType,
}: any) => {
    const typeArr =
        fieldName === 'shipper_contact_info'
            ? contactPhoneNoTypes2
            : contactPhoneNoTypes;
    const type =
        fieldName === 'shipper_contact_info' ? 'phoneNumberType' : 'type';
    const phoneNo =
        fieldName === 'shipper_contact_info' ? 'phone_number' : 'phone_no';

    return (
        <>
            {watchContactInfo &&
                watchContactInfo.length > 0 &&
                watchContactInfo?.map((item: any, index: number) => (
                    <Fragment key={index}>
                        <h6 className="text-sm mt-5 mb-0 d-flex justify-content-between">
                            <div>
                                {`CONTACT INFO ${index + 1}`}
                                <span className="text-light"> (Optional)</span>
                            </div>
                            {!isDisabled && index !== 0 && (
                                <Button
                                    variant="default"
                                    className="p-2 mx-3 rounded-3 h-100 py-1"
                                    onClick={() =>
                                        deleteContact(
                                            index,
                                            getValues,
                                            setValue,
                                            fieldName,
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </Button>
                            )}
                        </h6>
                        <Col md={12}>
                            <div className="mt-4">
                                <Controller
                                    name={`${fieldName}.${index}.name`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={9}
                                                id={`Name`}
                                                inputType={'text'}
                                                icon={faUser}
                                                placeholder={'Name'}
                                                disabled={isDisabled}
                                                groupInput={true}
                                                errorText={
                                                    errors?.[fieldName]?.[index]
                                                        ?.name?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="mt-4">
                                <Controller
                                    name={`${fieldName}.${index}.email`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={9}
                                                id={`Email`}
                                                inputType={'text'}
                                                icon={faEnvelope}
                                                placeholder={'Email'}
                                                disabled={isDisabled}
                                                groupInput={true}
                                                errorText={
                                                    errors?.[fieldName]?.[index]
                                                        ?.email?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                        <>
                            {(fieldName === 'shipper_contact_info' &&
                            TspId &&
                            TspId !== ''
                                ? getValues(
                                      `${fieldName}.${index}.phone_number`,
                                  ).length > 0
                                    ? getValues(
                                          `${fieldName}.${index}.phone_number.0.${type}`,
                                      ) !== ''
                                    : getValues(
                                          `${fieldName}.${index}.phone_number`,
                                      ) &&
                                      getValues(
                                          `${fieldName}.${index}.phone_number.0.${type}`,
                                      ) !== ''
                                : item?.phone_number &&
                                  (TspId
                                      ? firstPhoneType && firstPhoneType
                                          ? getValues(
                                                `${fieldName}.${0}.phone_number.0.${type}`,
                                            ) ===
                                            firstPhoneType
                                                .charAt(0)
                                                .toUpperCase() +
                                                firstPhoneType.slice(1)
                                          : getValues(
                                                `${fieldName}.${0}.phone_number.0.${type}`,
                                            ) &&
                                            getValues(
                                                `${fieldName}.${0}.phone_number.0.${phoneNo}`,
                                            ) !== ''
                                      : true)) &&
                                item?.phone_number.map(
                                    (data: any, dataIndex: number) => (
                                        <Row
                                            key={dataIndex}
                                            className="align-items-center gx-3 px-2"
                                        >
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={8}
                                                className="d-flex"
                                            >
                                                <div className="mt-3 min-w-120">
                                                    <Controller
                                                        name={`${fieldName}.${index}.phone_number.${dataIndex}.${type}`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <ReactSelectGroup
                                                                value={typeArr.find(
                                                                    (c: any) =>
                                                                        c?.value ===
                                                                        value,
                                                                )}
                                                                labelShow={
                                                                    false
                                                                }
                                                                setBorderRadiusClass="mfnw__borderless_select"
                                                                inputClassName="mfnw__input-group"
                                                                options={
                                                                    typeArr
                                                                }
                                                                onChangeFunc={(
                                                                    valueStateTownChange: any,
                                                                ) => {
                                                                    setValue(
                                                                        `${fieldName}.${index}.phone_number.${dataIndex}.${type}`,
                                                                        valueStateTownChange?.value,
                                                                    );
                                                                }}
                                                                tabIndex={9}
                                                                className="w-100"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                isDisabled={
                                                                    isDisabled
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="mt-3">
                                                    <Controller
                                                        name={`${fieldName}.${index}.phone_number.${dataIndex}.${phoneNo}`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <PhoneInputMaskGroup
                                                                maskFormat={
                                                                    phoneFormat
                                                                }
                                                                inputName="organization_phone"
                                                                inputType="text"
                                                                label="Phone No."
                                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                onChangeFunc={
                                                                    onChange
                                                                }
                                                                id="organization_phone"
                                                                aria-describedby="phone"
                                                                tabIndex={9}
                                                                faphoneIcon={
                                                                    faPhone
                                                                }
                                                                value={value}
                                                                labelShow={
                                                                    false
                                                                }
                                                                setPhoneRadiusClass="mnfw_border-left"
                                                                placeholder="(xxx) xxx-xxxx"
                                                                errorText={
                                                                    errors?.[
                                                                        fieldName
                                                                    ]?.[index]
                                                                        ?.phone_number?.[
                                                                        dataIndex
                                                                    ]?.[phoneNo]
                                                                        ?.message
                                                                }
                                                                disabled={
                                                                    isDisabled
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={4} sm={3} md={3}>
                                                {getValues(fieldName)?.[index]
                                                    ?.phone_number?.[dataIndex]
                                                    ?.is_default === '1' ||
                                                getValues(fieldName)?.[index]
                                                    ?.phone_number?.[dataIndex]
                                                    ?.is_default === 1 ? (
                                                    <div className="mt-md-3">
                                                        <Badge
                                                            className="badge bg-success text-base align-text-bottom px-3 py-2 cursor-pointer"
                                                            onClick={() =>
                                                                !isDisabled &&
                                                                setPrimary(
                                                                    index,
                                                                    dataIndex,
                                                                    getValues,
                                                                    setValue,
                                                                    fieldName,
                                                                )
                                                            }
                                                        >
                                                            PRIMARY
                                                        </Badge>
                                                    </div>
                                                ) : (
                                                    <div className="mt-md-3">
                                                        <Badge
                                                            className="badge bg-disable text-base align-text-bottom px-3 py-2 cursor-pointer"
                                                            onClick={() =>
                                                                !isDisabled &&
                                                                setPrimary(
                                                                    index,
                                                                    dataIndex,
                                                                    getValues,
                                                                    setValue,
                                                                    fieldName,
                                                                )
                                                            }
                                                        >
                                                            PRIMARY
                                                        </Badge>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col xs={8} sm={9} md={1}>
                                                <div className="mt-md-3">
                                                    {!isDisabled &&
                                                        dataIndex !== 0 && (
                                                            <Button
                                                                variant="default"
                                                                className="p-2 rounded-3 py-1"
                                                                onClick={() =>
                                                                    deletePhone(
                                                                        index,
                                                                        dataIndex,
                                                                        getValues,
                                                                        setValue,
                                                                        fieldName,
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faXmark
                                                                    }
                                                                />
                                                            </Button>
                                                        )}
                                                </div>
                                            </Col>
                                        </Row>
                                    ),
                                )}
                            {!isDisabled && (
                                <Col md={12}>
                                    <div
                                        className="text-primary my-3 cursor-pointer"
                                        onClick={() =>
                                            addContactPhoneNo(
                                                index,
                                                getValues,
                                                setValue,
                                                contactPhoneObj,
                                                fieldName,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span className="fw-semibold ms-3">
                                            Add more phone numbers
                                        </span>
                                    </div>
                                </Col>
                            )}
                        </>
                    </Fragment>
                ))}
            {!isDisabled && (
                <Col md={12}>
                    <div
                        className="text-primary my-3 mt-4 cursor-pointer fw-semibold w-100 text-center add_more_contact_btn"
                        onClick={() =>
                            addContactInfo(
                                fieldName,
                                getValues,
                                setValue,
                                contactObj,
                            )
                        }
                    >
                        Add more contacts
                    </div>
                </Col>
            )}
        </>
    );
};

export default ContactInfo;
