import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Collapse, Image, Offcanvas, Navbar, Nav } from 'react-bootstrap';

import { routesPath } from '@constants/routesNavigate';

import { ReactComponent as ArrowUp } from '@images/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@images/arrow-down.svg';
import Logo from '@images/logo.svg';
import SettingIcon from '@images/setting-icon.svg';
import Bar from '@images/bar.svg';

import {
    saveLoggedInUser,
    updateIsLogOutUser,
} from '@app/Auth/Login/loginSlice';
import {
    sideBarItemArray,
    sideBarSubItemItemArray,
} from '@app/AppLayout/sidebarList';
import { userProfileGet } from '@app/Auth/UserProfile/functions';
import { useDeviceDetect } from '@redux/useDeviceDetection';

const Sidebar = ({ setSideToggle, sideToggle }: any) => {
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const { login } = useSelector((state: any) => state);
    const { JWTToken } = login;

    const { isTab } = useDeviceDetect();
    //  ask fore type
    const [show, setShow] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [defaultOrganization, setDefaultOrganization] = useState<string>('');

    useEffect(() => {
        getUserProfile();
    }, [JWTToken]);

    useEffect(() => {
        if (!isTab && show) {
            setShow(false);
        }
    }, [isTab]);

    const handleClose = () => show && setShow(false);
    const handleShow = () => setShow(true);

    const getUserProfile = async () => {
        const { payload } = await dispatch(userProfileGet(''));
        if (payload?.data?.status) {
            dispatch(saveLoggedInUser(payload));
            setDefaultOrganization(
                payload?.data?.defaultOrganization?.organization_nano_id,
            );
        } else {
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    return (
        <>
            <Navbar
                expand={'xl'}
                className="align-items-start h-100 position-relative pt-0"
            >
                <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-xl`}
                    className="my-3 ms-3"
                    onClick={handleShow}
                />
                <Navbar.Offcanvas
                    show={show}
                    onHide={handleClose}
                    id={`offcanvasNavbar-expand-xl`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                    placement="start"
                    className="sidebar_offcanvase"
                >
                    <div className="sidebar_logo d-none d-xl-flex justify-content-center align-items-center">
                        <Image
                            src={Logo}
                            width="200px"
                            className="mx-auto px-3 sidebar_logo-big"
                        />
                    </div>
                    <Offcanvas.Header closeButton className="offcanvas_head">
                        <Image src={Logo} width="150px" />
                    </Offcanvas.Header>
                    <Offcanvas.Body className="offcanvas_body text-white">
                        <Nav
                            className="flex-column justify-content-center"
                            activeKey="/dashboard"
                        >
                            {sideBarItemArray?.map(
                                (item: any, index: number) => {
                                    const { path, icon, displayName } = item;

                                    return (
                                        <NavLink
                                            to={path}
                                            key={index}
                                            onClick={handleClose}
                                        >
                                            <Image
                                                src={icon}
                                                className="me-2 icon_small py-2 py-xl-0"
                                            />
                                            <span className="text-toggle">
                                                {displayName}
                                            </span>
                                        </NavLink>
                                    );
                                },
                            )}
                            <span
                                onClick={() => setOpen(!open)}
                                className="side_settings d-flex justify-content-between cursor-pointer"
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                            >
                                <div className="pt-2 pt-lg-0">
                                    <Image
                                        src={SettingIcon}
                                        className="me-2 icon_small"
                                    />
                                    <span className="text-toggle">
                                        Settings
                                    </span>
                                </div>
                                <div className="me-2 mt-1 text-toggle">
                                    {open ? <ArrowDown /> : <ArrowUp />}
                                </div>
                            </span>
                            <Collapse in={open}>
                                <div id="example-collapse-text">
                                    <div className="d-flex flex-column collapse_text py-3 py-lg-0">
                                        {sideBarSubItemItemArray?.map(
                                            (
                                                {
                                                    path,
                                                    icon,
                                                    displayName,
                                                    accessible,
                                                }: any,
                                                index: number,
                                            ) => (
                                                <NavLink
                                                    onClick={handleClose}
                                                    to={
                                                        path ===
                                                            routesPath.updateOrganizations ||
                                                        path ===
                                                            routesPath.updateUserOrganizations
                                                            ? `${path}${defaultOrganization}`
                                                            : path
                                                    }
                                                    key={index}
                                                >
                                                    <Image
                                                        src={icon}
                                                        className="me-2 icon_small p-3 p-lg-0"
                                                    />
                                                    <span className="text-toggle">
                                                        {displayName}
                                                    </span>
                                                </NavLink>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </Collapse>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                <div
                    className="mfnw_bottom-toggle position-absolute"
                    onClick={() => setSideToggle(!sideToggle)}
                >
                    <img src={Bar} alt="toggle" />
                </div>
            </Navbar>
        </>
    );
};

export default Sidebar;
