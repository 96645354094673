import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';
import ToastBodyContainer from '@components/ToastContainer';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';

import OfficePopupStep2 from '@app/CreateOrganization/step2/officePopupStep2';
import {
    getOfficeData,
    officeDeleteById,
} from '@app/CreateOrganization/step2/step2.const';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';
import StepperHeader from '@app/CreateOrganization/stapperComponent/stepperHeader';
import StepperActionBtn from '@app/CreateOrganization/stapperComponent/StepperActionBtn';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const Step2: React.FC = () => {
    const dispatch = useDispatch<any>();

    const { officeStep2Data } = useSelector((state: any) => state);
    const { isDeleteOffice, isGetOfficeDataList } = officeStep2Data;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any[]>([]);

    const handleShow = () => setShowPopup(true);
    const handleClose = () => setShowPopup(false);

    useEffect(() => {
        getData();
    }, []);

    const deleteById = async (officeId: string) => {
        officeDeleteById(officeId, dispatch, getData);
    };

    const getData = async () => {
        getOfficeData(dispatch, { nanoId: '' }, setDataList);
    };

    return (
        <section className="mfnw_create_organization mfnw_create_step2">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Organization
                        </Link>
                    </Col>
                    <Col md={12}>
                        <StepBar nextActive={dataList.length > 0} />
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <StepperHeader
                                        Tag={'h5'}
                                        handleShow={handleShow}
                                        btnText={'Add New Office'}
                                        titleText={'Organization Offices'}
                                    />
                                    <hr className="border-border-opacity-10" />
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="mt-3 g-0">
                                            {isDeleteOffice ||
                                            isGetOfficeDataList ? (
                                                <LoaderBtn />
                                            ) : (
                                                <StepBadgeComponent
                                                    dataList={dataList}
                                                    deleteById={deleteById}
                                                    name={'office_name'}
                                                    nanoId={'office_nano_id'}
                                                    handleViewShow={false}
                                                    className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                                />
                                            )}
                                        </Row>
                                        <StepperActionBtn
                                            dataList={dataList}
                                            nextStep={routesPath?.step3}
                                            previousStep={routesPath?.step1}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <OfficePopupStep2
                showPopup={showPopup}
                handleClose={handleClose}
                getDataListCall={getData}
            />
            <ToastBodyContainer />
        </section>
    );
};

export default Step2;
