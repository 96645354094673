import _ from 'lodash';
import { toast } from 'react-toastify';

import { validType } from '@constants/general.const';

export const ALLOWED_EXTENSIONS: string[] = ['pdf', 'jpg', 'png'];
export const MAX_FILE_SIZE: number = 102400;
export const MAX_FILE_SIZE_IN_MB: string = '100MB';
export const noteDefaultData = {
    document: '',
    document_types: 'Bill of Laden (Government)',
};

export const checkFileSizeValidation = (uploadedFile: Array<File>) => {
    const fileSizeValidation = _.map(uploadedFile, (file: File) => {
        if (file.size === 0) {
            toast.error('Files must be greater than 0 bytes');
            return false;
        }
        if (file.size / 1024 > MAX_FILE_SIZE) {
            toast.error(
                `File size is greater than maximum limit (${MAX_FILE_SIZE_IN_MB})`,
            );
            return false;
        }
        return true;
    });
    if (!_.every(fileSizeValidation, (value: boolean) => value === true)) {
        toast.error(`Max image size is ${MAX_FILE_SIZE_IN_MB}.`);
        return false;
    }
    return true;
};

export const checkFileTypeValidation = (uploadedFile: Array<File>) => {
    const fileTypeValidation = _.map(uploadedFile, (file: File) =>
        validType.includes(file.type),
    );
    if (!_.every(fileTypeValidation, (value: boolean) => value === true)) {
        toast.error('Upload failed. Document type not supported.');
        return false;
    }
    return true;
};
