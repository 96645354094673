import React from 'react';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { Button, Col, Image, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import InputText from '@components/InputText/InputText';
import ChangeImage from '@components/ChangeImage/ChangeImage';

import { routesPath } from '@constants/routesNavigate';

import 'react-toastify/dist/ReactToastify.css';

const UserFormField = ({
    control,
    errors,
    userImage,
    authUser,
    handleClickImageChange,
    handleDeleteImage,
    passwordCheck,
    refSubmitButton,
    isUpdateProfile,
}: any) => (
    <>
        <Row className="mt-5 mt-md-3">
            <Col xs={12} lg={6} className="mt-4 mt-md-0">
                <Row>
                    <Col md={4} xs={12} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 fw-bold text-base"
                        >
                            First Name*
                        </label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="firstName"
                                        inputName="firstName"
                                        inputType="text"
                                        placeholder="First Name"
                                        errorText={errors.firstName?.message}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6}>
                <Row>
                    <Col md={4} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 text-base fw-bold"
                        >
                            Last Name*
                        </label>
                    </Col>
                    <Col md={8}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="lastName"
                                        inputName="lastName"
                                        inputType="text"
                                        placeholder="Last Name"
                                        errorText={errors.lastName?.message}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={12} md={4} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 fw-bold text-base"
                        >
                            Email
                        </label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="email"
                                        inputName="email"
                                        inputType="email"
                                        placeholder="Email"
                                        disabled={true}
                                        errorText={errors.email?.message}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr />

        <Row>
            <Col xs={12} md={2}>
                <p className="py-md-4 fw-bold text-base text-start text-md-center text-center">
                    Your Photo
                </p>
            </Col>
            <Col xs={12} md={10}>
                <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                    {userImage == null || userImage === '' ? (
                        <>
                            <div className="profile_text_img_small">
                                {authUser?.firstName
                                    ?.toUpperCase()
                                    .substring(0, 1) +
                                    authUser?.lastName
                                        ?.toUpperCase()
                                        .substring(0, 1)}
                            </div>
                        </>
                    ) : (
                        <>
                            <Image
                                src={userImage}
                                className="rounded-pill"
                                width={75}
                                height={75}
                            />
                        </>
                    )}

                    <div className="mx-3 mx-sm-5">
                        <div className="d-flex justify-content-center">
                            <ChangeImage
                                handleClickProfilePic={handleClickImageChange}
                                handleDeleteImage={handleDeleteImage}
                                id="contained-button-file"
                                btnText={userImage ? 'CHANGE' : 'SELECT'}
                                userImage={userImage}
                                userProfileData={authUser}
                            />
                            {userImage && (
                                <label htmlFor="delete" className="label-w-20">
                                    <div
                                        className="mfnw__button_user text-uppercase text-base fw-bold mfnw_bg"
                                        onClick={handleDeleteImage}
                                    >
                                        Delete
                                    </div>
                                </label>
                            )}
                        </div>
                        <p className="p-0 ms-3 text-base fw-normal text-light d-flex">
                            This will displayed on your profile.
                        </p>
                    </div>
                </div>
            </Col>
        </Row>

        <hr />
        <Row className="pt-md-4">
            <Col xs={12} lg={6}>
                <Row className="pt-md-0 mt-3 mt-sm-1 ">
                    <Col xs={12} md={4} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 fw-bold text-base"
                        >
                            Old Password
                        </label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Controller
                            name="current_pass"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="current_pass"
                                        inputName="current_pass"
                                        inputType="password"
                                        placeholder="Old Password"
                                        errorText={errors.current_pass?.message}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={12} md={4} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 text-base fw-bold align-items-center"
                        >
                            New Password
                        </label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="password"
                                        inputName="password"
                                        inputType="password"
                                        placeholder="New Password"
                                        disabled={!passwordCheck}
                                        errorText={errors.password?.message}
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6}></Col>
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={12} md={4} className="text-start text-md-center">
                        <label
                            htmlFor="myInput"
                            aria-describedby="inputDescription"
                            className="py-md-3 text-base fw-bold align-items-center"
                        >
                            Confirmation Password
                        </label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Controller
                            name="password_confirmation"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="login-input-group mb-3">
                                    <InputText
                                        onChangeFunc={onChange}
                                        value={value}
                                        id="password_confirmation"
                                        inputName="password_confirmation"
                                        inputType="password"
                                        placeholder="Confirmation Password"
                                        disabled={!passwordCheck}
                                        errorText={
                                            errors.password_confirmation
                                                ?.message
                                        }
                                    />
                                </div>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="my-4 my-md-3 mt-md-5 ms-md-5 justify-content-center">
            <Col xs={12} sm={6} md={5} className="order-md-2">
                <Button
                    className="mfnp_last_button text-uppercase text-base fw-bold text-center w-100 mb-sm-3"
                    variant="primary"
                    type="submit"
                    ref={refSubmitButton}
                >
                    {isUpdateProfile ? <LoaderBtn /> : 'Save'}
                </Button>
            </Col>
            <Col xs={12} sm={6} md={5} className="my-3 my-sm-0 order-md-1">
                <Link to={routesPath.dashboard}>
                    <Button
                        className="mfnp_last_button last_btn_bg text-uppercase text-base fw-bold text-center w-100 mb-sm-3"
                        variant=""
                    >
                        CANCEL
                    </Button>
                </Link>
            </Col>
        </Row>
    </>
);

export default UserFormField;
