import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEnvelope } from '@fortawesome/fontawesome-pro-regular';

import { IFields } from './register.interface';

const faEnvelopeProp = faEnvelope as IconProp;
const faEyeProp = faEye as IconProp;

export const registerFieldArr: IFields[] = [
    {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        placeholder: 'First Name',
        classname: 'col-md-6',
        icon: '',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        placeholder: 'Last Name',
        classname: 'col-md-6',
        icon: '',
    },
    {
        name: 'email',
        label: 'Email',
        type: 'text',
        placeholder: 'Email',
        classname: 'col-md-12',
        icon: { faEnvelopeProp },
    },
    {
        name: 'password',
        label: 'Password',
        type: 'password',
        placeholder: 'Password',
        classname: 'col-md-12',
        icon: { faEyeProp },
    },
    {
        name: 'password_confirmation',
        label: 'Confirm Password',
        type: 'password',
        placeholder: 'Confirm Password',
        classname: 'col-md-12',
        icon: '',
    },
];
