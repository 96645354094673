import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Image, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import InputText from '@components/InputText/InputText';

import {
    showDeleteConfirmationDialog,
    searchTimeOut,
} from '@constants/general.const';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import GroupNotFound from '@images/group-nor-found.svg';

import {
    deleteGroup,
    getGroupDataList,
} from '@app/UserListing/Groups/functions';
import GroupPopup from '@app/UserListing/Groups/groupPopup';

import '@app/UserListing/userList.scss';

const GroupListing = () => {
    const dispatch = useDispatch<any>();

    const { groupData } = useSelector((state: any) => state);
    const { isDeleteGroup, isGetGroupDataList, isAddGroupData } = groupData;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string | null>();

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);

    useEffect(() => {
        const getDataSearch: any = setTimeout(() => {
            getData();
        }, searchTimeOut);
        return () => clearTimeout(getDataSearch);
    }, [search]);

    const getData = async () => {
        const { payload } = await dispatch(
            getGroupDataList({
                searchText: search,
            }),
        );
        const { data: payloadData, message: errorMessage } = payload || {};
        const { message, status, data } = payloadData || {};
        if (message !== '' && status) {
            setDataList(data);
        } else {
            toast.error(errorMessage);
        }
    };

    const deleteById = async (id: string) => {
        const confirmationOptions = {
            title: 'Delete Group?',
            icon: 'warning',
            confirmButtonText: 'Yes, delete it!',
            text: "You won't be able to revert this operation!",
        };
        const isConfirmed = await showDeleteConfirmationDialog(
            confirmationOptions,
        );
        if (isConfirmed) {
            const { payload } = await dispatch(deleteGroup(id));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getData();
            } else {
                toast.error(errorMessage);
            }
        }
    };

    return (
        <div className="mfnw__grouplisting_content">
            <Row className="align-items-center py-3 justify-content-between">
                <Col
                    xs={12}
                    sm={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    className="text-center text-sm-start"
                >
                    <h5 className="fw-semibold m-0 mb-2 mb-lg-0">Group List</h5>
                </Col>
                <Col
                    xs={12}
                    sm={9}
                    lg={6}
                    xl={6}
                    xxl={5}
                    className="d-flex justify-content-center justify-content-sm-end "
                >
                    <Row className="justify-content-end">
                        <Col className="col-sm-6 mt-3 mt-md-0 ">
                            <div className=" main-par d-flex gap-3 justify-content-center text-center flex-wrap">
                                <div className="main-serchinput position-relative d-flex">
                                    <InputText
                                        className="mfnw__login-form-control mfnw__search-form-control-bg pos"
                                        id=""
                                        inputName=""
                                        inputType="text"
                                        classNa5meFormGroup="mb-0"
                                        placeholder="Search..."
                                        errorText={''}
                                        value={search}
                                        onChange={(e: any) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                            color="#b7b7b7"
                                            size="lg"
                                            className="position-absolute form-control-serch"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className="col-sm-6 text-center mt-3 mt-md-0">
                            <Button
                                variant="primary"
                                className="btn_type_large fw-semibold button-responsive"
                                onClick={handleShow}
                            >
                                Add New Group
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="d-flex flex-wrap gap-2 mt-4 mt-md-0">
                {isDeleteGroup || isGetGroupDataList ? (
                    <span className="ms-5">
                        <LoaderBtn />
                    </span>
                ) : dataList && dataList.length !== 0 ? (
                    dataList.map((item: any, index: number) => (
                        <Badge
                            className="mnfw__badge_light lh-base text-dark py-2 fs-6 fw-semibold d-flex align-align-items-center bg-white border-opacity-25"
                            key={index}
                        >
                            <span className="me-3">{item?.group_name}</span>

                            <div
                                className="cursor-pointer"
                                onClick={() => deleteById(item?.group_nano_id)}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    color="#b7b7b7"
                                    size="lg"
                                />
                            </div>
                        </Badge>
                    ))
                ) : (
                    <>
                        {dataList && dataList.length === 0 && (
                            <Row className="justify-content-center m-auto">
                                <Col
                                    className="text-center mt-5"
                                    xs={12}
                                    sm={8}
                                    md={6}
                                    xxl={12}
                                    xl={7}
                                >
                                    <Image src={GroupNotFound} fluid />
                                    <h5 className="text-light mt-4 fw-bold">
                                        Groups help you organize your users. Add
                                        the groups you think you need.
                                    </h5>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </div>
            <GroupPopup
                showPopup={showPopup}
                handleClose={handleClose}
                getDataListCall={() => getData()}
                isAddGroupData={isAddGroupData}
            />
        </div>
    );
};
export default GroupListing;
