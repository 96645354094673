import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Form } from 'react-bootstrap';

import { searchTimeOut } from '@constants/general.const';

import { addRegion } from '@app/CreateOrganization/step6/functions';
import { updateCurrentStep } from '@app/Auth/Login/loginSlice';
import {
    schema,
    onPopUpClose,
    getRegionCityStateData,
} from '@app/CreateOrganization/step6/step6.const';
import RegionPopUpFormField from '@app/CreateOrganization/step6/regionPopUpFormField';

import { IStep6Props } from './step6.interface';
import { IListOption } from '../../../Interface/common';

const RegionPopupStep6 = ({
    showPopup,
    handleClose,
    getDataListCall,
    organizationId,
}: any) => {
    const dispatch = useDispatch<any>();

    const { regionsStep6Data } = useSelector((state: any) => state);
    const { regionData, isGetCityStateInfo } = regionsStep6Data;
    //  ask fore type

    const [searchStateText, setSearchStateText] = useState<any>(null);
    const [optionArr, setOptionArr] = useState<IListOption[]>([]);
    const [selectList, setSelectList] = useState<IListOption[]>([]);
    const [regionError, setRegionError] = useState<any | null>(null);

    const regionDataValues: IStep6Props = {
        region_name: '',
        region_type: 'State',
        region_state: [],
        region_city: '',
        is_active: 1,
    };

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors, isDirty },
    } = useForm<IStep6Props>({
        resolver: yupResolver(schema),
        defaultValues: regionData,
        mode: 'onBlur',
    });

    useEffect(() => {
        if (searchStateText || searchStateText === '') {
            const getData: NodeJS.Timeout = setTimeout(() => {
                getCityStateData();
            }, searchTimeOut);
            return () => clearTimeout(getData);
        }
    }, [searchStateText]);

    const watchRegionType = watch('region_type');

    useEffect(() => {
        if (watchRegionType) {
            setValue('region_state', []);
            setSelectList([]);
            getCityStateData();
        }
    }, [watchRegionType]);

    const getCityStateData = async () => {
        getRegionCityStateData(
            dispatch,
            getValues,
            searchStateText,
            setOptionArr,
        );
    };

    const HandleChange = async (data: IStep6Props) => {
        const sendData = { ...data, nanoId: organizationId };
        const { payload } = await dispatch(addRegion(sendData));
        if (payload?.data?.message !== '' && payload?.data?.status) {
            dispatch(updateCurrentStep(payload));
            getDataListCall();
            handleClose();
            reset(regionDataValues);
        } else {
            if (payload?.message !== '') {
                setRegionError(payload?.message);
                toast.error(payload?.message);
            } else {
                setRegionError(null);
            }
        }
    };

    const updateSelectedList = async (itemId: string) => {
        const newArray = selectList.filter(
            (item: any) => item?.value !== itemId,
        );
        setSelectList(newArray);
        setValue('region_state', newArray);
    };

    const onClose = async () => {
        onPopUpClose(isDirty, handleClose, reset);
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Add New Region
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <RegionPopUpFormField
                        regionError={regionError}
                        control={control}
                        errors={errors}
                        selectList={selectList}
                        optionArr={optionArr}
                        setSearchStateText={setSearchStateText}
                        setValue={setValue}
                        setSelectList={setSelectList}
                        isGetCityStateInfo={isGetCityStateInfo}
                        updateSelectedList={updateSelectedList}
                        onClose={onClose}
                        isAdd={true}
                        getValues={getValues}
                        memoizedRegionInfo={false}
                        isImageShow={true}
                    />
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RegionPopupStep6;
