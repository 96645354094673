/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { Image, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/fontawesome-pro-regular';
import UserPluse from '@images/userplus.svg';
import Userlist from '@images/listuser.svg';
import unload from '@images/unload.svg';
import load from '@images/load.svg';
import pack from '@images/pack.svg';
import Assign from '@images/assign.svg';
import Schedule from '@images/calendar-schedule.svg';
import Unschedule from '@images/unschedule.svg';

const faClockProp = faClock as IconProp;

const PackerDayTableView = ({ packerArrData, dateRange, type }: any) => {
    const hours: any = [];
    for (let i = 0; i <= 23; i++) {
        const amPm = i < 12 ? 'AM' : 'PM';
        const hour = i === 0 ? 12 : i > 12 ? i - 12 : i;
        hours.push(`${hour}${amPm}`);
    }

    let totalTd = 0;

    return (
        <div className="driver_element custom-calender">
            <Table bordered>
                <thead>
                    <tr className="driver_element_header">
                        <th className="driver_element_header_width fw-semibold driver_element_font_width">
                            Employees
                        </th>
                        {hours.map((hour: any) => (
                            <th
                                className="driver_element_font_width  text-align-center"
                                key={hour}
                            >
                                {hour}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="driver_element driver_element_bg">
                        <td className="driver_element_dataside">
                            <div className="d-flex align-items-center driver_element_spacing">
                                <div className="rounded-pill element_box_icon py-2 px-2 text-center me-2">
                                    <img src={UserPluse} alt="User" />
                                </div>
                                <div className="w-70 element_detailoverflow">
                                    <span className="fw-bolder text-base text-capitalize">
                                        Antonio
                                    </span>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon
                                            icon={faClockProp}
                                            size="sm"
                                            className="pe-1"
                                        />
                                        <p className="p-0 m-0 text-sm fw-normal">
                                            9
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {packerArrData &&
                        dateRange &&
                        Object.keys(packerArrData).map((dataKey, index) => {
                            const packerData = packerArrData[dataKey][
                                moment(dateRange[0]).format('YYYY-MM-DD')
                            ]
                                ? packerArrData[dataKey][
                                      moment(dateRange[0]).format('YYYY-MM-DD')
                                  ][0]
                                : null;
                            const icon =
                                packerData?.icon === 'Schedule'
                                    ? Schedule
                                    : packerData?.icon === 'Unschedule'
                                    ? Unschedule
                                    : Assign;
                            totalTd = 0;
                            return (
                                <tr key={index} className="driver_element">
                                    <td
                                        className="driver_element_dataside"
                                        title={dataKey}
                                    >
                                        <div className="d-flex align-items-center driver_element_spacing">
                                            <div className="bg-primary rounded-pill element_box_icon py-2 px-2 text-center me-2">
                                                <img
                                                    src={Userlist}
                                                    alt="User"
                                                    className="driver_image_size"
                                                />
                                            </div>
                                            <div className="w-70 element_detailoverflow text-left">
                                                <span className="fw-bolder text-base text-capitalize">
                                                    {dataKey}
                                                </span>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faClockProp}
                                                        size="sm"
                                                        className="pe-1"
                                                    />
                                                    <p className="p-0 m-0 text-sm fw-normal">
                                                        9
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {hours.map(
                                        (hour: any, indexKey: number) => {
                                            if (totalTd < 24) {
                                                totalTd =
                                                    totalTd +
                                                    (packerData &&
                                                    hour === '9AM'
                                                        ? 9
                                                        : 1);
                                                return (
                                                    <td
                                                        key={indexKey}
                                                        colSpan={
                                                            packerData &&
                                                            hour === '9AM'
                                                                ? 9
                                                                : 0
                                                        }
                                                        className="driver-box-collapse"
                                                    >
                                                        {packerData &&
                                                            hour === '9AM' && (
                                                                <div
                                                                    className={`${packerData?.class} driver_box_content_bg d-flex align-items-center`}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faClockProp
                                                                        }
                                                                        size="sm"
                                                                        className="pe-1"
                                                                    />
                                                                    <span className="text-base fw-normal">
                                                                        9a - 5p
                                                                    </span>
                                                                    <div className="d-flex justify-content-between ps-2">
                                                                        <div
                                                                            className={`${packerData?.badge} ps-2 pe-2`}
                                                                        >
                                                                            <Image
                                                                                src={
                                                                                    icon
                                                                                }
                                                                                className="pe-2"
                                                                            />
                                                                            <span className="text-sm fw-normal text-white text-capitalize">
                                                                                {
                                                                                    packerData?.type
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            {type ===
                                                                                'packer' &&
                                                                                packerData
                                                                                    ?.typeIcon
                                                                                    .length >
                                                                                    0 &&
                                                                                packerData?.typeIcon.map(
                                                                                    (
                                                                                        icons: any,
                                                                                    ) => {
                                                                                        var imgIcon =
                                                                                            icons ===
                                                                                            'pack'
                                                                                                ? pack
                                                                                                : icons ===
                                                                                                  'load'
                                                                                                ? load
                                                                                                : unload;
                                                                                        return (
                                                                                            <Image
                                                                                                src={
                                                                                                    imgIcon
                                                                                                }
                                                                                                className="mx-2"
                                                                                                key={
                                                                                                    icons
                                                                                                }
                                                                                                width={
                                                                                                    24
                                                                                                }
                                                                                                height={
                                                                                                    24
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    },
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </td>
                                                );
                                            } else {
                                                return null;
                                            }
                                        },
                                    )}
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default PackerDayTableView;
