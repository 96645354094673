import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiAddPacker,
    apiDeletePacker,
    apiPackerData,
    apiPackersList,
    apiUpdatePacker,
} from '@services/API/apiFunctions';

const addPacker = createAsyncThunk(
    'addPacker',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiAddPacker(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const packersList = createAsyncThunk(
    'packersList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiPackersList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updatePacker = createAsyncThunk(
    'updatePacker',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdatePacker(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const getPackerInfo = createAsyncThunk(
    'getPackerInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiPackerData(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deletePacker = createAsyncThunk(
    'deletePacker',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeletePacker(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { addPacker, packersList, updatePacker, getPackerInfo, deletePacker };
