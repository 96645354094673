import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import ToastBodyContainer from '@components/ToastContainer';

import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';

import RegionPopupStep6 from '@app/CreateOrganization/step6/RegionPopupStep6';
import RegionViewOrEditPopupStep6 from '@app/CreateOrganization/step6/regionViewOrEditPopupStep6';
import StepperActionBtn from '@app/CreateOrganization/stapperComponent/StepperActionBtn';
import StepperHeader from '@app/CreateOrganization/stapperComponent/stepperHeader';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';
import {
    getCurrentRegionData,
    getRegionsData,
    regionDeleteById,
} from '@app/CreateOrganization/step6/step6.const';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const Step2: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { regionsStep6Data } = useSelector((state: any) => state);
    const { isDeleteRegion, getRegionDataList } = regionsStep6Data;
    //  ask fore type

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [regionData, setRegionData] = useState<any>();
    const [dataList, setDataList] = useState<any>([]);

    const handleClose = () => setShowPopup(false);
    const handleShow = () => setShowPopup(true);

    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);

    const handleEditClose = () => {
        setShowEditPopup(false);
        setRegionData(null);
    };
    const handleEditShow = () => {
        setShowEditPopup(true);
        setShowViewPopup(false);
    };

    const [showViewPopup, setShowViewPopup] = useState<boolean>(false);

    const handleViewClose = () => setShowViewPopup(false);

    const handleViewShow = (nanoId: any) => {
        setShowViewPopup(true);
        getRegionData(nanoId);
    };

    useEffect(() => {
        getData();
    }, []);

    const getRegionData = async (regionId: any) => {
        getCurrentRegionData(regionId, dispatch, setRegionData, navigate);
    };

    const deleteById = async (officeId: string) => {
        regionDeleteById(
            officeId,
            dispatch,
            getData,
            showViewPopup,
            handleViewClose,
        );
    };

    const getData = async () => {
        getRegionsData(dispatch, { nanoId: '' }, setDataList);
    };

    return (
        <section className="mfnw_create_organization mfnw_create_step5">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Organization
                        </Link>
                    </Col>
                    <Col md={12}>
                        <StepBar />
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <StepperHeader
                                        Tag={'h5'}
                                        handleShow={handleShow}
                                        btnText={'Add New Region'}
                                        titleText={'Organization Regions'}
                                    />
                                    <hr className="border-border-opacity-10" />
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="mt-3 g-0">
                                            {isDeleteRegion ||
                                            getRegionDataList ? (
                                                <LoaderBtn />
                                            ) : (
                                                <StepBadgeComponent
                                                    dataList={dataList}
                                                    deleteById={deleteById}
                                                    name={'region_name'}
                                                    nanoId={'region_nano_id'}
                                                    handleViewShow={
                                                        handleViewShow
                                                    }
                                                    className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                                />
                                            )}
                                        </Row>
                                        <StepperActionBtn
                                            dataList={dataList}
                                            nextStep={routesPath?.dashboard}
                                            previousStep={routesPath?.step5}
                                            sendMsg={true}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastBodyContainer />
            {showPopup && (
                <RegionPopupStep6
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getData()}
                />
            )}
            {showEditPopup && (
                <RegionViewOrEditPopupStep6
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getData()}
                    editData={regionData}
                    isEdit={true}
                />
            )}
            {showViewPopup && (
                <RegionViewOrEditPopupStep6
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    handleEditShow={handleEditShow}
                    getDataListCall={() => getData()}
                    editData={regionData}
                    isEdit={false}
                    deleteById={deleteById}
                />
            )}
        </section>
    );
};

export default Step2;
