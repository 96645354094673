import React, { Fragment } from 'react';

import { Form } from '@components-default';
import ErrorMsg from '@components/errorMsg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IInputTextProps } from './imageUpload.interface';

const InputText = ({
    inputName,
    placeholder,
    errorText,
    asTextarea,
    labelcontainer,
    className,
    regularExp,
    classNameFormGroup,
    label,
    tabIndex,
    maxLength,
    icon,
    min,
    max,
    disabled,
    inputType,
    minLength,
    iconShow,
    onIconClick,
    onChangeFunc,
    ...props
}: IInputTextProps) => {
    const regExpArray: any = {
        number: /(^[0-9]+$|^$)/,
        letters: /^[A-za-z]+$/,
        alphanumeric: /^[ A-Za-z0-9_@.]*$/,
        regularExp: regularExp,
    };
    const typeArr = ['text', 'email', 'password', 'file'];
    const HandleChange = (e: any) => {
        var testValueType: any = regularExp === '' ? inputType : 'regularExp';
        var valueTypeMatch =
            testValueType !== '' && !typeArr.includes(testValueType)
                ? regExpArray[testValueType].test(e.target.value)
                : true;
        if (e.target.value === '' || valueTypeMatch) {
            onChangeFunc(e);
        }
    };

    return (
        <>
            <Form.Group
                className={`position-relative
                        ${classNameFormGroup}`}
            >
                {label && <Form.Label>{label}</Form.Label>}
                {asTextarea === 'textarea' ? (
                    <Form.Control
                        as="textarea"
                        type={inputType}
                        name={inputName}
                        className={className}
                        placeholder={placeholder}
                        onChange={HandleChange}
                        tabIndex={tabIndex}
                        min={min}
                        rows="3"
                        maxLength={maxLength}
                        minLength={minLength}
                        max={max}
                        disabled={disabled}
                        autoComplete="off"
                        {...props}
                    />
                ) : (
                    <Form.Control
                        type={inputType}
                        name={inputName}
                        className={`mfnw__login-form-control ${className}`}
                        placeholder={placeholder}
                        onChange={HandleChange}
                        tabIndex={tabIndex}
                        min={min}
                        maxLength={maxLength}
                        minLength={minLength}
                        max={max}
                        disabled={disabled}
                        autoComplete="off"
                        {...props}
                    />
                )}
                {iconShow && (
                    <i className="icon" onClick={() => onIconClick()}>
                        <FontAwesomeIcon icon={icon} />
                    </i>
                )}
            </Form.Group>
            {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
        </>
    );
};

InputText.defaultProps = {
    inputName: '',
    placeholder: '',
    errorText: '',
    asTextarea: '',
    inputType: '',
    label: '',
    regularExp: '',
    id: '',
    icon: '',
    labelcontainer: '',
    maxLength: null,
    className: '',
    onChangeFunc: () => void 0,
};

export default InputText;
