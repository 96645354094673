import React from 'react';
import ReactPaginate from 'react-paginate';
import { Badge, Card, Col, Row } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import NotFoundPages from '@components/NotFoundPages/notFoundPages';

import UsersNotFound from '@images/user-not-found.svg';

import CommonActions from '@app/SettingsLists/CommonActions';

const UserTable = ({
    currentPageData,
    isUsersList,
    handleViewEventUser,
    authUser,
    actionUserById,
    handleViewEditUser,
    pageCount,
    handlePageClick,
    filters,
}: any) => (
    <>
        <div className="d-flex flex-column">
            {isUsersList ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : currentPageData && currentPageData.length !== 0 ? (
                currentPageData?.map((item: any, index: number) => (
                    <Card className="border-0 p-4 rounded-4 mt-4" key={index}>
                        <Row>
                            <Col
                                xs={6}
                                md={3}
                                lg={3}
                                className="cursor-pointer order-md-1 m-auto"
                                onClick={() =>
                                    handleViewEventUser(item?.users_nano_id)
                                }
                            >
                                <h5>{`${item?.lastName}, ${item?.firstName}  ${
                                    authUser?.email === item?.email
                                        ? "(It's You)"
                                        : ''
                                }`}</h5>
                                <p className="m-0 text-desc">{item?.email}</p>
                            </Col>
                            <Col
                                xs={6}
                                md={3}
                                lg={2}
                                className="d-flex align-items-center justify-content-end order-md-3"
                            >
                                <div className="d-flex align-items-center justify-content-end ">
                                    <CommonActions
                                        item={item}
                                        showAlert={actionUserById}
                                        handleEditShow={handleViewEditUser}
                                        nanoId={item?.users_nano_id}
                                        isUserList={true}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={7}
                                className="mt-3 order-md-2"
                                onClick={() =>
                                    handleViewEventUser(item?.users_nano_id)
                                }
                            >
                                <div className="d-flex gap-3 flex-wrap">
                                    <Badge
                                        bg="light"
                                        className="bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25 rounded-3"
                                    >
                                        <span className="px-2">
                                            {item?.departments?.department_name}
                                        </span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25 rounded-3"
                                    >
                                        <span className="px-2">
                                            {item?.offices?.office_name}
                                        </span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25 rounded-3"
                                    >
                                        <span className="px-2">
                                            {item?.roles?.role_name}
                                        </span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25 rounded-3"
                                    >
                                        <span className="px-2">
                                            {item?.groups?.group_name}
                                        </span>
                                    </Badge>
                                    <Badge
                                        bg="light"
                                        className="bg-transparent lh-base text-dark py-2 text-sm text-dark d-flex align-align-items-center border border-1 border-opacity-25 rounded-3"
                                    >
                                        <span className="px-2">
                                            {item?.job_titles?.job_title}
                                        </span>
                                    </Badge>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                ))
            ) : (
                <NotFoundPages
                    currentPageData={currentPageData}
                    image={UsersNotFound}
                    message={` We will not need this one. There will always
                        be at least one user`}
                />
            )}
        </div>
        <div className="mt-4">
            {currentPageData && currentPageData.length > 0 && (
                <ReactPaginate
                    previousLabel={'← Previous'}
                    nextLabel={'Next →'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active active'}
                    pageRangeDisplayed={2}
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageLinkClassName="page-link"
                    previousClassName="page-link rounded-start"
                    nextClassName="page-link rounded-end"
                    forcePage={filters?.currentPage - 1}
                />
            )}
        </div>
    </>
);

export default UserTable;
