/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import { Table, Image, Row, Col } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';
import {
    pageLimitList,
    searchTimeOut,
    PageLimitChangeCalculation,
    PageClickCalculation,
    FiltersData,
} from '@constants/general.const';

import driverNotFound from '@images/driver-not-found.svg';

import { clearResponse } from '@redux/app/appSlice';

import { getImportExceptions } from '@app/SettingsLists/ImportExceptions/functions';
import { updateIsLogOutUser } from '@app/Auth/Login/loginSlice';

import ListHeader from '../listHeader';

const ImportExceptions = () => {
    const getFilters = async () => {
        FiltersData(
            searchText,
            getExceptions,
            setFilters,
            'exceptions_list',
            dispatch,
            navigate,
        );
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const { common, exceptionsData } = useSelector((state: any) => state);
    const { response } = common;
    const { isGetImportExceptions } = exceptionsData;

    const [currentPageData, setCurrentPageData] = useState<number[] | null>(
        null,
    );
    const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
    const [startDate, endDate] = dateRange;

    const [pageCount, setPageCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<any>(null);
    const [totalDataCount, setTotalDataCount] = useState<number>(0);
    const [filters, setFilters] = useState<any>();

    useEffect(() => {
        const { message, status } = response;
        const toastType = status && message !== '' ? 'success' : 'error';
        toast[toastType](message);
        dispatch(clearResponse());
        getFilters();
    }, []);

    useEffect(() => {
        const selectedDate: any = [
            moment.utc(startDate).local().format('MM-DD-Y') !== 'Invalid date'
                ? moment.utc(startDate).local().format('MM-DD-Y')
                : moment(),
            moment.utc(endDate).local().format('MM-DD-Y') !== 'Invalid date'
                ? moment.utc(endDate).local().format('MM-DD-Y')
                : moment(),
        ];
        startDate && endDate && getExceptions(1, filters, selectedDate);
    }, [startDate, endDate]);

    useEffect(() => {
        const getData: any =
            filters &&
            setTimeout(() => {
                getExceptions(1, filters);
            }, searchTimeOut);
        return () => clearTimeout(getData);
    }, [searchText]);

    const getExceptions = async (
        currentPage: number = 1,
        res: any = {},
        selectedDate: any = null,
    ) => {
        const { payload } = await dispatch(
            getImportExceptions({
                filters: {
                    perPageLimit: res?.perPageLimit ? res?.perPageLimit : 5,
                    currentPage:
                        currentPage === 0 || !currentPage ? 1 : currentPage,
                },
                searchText: searchText,
                startDate: selectedDate?.[0],
                endDate: selectedDate?.[1],
            }),
        );

        if (payload?.data?.status) {
            setFilters(payload?.data?.filters);
            setCurrentPageData(payload?.data?.data);
            setPageCount(payload?.data?.meta?.last_page);
            setTotalDataCount(payload?.data?.meta?.total);
        } else {
            toast.error(payload?.message);
            if (payload?.logout) {
                localStorage.removeItem('accessToken');
                dispatch(updateIsLogOutUser());
                navigate(routesPath?.login);
            }
        }
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        var updateFilter = PageClickCalculation(filters, selectedPage);
        setFilters(updateFilter);
        getExceptions(updateFilter?.currentPage, updateFilter);
    };

    const handleLimitChange = async (limit: number) => {
        var updateFilter = PageLimitChangeCalculation(
            filters,
            totalDataCount,
            limit,
        );
        setFilters(updateFilter);
        getExceptions(updateFilter?.currentPage, updateFilter);
    };

    return (
        <div>
            <ListHeader
                title={'Import Offer Email Exception List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleLimitChange={handleLimitChange}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
            />

            {isGetImportExceptions ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : currentPageData && currentPageData.length !== 0 ? (
                <>
                    <section className="overflow-table-auto">
                        <Table
                            borderless
                            className="mfnw__borderless_card-table mb-0 w-100"
                        >
                            <thead>
                                <tr>
                                    <th>Email Subject</th>
                                    <th>Email From</th>
                                    <th>Email To</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData &&
                                    currentPageData?.map(
                                        (item: any, index: number) => (
                                            <tr key={index}>
                                                <td className="text-truncate">
                                                    {item?.subject_email ||
                                                        '(N/A)'}
                                                </td>
                                                <td className="text-truncate">
                                                    {item?.email_from ||
                                                        '(N/A)'}
                                                </td>
                                                <td className="text-truncate">
                                                    {item?.email_to || '(N/A)'}
                                                </td>
                                                <td className="text-truncate">
                                                    {item?.email_date ||
                                                        '(N/A)'}
                                                </td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </Table>
                        <div>
                            {currentPageData && currentPageData.length > 0 && (
                                <ReactPaginate
                                    previousLabel={'← Previous'}
                                    nextLabel={'Next →'}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    previousLinkClassName={'pagination__link'}
                                    nextLinkClassName={'pagination__link'}
                                    disabledClassName={
                                        'pagination__link--disabled'
                                    }
                                    activeClassName={
                                        'pagination__link--active active'
                                    }
                                    pageRangeDisplayed={2}
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-link rounded-start"
                                    nextClassName="page-link rounded-end"
                                    forcePage={filters?.currentPage - 1}
                                />
                            )}
                        </div>
                    </section>
                </>
            ) : (
                <>
                    {currentPageData && currentPageData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={driverNotFound} fluid />
                                <h5 className="text-light mt-4 fw-bold">
                                    Not Found any Import Offer Email Exception
                                    List
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default ImportExceptions;
