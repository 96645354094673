import axios from 'axios';
import { toast } from 'react-toastify';

const BaseApiUrl = process.env.REACT_APP_API_URL;

export const axiosInterceptor = axios.create({
    baseURL: BaseApiUrl,
});

axiosInterceptor.interceptors.request.use(
    (config: any) => {
        if (localStorage.getItem('accessToken')) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(
                'accessToken',
            )}`;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

axiosInterceptor.interceptors.response.use(
    (response) => response,
    (error) => {
        const errorCatch =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (!errorCatch) {
            toast.error(
                'We apologies for the inconvenience. Please try again later.',
            );
        }
        return Promise.reject(error);
    },
);
