import React, { useState } from 'react';

import JobTabCalender from '@app/Schedule/JobCalender/jobTabCalender';

const WorkCompleted = () => {
    //  ask fore type

    const [viewName, setViewName] = useState<string>('Weekly Complete View');

    return (
        <div className="px-4 mt-5">
            <JobTabCalender
                type={'completed'}
                setViewName={setViewName}
                viewName={viewName}
            />
        </div>
    );
};

export default WorkCompleted;
