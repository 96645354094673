import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Button, Col, Row, Modal, Image, Form } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { showDeleteConfirmationDialog } from '@constants/general.const';

import userGroup from '@images/user-group.svg';

import { addGroupData } from '@app/UserListing/Groups/functions';

import { IGroupProps } from './group.interface';

const GroupPopup = ({
    showPopup,
    getDataListCall,
    handleClose,
    isAddGroupData,
}: any) => {
    const dispatch = useDispatch<any>();

    const schema = Yup.object({
        group_name: Yup.string().required('Group Name is a required field'),
    }).required();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
    } = useForm<IGroupProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            group_name: '',
        },
        mode: 'onBlur',
    });

    const HandleChange = async (data: IGroupProps) => {
        const sendData = { ...data };
        const { payload } = await dispatch(addGroupData(sendData));
        const { data: payloadData } = payload || {};
        if (payloadData?.message !== '' && payloadData?.status) {
            getDataListCall();
            handleClose();
            setValue('group_name', '');
        } else {
            toast.error(payload?.message);
        }
    };

    const onClose = async () => {
        if (isDirty) {
            const confirmationOptions = {
                title: 'Warning',
                html: 'Are you sure you want to close this form?<br>All changes made will be lost.',
                icon: 'warning',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                text: '',
            };
            const isConfirmed = await showDeleteConfirmationDialog(
                confirmationOptions,
            );
            if (isConfirmed) {
                handleClose();
                setValue('group_name', '');
            }
        } else {
            handleClose();
            setValue('group_name', '');
        }
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    Add New Group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <div className="d-flex justify-content-center mb-4">
                        <Image src={userGroup} alt="add-user" />
                    </div>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <div className="mb-3">
                                <Controller
                                    name={`group_name`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <>
                                            <InputGroupText
                                                onChangeFunc={onChange}
                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                value={value}
                                                tabIndex={1}
                                                id={`Group Name`}
                                                inputType={'text'}
                                                placeholder={'Group Name'}
                                                disabled={false}
                                                groupInput={false}
                                                inputGroupClassName="mfnw__input-group"
                                                autoFocus={true}
                                                errorText={
                                                    errors?.group_name
                                                        ?.message || ''
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <Button
                                variant="primary w-100 mt-3 text-uppercase fw-semibold"
                                type="submit"
                                tabIndex={1}
                                disabled={isAddGroupData}
                            >
                                {isAddGroupData ? <LoaderBtn /> : 'SAVE'}
                            </Button>
                            <Button
                                className="btn-cancel mt-3 w-100 text-uppercase fw-semibold"
                                onClick={() => onClose()}
                                tabIndex={1}
                            >
                                CANCEL
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default GroupPopup;
