/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Badge, Button, Col, Row, Modal, Form } from 'react-bootstrap';

import ErrorMsg from '@components/errorMsg';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import DateSelect from '@components/DateSelect/DateSelect';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';

import {
    cancelButtonColor,
    confirmButtonColor,
    contactPackActions,
    contactUnloadTypes,
    contactPackLoadTypes,
    searchTimeOut,
} from '@constants/general.const';

import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarRange } from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

import { pacerSchema, selectPacers } from '@app/OffersList/offers.const';
import {
    getAllDataList,
    selectPackersLoad,
    updateOfferPacker,
} from '@app/OffersList/functions';

import { IPackersProps } from '../OffersList/offer.interface';
import { IListOption, IListOptionSearchObj2 } from '../../Interface/common';
import DateRangeSelect from '../../components/DateRengeSelect/DateRengeSelect';
import { updatePackerSchema } from '../OffersList/offers.const';

const SelectPackersPopup = ({
    showPopup,
    handleClose,
    offerId,
    getOfferData,
    offerPackerData,
    listCall,
    isDisabled,
    PackerName,
    packerPopUpTitle,
    packerPopUpDateRange,
}: any) => {
    const dispatch = useDispatch<any>();

    const offerPackerId = offerPackerData?.pack_load_unload_nano_id;

    const { offersData } = useSelector((state: any) => state);
    const { isUpdateOfferPacker, isSelectPackersLoad } = offersData;
    const [selectList, setSelectList] = useState<IListOption[]>([]);
    const [dateRange, setDateRange] = useState<[any, any]>([null, null]);
    const [startDate, endDate] = dateRange;

    const [packerListOptionArr, setPackerListOptionArr] = useState<
        IListOption[] | null
    >([]);

    const [packerListCount, setPackerListCount] = useState<number>(0);
    const [searchObj2, setSearchObj2] = useState<IListOptionSearchObj2 | null>(
        null,
    );
    const prevSearchObj2 = useRef<IListOptionSearchObj2 | null>({
        ...searchObj2,
        packerSearch: null,
    });
    const packStartRef: any = useRef();

    const schem = offerPackerId ? pacerSchema : updatePackerSchema;
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors, isDirty },
    } = useForm<IPackersProps>({
        resolver: yupResolver(schem),
        defaultValues: { ...selectPacers },
    });

    useEffect(() => {
        packerPopUpTitle !== 'Packers' &&
            setValue('packer_action', 'unloading');
    }, []);

    useEffect(() => {
        if (
            prevSearchObj2?.current?.packerSearch !== searchObj2?.packerSearch
        ) {
            const getData: NodeJS.Timeout = setTimeout(() => {
                getAllList(searchObj2);
            }, searchTimeOut);
            return () => clearTimeout(getData);
        }
    }, [searchObj2]);

    const HandleChange = async (data: IPackersProps) => {
        if (startDate && endDate) {
            const newStartDate =
                moment.utc(startDate).local().format('MM-DD-Y') !==
                'Invalid date'
                    ? moment.utc(startDate).local().format('MM-DD-Y')
                    : moment();
            const newEndDate =
                moment.utc(endDate).local().format('MM-DD-Y') !== 'Invalid date'
                    ? moment.utc(endDate).local().format('MM-DD-Y')
                    : moment();

            const sendData = {
                ...data,
                offerId: offerId,
                offerPackerId: offerPackerId,
                pack_date: offerPackerId
                    ? moment.utc(data?.pack_date).local().format('MM-DD-Y') !==
                      'Invalid date'
                        ? moment.utc(data?.pack_date).local().format('MM-DD-Y')
                        : moment()
                    : { newStartDate, newEndDate },
            };
            const { payload } = await dispatch(
                offerPackerId
                    ? updateOfferPacker(sendData)
                    : selectPackersLoad(sendData),
            );
            if (payload?.data?.message !== '' && payload?.data?.status) {
                listCall();
                getOfferData(offerId);
                handleClose();
                reset(selectPacers);
            }
        }
    };

    useEffect(() => {
        if (offerPackerData) {
            reset(offerPackerData);

            setDateRange([
                new Date(offerPackerData?.pack_start_date),
                new Date(offerPackerData?.pack_end_date),
            ]);

            var defaultSelect: IListOption[] = [];
            offerPackerData?.offerpacker.map((name: any, key: any) => {
                defaultSelect.push({
                    value: name?.packer_name,
                    label: name?.packer_name,
                });
                return null;
            });
            const getPacker: any =
                defaultSelect && setSelectList(defaultSelect);
            defaultSelect && setValue('packer_ids', defaultSelect);
            return () => clearTimeout(getPacker);
        }

        if (!isDisabled) {
            packStartRef?.current?.setOpen(true);
        }
    }, [offerPackerData]);

    useEffect(() => {
        if (PackerName) {
            var defaultSelect: IListOption[] = [];
            defaultSelect.push({
                value: PackerName,
                label: PackerName,
            });
            const getPacker: any =
                defaultSelect && setSelectList(defaultSelect);
            defaultSelect && setValue('packer_ids', defaultSelect);
            return () => clearTimeout(getPacker);
        }
    }, [PackerName]);

    const getAllList = async (searchObj: any) => {
        const type = { packer: 'packer' };
        const { payload } = await dispatch(
            getAllDataList({
                type: type,
                searchArr: searchObj,
                offset: packerListOptionArr?.length,
            }),
        );
        if (payload?.data?.message !== '' && payload?.data?.status) {
            var allDataList = payload?.data?.dataList;

            var packersData: IListOption[];
            if (searchObj && searchObj?.packerSearch !== '') {
                packersData = [];
            } else {
                packersData = packerListOptionArr ?? [];
            }
            allDataList?.packersData?.map((dataOption: any) => {
                if (dataOption?.is_active) {
                    packersData.push({
                        value: dataOption.packer_name,
                        label: dataOption.packer_name,
                    });
                }
                return null;
            });
            setPackerListOptionArr(packersData);
            setPackerListCount(payload?.data?.dataList?.packersDataCount);
        } else {
            toast.error(payload?.message);
        }
    };

    const updateSelectedList = async (itemId: string) => {
        const newArray = selectList.filter(
            (item: any) => item?.value !== itemId,
        );
        setSelectList(newArray);
        setValue('packer_ids', newArray);
    };
    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset(selectPacers);
                }
            });
        } else {
            handleClose();
        }
    };

    const scheduleDateRange: any = useMemo(
        () => (
            <Controller
                name={`pack_date`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateRangeSelect
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        groupInput={true}
                        icon={faCalendarRange}
                        inputGroupClassName="mfnw__input-date-group h-auto"
                        onChangeFunc={(update: any) => {
                            setDateRange(update);
                            onChange(update);
                        }}
                        placeholderText={'Select date'}
                        disabled={isDisabled}
                        className="mfnw__login-form-control mfnw__login-form-control-bg me-4"
                        minDate={packerPopUpDateRange[0]}
                        maxDate={packerPopUpDateRange[1]}
                    />
                )}
            />
        ),
        [dateRange],
    );

    const packDate: any = useMemo(
        () => (
            <Controller
                name={`pack_date`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <DateSelect
                        ref={packStartRef}
                        className="mfnw__login-form-control mfnw__login-form-control-bg"
                        inputGroupClassName="mfnw__input-group"
                        placeholder="MM/DD/YYYY"
                        label="Date"
                        selected={
                            typeof value === 'string'
                                ? moment(value).tz('America/New_York').toDate()
                                : value || null
                        }
                        dateFormat="MM/dd/yyyy"
                        inputName="packDate"
                        icon={faCalendar}
                        onChangeFunc={({ value: valueDateChange }: any) =>
                            onChange(valueDateChange)
                        }
                        groupInput={true}
                        disabled={isDisabled}
                        minDate={packerPopUpDateRange[0]}
                        maxDate={packerPopUpDateRange[1]}
                    />
                )}
            />
        ),
        [offerPackerData],
    );

    return (
        <Modal
            show={showPopup}
            onHide={onClose}
            backdrop="static"
            backdropClassName="modal-backdrop-upper"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {isDisabled
                        ? `View ${packerPopUpTitle}`
                        : `Select ${packerPopUpTitle}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <Form onSubmit={handleSubmit(HandleChange)}>
                    <Row className="mt-4">
                        <Col md={7}>
                            {offerPackerData ? packDate : scheduleDateRange}
                            <ErrorMsg errorText={errors?.pack_date?.message} />
                        </Col>
                        <Col md={5} className="mt-3 mt-md-0">
                            <Controller
                                name={'packer_action'}
                                control={control}
                                render={({ field: { value } }: any) => (
                                    <ReactSelectGroup
                                        value={(packerPopUpTitle === 'Packers'
                                            ? contactPackLoadTypes
                                            : contactUnloadTypes
                                        ).find((c: any) => c?.value === value)}
                                        labelShow={false}
                                        inputClassName="mfnw__input-group"
                                        onChangeFunc={(stateValue: any) => {
                                            setValue(
                                                'packer_action',
                                                stateValue?.value,
                                            );
                                        }}
                                        options={
                                            packerPopUpTitle === 'Packers'
                                                ? contactPackLoadTypes
                                                : contactUnloadTypes
                                        }
                                        className="w-100"
                                        isSearchable={false}
                                        isDisabled={isDisabled}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={12}>
                            <div className="mt-3">
                                <Controller
                                    name="packer_ids"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }: any) => (
                                        <ReactSelectGroup
                                            value={
                                                value === selectList
                                                    ? value
                                                    : selectList
                                            }
                                            name={'packer_ids'}
                                            inputClassName="mfnw__input-group"
                                            options={packerListOptionArr}
                                            onInputChange={(
                                                valueChange: any,
                                            ) => {
                                                if (valueChange !== '') {
                                                    var set: any = {
                                                        ...searchObj2,
                                                        packerSearch:
                                                            valueChange,
                                                    };
                                                    prevSearchObj2.current =
                                                        searchObj2;
                                                    setSearchObj2(set);
                                                } else {
                                                    prevSearchObj2.current =
                                                        searchObj2;
                                                    setSearchObj2({
                                                        ...searchObj2,
                                                        packerSearch: '',
                                                    });
                                                }
                                            }}
                                            isMulti={true}
                                            labelShow={false}
                                            onChangeFunc={(stateValue: any) => {
                                                setValue(
                                                    'packer_ids',
                                                    stateValue,
                                                );
                                                setSelectList(stateValue);
                                            }}
                                            label={''}
                                            isSearchable={true}
                                            errorText={
                                                errors?.packer_ids?.message
                                            }
                                            isDisabled={
                                                PackerName ? true : isDisabled
                                            }
                                            onMenuScrollToBottom={() => {
                                                if (
                                                    packerListOptionArr &&
                                                    !(
                                                        packerListOptionArr?.length >=
                                                        packerListCount
                                                    )
                                                ) {
                                                    getAllList(null);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mt-3">
                                <p className="text-sm fw-bold">
                                    {packerPopUpTitle === 'Packers'
                                        ? 'PACKER(S)'
                                        : 'UNLOADER(S)'}
                                </p>
                            </div>
                            <Row className="mt-3 g-0">
                                {selectList?.map((item: any, index: number) => (
                                    <Badge
                                        key={index}
                                        bg="light"
                                        className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                    >
                                        <span className="me-3">
                                            {item?.label}
                                        </span>
                                        {!PackerName && !isDisabled && (
                                            <div
                                                onClick={() =>
                                                    updateSelectedList(
                                                        item?.value,
                                                    )
                                                }
                                                className="cursor-pointer"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleXmark}
                                                    color="#b7b7b7"
                                                    size="lg"
                                                />
                                            </div>
                                        )}
                                    </Badge>
                                ))}
                            </Row>
                        </Col>
                        <Col md={6} className="mb-4">
                            <div className="mt-3">
                                <p className="text-sm fw-bold">STATUS</p>
                            </div>
                            <Controller
                                name={'packer_status'}
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <ReactSelectGroup
                                        value={contactPackActions.find(
                                            (c: any) => c?.value === value,
                                        )}
                                        labelShow={false}
                                        inputClassName="mfnw__input-group"
                                        onChangeFunc={(stateValue: any) => {
                                            setValue(
                                                'packer_status',
                                                stateValue?.value,
                                            );
                                        }}
                                        options={contactPackActions}
                                        className="w-100"
                                        isSearchable={false}
                                        isDisabled={isDisabled}
                                    />
                                )}
                            />
                        </Col>
                        {!isDisabled && (
                            <Col md={12}>
                                <Button
                                    variant="primary w-100 mt-3"
                                    type="submit"
                                    disabled={
                                        isUpdateOfferPacker ||
                                        isSelectPackersLoad
                                    }
                                >
                                    {isUpdateOfferPacker ||
                                    isSelectPackersLoad ? (
                                        <LoaderBtn />
                                    ) : (
                                        'SAVE'
                                    )}
                                </Button>
                                <Button
                                    variant="default"
                                    className="btn-cancel mt-3 w-100"
                                    onClick={() => onClose()}
                                >
                                    CANCEL
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SelectPackersPopup;
