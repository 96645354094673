import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    apiDeleteUser,
    apiInviteUser,
    apiUpdateInfo,
    apiUserDataGet,
    apiUsersList,
} from '@services/API/apiFunctions';

const inviteUser = createAsyncThunk(
    'inviteUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiInviteUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const deleteUser = createAsyncThunk(
    'deleteUser',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiDeleteUser(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const updateInfo = createAsyncThunk(
    'updateInfo',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUpdateInfo(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const usersList = createAsyncThunk(
    'usersList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUsersList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const userDataGet = createAsyncThunk(
    'userDataGet',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiUserDataGet(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { inviteUser, usersList, userDataGet, updateInfo, deleteUser };
