import React from 'react';

import { contactObj, contactPhoneObj } from './agent.const';

import ContactInfo from '@app/SettingsLists/contactInfo';

const AgentContactInfoFields = ({
    getValues,
    agentId,
    setValue,
    control,
    errors,
    watchContactInfo,
    isDisabled,
}: any) => (
    <>
        <ContactInfo
            ContactInfo={ContactInfo}
            watchContactInfo={watchContactInfo}
            getValues={getValues}
            setValue={setValue}
            control={control}
            errors={errors}
            TspId={agentId}
            fieldName={'contact_info'}
            contactObj={contactObj}
            contactPhoneObj={contactPhoneObj}
            isDisabled={isDisabled}
        />
    </>
);

export default AgentContactInfoFields;
