import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faPhone } from '@fortawesome/fontawesome-pro-regular';

import { IStep1FieldsProps, IStep1Props } from './step1.interface';

const faEnvelopeIcon = faEnvelope as IconProp;
const faphoneIcon = faPhone as IconProp;

export const organizationDataObject: IStep1Props = {
    organization_nano_id: '',
    user_id: 20,
    organization_logo: '',
    organization_name: '',
    organization_address: '',
    organization_zip_code: null,
    organization_city: '',
    organization_state: '',
    organization_email: '',
    organization_phone: '',
    organization_mc: '',
    organization_dot: '',
    organization_scac: [''],
    is_active: '1',
    pivot: {},
};

export const step1FieldArr: IStep1FieldsProps[] = [
    {
        name: 'organization_name',
        label: 'Name',
        type: 'text',
        placeholder: '',
        position: 1,
        col: 6,
        ClassNameCustom: 'mt-4',
        tabIndex: 1,
    },
    {
        name: 'organization_address',
        label: 'Address',
        type: 'text',
        placeholder: '',
        position: 2,
        col: 6,
        ClassNameCustom: 'mt-4',
        tabIndex: 2,
    },
    {
        name: 'organization_zip_code',
        label: 'Zip Code',
        type: 'text',
        placeholder: '',
        position: 1,
        col: 3,
        ClassNameCustom: 'mt-4',
        tabIndex: 3,
    },
    {
        name: 'organization_city',
        label: 'City',
        type: 'text',
        placeholder: '',
        position: 2,
        col: 5,
        ClassNameCustom: 'mt-4',
        tabIndex: 4,
    },
    {
        name: 'organization_state',
        label: 'State',
        type: 'text',
        placeholder: '',
        position: 3,
        col: 4,
        ClassNameCustom: 'mt-4',
        tabIndex: 5,
    },
    {
        name: 'organization_email',
        label: null,
        icon: faEnvelopeIcon,
        type: 'text',
        placeholder: '',
        position: 1,
        col: 6,
        ClassNameCustom: 'mt-4',
        tabIndex: 6,
    },
    {
        name: 'organization_phone',
        label: null,
        icon: faphoneIcon,
        type: 'text',
        placeholder: '',
        position: 2,
        col: 6,
        ClassNameCustom: 'mt-4',
        tabIndex: 7,
    },
    {
        name: 'organization_mc',
        label: 'MC#',
        type: 'text',
        placeholder: 'XXXXXXX',
        position: 2,
        col: 6,
        tabIndex: 8,
        ClassNameCustom: 'mt-4',
        spacedText: 'spaced_text',
    },
    {
        name: 'organization_dot',
        label: 'DOT#',
        type: 'text',
        placeholder: 'XXXXXXX',
        position: 2,
        col: 6,
        tabIndex: 9,
        ClassNameCustom: 'mt-4',
        spacedText: 'spaced_text',
    },
];
export const organizationPopupStep1FieldArr: IStep1FieldsProps[] = [
    {
        name: 'organization_name',
        label: 'Name',
        type: 'text',
        placeholder: '',
        position: 1,
        col: 12,
        ClassNameCustom: 'mt-4',
        tabIndex: 1,
    },
    {
        name: 'organization_address',
        label: 'Address',
        type: 'text',
        placeholder: 'Address',
        groupInput: false,
        position: 2,
        col: 12,
        ClassNameCustom: 'mt-4',
        tabIndex: 2,
    },
    {
        name: 'organization_zip_code',
        label: 'Zip Code',
        type: 'text',
        placeholder: 'Zip Code',
        groupInput: false,
        position: 1,
        col: 3,
        ClassNameCustom: 'mt-4',
        tabIndex: 3,
    },
    {
        name: 'organization_city',
        label: 'City',
        type: 'text',
        placeholder: '',
        groupInput: false,
        position: 2,
        col: 5,
        ClassNameCustom: 'mt-4 ps-0',
        tabIndex: 4,
    },
    {
        name: 'organization_state',
        label: 'State',
        type: 'text',
        placeholder: '',
        position: 3,
        col: 4,
        ClassNameCustom: 'mt-4 ps-0',
        tabIndex: 5,
    },
    {
        name: 'organization_email',
        label: null,
        icon: faEnvelopeIcon,
        type: 'email',
        placeholder: '',
        position: 1,
        col: 9,
        ClassNameCustom: 'mt-4',
        tabIndex: 6,
    },
    {
        name: 'organization_phone',
        label: null,
        icon: faphoneIcon,
        type: 'text',
        placeholder: '',
        position: 2,
        col: 9,
        ClassNameCustom: 'mt-4',
        tabIndex: 7,
    },
    {
        name: 'organization_mc',
        label: 'MC#',
        type: 'text',
        placeholder: 'XXXXXXX',
        position: 2,
        col: 9,
        tabIndex: 8,
        spacedText: 'spaced_text',
        ClassNameCustom: 'mt-4',
    },
    {
        name: 'organization_dot',
        label: 'DOT#',
        type: 'text',
        placeholder: 'XXXXXXX',
        position: 2,
        col: 9,
        tabIndex: 9,
        ClassNameCustom: 'mt-4',
        spacedText: 'spaced_text',
    },
];
