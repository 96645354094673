import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { deleteOffice, getOfficeDataList } from './functions';

export const step1FieldArr: any[] = [];

export const getOfficeData = async (
    dispatch: any,
    params: any,
    setDataList: any,
) => {
    const { payload } = await dispatch(getOfficeDataList(params));
    const { data: payloadData, message: errorMessage } = payload || {};
    const { message, status, data } = payloadData || {};
    if (message && status) {
        setDataList(data);
    } else {
        toast.error(errorMessage);
    }
};

export const officeDeleteById = async (
    officeId: string,
    dispatch: any,
    getData: any,
) => {
    Swal.fire({
        title: 'Delete Office?',
        text: "You won't be able to revert this operation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
        if (result.isConfirmed) {
            const { payload } = await dispatch(deleteOffice(officeId));
            const { data: payloadData, message: errorMessage } = payload || {};
            const { message, status } = payloadData || {};
            if (message && status) {
                getData();
            } else {
                toast.error(errorMessage);
            }
        }
    });
};
