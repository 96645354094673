import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Card, Col, Row } from 'react-bootstrap';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import PhoneInputMaskGroup from '@components/PhoneInputMaskGroup/PhoneInputMask';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import LogoImageUpload from '@components/LogoImageUpload/LogoImageUpload';
import ScacInputField from '@components/ScacInputField/ScacInputField';

import { phoneFormat } from '@constants/general.const';

import { faPhone } from '@fortawesome/pro-light-svg-icons';

import { step1FieldArr } from '@app/CreateOrganization/step1/step1.const';
import StepperHeader from '@app/CreateOrganization/stapperComponent/stepperHeader';

const Step1FormField = ({
    handleClickImageChange,
    userImage,
    organizationData,
    control,
    cityArr,
    stateArr,
    setValue,
    zipError,
    fieldErrors,
    SCACarr,
    setSCACarrLength,
    setSCACarr,
    getValues,
    SCACarrLength,
    isAddOrganizationData,
    isUpdateOrganizationData,
}: any) => (
    <Card className="border-none shadow-md mfnw_organization-card">
        <Card.Body className="p-4">
            <StepperHeader
                Tag={'h5'}
                handleShow={false}
                btnText={false}
                titleText={'Organization Details'}
            />
            <hr className="border-border-opacity-10" />
            <div className="d-flex justify-content-between flex-column min-vh-80">
                <Row className="mfnw_org_detail_content justify-content-center">
                    <Col
                        md={6}
                        className="d-flex align-items-center  justify-content-center justify-content-xl-end my-4"
                    >
                        <LogoImageUpload
                            handleClickProfilePic={handleClickImageChange}
                            id="contained-button-file"
                            userImage={userImage}
                            userProfileData={organizationData}
                            dropAble={true}
                        />
                    </Col>
                    <Col md={10}>
                        <Row>
                            {step1FieldArr &&
                                step1FieldArr.map(
                                    (field: any, index: number) => {
                                        const {
                                            name,
                                            label,
                                            type,
                                            placeholder,
                                            col,
                                            icon,
                                            tabIndex,
                                            spacedText,
                                        } = field;
                                        return (
                                            <>
                                                {name === 'organization_mc' && (
                                                    <hr className="mt-5 mb-4" />
                                                )}
                                                <Col
                                                    md={col < 6 ? 6 : col}
                                                    lg={col}
                                                    key={index}
                                                    className="mt-4"
                                                >
                                                    <Controller
                                                        name={name}
                                                        control={control}
                                                        key={index}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }: any) => (
                                                            <Fragment
                                                                key={index}
                                                            >
                                                                {name ===
                                                                    'organization_city' ||
                                                                name ===
                                                                    'organization_state' ? (
                                                                    <ReactSelectGroup
                                                                        value={(name ===
                                                                        'organization_city'
                                                                            ? cityArr
                                                                            : stateArr
                                                                        ).find(
                                                                            (
                                                                                c: any,
                                                                            ) =>
                                                                                c?.value ===
                                                                                value,
                                                                        )}
                                                                        tabIndex={
                                                                            tabIndex
                                                                        }
                                                                        labelShow={
                                                                            true
                                                                        }
                                                                        placeholder={
                                                                            name ===
                                                                            'organization_city'
                                                                                ? 'City'
                                                                                : 'State'
                                                                        }
                                                                        className="w-100"
                                                                        classNamePrefix="select_control_group"
                                                                        inputClassName="mfnw__input-group"
                                                                        options={
                                                                            name ===
                                                                            'organization_city'
                                                                                ? cityArr
                                                                                : stateArr
                                                                        }
                                                                        onChangeFunc={(
                                                                            valueStateTownChange: any,
                                                                        ) => {
                                                                            if (
                                                                                name ===
                                                                                'organization_city'
                                                                            ) {
                                                                                setValue(
                                                                                    'organization_city',
                                                                                    valueStateTownChange?.value,
                                                                                );
                                                                                setValue(
                                                                                    'organization_city_name',
                                                                                    valueStateTownChange?.label,
                                                                                );
                                                                            } else {
                                                                                setValue(
                                                                                    'organization_state',
                                                                                    valueStateTownChange?.value,
                                                                                );
                                                                                setValue(
                                                                                    'organization_state_name',
                                                                                    valueStateTownChange?.label,
                                                                                );
                                                                            }
                                                                        }}
                                                                        label={
                                                                            label
                                                                        }
                                                                        isSearchable={
                                                                            zipError
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        isDisabled={
                                                                            (!zipError &&
                                                                            name ===
                                                                                'organization_city'
                                                                                ? cityArr.length
                                                                                : stateArr.length) ===
                                                                            1
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {name ===
                                                                        'organization_phone' ? (
                                                                            <PhoneInputMaskGroup
                                                                                maskFormat={
                                                                                    phoneFormat
                                                                                }
                                                                                inputName="organization_phone"
                                                                                inputType="text"
                                                                                label="Phone No."
                                                                                className="mfnw__login-form-control mfnw__login-form-control-bg"
                                                                                onChangeFunc={
                                                                                    onChange
                                                                                }
                                                                                id="organization_phone"
                                                                                aria-describedby="phone"
                                                                                groupLabelClass="mfnw__input-group"
                                                                                tabIndex={
                                                                                    tabIndex
                                                                                }
                                                                                faphoneIcon={
                                                                                    faPhone
                                                                                }
                                                                                value={
                                                                                    value
                                                                                }
                                                                                placeholder="Phone"
                                                                                errorText={
                                                                                    fieldErrors?.[
                                                                                        name
                                                                                    ]
                                                                                        ?.message
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <InputGroupText
                                                                                onChangeFunc={
                                                                                    onChange
                                                                                }
                                                                                value={
                                                                                    value
                                                                                }
                                                                                tabIndex={
                                                                                    tabIndex
                                                                                }
                                                                                disabled={
                                                                                    false
                                                                                }
                                                                                id={
                                                                                    name
                                                                                }
                                                                                inputName={
                                                                                    name
                                                                                }
                                                                                inputType={
                                                                                    type
                                                                                }
                                                                                icon={
                                                                                    icon
                                                                                }
                                                                                className={
                                                                                    spacedText
                                                                                }
                                                                                placeholder={
                                                                                    placeholder
                                                                                }
                                                                                label={
                                                                                    label
                                                                                }
                                                                                inputGroupClassName="mfnw__input-group"
                                                                                errorText={
                                                                                    name ===
                                                                                        'organization_zip_code' &&
                                                                                    zipError
                                                                                        ? zipError
                                                                                        : fieldErrors?.[
                                                                                              name
                                                                                          ]
                                                                                              ?.message
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </Col>
                                            </>
                                        );
                                    },
                                )}
                        </Row>

                        <Row>
                            <ScacInputField
                                SCACarr={SCACarr}
                                control={control}
                                col={6}
                                getValues={getValues}
                                setSCACarrLength={setSCACarrLength}
                                SCACarrLength={SCACarrLength}
                                setSCACarr={setSCACarr}
                                setValue={setValue}
                                tabIndex={9}
                            />
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={6} className="mt-md-5 order-md-2">
                                <Button
                                    variant="primary"
                                    className="text-uppercase button__details"
                                    type="submit"
                                    disabled={
                                        isAddOrganizationData ||
                                        isUpdateOrganizationData
                                    }
                                >
                                    {isAddOrganizationData ||
                                    isUpdateOrganizationData ? (
                                        <LoaderBtn />
                                    ) : (
                                        'Continue'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Card.Body>
    </Card>
);

export default Step1FormField;
