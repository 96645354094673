import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { Modal, Form, Badge } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';

import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DriverFormField from '@app/SettingsLists/Drivers/driverFormField';
import { uploadFile } from '@utils/utilFunctions';

import {
    IDriverAllFieldsProps,
    IDriverFieldsProps,
    IFilesArray,
} from './driver.interface';
import { addDriver, updateDriver } from './functions';
import { addSchema, driverDefaultData } from './driver.const';
const DriverPopup = ({
    showPopup,
    handleClose,
    editData,
    getDataListCall,
}: any) => {
    const dispatch = useDispatch<any>();
    let intervalProgress: any;
    const driverId = editData?.drivers_nano_id;
    const packStartRef = useRef();

    const { driverData } = useSelector((state: any) => state);
    const { isAddDriver, isUpdateDriver, isGetDriverInfo } = driverData;

    const [progress, setProgress] = useState<number>(0);
    const [progress2, setProgress2] = useState<number>(0);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [showProgressBar2, setShowProgressBar2] = useState<boolean>(false);
    const [preview, setPreview] = useState<any>('');
    const [preview2, setPreview2] = useState<any>('');
    const [filesArray, setFilesArray] = useState<IFilesArray>({
        isValid: true,
        files: undefined,
    });
    const [filesArray2, setFilesArray2] = useState<IFilesArray>({
        isValid: true,
        files: undefined,
    });

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        formState: { errors, isValid, isDirty },
    } = useForm<IDriverAllFieldsProps>({
        resolver: yupResolver(addSchema),
        defaultValues: driverDefaultData,
    });

    useEffect(() => {
        reset(editData);
        setValue('driver_license_pdf', editData?.driver_license_pdf || []);
        setValue('driver_certificate', editData?.driver_certificate || []);
    }, [editData]);

    useEffect(() => {
        if (progress === 100 && preview?.length > 0) {
            clearInterval(intervalProgress);
            setTimeout(() => {
                setShowProgressBar(false);
            }, 1000);
        }
    }, [progress, preview]);

    useEffect(() => {
        uploadFile(
            filesArray,
            setShowProgressBar,
            setProgress,
            setPreview,
            preview,
            'driver_certificate',
            'driver_license_pdf',
            checkFileLengthValidation,
            dispatch,
            setValue,
            getValues,
        );
    }, [filesArray]);

    useEffect(() => {
        uploadFile(
            filesArray2,
            setShowProgressBar2,
            setProgress2,
            setPreview2,
            preview2,
            'driver_license_pdf',
            'driver_certificate',
            checkFileLengthValidation,
            dispatch,
            setValue,
            getValues,
        );
    }, [filesArray2]);

    const checkFileLengthValidation = (checkType: string) => {
        const targetValues =
            checkType === 'driver_certificate'
                ? getValues('driver_certificate')
                : getValues('driver_license_pdf');

        const valuesArray =
            typeof targetValues === 'string'
                ? JSON.parse(targetValues)
                : targetValues;

        const valuesLength = Array.isArray(valuesArray)
            ? valuesArray.length
            : 0;

        if (valuesLength >= 5) {
            toast.error('Maximum 5 documents allowed.');
            return false;
        }

        return true;
    };

    const HandleChange = async (data: IDriverFieldsProps) => {
        if (isValid) {
            const { payload } = await dispatch(
                driverId
                    ? updateDriver({
                          ...data,
                          id: driverId,
                          driver_license_pdf:
                              typeof getValues('driver_license_pdf') ===
                              'string'
                                  ? JSON.parse(getValues('driver_license_pdf'))
                                  : getValues('driver_license_pdf'),
                          driver_certificate:
                              getValues('driver_certificate') &&
                              typeof getValues('driver_certificate') ===
                                  'string'
                                  ? JSON.parse(getValues('driver_certificate'))
                                  : getValues('driver_certificate'),
                      })
                    : addDriver(data),
            );
            const { message } = payload || {};
            if (payload?.data?.message && payload?.data?.status) {
                getDataListCall();
                handleClose();
                reset(driverDefaultData);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: message || '',
                    icon: 'error',
                    cancelButtonColor: cancelButtonColor,
                });
            }
        }
    };

    const onClose = async () => {
        if (isDirty) {
            Swal.fire({
                title: 'Warning',
                html:
                    'Are you sure you want to close this form? <br/> ' +
                    'All changes made will be lost.',
                allowOutsideClick: false,

                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: cancelButtonColor,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
            }).then(async (result) => {
                if (result?.isConfirmed) {
                    handleClose();
                    reset(driverDefaultData);
                }
            });
        } else {
            handleClose();
            reset(driverDefaultData);
        }
    };

    return (
        <Modal show={showPopup} onHide={onClose} backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-5 fw-semibold">
                    {driverId ? 'Edit Driver' : 'Add New Driver'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="main-body-section py-4">
                {isGetDriverInfo ? (
                    <LoaderBtn />
                ) : (
                    <Form onSubmit={handleSubmit(HandleChange)}>
                        <div className="right_content mb-3 align-item-center d-flex justify-content-end ">
                            {editData?.is_active &&
                            editData?.is_active === 1 ? (
                                <Badge className="badge bg-success align-text-bottom ms-2 rounded-1 py-2 px-3">
                                    <FontAwesomeIcon
                                        icon={faCircleSmall}
                                        className="me-2"
                                    />
                                    Enabled
                                </Badge>
                            ) : (
                                <Badge className="badge bg-disable align-text-bottom ms-2 rounded-1 py-2 px-3">
                                    <FontAwesomeIcon
                                        icon={faCircleSmall}
                                        className="me-2"
                                    />
                                    Disable
                                </Badge>
                            )}
                        </div>
                        <DriverFormField
                            control={control}
                            errors={errors}
                            packStartRef={packStartRef}
                            setFilesArray2={setFilesArray2}
                            preview2={preview2}
                            setPreview2={setPreview2}
                            setValue={setValue}
                            getValues={getValues}
                            progress2={progress2}
                            showProgressBar2={showProgressBar2}
                            setFilesArray={setFilesArray}
                            preview={preview}
                            setPreview={setPreview}
                            progress={progress}
                            showProgressBar={showProgressBar}
                            isAddDriver={isAddDriver}
                            isUpdateDriver={isUpdateDriver}
                            onClose={onClose}
                            isEditPopup={true}
                            isDisabled={false}
                        />
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    );
};
export default DriverPopup;
