import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Dropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faCircleSmall,
    faEllipsisVertical,
    faPen,
    faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';

const CommonActions = ({
    item,
    showAlert,
    handleEditShow,
    nanoId,
    isUserList,
}: any) => {
    const { login } = useSelector((state: any) => state);
    const { authUser } = login;

    const commonButton = () =>
        item?.is_active &&
        (item?.is_active === 1 ? (
            <Badge
                onClick={() => showAlert(nanoId, 'Disable')}
                className={`badge bg-success fs-6 align-text-bottom ms-2 px-3 py-2 cursor-pointer`}
            >
                <FontAwesomeIcon icon={faCircleSmall} className="me-2" />
                Enabled
            </Badge>
        ) : (
            <Badge
                onClick={() => showAlert(nanoId, 'Enable')}
                className={`badge bg-disable fs-6 align-text-bottom ms-2 px-3 py-2 cursor-pointer`}
            >
                <FontAwesomeIcon icon={faCircleSmall} className="me-2" />
                Disabled
            </Badge>
        ));

    const deleteButton = () => (
        <Dropdown.Item
            as="button"
            onClick={(e) => {
                showAlert(nanoId, 'delete');
            }}
        >
            <FontAwesomeIcon
                className="mx-2 cursor-pointer text-iconcolor"
                icon={faTrashCan}
            />{' '}
            Delete
        </Dropdown.Item>
    );
    return (
        <>
            <>
                {isUserList
                    ? authUser?.email !== item?.email && commonButton()
                    : commonButton()}
            </>
            <Dropdown className="profile__dropdown">
                <Dropdown.Toggle className="btn-header">
                    <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        size="xl"
                        className="ms-3 text-black"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                    <Dropdown.Item
                        as="button"
                        onClick={(e) => handleEditShow(nanoId)}
                    >
                        <FontAwesomeIcon
                            className="mx-2 cursor-pointer text-iconcolor"
                            icon={faPen}
                        />{' '}
                        Edit
                    </Dropdown.Item>
                    {isUserList
                        ? authUser?.email !== item?.email && deleteButton()
                        : deleteButton()}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default CommonActions;
