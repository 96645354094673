import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const NotFoundPages = ({ currentPageData, image, message }: any) =>
    currentPageData &&
    currentPageData.length === 0 && (
        <Row className="justify-content-center flex-column align-items-center">
            <Col md={6} xl={5} className="text-center mt-5 mfnw-respons-image">
                <Image src={image} />
                <h5
                    className="text-light mt-4 fw-bold mfnw-font-respon"
                    dangerouslySetInnerHTML={{ __html: message }}
                ></h5>
            </Col>
        </Row>
    );

export default NotFoundPages;
