import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import InputGroupText from '@components/InputGroupText/InputGroupText';
import ReactSelectGroup from '@components/reactSelectGroup/ReactSelectGroup';
import RadioButton from '@components/RadioButton/RadioButton';
import ErrorMsg from '@components/errorMsg';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';
import FilesUpload from '@components/FileUpload/filesUpload';

import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import PackerContactInfo from '@app/SettingsLists/Packers/packerContactInfo';

const PackerFormField = ({
    control,
    isDisabled,
    errors,
    isAddPacker,
    isUpdatePacker,
    onClose,
    packerId,
    getValues,
    editData,
    setValue,
    cityArr,
    zipError,
    setPrimary,
    deletePhone,
    addContactPhoneNo,
    RegionsData,
    handleShowRegion,
    setFilesArray,
    preview,
    setPreview,
    progress,
    showProgressBar,
    watchContactInfo,
}: any) => (
    <>
        <h6 className="text-sm text-uppercase">PACKER TYPE</h6>
        <div className="d-flex justify-content-between">
            <Controller
                name="packer_type"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <RadioButton
                        onChangeFunc={onChange}
                        id="inline-radio-1"
                        name="packer_type"
                        value="Company"
                        classNameParentDiv="bg-icon_radio_state"
                        checked={value === 'Company'}
                        label="COMPANY"
                        classes=""
                        disabled={isDisabled}
                    />
                )}
            />
            <Controller
                name="packer_type"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <RadioButton
                        onChangeFunc={onChange}
                        id="inline-radio-2"
                        name="packer_type"
                        value="Contractor"
                        checked={value === 'Contractor'}
                        classNameParentDiv="bg-icon_radio_city"
                        label="CONTRACTOR"
                        classes=""
                        disabled={isDisabled}
                    />
                )}
            />
        </div>
        <h6 className="text-sm mt-4 text-uppercase">PACKER INFO</h6>
        <Row className="justify-content-center">
            <Col md={12}>
                <div className="mt-1">
                    <Controller
                        name={`packer_name`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg text-dark"
                                    value={value}
                                    tabIndex={1}
                                    id={`Name`}
                                    inputType={'text'}
                                    placeholder={'Name'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    inputGroupClassName="mfnw__input-group"
                                    errorText={errors?.packer_name?.message}
                                    autoFocus={true}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    {getValues('packer_city_id') ? (
                        <Controller
                            name={`packer_city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <ReactSelectGroup
                                    value={cityArr.find(
                                        (c: any) =>
                                            getValues('packer_city_id') &&
                                            c?.value ==
                                                getValues('packer_city_id'),
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={cityArr}
                                    placeholder={'City'}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'packer_city',
                                            valueStateTownChange?.label,
                                        );
                                        setValue(
                                            'packer_city_id',
                                            valueStateTownChange?.value,
                                        );
                                    }}
                                    tabIndex={2}
                                    className="w-100"
                                    isSearchable={zipError ? true : false}
                                    isDisabled={
                                        isDisabled ||
                                        (!zipError && cityArr.length === 1) ||
                                        (!zipError && cityArr.length === 20)
                                    }
                                />
                            )}
                        />
                    ) : (
                        <Controller
                            name={`packer_city`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <ReactSelectGroup
                                    value={cityArr.find(
                                        (c: any) =>
                                            getValues('packer_city_id') &&
                                            c?.value ==
                                                getValues('packer_city_id'),
                                    )}
                                    labelShow={false}
                                    inputClassName="mfnw__input-group"
                                    options={cityArr}
                                    placeholder={'City'}
                                    onChangeFunc={(
                                        valueStateTownChange: any,
                                    ) => {
                                        setValue(
                                            'packer_city',
                                            valueStateTownChange?.label,
                                        );
                                        setValue(
                                            'packer_city_id',
                                            valueStateTownChange?.value,
                                        );
                                    }}
                                    tabIndex={2}
                                    className="w-100"
                                    isSearchable={zipError ? true : false}
                                    isDisabled={
                                        isDisabled ||
                                        (!zipError && cityArr.length === 1) ||
                                        (!zipError && cityArr.length === 20)
                                    }
                                />
                            )}
                        />
                    )}
                </div>
            </Col>
            <Col md={6}>
                <div className="mt-4">
                    <Controller
                        name={`packer_state`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={3}
                                    id={`State`}
                                    inputType={'text'}
                                    placeholder={'State'}
                                    disabled={true}
                                    groupInput={false}
                                    errorText={errors?.packer_state?.message}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={12}>
                <div className="mt-3">
                    <Controller
                        name={`packer_zip_code`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={3}
                                    id={`Zip Code`}
                                    inputType={'text'}
                                    placeholder={'Zip Code'}
                                    disabled={isDisabled}
                                    groupInput={false}
                                    errorText={
                                        zipError
                                            ? zipError
                                            : errors?.packer_zip_code?.message
                                    }
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <PackerContactInfo
                packerId={packerId}
                editData={editData}
                getValues={getValues}
                control={control}
                setValue={setValue}
                isDisabled={isDisabled}
                errors={errors}
                setPrimary={setPrimary}
                deletePhone={deletePhone}
                addContactPhoneNo={addContactPhoneNo}
                watchContactInfo={watchContactInfo}
            />
            <Col md={12}>
                <div className="mt-2">
                    <Controller
                        name={`packer_email`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputGroupText
                                    onChangeFunc={onChange}
                                    className="mfnw__login-form-control mfnw__login-form-control-bg"
                                    value={value}
                                    tabIndex={5}
                                    id={`Email`}
                                    inputType={'text'}
                                    icon={faEnvelope}
                                    placeholder={'Email'}
                                    disabled={isDisabled}
                                    groupInput={true}
                                    errorText={errors?.packer_email?.message}
                                />
                            </>
                        )}
                    />
                </div>
            </Col>
            <Col md={12}>
                {RegionsData?.length > 0 && (
                    <h6 className="text-sm mt-4 mb-0 text-uppercase">Region</h6>
                )}
                {!isDisabled && (
                    <Button
                        variant="primary w-100 mt-3 rounded-2 position-relative fw-semibold"
                        onClick={handleShowRegion}
                        tabIndex={6}
                    >
                        Select Region{' '}
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="position-absolute icon_right_button"
                        />
                    </Button>
                )}
                <div className="d-flex flex-wrap gap-2">
                    {errors?.regions?.message && (
                        <ErrorMsg
                            errorText={errors?.regions?.message}
                        ></ErrorMsg>
                    )}
                </div>
                <div className="d-flex flex-wrap gap-2">{RegionsData}</div>
            </Col>
            <Col md={12}>
                <FilesUpload
                    heading={'Packer Certifications'}
                    setFilesArray={setFilesArray}
                    preview={preview}
                    setPreview={setPreview}
                    setValue={setValue}
                    getValues={getValues}
                    progress={progress}
                    showProgressBar={showProgressBar}
                    forValue={
                        getValues('packer_certificate') &&
                        typeof getValues('packer_certificate') === 'string'
                            ? JSON.parse(getValues('packer_certificate'))
                            : getValues('packer_certificate')
                    }
                    isRemove={!isDisabled}
                    isEditPopup={!isDisabled}
                    tabIndex={7}
                />
            </Col>
            {!isDisabled && (
                <Col md={12}>
                    <Button
                        variant="primary w-100 mt-4 text-uppercase fw-semibold rounded-2"
                        type="submit"
                        tabIndex={8}
                        disabled={isAddPacker || isUpdatePacker}
                    >
                        {isAddPacker || isUpdatePacker ? <LoaderBtn /> : 'SAVE'}
                    </Button>
                    <Button
                        className="btn-cancel mt-4 w-100 text-uppercase fw-semibold rounded-2"
                        onClick={() => onClose()}
                        tabIndex={9}
                    >
                        Cancel
                    </Button>
                </Col>
            )}
        </Row>
    </>
);

export default PackerFormField;
