export const routesPath = {
    login: '/login',
    register: '/register',

    dashboard: '/dashboard',
    company: '/company',
    offers: '/offers',

    offersList: '/offers-list',

    updateOffer: '/update/offer/',
    jobs: '/jobs',
    updateJob: '/update/job/',

    jobList: '/jobList',

    agents: '/agents',
    createAgent: '/create/agent',
    updateAgent: '/update/agent/',

    drivers: '/drivers',
    createDriver: '/create/driver',
    updateDriver: '/update/driver/',

    tsp: '/tsp',
    createTsp: '/create/tsp',
    updateTsp: '/update/tsp/',

    packers: '/packers',
    createPacker: '/create/packer',
    updatePacker: '/update/packer/',

    regions: '/regions',
    createRegion: '/create/regions',
    updateRegion: '/update/regions/',

    users: '/users',
    emailVerify: '/email/verify',
    forgotPassword: '/forgot/password',
    userProfile: '/user/profile',

    viewOffer: '/view/offer/',
    viewJob: '/view/job/',

    step1: '/step1',
    step2: '/step2',
    step3: '/step3',
    step4: '/step4',
    step5: '/step5',
    step6: '/step6',
    step7: '/step7',

    organizations: '/organizations',
    updateOrganizations: '/update/organizations/',
    updateUserOrganizations: '/user/organizations/',

    listing: '/lists',
    workFlow: '/workFlow',
    schedule: '/schedule',
};
