import React from 'react';
import { Form } from 'react-bootstrap';

import ErrorMsg from '@components/errorMsg';

import { IRadioButtonProps } from './switchRadioButton.interface';

const SwitchRadioButton = ({
    name,
    placeholder,
    errorText,
    label,
    classes,
    checked,
    disabled,
    classNameParentDiv,
    required,
    tabindex,
    autoFocus,
    onChangeFunc,
    statusOld,
    ...props
}: IRadioButtonProps) => (
    <>
        <Form.Group
            className="mfnw_offer_radio_btn"
            onClick={() => {
                (disabled === false || !disabled) &&
                    onChangeFunc(props?.value ?? '');
            }}
        >
            <Form.Control
                type="radio"
                id={props.id}
                name={name}
                value={props.value}
                className={`btn-check form-control`}
                onChange={onChangeFunc}
                required={required}
                checked={checked}
                disabled={disabled}
                tabIndex={tabindex}
            />
            <label
                className={`btn btn-outline-success btn-option ${classes} rounded-2`}
                htmlFor={props.id}
            >
                {label}
            </label>
        </Form.Group>
        <ErrorMsg errorText={errorText}></ErrorMsg>
        {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
    </>
);

SwitchRadioButton.defaultProps = {
    name: '',
    placeholder: '',
    errorText: '',
    inputType: '',
    label: '',
    id: '',
    labelcontainer: '',
    className: '',
    minLength: '',
    maxLength: '',
    value: '',
    classes: '',
    checked: '',
    disabled: '',
    required: '',
    onChangeFunc: () => void 0,
};

export default SwitchRadioButton;
