import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoaderBtn = ({ size }: any) => (
    <span className="d-flex align-items-center justify-content-center">
        <Spinner
            animation="border"
            role="status"
            size={size ?? 'sm'}
            className="ml-2"
        ></Spinner>
        <span className="ms-2">Loading...</span>
    </span>
);

export default LoaderBtn;
