import React from 'react';
import { Badge, Col, Dropdown, Row } from 'react-bootstrap';

import { pageLimitList } from '@constants/general.const';

const HistoryFilters = ({
    totalDataCount,
    handleLimitChange,
    handleFilterChange,
    handleOrderChange,
    filters,
    listTitleText,
    shipper,
}: any) => (
    <>
        <Row className="d-flex flex-wrap">
            {shipper && (
                <Col xs={12} md={6} lg={5} className="mb-3">
                    <div className="text-capitalize">
                        <b>Shipper Name:</b> {shipper}
                    </div>
                </Col>
            )}
        </Row>
        <Row className="mt-4 justify-content-between align-items-center mb-2">
            <Col xs={3} className="title_common_main align-items-center">
                <div className="d-flex">
                    <h5 className="fw-semibold text-sm my-auto text-end text-truncate">
                        {listTitleText}
                    </h5>
                    <Badge className="d-flex align-items-center my-auto mb-1 ms-2">
                        {totalDataCount}
                    </Badge>
                </div>
            </Col>
            <Col xs={9}>
                <div className="d-flex float-end">
                    <div className="dropdown_filter-top">
                        <label
                            className={`text-sm text-light ${
                                handleOrderChange
                                    ? 'lable_set_padding'
                                    : 'ps-3 '
                            }`}
                            htmlFor="dropdown-basic-order"
                        >
                            Order:
                        </label>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                className="order"
                            >
                                {filters?.orderValue}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    active={filters?.orderValue === 'ASC'}
                                    eventKey="#/action-1"
                                    onClick={() => {
                                        handleOrderChange('ASC');
                                    }}
                                >
                                    ASC
                                </Dropdown.Item>
                                <Dropdown.Item
                                    active={filters?.orderValue === 'DESC'}
                                    eventKey="#/action-2"
                                    onClick={() => {
                                        handleOrderChange('DESC');
                                    }}
                                >
                                    DESC
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="dropdown_filter-top">
                        <label
                            className="text-sm ps-3 text-light"
                            htmlFor="shipperInput"
                        >
                            Limit:
                        </label>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                                className="limit"
                            >
                                {filters?.perPageLimit}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {pageLimitList?.map(
                                    (item: any, index: number) => (
                                        <Dropdown.Item
                                            active={
                                                filters?.perPageLimit === item
                                            }
                                            onClick={() => {
                                                handleLimitChange(item);
                                            }}
                                            key={index}
                                            eventKey={`#/action-${index}`}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    ),
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Col>
        </Row>
    </>
);

export default HistoryFilters;
