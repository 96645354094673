import React from 'react';

import { Image, Row, Col } from 'react-bootstrap';

import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { pageLimitList } from '@constants/general.const';

import holidayNotFound from '@images/packer-not-found.svg';

import HolidaysAddPopup from '@app/SettingsLists/Holidays/holidaysAddPopup';
import ListHeader from '@app/SettingsLists/listHeader';
import HolidayTable from '@app/SettingsLists/Holidays/holidayTable';

import { useHolidayPopup } from './holidays.const';

const HolidayList = () => {
    const {
        showViewPopup,
        showEditPopup,
        handleViewShow,
        handleEditShow,
        handleClose,
        currentPageData,
        pageCount,
        handleViewClose,
        handleEditClose,
        filters,
        searchText,
        setSearchText,
        handleShow,
        handleLimitChange,
        isHolidaysList,
        isDeleteHoliday,
        showAlert,
        handlePageClick,
        showPopup,
        getFilters,
        getList,
        isDisabled,
        holidayData,
        setYearFilter,
        yearFilter,
    } = useHolidayPopup();

    return (
        <div>
            <ListHeader
                title={'Holiday List'}
                searchText={searchText}
                setSearchText={setSearchText}
                filters={filters}
                pageLimitList={pageLimitList}
                handleShow={handleShow}
                handleLimitChange={handleLimitChange}
                setYearFilter={setYearFilter}
                yearFilter={yearFilter}
                buttonText={'Add New Holiday'}
                year={true}
            />
            {isHolidaysList ? (
                <span className="ms-5">
                    <LoaderBtn size="sm" />
                </span>
            ) : !isDeleteHoliday &&
              currentPageData &&
              currentPageData.length !== 0 ? (
                <>
                    <section className="overflow-table-auto">
                        <HolidayTable
                            currentPageData={currentPageData}
                            handleViewShow={handleViewShow}
                            showAlert={showAlert}
                            handleEditShow={handleEditShow}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            filters={filters}
                        />
                    </section>
                </>
            ) : (
                <>
                    {currentPageData && currentPageData.length === 0 && (
                        <Row className="justify-content-center flex-column align-items-center">
                            <Col md={6} xl={3} className="text-center mt-5">
                                <Image src={holidayNotFound} fluid />
                                <h5 className="text-light mt-4 fw-bold">
                                    Let&rsquo;s get packing! Add at least one
                                    holiday.
                                </h5>
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {showPopup && (
                <HolidaysAddPopup
                    showPopup={showPopup}
                    handleClose={handleClose}
                    getDataListCall={() => getList(1, getFilters)}
                    isDisabled={isDisabled}
                    modelTitle={'Add New Holiday'}
                />
            )}

            {showEditPopup && holidayData && (
                <HolidaysAddPopup
                    showPopup={showEditPopup}
                    handleClose={handleEditClose}
                    getDataListCall={() => getList(1, getFilters)}
                    editData={holidayData}
                    isDisabled={isDisabled}
                    modelTitle={'Edit Holiday'}
                />
            )}

            {showViewPopup && (
                <HolidaysAddPopup
                    showPopup={showViewPopup}
                    handleClose={handleViewClose}
                    getDataListCall={() => getList(1, getFilters)}
                    editData={holidayData}
                    isDisabled={isDisabled}
                    modelTitle={'View Holiday'}
                    handleEditShow={handleEditShow}
                    showAlert={showAlert}
                />
            )}
        </div>
    );
};

export default HolidayList;
