import { cloneDeep } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import {
    addJobTitleData,
    deleteJobTitle,
    getJobTitleDataList,
} from '@app/CreateOrganization/step5/functions';

const defaultState = {
    isGetJobTitleDataList: false,
    isAddJobTitleData: false,
    isDeleteJobTitle: false,
    jobTitleData: {
        job_title: '',
    },
};

export const jobTitleStep5Slice: any = createSlice({
    name: 'jobTitleStep5Slice',
    initialState: cloneDeep(defaultState),
    reducers: {},
    extraReducers: {
        [addJobTitleData.pending.type]: (state: any) => {
            state.isAddJobTitleData = true;
        },
        [addJobTitleData.fulfilled.type]: (state: any) => {
            state.isAddJobTitleData = false;
        },
        [addJobTitleData.rejected.type]: (state: any) => {
            state.isAddJobTitleData = false;
        },

        [getJobTitleDataList.pending.type]: (state: any) => {
            state.isGetJobTitleDataList = true;
        },
        [getJobTitleDataList.fulfilled.type]: (state: any) => {
            state.isGetJobTitleDataList = false;
            if (state?.isDeleteJobTitle) {
                state.isDeleteJobTitle = false;
            }
        },
        [getJobTitleDataList.rejected.type]: (state: any) => {
            state.isGetJobTitleDataList = false;
            if (state?.isDeleteJobTitle) {
                state.isDeleteJobTitle = false;
            }
        },

        [deleteJobTitle.pending.type]: (state: any) => {
            state.isDeleteJobTitle = true;
        },
        [deleteJobTitle.fulfilled.type]: (state: any) => {
            state.isDeleteJobTitle = true;
        },
        [deleteJobTitle.rejected.type]: (state: any) => {
            state.isDeleteJobTitle = false;
        },
    },
});

export default jobTitleStep5Slice.reducer;
