import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactSelect from 'react-select';

import ErrorMsg from '@components/errorMsg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IReactSelectGroupProps } from './reactSelectGroup.interface';

const ReactSelectGroup = ({
    name,
    errorText,
    label,
    id,
    options,
    className,
    inputClassName,
    classNamePrefix,
    tabIndex,
    labelShow,
    isSearchable,
    placeholder,
    isDisabled,
    isMulti,
    tooltip,
    setBorderRadiusClass,
    value,
    isLoading,
    onInputChange,
    onChangeFunc,
    icon,
    ...props
}: IReactSelectGroupProps) => (
    <>
        <InputGroup>
            {labelShow && (
                <InputGroup.Text className={inputClassName || ''}>
                    {icon ? (
                        <>
                            <FontAwesomeIcon
                                icon={icon}
                                className="me-2"
                                size="xl"
                            />{' '}
                            {label}
                        </>
                    ) : (
                        label
                    )}
                </InputGroup.Text>
            )}

            <ReactSelect
                value={value}
                onChange={onChangeFunc}
                onInputChange={onInputChange}
                options={options}
                tabIndex={tabIndex}
                className={`w-100 ${className}`}
                classNamePrefix={
                    classNamePrefix
                        ? classNamePrefix
                        : `${setBorderRadiusClass} select_control_single`
                }
                placeholder={placeholder}
                isMulti={isMulti}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                {...props}
            />
        </InputGroup>
        {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
    </>
);

ReactSelectGroup.defaultProps = {
    name: '',
    errorText: '',
    label: '',
    tooltip: '',
    className: '',
    options: '',
    id: '',
};

export default ReactSelectGroup;
