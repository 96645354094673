import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiJobsList } from '@services/API/offerFunctions';
import {
    apiJobsCalenderList,
    apiExportJobsList,
} from '@services/API/offerFunctions';
import { apiJobsSITList } from '../../services/API/offerFunctions';

const jobsList = createAsyncThunk(
    'jobsList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiJobsList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
const jobsSITList = createAsyncThunk(
    'jobsSITList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiJobsSITList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

const jobsCalenderList = createAsyncThunk(
    'jobsCalenderList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiJobsCalenderList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
const exportJobList = createAsyncThunk(
    'exportJobList',
    async (payload: any, { rejectWithValue }) => {
        try {
            const data: any = await apiExportJobsList(payload);
            return data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export { jobsList, jobsSITList, jobsCalenderList, exportJobList };
