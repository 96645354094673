/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    Col,
    Form,
    ProgressBar,
    Card,
    Image,
    Accordion,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DropEvent, useDropzone } from 'react-dropzone';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
    cancelButtonColor,
    confirmButtonColor,
} from '@constants/general.const';
import { allowedFileExtensionsForSystemExtensions } from '@constants/general.const';

import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faEye, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePause, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IFilesArray } from '../../app/SettingsLists/Drivers/driver.interface';

const FilesUpload = ({
    heading,
    setFilesArray = null,
    setPreview,
    setValue,
    getValues,
    showProgressBar,
    progress,
    forValue,
    isRemove,
    tabIndex,
    isEditPopup,
}: any) => {
    const [isDragOverEvent, setIsDragOverEvent] = useState(false);

    const dropRejectHandler = () => {
        setFilesArray({ isValid: false, files: undefined });
        toast.error('Upload failed. Document type not supported.');
    };
    const dropAcceptHandler = (files: Array<File>, event: DropEvent) => {
        if (files) {
            setFilesArray((previousStatus: IFilesArray) => {
                if (previousStatus.isValid) {
                    return { ...previousStatus, files };
                }
                return previousStatus;
            });
        }
    };

    const dropDocumentHandler = () => {
        setIsDragOverEvent(false);
    };
    const dragOverHandler = () => {
        setIsDragOverEvent(true);
    };
    const dragLeaveHandler = () => {
        setIsDragOverEvent(false);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: dropDocumentHandler,
        onDragEnter: dragOverHandler,
        onDragLeave: dragLeaveHandler,
        onDropRejected: dropRejectHandler,
        onDropAccepted: dropAcceptHandler,
        accept: allowedFileExtensionsForSystemExtensions,
        multiple: true,
    });

    const removeImage = (id: number) => {
        Swal.fire({
            title: 'Warning',
            html:
                'Are you sure you want to remove this file? <br/> ' +
                ' You won&apos;t be able to retrieve it after removal.',
            allowOutsideClick: false,

            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: cancelButtonColor,
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then(async (result) => {
            if (result?.isConfirmed) {
                var tempVar;
                if (heading === `Driver's License`) {
                    tempVar =
                        typeof getValues('driver_license_pdf') === 'string'
                            ? JSON.parse(getValues('driver_license_pdf'))
                            : getValues('driver_license_pdf');
                } else if (heading === `Certifications`) {
                    tempVar =
                        typeof getValues('driver_certificate') === 'string'
                            ? JSON.parse(getValues('driver_certificate'))
                            : getValues('driver_certificate');
                } else if (heading === `Packer Certifications`) {
                    tempVar =
                        typeof getValues('packer_certificate') === 'string'
                            ? JSON.parse(getValues('packer_certificate'))
                            : getValues('packer_certificate');
                } else if (heading === `Document`) {
                    tempVar =
                        typeof getValues('document') === 'string'
                            ? JSON.parse(getValues('document'))
                            : getValues('document');
                }
                const newArray: any = [];

                tempVar?.map(async (uploadDocument: any, index: any) => {
                    if (index !== id) {
                        newArray[index] = uploadDocument;
                    }
                });
                setPreview(newArray.filter((item: any) => item !== null));
                if (heading === `Driver's License`) {
                    setValue(
                        'driver_license_pdf',
                        newArray.filter((item: any) => item !== null),
                    );
                } else if (heading === `Certifications`) {
                    setValue(
                        'driver_certificate',
                        newArray.filter((item: any) => item !== null),
                    );
                } else if (heading === `Packer Certifications`) {
                    setValue(
                        'packer_certificate',
                        newArray.filter((item: any) => item !== null),
                    );
                } else if (heading === `Document`) {
                    setValue(
                        'document',
                        newArray.filter((item: any) => item !== null),
                    );
                }
            }
        });
    };

    return (
        <>
            {isEditPopup && (
                <>
                    <h6 className="mt-5">UPLOAD DOCUMENTS</h6>
                    <div className="main-background" tabIndex={tabIndex}>
                        <div
                            className="rt-step5-cmn-upload-box text-center cursor-pointer fw-semibold w-100 add_more_contact_btn mt-4 ssecond-background"
                            {...getRootProps({
                                onClick: (evt) => evt.preventDefault(),
                            })}
                        >
                            <Form.Group controlId={'file'}>
                                <Form.Label className="mb-0">
                                    <h5 className="text-normal m-0">
                                        {heading}
                                    </h5>
                                    <div className="mt-4">
                                        <h6 className="text-light m-0 p-0">
                                            Drop your file’s here or{' '}
                                            <span className="text-primary m-0">
                                                browse
                                            </span>
                                        </h6>
                                        <h6 className="text-light" id="license">
                                            Max file size: 2MB
                                        </h6>
                                    </div>
                                </Form.Label>
                                <input
                                    className="form-control d-none"
                                    type={'file'}
                                    name={'file'}
                                    id="step2UploadPic"
                                    {...getInputProps()}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    {showProgressBar && (
                        <>
                            <div className="d-flex justify-content-between align-items-center pt-3">
                                <div>
                                    <h4 className="fw-semibold m-0 text-light">
                                        {heading}
                                    </h4>
                                    <p className="text-light">{progress}%</p>
                                </div>
                                <div className="d-flex gap-2">
                                    <FontAwesomeIcon
                                        icon={faCirclePause}
                                        size="lg"
                                        className="form-control-serch text-primary"
                                    />
                                    <FontAwesomeIcon
                                        icon={faCircleXmark}
                                        size="lg"
                                        className="form-control-serch text-danger"
                                    />
                                </div>
                            </div>
                            <ProgressBar now={progress} className="my-2" />
                        </>
                    )}
                </>
            )}
            {forValue && forValue.length > 0 ? (
                <>
                    <div className="file-view mt-4">
                        <h6>FILES</h6>
                    </div>
                    <Card className="card-addingborder my-4">
                        <Card.Body>
                            {forValue &&
                            forValue.length > 0 &&
                            typeof forValue !== 'string'
                                ? forValue.map((file: any, index: number) => (
                                      <>
                                          <Accordion key={index}>
                                              <Accordion.Item
                                                  eventKey={`file${index}`}
                                              >
                                                  <Accordion.Header>
                                                      <div className="d-flex justify-content-between mb-3 text-truncate">
                                                          <div className="image-info">
                                                              <h6>
                                                                  {file.name}
                                                              </h6>
                                                              <span className="p-0 m-0">
                                                                  {
                                                                      file.fileSize
                                                                  }
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                      <div className="d-flex justify-content-between p-3">
                                                          <NavLink
                                                              to={file?.url}
                                                              target="_blank"
                                                          >
                                                              <FontAwesomeIcon
                                                                  icon={faEye}
                                                                  size="lg"
                                                                  className="form-control-serch text-primary"
                                                              />
                                                              <span className="text-light px-2">
                                                                  View File
                                                              </span>
                                                          </NavLink>
                                                          {isRemove && (
                                                              <div>
                                                                  <span
                                                                      onClick={() =>
                                                                          removeImage(
                                                                              index,
                                                                          )
                                                                      }
                                                                  >
                                                                      <FontAwesomeIcon
                                                                          icon={
                                                                              faXmark
                                                                          }
                                                                          size="lg"
                                                                          className="form-control-serch1 text-secondary text-light"
                                                                      />
                                                                  </span>
                                                              </div>
                                                          )}
                                                      </div>
                                                      {file?.url
                                                          .split('.')
                                                          .pop()
                                                          .toLowerCase() ===
                                                      'pdf' ? (
                                                          <Col
                                                              xs={8}
                                                              md={12}
                                                              className="mb-5 mt-3 d-flex justify-content-center h-500 preview-bg-color py-3"
                                                          >
                                                              <iframe
                                                                  src={
                                                                      file?.url
                                                                  }
                                                                  className="w-75 h-90"
                                                                  title={`PDF Viewer`}
                                                              />
                                                          </Col>
                                                      ) : (
                                                          <Col
                                                              xs={8}
                                                              md={6}
                                                              className="mb-5 mt-3"
                                                          >
                                                              <Image
                                                                  src={
                                                                      file?.url ||
                                                                      faFileLines
                                                                  }
                                                                  width="420"
                                                                  height="350"
                                                              />
                                                          </Col>
                                                      )}
                                                  </Accordion.Body>
                                              </Accordion.Item>
                                          </Accordion>
                                      </>
                                  ))
                                : null}
                        </Card.Body>
                    </Card>
                </>
            ) : null}
        </>
    );
};

export default FilesUpload;
