import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';

import StepBar from '@components/StepBar/StepBar';
import ToastBodyContainer from '@components/ToastContainer';
import LoaderBtn from '@components/LoaderBtn/loaderBtn';

import { routesPath } from '@constants/routesNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/fontawesome-pro-regular';

import JobTitlePopupStep5 from '@app/CreateOrganization/step5/jobTitlePopupStep5';
import StepperActionBtn from '@app/CreateOrganization/stapperComponent/StepperActionBtn';
import StepperHeader from '@app/CreateOrganization/stapperComponent/stepperHeader';
import {
    JobTitleDeleteById,
    getJobTitleData,
} from '@app/CreateOrganization/step5/step5.const';
import StepBadgeComponent from '@app/CreateOrganization/StepBadgeComponent/StepBadgeComponent';

import '../createOrganization.scss';

const faArrowLeftLongIcon = faArrowLeft as IconProp;
const Step2: React.FC = () => {
    const dispatch = useDispatch<any>();

    const { jobTitleStep5Data } = useSelector((state: any) => state);
    const { isDeleteJobTitle } = jobTitleStep5Data;

    //  ask fore type

    const [show, setShow] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any[]>([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getData();
    }, []);

    const deleteById = async (officeId: string) => {
        JobTitleDeleteById(officeId, dispatch, getData);
    };

    const getData = async () => {
        getJobTitleData(dispatch, { nanoId: '' }, setDataList);
    };

    return (
        <section className="mfnw_create_organization mfnw_create_step5">
            <Container>
                <Row className="justify-content-center mt-3">
                    <Col md={12}>
                        <Link to="/">
                            <FontAwesomeIcon
                                icon={faArrowLeftLongIcon}
                                className="me-2"
                            />
                            Create New Organization
                        </Link>
                    </Col>
                    <Col md={12}>
                        <StepBar />
                    </Col>
                    <Col md={12}>
                        <div className="mt-2">
                            <Card className="border-none shadow-md">
                                <Card.Body className="p-4">
                                    <StepperHeader
                                        Tag={'h5'}
                                        handleShow={handleShow}
                                        btnText={'Add New Job Title'}
                                        titleText={'Organization Job Titles'}
                                    />
                                    <hr className="border-border-opacity-10" />
                                    <div className="d-flex justify-content-between flex-column min-vh-80">
                                        <Row className="mt-3 g-0">
                                            {isDeleteJobTitle ? (
                                                <LoaderBtn />
                                            ) : (
                                                <StepBadgeComponent
                                                    dataList={dataList}
                                                    deleteById={deleteById}
                                                    name={'job_title'}
                                                    nanoId={'job_nano_id'}
                                                    handleViewShow={false}
                                                    className="mnfw__badge_light bg-transparent lh-base text-dark py-2 fs-6 d-flex align-align-items-center border border-1 border-opacity-25"
                                                />
                                            )}
                                        </Row>
                                        <StepperActionBtn
                                            dataList={dataList}
                                            nextStep={routesPath?.step6}
                                            previousStep={routesPath?.step4}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <JobTitlePopupStep5
                show={show}
                getData={getData}
                handleClose={handleClose}
            />
            <ToastBodyContainer />
        </section>
    );
};

export default Step2;
