import React from 'react';
import { Card, Image } from 'react-bootstrap';

import BannerLogo from '@images/moveflow-logo.svg';
import BusinessLogo from '@images/banner-image.png';

const LeftSideBox = () => (
    <Card className="leftBox">
        <div className="bannerWrapper">
            <div className="logo-wrapper">
                <Image src={BannerLogo} />
            </div>
            <h4>
                Welcome to<span>MOVEFLOWS</span>
            </h4>
            <div className="logo-banner-wrapper d-none d-lg-block m-auto">
                <Image src={BusinessLogo} />
            </div>
            <span className="mfnw-hide-footer">MoveFlows © 2023</span>
        </div>
    </Card>
);
export default LeftSideBox;
